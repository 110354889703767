import * as services from '@api/energyWeb/charge';
import { fillDatasByType } from '@/utils/tool';

const defaultQuery = {
  p: '',
  m: moment(),
  t: 'month',
};

export default {
  namespace: 'chargeOverview',

  state: {
    cacheQuery: {
      ...defaultQuery,
    },

    cacheQueryMeasurePoint: {
      ...defaultQuery,
    },

    measurePoints: [],
    datasMeasurePoint: {},

    datasEnergyType: {
      500001: {
        data: {},
        graph: [],
      },
      500002: {
        data: {},
        graph: [],
      },
      500003: {
        data: {},
        graph: [],
      },
      500004: {
        data: {},
        graph: [],
      },
      500005: {
        data: {},
        graph: [],
      },
    },
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetchDatas ({ payload: { m } = {} }, { select, put, call, all }) {
      const stationId = yield select(state => state.app.saasStationId);
      const stationHash = yield select(state => state.station.hash);
      const stationItem = stationHash[stationId];
      const energyTypes = stationItem?.energyCategories || [];

      const cacheQuery = yield select(state => state.chargeOverview.cacheQuery);

      const query = {
        ...cacheQuery,
        ..._.pickBy({ m }, i => i !== undefined),
      };

      const res = yield all(energyTypes.map(i => call(services.queryOverviewDatasEnergyType, { stationId, energyType: i, ...query })));
      const datasEnergyType = res.reduce((acc, item, index) => {
        const [{ data, graph, start, end }] = item;
        const energyType = energyTypes[index];

        acc[energyType] = {
          data,
          graph: fillDatasByType('month', start, end)(graph),
        };

        return acc;
      }, {});

      yield put({
        type: 'update',
        payload: {
          cacheQuery: query,
          datasEnergyType,
        },
      });
    },

    *fetchInits (_, { put, take }) {
      yield put({ type: 'fetchStatDays' });
      yield take('fetchStatDays/@@end');

      yield put({ type: 'fetchMeasurePoints' });
      yield take('fetchMeasurePoints/@@end');
    },

    *updateQueryDate ({ payload: { m } }, { put }) {
      yield put({
        type: 'fetchDatasMeasurePoint',
        payload: {
          m,
        },
      });

      yield put({
        type: 'fetchDatas',
        payload: {
          m,
        },
      });
    },

    *fetchStatDays (_, { put, call, select }) {
      const stationId = yield select(state => state.app.saasStationId);
      const [fres] = yield call(services.queryOverviewStatDays, { stationId });
      const d = fres[0];
      const mn = moment();
      const cacheQuery = {
        ...defaultQuery,
        // m: (d && mn.date() > d) ? mn.add(1, 'months') : mn,
        m: (d && mn.date() > d) ? mn : mn, // 修改bug：1423 费用管理-费用统计
      };

      yield put({
        type: 'update',
        payload: {
          cacheQuery,
          cacheQueryMeasurePoint: cacheQuery,
        },
      });

      yield put({ type: 'fetchDatas' });
    },

    *fetchMeasurePoints (_, { put, call, select }) {
      yield put({
        type: 'update',
        payload: {
          measurePoints: [],
          datasMeasurePoint: {},
        },
      });

      const stationId = yield select(state => state.app.saasStationId);

      const [fres] = yield call(services.queryMeasurePoints, { stationId });

      yield put({
        type: 'update',
        payload: {
          measurePoints: fres,
        },
      });

      if (fres.length) {
        yield put({
          type: 'fetchDatasMeasurePoint',
          payload: {
            p: fres[0].key,
          },
        });
      }
    },

    *fetchDatasMeasurePoint ({ payload: { p, m } }, { put, call, select }) {
      yield put({
        type: 'update',
        payload: {
          datasMeasurePoint: {},
        },
      });

      const cacheQueryMeasurePoint = yield select(state => state.chargeOverview.cacheQueryMeasurePoint);

      const query = {
        ...cacheQueryMeasurePoint,
        ..._.pickBy({ p, m }, i => i !== undefined),
      };

      const [fres] = yield call(services.queryOverviewDatasMeasurePoint, { ...query });
      yield put({
        type: 'update',
        payload: {
          datasMeasurePoint: fres,
          cacheQueryMeasurePoint: query,
        },
      });
    },
  },

  subscriptions: {
  },
};
