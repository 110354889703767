import { getData } from '@services/request';

// 限额录入-添加产量产值
export async function addOutputValue (params) {
  const response = await getData('energyManagement', 'ylAddProduction', { ...params });
  return response;
}

// 根据节点id获取节点下的产量产值
export async function getOutputValueByNodeId (params) {
  const response = await getData('energyManagement', 'ylGetProductionByNodeId', { ...params });
  return response;
}

// 删除产量产值
export async function deleteOutputValue (params) {
  const response = await getData('energyManagement', 'ylDeleteProduction', { ...params });
  return response;
}

// 更新产量产值
export async function updateOutputValue (params) {
  const response = await getData('energyManagement', 'ylUpdateProduction', { ...params });
  return response;
}
