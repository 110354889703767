import { getDataParti } from "@/services/request";

/**
 * 查询左边菜单栏权限
 */
export const getRightList = getDataParti("applicationPlatform", "leftMenuList", (accountId, enterpriseId, type) => ({
  accountId,
  enterpriseId,
  type
}));
export const getRightAllow = getDataParti(
  "applicationPlatform",
  "queryAcccountChangeEnterpriseList",
  (accountId, enterpriseId) => ({ accountId, enterpriseId })
);

export const checkRole = getDataParti("applicationPlatform", "checkAdminRole", (accountId, enterpriseId) => ({
  accountId,
  enterpriseId,
}));

/**
 * 权限枚举表
 */
export const ACCESS_CODE = {
  /**
   * 能源总览
   */
  // VIEW_OVERVIEW: '62298f1f481824e1',
  /**
   * 能源监测
   */
  VIEW_ENERGYMONITOR: "62298f1f4b06e061",
  /**
   * 一次图
   */
  VIEW_GRAPH: "62298f1f4d7d1081",
  /**
   * 接线图
   */
  VIEW_GRAPH_ELECTRIC: "62298f1f4dcdefa1",
  /**
   * 管网图
   */
  VIEW_GRAPH_PIPE: "62298f1f4dcdefa2",
  /**
   * 用能数据
   */
  VIEW_DATUM: "62298f1f4d7e9720",
  /**
   * 用电数据
   */
  VIEW_DATUM_ELECTRIC: "62298f1f4e24e941",
  /**
   * 用水数据
   */
  VIEW_DATUM_WATER: "62298f1f4e266fe0",
  /**
   * 燃气数据
   */
  VIEW_DATUM_GAS: "62298f6ef7b1d001",
  /**
   * 空调数据
   */
  VIEW_EQUIPMENT_AIR_CONDITIONER: "62298f1e4d7e9624",
  /**
   * 路灯数据
   */
  VIEW_EQUIPMENT_STREET_LAMP: "62298f1e4d7e9625",

  /**
   * 能源分析
   */
  VIEW_ENERGYANALYZE: "62298f1f4b34a721",
  /**
   * 用能分析
   */
  VIEW_ENERGY_ANALYZE: "62298f1f4e75c861",
  /**
   * 能量平衡
   */
  VIEW_ENERGY_BALANCE: "62298f1f4eeccd21",
  /**
   * 趋势分析
   */
  VIEW_ENERGY_TENDENCY: "62298f1f4eee53c0",
  /**
   * 光伏运行分析
   */
  VIEW_ENERGY_PHOTOVOLTAIC: "62298f1f4eee53c1",
  /**
   * 储能运行分析
   */
  VIEW_ENERGY_ENERGYSTORAGE: "62298f1f4eee53c2",

  /**
   * 能效分析
   */
  VIEW_ENERGY_EFFICIENCY_ANALYZE: "62298f6ef84efa61",
  /**
   * 能效排名
   */
  VIEW_ENERGY_EFFICIENCY_RANK: "62298f6ef9d10681",
  /**
   * 分户计量
   */
  HOUSEHOLD_METERING_PARENT: "6229905654207341",
  /**
   * 分户计量-分户管理
   */
  HOUSEHOLD_MANAGEMENT: "622990565503ce61",
  /**
   * 分户计量-分户计量
   */
  HOUSEHOLD_METERING: "622990565503ce62",
  /**
   * 分户计量-用量明细
   */
  DOSAGE_DETAIL: "622990565503ce63",

  /**
   * 费用分析
   */
  VIEW_COSTANALYSIS: "62298f1f4b5accc1",
  /**
   * 费用总览
   */
  VIEW_COST_ANALYSIS_VIEW: "62298f1f4f592381",
  /**
   * 能源支出
   */
  VIEW_COST_ANALYSIS_PAY: "62298f1f4f592382",
  /**
   * 电费分析
   */
  VIEW_COST_ANALYSIS_PAY_ELECTRIC: "62298f1f4fb7be41",
  /**
   * 水费分析
   */
  VIEW_COST_ANALYSIS_PAY_WATER: "62298f1f4fb7be42",
  /**
   * 燃气费分析
   */
  VIEW_COST_ANALYSIS_PAY_GAS: "62298f1f4fb7be43",
  /**
   * 能源收益
   */
  VIEW_COST_ANALYSIS_PROFIT: "62298f1f4f592383",
  /**
   * 光伏收益分析
   */
  VIEW_COST_ANALYSIS_PROFIT_PV: "62298f1f500eb7e1",
  /**
   * 储能收益分析
   */
  VIEW_COST_ANALYSIS_PROFIT_BESS: "62298f1f500eb7e2",

  /**
   * 能源管控
   */
  VIEW_ENERGYCONTROL: "62298f1f4b858641",
  /**
   * 储能管控
   */
  VIEW_ENERGY_CONTROL_BESS: "62298f1f5082af61",
  /**
   * 空调管控
   */
  VIEW_ENERGY_CONTROL_AIR: "62298f1f5082af62",
  /**
   * 总控策略
   */
  VIEW_ENERGY_CONTROL_AIRCONTROL_TOTAL: "62298f6f00735421",
  /**
   * 空调控制
   */
  VIEW_ENERGY_CONTROL_AIRCONTROL: "62298f2300738cc1",
  /**
   * 空调管控记录
   */
  VIEW_ENERGY_CONTROL_AIRCONTROL_RECORD: "62298f2300738ca1",
  /**
   * 路灯管控
   */
  VIEW_ENERGY_CONTROL_STREETLAMP: "62298f6efb8b87c1",
  /**
   * 路灯控制
   */
  VIEW_ENERGY_CONTROL_STREETLAMP_CONTROL: "62298f6efd216a01",
  /**
   * 路灯管控记录
   */
  VIEW_ENERGY_CONTROL_STREETLAMP_CONTROL_RECORD: "62298f6efd216a02",
  /**
   * 空开管控
   */
  VIEW_ENERGY_CONTROL_AIRSWITCH: "62298ff1c82a36a1",
  /**
   * 空开控制
   */
  VIEW_ENERGY_CONTROL_AIRSWITCH_CONTROL: "62298ff200fae421",
  /**
   * 空开管控记录
   */
  VIEW_ENERGY_CONTROL_AIRSWITCH_CONTROL_RECORD: "62298ff200fae422",
  /**
   * 柔性负荷管理
   */
  VIEW_ENERGY_CONTROL_FLEXIBLELOADMNG: "62299003d3063121",
  /**
   * 地下照明管控
   */
  UNDERGROUND_LIGHTING_CONTROL_PARENT: "62299056561e1801",
  /**
   * 地下照明控制
   */
  UNDERGROUND_LIGHTING_CONTROL: "62299056569fcb21",
  /**
   * 管控记录
   */
  UNDERGROUND_LIGHTING_RECORD: "62299056569fcb21",

  /**
   * 报表中心
   */
  VIEW_REPORTCENTER: "62298f1f4baa2541",
  /**
   * 报表导出
   */
  VIEW_REPORTCENTER_EXPORT: "62299003d44cbae1",
  /**
   * 报表导入
   */
  VIEW_REPORTCENTER_IMPORT: "62299003d44cbae2",

  /**
   * 事件中心（一级）
   */
  VIEW_EVENT: "62298f1f4bc72321",
  /**
   * 异常统计
   */
  VIEW_EVENT_EXCEPTION_STATISTICS: "62298f1f50e2d0c1",
  /**
   * 异常中心
   */
  VIEW_EVENT_EXCEPTION_CENTER: "62298f1f50e2d0c2",
  /**
   * 事件中心
   */
  VIEW_EVENT_EVENTCENTER: "62298f1f50e2d0c3",

  /**
   * 应急保障
   */
  VIEW_EMERGENCY_SECURITY: "62298f6ef66fda2",
  /**
   * 智慧安防
   */
  VIEW_SECURITY: "62298f6efdac44e1",
  /**
   * 安防总览
   */
  VIEW_SECURITY_OVERVIEW: "62298f6efed13ce1",
  /**
   * 道闸记录
   */
  VIEW_SECURITY_BRAKE_RECORD: "62299056975c5d41",
  /**
   * 视频实况
   */
  VIEW_SECURITY_CAMERA: "62298f6efed13ce2",
  /**
   * 智慧消防
   */
  VIEW_FIRECONTROL: "62298f6efdadcb80",
  /**
   * 消防总览
   */
  VIEW_FIRECONTROL_OVERVIEW: "62298f6eff3d9341",
  /**
   * 消防水压监控
   */
  VIEW_FIRECONTROL_WATERPRESSURE: "62298f6eff3d9342",
  /**
   * 大屏
   */
  VIEW_BIGSCREEN: "62298f1f4c1fa361",
  /**
   * 平台总览
   */
  VIEW_BIGSCREEN_OVERVIEW: "62298f1f51fea101",
  /**
   * 能源总览
   */
  VIEW_BIGSCREEN_ENERGY: "62298f1f51fea102",
  /**
   * 能流总览
   */
  VIEW_BIGSCREEN_CURRENT: "62298f1f51fea103",
  /**
   * 空调总览
   */
  VIEW_BIGSCREEN_AIR: "62298f1f51fea104",
  /**
   * 光储总览
   */
  VIEW_BIGSCREEN_PV_BESS: "62298f1f51fea105",
};
export const NEW_ACCESS_CODE = {
  // 1总览
  VIEW_ZX_STATION: '62219653b11cb1f0',
  // 1-1场站中心
  VIEW_ZX_STATION_CENTER: '62219653b11cc1f0',
  // 1-1-1场站总览
  VIEW_ZX_STATIONO_VERVIEW: '62219653b11cc2f0',

  //2新能源发电
  VIEW_ZX_ENERGY: '62219653b11cb1f1',
  // 2-1光伏监测
  VIEW_ZX_ENERGY_MONITOR: '62219653b11cc1f1',
  // 2-1-1光伏总览
  VIEW_ZX_ENERGY_OVERVIEW: '62219653b11cc2f1',
  // 2-1-2发电分析
  VIEW_ZX_ENERGY_GENERATEANALYSE: '62219653b11cc3f1',

  // 发电概况
  VIEW_ZX_ENERGY_FDGK: '62219653b11e0006',

  // 结算管理
  VIEW_ZX_ENERGY_SETTLEMENTMANAGEMENT: '62219653b11cc1a0',

  // VIEW_ZX_ENERGY_SETTLEMENTMANAGEMENT:'62219653b11cc4f1',

  // 统计报表
  VIEW_ZX_ENERGY_STATICREPORT: '62219653b11e0005',

  // 2-1-3 设备监测
  VIEW_ZX_ENERGY_EQUIPMENTMONITOR: '62219653b11cc4f1',
  // 2-1-4 项目台账
  VIEW_ZX_ENERGY_PROJECTLEDGER: '62219653b11cc6f1',
  // 2-1-5 异常管理
  VIEW_ZX_ENERGY_EXCEPTIONMANAGEMENT: '62219653b11cc5f1',



  //3储能
  VIEW_ZX_STORAGE: '62219653b11cb1f2',
  // 3-1电化学储能
  VIEW_ZX_STORAGE_ELECTROCHEMISTRY: '62219653b11cc1f2',
  // 3-1-1监测大厅
  VIEW_ZX_STORAGE_OVERVIEW: '62219653b11cc2f2',
  // 3-1-2运行管理
  VIEW_ZX_STORAGE_OPOPERATEMANAGE: '62219653b11cc3f2',

  // 3-1-3设备监测
  VIEW_ZX_STORAGE_EQUIPMENTMONITOR: '62219653b11cc4f2',
  // 3-1-4策略管理
  VIEW_ZX_STORAGE_POLICYMANA: '62219653b11cc4f3',
  // 3-1-5项目台账
  VIEW_ZX_STORAGE_PROJECTLEDGER: '62219653b11cc4f4',
  // 3-1-6异常管理
  VIEW_ZX_STORAGE_EXCEPTIONMANAGEMENT: '62219653b11cc4f5',
  //  3-1-6ems管理
  VIEW_ZX_STORAGE_EMSMANAGE: '62319653b25cb1f9',

  //4充换电
  VIEW_ZX_CHARGEREPLACE: '62219653b11cb1f3',
  // 4-1充电管理中心
  VIEW_ZX_CHARGEREPLACE_MONITOR: '62219653b11cc1f3',
  // 4-1-1监测大厅
  VIEW_ZX_CHARGEREPLACE_OVERVIEW: '62219653b11cc2f3',
  // 4-1-2充电趋势
  VIEW_ZX_CHARGEREPLACE_TREND: '62219653b11cc3f3',


  // 4-1-3设备监测
  VIEW_JHD_ENERGY_EQUIPMENTMONITOR: '62219653b11cc3f4',
  // 4-1-项目台账
  VIEW_JHD_ENERGY_PROJECTLEDGER: '62219653b11cc3f5',


  //5售电
  VIEW_ZX_SELLELECTRICITY: '62219653b11cb1f4',
  // 5-2中长期交易
  VIEW_ZX_SELLELECTRICITY_TRADE: '62219653b11cc1f7',
  // 5-2-1市场信息
  VIEW_ZX_SELLELECTRICITY_TRADE_MARKETINFO: '62219653b11cc1f8',
  // 5-2-2在线监测
  VIEW_ZX_SELLELECTRICITY_TRADE_ONLINEMONITOR: '62219653b11cc1f9',

  //6碳资产
  VIEW_ZX_CARBONASSETS: '62219653b11cb1f5',


  //7运维管理
  VIEW_ZX_YUNWEIMANAGE: '62219653b11cb1f6',
  // 7-1我的场站
  VIEW_ZX_YUNWEIMANAGE_MY: '62219653b11cd3f3',

  // 8能源站
  VIEW_ZX_ENERGYSTATION: '62219653b11cb1f7',
  // 8-1设备监控
  VIEW_ZX_ENERGYSTATION_MONITOR: '62219653b12cb1f7',
  // 8-2设备清单
  VIEW_ZX_ENERGYSTATION_LIST: '62219653b13cb1f7',
  // 8-3统计分析
  VIEW_ZX_ENERGYSTATION_ANALYSE: '62219653b14cb1f7',

  // zx前往大屏
  VIEW_ZX_QWDP: "62219653b11cb2e1",

  // zx系统设置
  VIEW_ZX_XTSZ: '62219653e9c73ac2',
  // zx账号管理
  VIEW_ZX_ZHGL: '62219653eacdae51',
  // zx角色管理
  VIEW_ZX_JSGL: '62219653eacdae52',
  // zx日志管理
  VIEW_ZX_RZGL: '62219653eacdae53',

  /**
   * 集团总览
   */
  VIEW_COMPANY: "62219653b11cb1e1",
  /**
   * 场站总览
   */
  VIEW_STATION: "62219653b11cb1e2",
  /**
   * 光伏概览
   */
  VIEW_GUANGFU: "62219653b11cb1e3",
  /**
   * 储能概览
   */
  VIEW_CHUNENG: "62219653b11cb1e4",
  /**
   * 充电桩概览
   */
  VIEW_DIANZHUANG: "62219653b11cb1e5",
  /**
   * 资产总览
   */
  VIEW_ZICHAN: "62219653b11cb1e6",
  /**
   * 运营报告
   */
  VIEW_YUNYING: "62219653b11cb1e7",

  /**
   * 
   * ========================================================================================
   * 
   * */
  //   能源站  62319653b11cb1f7
  // 设备监控   62319653b12cb1f7
  // 设备清单   62319653b13cb1f7
  // 统计分析  62319653b14cb1f7


  VIEW_ENERGYSTATION: '62319653b11cb1f7',
  VIEW_ENERGYSTATION_MONITOR: '62319653b12cb1f7',
  VIEW_ENERGYSTATION_LIST: '62319653b13cb1f7',
  VIEW_ENERGYSTATION_ANAYLSE: '62319653b14cb1f7',

  /**
   * 前往大屏
   */
  VIEW_QWDP: "62219654b11cb2e1",
  /**
   * 能源总览
   */
  VIEW_NYZL: "62219653b11cb2e2",
  VIEW_NYZL_ZL: "62219653b11cb3e2",
  /**
   * 能源生产
   */
  VIEW_NYSC: "62219653b11cb2e3",
  /**
   * 用能负荷
   */

  VIEW_YNFH: "62219653b11cb2e4",
  VIEW_NYTJ: "62219653b11e0011",
  VIEW_NHTJ: "62219653b11e0012",
  VIEW_NXTJ: "62219653b11e0013",
  VIEW_SBGL: '62219653b11e0014',

  VIEW_YNPZ: '62219653b11e0015',
  VIEW_FLGL: '62219653b11e0016',
  VIEW_JZGL: '62219653b11e0017',
  VIEW_CLCZLR: '62219653b11e0018',

  /**
   * 柔性储能
   */
  VIEW_RXCN: "62219653b11cb2e5",
  /**
   * 能源中控
   */
  VIEW_NYZK: "62219653b11cb2e6",
  // 储能智控
  VIEW_NYZKCNZK: "62219653b11e0007",
  // 运行策略
  VIEW_NYZKYXCL: "62219653b11e0009",
  // 电价信息
  VIEW_NYZKDJXX: "62219653b11e0010",

  /**
   * 量费统计
   */
  VIEW_LFTJ: "62219653b11cb2e7",
  /**
   * 资产管理
   */
  VIEW_ZCGL: "62219653b11cb2e8",
  /**
   * 增值业务
   */
  VIEW_ZZYW: "62219653b11cb2e9",
  /**
   * 运维维护
   */
  VIEW_YWWH: "62219653b11cb2ea",
  /**
   * 光伏
   */
  VIEW_GF: "62219653b20f5041",
  /**
   * 电站监测（能源生产）
   */
  VIEW_GFDZJZ: "62219653b20f5042",
  /**
  * 统计分析 (能源生产)
  */
  VIEW_TJFX: "62219653b20f5046",
  /**
   * 发电分析（能源生产）
   */
  VIEW_GFFDFX: "62219653b20f5047",
  /**
   * 收益分析（能源生产）
   */
  // VIEW_GFSYFX: "62219653b20f5048",
  VIEW_SYFX: "62219653b20f5048",

  VIEW_TJBB: '62219653b11e0004',
  VIEW_SBJC: '62219653b11e0003',
  /**
   * 异常监测（能源生产）
   */
  VIEW_GFYCJC: "62219653b20f5043",
  /**
   * 告警管理（能源生产）
   */
  VIEW_GFGJGL: "62219653b20f5044",
  /**
   * 运行监测
   */
  VIEW_YXJC: "62219653b2a4d982",
  /**
   * 异常监测
   */
  VIEW_YCJC: "62219653b3080821",
  /**
   * 电站监测
   */
  VIEW_GFDZJC: "62219653b3080822",
  /**
   * 能源监测
   */
  VIEW_NYJC: "62219653b38b41e1",
  /**
   * 能源分析
   */
  VIEW_NYFX: "62219653b38b41e2",
  /**
   * 用户数据
   */
  VIEW_YHSJ: "62219653b4130f81",
  /**
   * 用电数据
   */
  VIEW_YDSJ: "62219653b4827321",
  /**
   * 用水数据
   */
  VIEW_YSSJ: "62219653b4827322",
  /**
   * 燃气数据
   */
  VIEW_RQSJ: "62219653b4827323",
  /**
   * 用能分析
   */
  VIEW_YNFX: "62219653b508ba21",
  /**
   * 能效分析
   */
  VIEW_NXFX: "62219653b508ba22",
  /**
   * 能量平衡
   */
  VIEW_NLPH: "62219653b5b9baa1",
  /**
   * 趋势分析
   */
  VIEW_QSFX: "62219653b5b9baa2",
  /**
   * 储能运行分析
   */
  VIEW_CNYXFX: "62219653b5b9baa3",
  /**
   * 能效排名
   */
  VIEW_NXPM: "62219653b60f2da1",
  /**
   * 电池储能
   */
  VIEW_DCCN: "62219653b670d5a1",
  /**
 * 电站总览
 */
  VIEW_DZZL: "62219653b20f6051",
  /**
  * 数据监测
  */
  VIEW_SJJC: "62219653b20f6052",
  /**
  * 运行参数
  */
  VIEW_YXCS: "62219653b20f6053",
  /**
 * 动环系统
 */
  VIEW_DHXT: "62219653b20f6054",
  /**
* 运动分析
*/
  VIEW_YDFX: "62219653b20f5052",
  /**
* 运动-收益分析
*/
  VIEW_YDSTFX: "62219653b20f5054",
  /**
* 运动-优化分析
*/
  VIEW_YDYHFX: "62219653b20f5055",
  /**
   * 监测大厅
   */
  VIEW_JCDT: "62219653b7311861",
  /**
   * 电站监测
   */
  VIEW_DZJC: "62219653b7311862",
  /**
   * 告警管理
   */
  VIEW_GJGL: "62219653b7311863",
  /**
   * 数据分析
   */
  VIEW_SJFX: "62219653b7311864",
  /**
   * 量费总览
   */
  VIEW_LFGL: "62219653b7311865",
  // 量费总览
  VIEW_LFZL: "62219653b7311954",
  // 量费分析
  VIEW_LFFX: "62219653b7311955",
  // 收益分析
  VIEW_RXCNSYFX: "62219653b20f5059",
  //数据分析
  VIEW_RXCNSJFX: "62219653b20f5060",
  // 策略运行分析
  VIEW_CLRXCNSJFX: "62219653b20f5061",

  /**
   * 报表中心
   */
  VIEW_BBZX: "62219653b7311866",
  /**
   * 告警记录
   */
  VIEW_GJJL: "62219653b8ce9bc1",
  /**
   * 柔性负荷管理
   */
  VIEW_RXFHGL: "62219653b98d57e1",
  /**
   * 充电桩管控
   */
  VIEW_CDZGK: "62219653ba0769e1",
  /**
   * 储能智控
   */
  VIEW_CNZK: "62219653ba0769e5",
  /**
   * 充电趋势分析
   */
  VIEW_CDZQSFX: "62219653ba0769e5",
  /**
   * 充电桩控制
   */
  VIEW_CDZKZ: "62219653ba0769e6",
  /**
   * 空调管控
   */
  VIEW_KTGK: "62219653ba0769e2",
  /**
   * 路灯管控
   */
  VIEW_LDGK: "62219653ba0769e3",
  /**
   * 空开管控
   */
  VIEW_KKGK: "62219653ba0769e4",
  /**
   * 总控策略
   */
  VIEW_ZKCL: "62219653baaf42a1",
  /**
  * 无感调节
  */
  VIEW_WGTJ: "62219653b11cb2f6",
  /**
   * 空调控制
   */
  VIEW_KTKZ: "62219653baaf42a2",
  /**
   * 管控记录（空调）
   */
  VIEW_KTGKJL: "62219653baaf42a3",
  /**
   * 路灯控制
   */
  VIEW_LDKZ: "62219653bbb5b621",
  /**
   * 管控记录（路灯）
   */
  VIEW_LDGKJL: "62219653bbb5b622",
  /**
   * 空开控制
   */
  VIEW_KKKZ: "62219653bc050ea1",
  /**
   * 管控记录（空开）
   */
  VIEW_KKGKJL: "62219653bc050ea2",
  /**
   * 费用分析
   */
  VIEW_FYFX: "62219653bcfdc681",
  /**
   * 费用总览
   */
  VIEW_FYZL: "62219653bd7df301",
  /**
   * 能源支出
   */
  VIEW_NYZC: "62219653bd7df302",
  /**
   * 能源收益
   */
  VIEW_NYSY: "62219653bd7df303",
  /**
   * 电费统计
   */
  VIEW_DFTJ: "62219653be3b2881",

  /**
   * 电费分析
   */
  VIEW_DFFX: '62219653b11e0019',

  /**
   * 水费分析
   */
  VIEW_SFFX: "62219653be3b2882",
  /**
   * 燃气费分析
   */
  VIEW_RQFFX: "62219653be3b2883",
  /**
   * 光伏收益分析  后面新加的（和能源生产的收益分析冲突了）
   */
  VIEW_GFSYFX: "62219653be9e5721",
  /**
   * 储能收益分析
   */
  VIEW_CNSYFX: "62219653be9fddc0",
  /**
   * 负荷设备
   */
  VIEW_FHSB: "62219653e32e14e1",
  /**
   * 数据设备
   */
  VIEW_SJSB: "62219653e3df1561",
  /**
   * 空调数据
   */
  VIEW_KTSJ: "62219653e5429d01",
  /**
   * 路灯数据
   */
  VIEW_LDSJ: "62219653e5429d02",
  /**
   * 台账管理光伏
   */

  VIEW_GFZS: "62219653e5429d03",

  // 售电
  VIEW_ZZYWSD: "62219653b11e0001",
  /**
   * 售电分析
   */
  VIEW_SDFX: "62219653e5b200a1",
  // 统计分析
  VIEW_CZTTJFX: "62219653b11e0002",
  /**
   * 需求响应
   */
  VIEW_XQXY: "62219653e5b200a2",
  /**
   * 碳交易
   */
  VIEW_TJY: "62219653e5b200a3",
  /**
   * 事件中心
   */
  VIEW_SJZX: "62219653e67fff01",
  /**
   * 应急保障
   */
  VIEW_YJBZ: "62219653e67fff02",
  /**
   * 异常统计
   */
  VIEW_YCTJ: "62219653e707cca1",
  /**
   * 异常中心
   */
  VIEW_YCZX: "62219653e7095340",
  /**
   * 事件中心（子菜单）
   */
  VIEW_ZSJZX: "62219653e7095341",
  /**
   * 智慧安防
   */
  VIEW_ZHAF: "62219653e764e0c1",
  /**
   * 智慧消防
   */
  VIEW_ZHXF: "62219653e764e0c2",
  /**
   * 视频实况
   */
  VIEW_SPSK: "62219653e7c688c1",
  /**
   * 消防总览
   */
  VIEW_XFZL: "62219653e86cdae1",
  /**
   * 消防水压控制
   */
  VIEW_XFSYKZ: "62219653e86cdae2",
  /**
   * 系统设置
   */
  VIEW_XTSZ: "62219653e9c73ac1",
  /**
   * 账号管理
   */
  VIEW_ZHGL: "62219653eacdae41",
  /**
   * 角色管理
   */
  VIEW_JSGL: "62219653eacdae42",
  /**
   * 日志管理
   */
  VIEW_RZGL: "62219653eacdae43",

  /**
   * 输配网络
   */
  VIEW_SPWL: "62219657a4df0381",
  /**
   * 一次图
   */
  VIEW_YCT: "62219657a5992bc1",
  /**
   * 接线图
   */
  VIEW_JXT: "62219657a60274e1",
  /**
   * 网管图
   */
  VIEW_WGT: "62219657a603fb80",
  /**
  * 月度报告
  */
  VIEW_YDBG: "62219653b11cb2ec",
  /**
  * 账单管理
  */
  VIEW_ZDGL: "62219653b11cb2ed",
  /**
  * 报告中心
  */
  VIEW_BGZX: "62219653b11cb2eb",
  // 设备监控
  VIEW_SBJK: "62219653ba0769e8",
  // 定时任务
  VIEW_DSRW: "62219653ba0769e9",
  // 报警监控
  VIEW_BJJK: "62219653ba0769f0",
  // 运行分析
  VIEW_YXFX: "62219653b20f5052",
  // 策略分析
  VIEW_CLFX: "62219653b20f5053",
  // 策略电价策略
  VIEW_CLDJFX: "62219653b20f5057",
  // 策略充放电策略
  VIEW_CLCFDFX: "62219653b20f5058",
};
