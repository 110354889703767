/**
 * 单个运维商的站点信息库
 */
import moment from 'moment';
import * as assetsService from '@stroageopenapi/assets';
// import * as queryLatAndLngService from '@services/queryLatAndLng';
// import Lo from 'lodash';

const
  YDLX_ENUM = {
    100: '大工业用电',
    101: '大工业中小化肥',
    102: '大工业其它优待',
    200: '居民生活用电',
    201: '乡村居民生活用电',
    202: '城镇居民生活用电',
    203: '中小学教学用电',
    300: '农业生产用电',
    301: '农业排灌',
    302: '贫困县农业排灌用电',
    400: '一般工商业',
    401: '非居民照明',
    402: '非工业',
    403: '普通工业',
    404: '普通工业中小化肥',
    405: '商业用电',
    500: '趸售',
    501: '趸售大工业',
    502: '趸售普工、非普工业',
    503: '趸售非居民',
    504: '趸售居民生活用电',
    505: '趸售农业生产用电',
    506: '趸售商业用电',
    900: '其它用电',
    901: '大用户直购电',
    902: '抽水蓄能',
    903: '售邻省',
    904: '其他',
  };

const itemMap = stObj => ({
  ...stObj,
  // 站点ID
  id: `${stObj.stationId}`,
  // 站点code
  // stationCode: stObj.stationCode,
  // 站点名称
  name: stObj.stationName || '',
  // 用电客户简称
  shortName: stObj.shortName || stObj.stationName || '',
  // 纬度
  lat: +stObj.latitude,
  // 经度
  lon: +stObj.longitude,
  // 主变台数 属性丢失
  // totalTransformerCount: stObj.gatewayTransformerNumber,
  // 主变总容量 属性丢失
  // totalTransformerCapacity: stObj.runCapacity,
  // 合同容量
  // contractCapacity: stObj.contractCapacity,
  // 省编码
  provinceCode: stObj.provinceCode,
  // 市编码
  cityCode: stObj.cityCode,
  // 完整地址
  address: stObj.address,
  // 用电类型
  ydType: YDLX_ENUM[stObj.electricCategory],
  // 工程接入阶段
  projectAccessStage: stObj.projectAccessStage || '5',
  // 服务商ID
  serviceProviderIds: stObj.serviceProviderIds.split(','),
  // 服务商Code
  // serviceCode: stObj.serviceProviderCode,
  //
  enterpriseId: stObj.enterpriseId,
  //
  // customerCode: stObj.comCode,
  // 是否可见（过滤选项），默认true
  visible: true,
  // 编号
  // code: stObj.code,
  // 接入时间
  accessDate: stObj.establishTime,
  // 所属服务商是否有监测业务
  isMonitoringStations: stObj.isMonitoringStations,
  // 所属服务商是否有运维业务
  isOperationStations: stObj.isOperationStations,
});
// 站点key为id的对象
const listToHash = (list, key = 'id') => {
  const hash = {};
  list.forEach((obj) => { hash[obj[key]] = obj; hash[obj.stationCode] = obj; });
  return hash;
};
// 站点key为name的对象
const listToHashName = (list) => {
  const hash = {};
  list.forEach((obj) => { hash[obj.name] = obj; });
  return hash;
};
// 站点id数组
const listToHashId = (list) => {
  const selfIdList = [];
  list.forEach((it, i) => {
    selfIdList[i] = it.id;
  });
  return selfIdList;
};

// const SUB_FLAG = false;

export default {
  namespace: 'stations',
  state: {
    list: [],
    totalList: [],
    idList: [],
    byId: {},
    byName: {},
  },
  reducers: {
    update (state, { payload: { list, totalList } }) {
      const byId = listToHash(totalList);
      return {
        list, // 只有直属用电客户
        totalList, // 包含下级服务商的用电客户
        byId,
        idList: listToHashId(list),
        byName: listToHashName(list),
      };
    },
    clear () {
      return { list: [], byId: {}, byName: {} };
    },
    filterVisibleStations (state, { payload: list }) {
      state.list.forEach((item) => {
        item.visible = false;
      });
      list.forEach((item) => {
        state.list[item.id].visible = true;
      });
      return { ...state };
    },
  },
  effects: {
    fetch: [
      function* fetch ({ payload: { facilitatorIds } }, { call, put, select }) {
        if (!facilitatorIds) {
          return;
        }
        const isStation = yield select(state => state.user.isStation);

        const { list: facilitatorsList } = yield select(state => state.facilitators);

        const customerId = yield select(state => state.user.serviceProvidorId);
        console.warn(`[${moment().format('HH:mm:ss SSS')}]try to update model: stations`);
        // 获取stationList
        const { dataList: list, code } = yield call(
          isStation ? assetsService.fetchStationsByEnterpriseId : assetsService.fetchStationsBySPIds,
          isStation ? { enterpriseId: facilitatorIds[0] } : { serviceProviderIds: facilitatorIds },
        );
        if (code) {
          console.error(`Fetch stationList failed,code:${code}`);
        }
        // const dataList = list || [];

        // const filterByMonitoringStations = filterByMonitoringBusiness.map(({ trusteeshipCustomerId }) => {
        //   return dataList.filter(item => trusteeshipCustomerId === item.serviceProviderId);
        // });
        // const filterByMonitoringStations = dataList.reduce((arr, item) => {
        //   filterByMonitoringBusiness.forEach(({ trusteeshipCustomerId }) => {
        //     if (trusteeshipCustomerId === item.serviceProviderId) {
        //       arr.push(item);
        //     }
        //   });
        //   return arr;
        // }, []);
        const totalList = (list || []).map((item) => {
          facilitatorsList.forEach(({ monitoringBusiness, operationBusiness, trusteeshipCustomerId }) => {
            if (item.serviceProviderIds.indexOf(trusteeshipCustomerId) !== -1 || isStation) {
              item.isMonitoringStations = monitoringBusiness === 1;
              item.isOperationStations = operationBusiness === 1;
            }
          });
          return item;
        });
        const zhishuList = totalList.filter(item => item.serviceProviderIds.indexOf(customerId) !== -1);
        yield put({ type: 'update', payload: { list: zhishuList.map(itemMap), totalList: totalList.map(itemMap) } });
        yield put({ type: 'STATION_CHANGE' });
      },
      { type: 'takeLatest' },
    ],
    // *queryById({ payload: { id, cb = () => { } } }, { call }) {
    //   if (!id) return;
    //   const resData = yield call(stationService.queryPowerStation, { powerStationId: id });
    //   yield call(cb, resData);
    // },
    // *updateStation({ payload: { cb = () => { }, param } }, { call, select }) {
    //   const accountId = yield select(state => state.user.accountId);
    //   const resData = yield call(
    //     stationService.updatePowerStation,
    //     { ...param, modifier: accountId },
    //   );
    //   yield call(cb, resData);
    // },
    // *createStation({ payload: { cb = () => { }, param } }, { call, select }) {
    //   const accountId = yield select(state => state.user.accountId);
    //   const resData = yield call(
    //     stationService.createPowerStation,
    //     { ...param, creator: accountId },
    //   );
    //   yield call(cb, resData);
    // },
    // *queryIndustryByIndestryCode({ payload: { cb = () => { }, indestryCode } }, { call }) {
    //   if (!indestryCode) return;
    //   const resData = yield call(
    //     stationService.queryIndustryByIndestryCode,
    //     { indestryCode },
    //   );
    //   yield call(cb, resData);
    // },
    // *searchByAddress({ payload: { cb = () => { }, address: addr } }, { call }) {
    //   if (!addr) return;
    //   const resData = yield call(
    //     queryLatAndLngService.searchByAddress,
    //     { addr },
    //   );
    //   yield call(cb, resData);
    // },
  },
  subscriptions: {},
};
