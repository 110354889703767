import React from 'react';
import styles from './index.scss';
import SvgIcon from '../SvgIcon';

class Tag extends React.Component {
  render() {
    // 显示单个按钮
    return (
      <span
        {...this.props}
        className={`${styles.tag} ${this.props.className || ''}`}
      >
        {this.props.children}
        <SvgIcon xlinkHref="icon-delete" className={styles.icon} onClick={this.props.onDelete}></SvgIcon>
      </span>
    );
  }
}
export default Tag;
