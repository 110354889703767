import * as services from '@services/storage/priceScheme';
import { dataProcessing, formatDetailsObj } from '@/utils/toolFunction';

const initialData = {
  effectiveTime: moment().add(2, 'days').format('X'),
  feePolicy: {
    cycleBill: 0,
  },
  seasonPriceList: [{
    time: ['00:00', '24:00'],
    type: 1,
    num: '01',
    price: '1.00000',
    dischargePrice: '0',

  }],
  seasonPriceMounths: ['6', '7', '8', '9'],
  unseasonPriceList: [{
    time: ['00:00', '24:00'],
    type: 1,
    num: '01',
    price: '1.00000',
    dischargePrice: '0',

  }],
  unseasonPriceMounths: ['1', '2', '3', '4', '5', '10', '11', '12'],
};

export default {
  namespace: 'vpriceScheme',
  state: {
    loading: true,
    data: initialData,
    dataList: [initialData],
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *querPriceInfo ({ payload: { stationId } }, { call, put }) {
      const dataList = yield call(services.findCurrentAndPlaningFeePolicyByStationId, { stationId });
      yield put({ type: 'update', payload: { loading: false } });

      dataList.map((data) => {
        const feePolicy = data.feePolicy || {};
        const table = feePolicy.details || [];
        const seasonPrice = table[0] || {}; // 季节电价
        const unseasonPrice = table[1] || {}; // 非季节电价
        data.seasonPriceList = dataProcessing(seasonPrice);
        data.seasonPriceMounths = seasonPrice.months ? seasonPrice.months.split(',') : ['6', '7', '8', '9'];
        if (String(feePolicy.cycleBill) === '1') {

          data.unseasonPriceList = dataProcessing(unseasonPrice);
          data.unseasonPriceMounths = unseasonPrice.months ? unseasonPrice.months.split(',') : ['1', '2', '3', '4', '5', '10', '11', '12'];
        } else {
          data.unseasonPriceList = [{
            type: 1,
            num: '01',
            price: 1,
            dischargePrice: 0,
            time: ['00:00', '06:00'],
          }, {
            type: 2,
            num: '02',
            price: 1,
            dischargePrice: 0,
            time: ['06:00', '12:00'],
          }, {
            type: 3,
            num: '03',
            price: 1,
            dischargePrice: 0,
            time: ['12:00', '18:00'],
          }, {
            type: 4,
            num: '04',
            price: 1,
            dischargePrice: 0,
            time: ['18:00', '24:00'],
          }];
          data.unseasonPriceMounths = [];
        }
        return data;
      });

      if (dataList.length) {
        yield put({
          type: 'update',
          payload: {
            dataList,
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            dataList: [],
          },
        });
      }
    },
    *deletePrice ({ payload, cb = () => { } }, { put, select, call }) {
      const data = yield call(services.deletePriceByStationIdAndFeePolicyId, { ...payload });
      cb(data);
    },
    *updateOrCreatPrice ({ payload: {
      stationId,
      feePolicyId,
      effectiveTime,
      cycleBill,
      resultSeason,
      resultUnSeason,
      seasonPriceMounths,
      unseasonPriceMounths,
    }, callback = () => { } }, { call, put, select }) {
      yield put({ type: 'update', payload: { loading: true } });
      const { _accountId } = yield select(({ user, vpriceScheme }) => {
        return {
          _accountId: user.accountId,
        };
      });
      const details = formatDetailsObj(cycleBill, resultSeason, resultUnSeason, seasonPriceMounths, unseasonPriceMounths);

      if (feePolicyId) { // 更新电价方案
        const obj = {
          stationId,
          feePolicyId,
          cycleBill,
          effectiveTime,
          _accountId,
          details: JSON.stringify(details),
        };

        const data = yield call(services.updateFeePolicyById, { ...obj });
        yield put({ type: 'update', payload: { loading: false } });
        return callback(data, true);

      } else { // 新建电价方案
        const obj = {
          stationId,
          cycleBill,
          effectiveTime,
          _accountId,
          details: JSON.stringify(details),
        };

        const data = yield call(services.createFeePolicy, { ...obj });
        yield put({ type: 'update', payload: { loading: false } });
        return callback(data);
      }
    },
    *deleteInfo (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          dataList: [initialData],
        },
      });
    },
  },
};
