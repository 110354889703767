import React from 'react';
import { composeClass } from '@/utils/tool';
import styles from './index.scss';

export default function SvgIcon(props) {
  const { className, xlinkHref, text = false, ...restProps } = props;
  const prefixedXlinkHref = xlinkHref.indexOf('icon-') === 0 ? xlinkHref : `icon-${xlinkHref}`;
  if (text === true) {
    return (
      <span className={composeClass('iconfont', prefixedXlinkHref, styles.iconfont, className)} {...restProps} />
    );
  }
  return (
    <svg className={composeClass('icon', className)} {...restProps}>
      <use xlinkHref={`#${prefixedXlinkHref}`} />
    </svg>
  );
}
