import * as services from '@services/storage/station';
import * as wsocket from '@services/storage/socketCluster';

export default {
  namespace: 'station',
  state: {
    list: [],
    hash: {},
    currentStation: '',
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetch(_, { call, put, select }) {
      const id = yield select(state => state.user.accountId);
      let [list] = yield call(services.getStationDataPerssionsByAccountId, { id });
      list = list.map((item) => ({ ...item, stationId: item.id, shortName: item.stationName }));
      const hash = (list || []).reduce((acc, item) => {
        acc[item.stationId] = item;
        return acc;
      }, {});
      yield put({ type: 'update', payload: { list, hash } });
    },

    // 订阅websocket
    *subscribe(_, { call, select }) {
      const accountId = yield select(state => state.user.accountId);
      yield call(wsocket.registerTopic, `/storedEnergy/esNumChanged/${accountId}`, 'station/fetch');
    },
  },
  subscriptions: {},
};
