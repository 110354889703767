// import { getData } from '@services/request';
import { getDataParti as getData } from '@/services/request';

/**
 * 根据服务商ID查询告警记录统计
 * @param {String} opt.serviceProviderId 服务商ID
 */
export async function queryAlarmRecordCountByServiceProvider(opt) {
  const stationIds = opt.stationIds || [];
  return await getData('alarm', 'queryAlarmRecordCountByServiceProvider')({ ...opt, stationIds: stationIds.join(',') });
}

/**
 * 根据企业ID查询告警记录统计
 * @param {String} opt.customerid 企业ID
 */
export async function queryAlarmRecordCountByCustomerId(opt) {
  const stationIds = opt.stationIds || [];
  return await getData('alarm', 'queryAlarmRecordCountByCustomerId')({ ...opt, stationIds: stationIds.join(',') });
}

/**
 * 根据站点编码查询告警记录统计
 * @param {String} opt.stationIds 站点ID
 */
export const queryAlarmRecordCountByStationCodes = getData('alarm', 'queryAlarmRecordCountByStationIds');

/**
 * 根据站点编码查询未结告警记录统计
 * @param {String} opt.stationIds 站点ID
 */
export const queryUnFinishAlarmRecordCountByStationIds = getData('alarm', 'queryUnFinishAlarmRecordCountByStationIds');


/**
 * 查询某站点近12个月的告警统计
 * @param {String} opt.stationId 站点ID
 */
// export async function queryLastYearAlarmRecordCountByStationId(opt) {
//   return await getData('alarm', 'queryLastYearAlarmRecordCountByStationId', opt);
// }


/**
 * 查询未结告警记录
 * @param {String[]} opt.stationIds 站点列表
 * @param {String[]} opt.ruleTypeIds 规则类型列表
 * @param {String[]} opt.exceptionTypes 异常类型列表，故障：0，预警：1，告警：2，事件：3，NULL表示全部[暂定，后续会有修改]
 * @param {Number} opt.startTime 开始时间，秒数
 * @param {Number} opt.endTime 结束时间，秒数
 * @param {Number} opt.page 当前页数
 * @param {Number} opt.length 显示长度
 * @param {Number} opt.fristHappenTimeOrderType 首发时间排序类型，顺序：0 倒序：1
 * @param {Number} opt.currentTimeOrderType 当前时间排序类型，顺序：0 倒序：1
 * @param {Number} opt.orderPriority 排序优先级，首发优先：0 当前时间优先：1
 */
export async function queryUnDealAlarmRecord({ ruleTypeIds, exceptionTypes, ...args }) {
  const opt = { ...args };
  if (ruleTypeIds && ruleTypeIds.length !== 0) opt.ruleTypeIds = JSON.stringify(ruleTypeIds);
  if (exceptionTypes && exceptionTypes.length !== 0) opt.exceptionTypes = JSON.stringify(exceptionTypes);
  return await getData('alarm', 'queryUnRecoverRecord')(opt);
}

/**
 * 查询所有告警记录 ?
 * @param {String[]} opt.stationIds 站点ID列表
 * @param {String[]} opt.deviceCodes 设备ID
 * @param {Number} opt.exceptionTypes 异常类型，故障：0，预警：1，告警：2，事件：3，NULL表示全部[暂定，后续会有修改]
 * @param {Number} opt.startTime 开始时间，秒数
 * @param {Number} opt.endTime 结束时间，秒数
 * @param {Number} opt.page 当前页数
 * @param {Number} opt.length 每页条数
 * @param {Number} opt.fristHappenTimeOrderType 首发时间排序类型，顺序：0 倒序：1
 * @param {Number} opt.recoverTimeOrderType 恢复时间排序类型，顺序：0 倒序：1
 * @param {Number} opt.orderPriority 排序优先级，首发优先：0 当前时间优先：1
 */
export async function queryAlarmRecord({ deviceCodes, ...args }) {
  const opt = { ...args };
  if (deviceCodes && deviceCodes.length) opt.deviceIds = JSON.stringify(deviceCodes);

  return await getData('alarm', 'queryAllRecord')(opt);
}

/**
 * 修改告警记录处理状态
 * @param {Object} opt.updateMap 更新设置，key:告警记录ID，value:修改后的状态，未处理：0 已处理：1 已忽略：2
 */
export async function updateAlarmRecordDealStatus({ updateMap, ...args }) {
  return await getData('alarm', 'updateAlarmRecordDealStatus')({ updateMap: JSON.stringify(updateMap), ...args });
}
