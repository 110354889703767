import { getData } from '@services/request';
import { filterAlarmStatusAccoLevels } from '@services/alarm';
import { fetchDeviceTree } from '@stroageopenapi/assets';


/**
 * 根据 监测对象id 获取 树节点哈希
 */
export async function queryTopologyStructureTreeHash ({ monitoredObjectId }) {
  let fres = [];
  const data = await fetchDeviceTree(monitoredObjectId);

  if (!data.code) {
    try {
      const format = (nodes, hash = {}) => {
        return (nodes || []).reduce((acc, item) => {
          if (!acc[item.name]) {
            acc[item.name] = [];
          }
          acc[item.name].push(item.id);

          if (Array.isArray(item.children)) {
            acc = {
              ...acc,
              ...format(item.children, acc),
            };
          }

          return acc;
        }, hash);
      };

      // 过滤该站点层级
      fres = format(data.data.children);
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}


/**
 * 获取 告警记录
 */
export async function queryAlarmRecord ({
  selectedStationIds,
  selectedDevices,
  selectedLevelTypes,
  selectedDateStart,
  selectedDateEnd,
  firstHappenTimeOrderType = 1,
  recoverTimeOrderType = 1,
  orderPriority = 0,
  page,
  pageSize,
}) {
  const fres = {
    data: [],
    hash: {},
    srcHash: {},
    totalCount: 0,
  };
  const data = await getData('alarm', 'queryAllRecord', {
    stationIds: JSON.stringify(selectedStationIds),
    deviceIds: JSON.stringify(selectedDevices),
    exceptionTypes: JSON.stringify(selectedLevelTypes),
    startTime: selectedDateStart,
    endTime: selectedDateEnd,
    firstHappenTimeOrderType,
    recoverTimeOrderType,
    orderPriority,
    page,
    length: pageSize,
  });

  if (!data.code) {
    try {
      fres.list = data.dataList.map((item, index) => {
        const i = {
          ...item,
          recordId: item.alarmRecordId,
          index: ((page - 1) * pageSize) + (index + 1),
          happenTime: item.happenTime ? (+item.happenTime) : null,
          recoverTime: item.recoverTime ? (+item.recoverTime) : null,
          lastHappenTime: item.lastHappenTime ? (+item.lastHappenTime) : null,
          alarmStatus: filterAlarmStatusAccoLevels.some(i => i === item.infoCategory) ? null : item.alarmStatus,
        };
        fres.hash[item.alarmRecordId] = i;
        fres.srcHash[item.alarmRecordId] = item;
        return i;
      });
      fres.totalCount = data.totalCount;
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}

/**
 * 获取 导出告警列表的路径
 */
export async function queryAlarmRecordExportUrl ({
  selectedStation,
  selectedDevices,
  selectedLevelTypes,
  selectedDateStart,
  selectedDateEnd,
  serviceProvidorName,
  firstHappenTimeOrderType = 1,
  recoverTimeOrderType = 1,
  orderPriority = 0,
}) {
  let fres = '';
  const data = await getData('alarm', 'exportAlarmRecordHistory', {
    stations: JSON.stringify(selectedStation),
    deviceIds: JSON.stringify(selectedDevices),
    exceptionTypes: JSON.stringify(selectedLevelTypes),
    startTime: selectedDateStart,
    endTime: selectedDateEnd,
    firstHappenTimeOrderType,
    recoverTimeOrderType,
    orderPriority,
    serviceProviderName: serviceProvidorName,
  });

  if (data.data) {
    fres = data.data;
  }

  return [fres, data];
}


/**
 * 根据  告警规则id 获取 规则判据
 */
export async function queryAlarmRuleDesc ({ alarmId }) {
  let fres = [];
  const data = await getData('alarm', 'queryAlarmRuleByAlarmId', {
    alarmId,
  });

  if (!data.code) {
    try {
      fres = data.data.formulaList || [];
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}

/**
 * 根据 告警id 获取 工单id
 */
export async function queryOrder ({ alarmRecordId }) {
  let fres = '';
  const data = await getData('repairTask', 'getOrderIdByAlarmId', {
    alarmId: alarmRecordId,
  });

  if (!data.code) {
    try {
      fres = data.data;
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}
