import * as servers from '@api/energyWeb/Statistics';

export default {
  namespace: 'vComparison',
  state: {
    loading: false,
    energyType: '',
    dateType: 'day',
    timeStart: moment().subtract(1, 'days').format('YYYY-MM-DD'),
    timeEnd: moment().format('YYYY-MM-DD'),
    dataMap: {},
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *queryChartData ({ payload }, { put, call }) {
      yield put({
        type: 'update',
        payload: {
          loading: true,
          dataMap: {},
        },
      });
      const { dateType, ...respayload } = payload;
      const response = yield call(servers.sequentialCompared, { ...respayload });
      const list = response.dataList || [];
      const dataMap = {};
      list.forEach((item) => {
        Object.keys(item || {}).forEach((key) => {
          dataMap[key] = item[key];
        });
      });

      yield put({
        type: 'update',
        payload: {
          loading: false,
          dateType,
          energyType: payload.energyType,
          timeStart: payload.firstTime,
          timeEnd: payload.secondTime,
          dataMap,
        },
      });

      return response;
    },
    *deleteInfo (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataMap: {},
        },
      });
    },
  },
};
