import { useEffect } from 'react';
import io from 'socketcluster-client';
import * as environmentService from '@/services/environment';

let socket = null;

const isInvalidSocket = () => socket === null;
let listenFunc = () => { };
// let topicHash = {};
// 订阅的topic列表
let channelList = [];
// 缓冲队列, 在socket未建立时缓存信息
let bufferList = [];

let registerHash = {};

export async function init() {
  if (socket) return socket;
  try {
    const { data: wsOption } = await environmentService.getWebSocketOption();
    const { ip, port, query = '' } = wsOption;
    if (ip) {
      socket = io.connect({ hostname: ip, port, query });
    }
  } catch (e) {
    console.error('establish websocket failed:', e);
  }
  if (!socket) {
    return;
  }
  if (bufferList.length > 0) {
    // 处理缓冲
    bufferList.forEach(({ topic, callback }) => {
      innerSubChannel(topic, callback);
    });
    bufferList = [];
  }

  socket.on('error', err => {});
  // socket.on('connect', () => console.log('Socket connect'));
  // socket.on('disconnect', () => console.log('Socket disconnect'));
  socket.on('raw', (...args) => {});
  global.window.wsocket = socket;
  return socket;
}

export function publish(evname, data) {
  if (isInvalidSocket()) { return; }
  socket.publish(evname, data, e => console.log(`Failed to publish, error: ${e}`));
}


export function subChannel(evname, cb) {
  if (isInvalidSocket()) {
    bufferList.push({ topic: evname, callback: cb });
  } else {
    innerSubChannel(evname, cb);
  }
}

function innerSubChannel(evname, cb) {
  if (isInvalidSocket() || typeof cb !== 'function') {
    return;
  }
  const channel = socket.channel(evname);
  if (channel.state === 'unsubscribed') {
    channel.subscribe();
    channel.on('subscribeFail', (err) => {
      console.log('Failed to subscribe to the channel due to error: ', err);
    });
    channel.on('subscribe', () => { });
    channelList.push(evname);
  }
  channel.watch(cb);
  // return () => unwatch(evname, cb);
}

export function unsubChannel(evname, callback) {
  if (isInvalidSocket() || typeof callback !== 'function') {
    return;
  }
  socket.unwatch(evname, callback);
  if (socket.watchers(evname).length < 1) {
    socket.destroyChannel(evname);
  }
}

// function clear() {
//   if (isInvalidSocket())
//     return;
//   for (let i = 0; i < channelList.length; i++) {
//     socket.destroyChannel(channelList[i]);
//   }
//   channelList.splice(0, channelList.length);
// }

// export function unsubscribe(evname) {
//   if (isInvalidSocket())
//     return;
//   for (let i = 0; i < events.length; i++) {
//     if (events[i] == evname) {
//       socket.destroyChannel(evname);
//       events.splice(i, 1);
//       break;
//     }
//   }
// }

// 用于 APP启动时 基础数据模型中的subscription中传入通用dispatch
export function watch(cb) {
  listenFunc = cb;
  // 按subscripton的要求返回一个取消监测的方法
  // TODO: 这里可能会需要销毁很多东西
  return () => (listenFunc = () => { });
}

/**
 * redux effect中注册websocket监听方法
 * @param {*} topic 订阅websocket的topic
 * @param {*} action 对应需要出发的model中的action 需要代码model名，如'alarm/receiveSocketHandler'
 */
export function subChannelInModel(topic, action) {
  const key = `${topic}/${action}`;
  if (!registerHash[key]) {
    const f = data => listenFunc(action, data, topic);
    subChannel(topic, f);
    registerHash[`${topic}/${action}`] = f;
  }
}

/**
 * 取消监听redux effect中注册的websocket监听方法
 * @param {*} topic 订阅websocket的topic
 * @param {*} action 对应需要出发的model中的action 需要代码model名，如'alarm/receiveSocketHandler'
 */
export function unsubChannelInModel(topic, action) {
  const key = `${topic}/${action}`;
  if (!registerHash[key]) return;
  const f = registerHash[key];
  unsubChannel(topic, f);
  delete registerHash[key];
}

// 从具体数据模型中传入topic和action
export function unregiterAll() {
  if (!isInvalidSocket()) {
    channelList.forEach((channel) => { socket.destroyChannel(channel); });
    channelList = [];
    bufferList = [];
    registerHash = {};
  }
}


export function useWatchWebsocket(topic, handler) {
  useEffect(() => {
    subChannel(topic, handler);
    return function unregisterWebsocketHandler() {
      unsubChannel(topic, handler);
    };
  }, [topic, handler]);
}
