import { getData } from '@services/request';

// 操作平级树结构,成为标准树结构
const optTreeData = (sourceTreeData, item) => {
  const filterData = sourceTreeData.filter((d) => { return d.parentId === item.id; });
  if (filterData.length > 0) {
    item.children = filterData;
    filterData.forEach((d) => {
      optTreeData(sourceTreeData, d);
    });
  }
};
// 根据电站获取设备树
export async function getAssetTreeByStationId (params) {
  const { stationId } = params;
  const response = await getData('storedEnergy', 'getAssetTreeByStationId', { stationId });
  const dataList = response.dataList || [];
  // 设备hash
  // 拿到需要的设备
  const treeDataScoure = dataList.reduce((current, item) => {
    if (item.displayType === 40012 || item.displayType === 40027) {
      current.push({
        ...item,
        key: item.displayId,
        title: item.displayName,
        children: [],
        pid: item.parentId,
      });
    }
    return current;
  }, [{
    title: '量费总览',
    key: 'all',
    parentId: null,
    children: [],
  }]);
  const treeDataHash = treeDataScoure.reduce((total, item) => {
    total[item.key] = item;
    return total;
  }, {});

  const treeData = treeDataScoure.filter((item) => item.parentId === null);
  const newTree = treeDataScoure.map(item => (
    {
      ...item,
      parentId: item.displayType === 40027 ? treeDataHash[item.stationId].id : item.parentId,
      parentType: item.displayType === 40027 ? 40012 : item.displayType,
    }
  ));
  treeData.forEach((item) => {
    optTreeData(newTree, item);
  });
  // 拼接第二层
  // const treeData = firstTree.map((item) => {
  //   const parentid = String(item.id);
  //   const parentTitle = item.title;
  //   treeDataScoure.forEach((data) => {
  //     if (data.parentId === parentid) {
  //       data.titles = `${parentTitle}_${data.title}`;
  //       item.children.push(data);
  //     }
  //   });
  //   return item;
  // });

  return [treeData, treeDataHash];
}
// 查询point
export async function queryPointByMonitoredObjectIdsAndMetricCodes (params) {
  let fres = [];
  const response = await getData('assetManagement', 'queryPointByMonitoredObjectIdsAndMetricCodes', { ...params });
  if (response.code === null) {
    fres = (response.dataList || []).reduce((acc, item) => {
      acc[0][item.pointId] = item;
      acc[1].push(item.pointId);
      acc[2][item.metricCode] = item;
      return acc;
    }, [{}, [], {}]);
  }

  return [fres, response];
}

// 根据pointId查数据
export async function queryLatestMetricDatum ({ pointIds }) {
  let fres = [];
  if (!pointIds) return;
  const res = await getData('metricDatum', 'queryLatestMetricDatum', { pointIds });

  if (res.code === null) {
    fres = res.dataList || [];
  }

  return [fres, res];
}

// 根据pointId, startTime, endTime 查数据
export async function queryRawMetricDatum ({ pointIds, startTime, endTime }) {
  let fres = '';
  if (!pointIds) return;
  const res = await getData('metricDatum', 'queryMetricDatum', {
    pointIds: JSON.stringify(pointIds.split(',')),
    startTime,
    endTime,
    offset: 0,
    limit: -1,
    aggregator: JSON.stringify({
      interval: 1,
      unit: 'm',
      fillPolicy: 'previous',
    }),
  });

  if (res.code === null) {
    fres = res.dataList || '';
  }

  return [fres, res];
}

// 电量分析&电费分析
export async function queryChartData ({ startTime, endTime, metric, requirdId }) {
  let fres = {};
  const [[pointIdsHash, pointIdsMap]] = await queryPointByMonitoredObjectIdsAndMetricCodes({ monitoredObjectIds: JSON.stringify(requirdId), metricCodes: JSON.stringify(metric) });

  if (pointIdsMap && pointIdsMap.length) {
    const [data] = await queryRawMetricDatum({ pointIds: pointIdsMap.join(','), startTime, endTime });

    fres = data.reduce((acc, item) => {
      acc[pointIdsHash[item.id].metricCode] = (item.dataList || []).map((item) => {
        return { value: [+item.t, (+item.v).toFixed(2)] };
      });
      return acc;
    }, {});
  }
  return [fres];
}


// 量费分析-合计
export async function queryLatestElectricityAndElectricityCharges (params) {
  const respon = await getData('storedEnergy', 'queryLatestElectricityAndElectricityCharges', { ...params });
  return respon;
}

// 量费分析-柱状图
function getDaysNumX (num, startTime, dataHash) {
  const arr = [];
  for (let i = 0; i <= num; i++) {
    const day = moment(startTime).add(i, 'days').format('YYYY-MM-DD');
    arr.push(dataHash[day] || '');
  }
  return arr;
}
function getmonthNumX (num, startTime, dataHash) {
  const arr = [];
  for (let i = 0; i <= num; i++) {
    const month = moment(startTime).add(i, 'months').format('YYYY-MM');
    arr.push(dataHash[month] || '');
  }
  return arr;
}
function formartValue (obj = {}, defaultValueType) {
  const NewObj = {};
  Object.keys(obj).forEach((item) => {
    const data = moment(Number(item)).format(defaultValueType === 'day' ? 'YYYY-MM-DD' : 'YYYY-MM');
    NewObj[data] = obj[item];
  });
  return NewObj;
}
export async function queryElectricityAndElectricityCharges (params) {
  const { defaultValueType, startTime, endTime, metricCodes, monitoredObjectIds } = params;

  const respon = await getData('storedEnergy', 'queryElectricityAndElectricityCharges', { startTime, endTime, metricCodes, monitoredObjectIds });
  const dataList = respon.data || {};
  Object.keys(dataList).forEach((key) => {
    const value = dataList[key];
    const dataHash = formartValue(value, defaultValueType);
    if (defaultValueType === 'day') {
      const num = moment(endTime).diff(moment(startTime), 'days');
      dataList[key] = getDaysNumX(num, moment(startTime).format('YYYY-MM-DD'), dataHash);
    } else {
      const num = moment(endTime).diff(moment(startTime), 'months');
      dataList[key] = getmonthNumX(num, moment(startTime).format('YYYY-MM'), dataHash);
    }
  });
  return dataList;
}

// 查询装机容量，装机功率
export async function getCapacityAndPower ({ id, type }) {
  let fres = {};
  let dataRes;
  if (type === 40012) {
    dataRes = await getData('storedEnergy', 'getStationInfoById', { stationId: id });
  } else if (type === 40027) {
    dataRes = await getData('storedEnergy', 'queryGridSupplyPointDetail', { gridSupplyPointId: id });
  } else {
    dataRes = await getData('storedEnergy', 'getEnergyStorageRoomInfoById', { energyStorageRoomId: id });
  }

  if (dataRes.code === null || dataRes.code === '200') {
    const { installedPower, capacity } = dataRes.data || {};
    fres = {
      installedPower,
      capacity: type === 40021 ? (capacity / 1000).toFixed(2) : capacity,
    };
  }

  return [fres];
}

// 量费分析-查询数据报表
export async function queryElectricityChargesTable (params) {
  const respon = await getData('storedEnergy', 'queryElectricityChargesTable', { ...params });
  const dataList = respon.dataList || [];
  return dataList;
}

// 量费分析-导出数据报表
export async function exportReport (params) {
  const { id, name, facility, defaultValueType, startTime, endTime, monitoredObjectIds, metricCodes } = params;
  const timeS = defaultValueType === 'day' ? moment(Number(startTime)).format('YYYY-MM-DD') : moment(Number(startTime)).format('YYYY-MM');
  const timeE = defaultValueType === 'day' ? moment(Number(endTime)).format('YYYY-MM-DD') : moment(Number(endTime)).format('YYYY-MM');
  const type = defaultValueType === 'day' ? '量费日报' : '量费月报';
  const nameTitle = id[0] === 'all' ? `${name}_` : '';
  const nameStation = `${facility.titles || facility.title}_`;
  const excelName = `${nameTitle}${nameStation === '量费总览_' ? '' : nameStation}${type}_${timeS}_${timeE}`;

  const obj = {
    excelName,
    startTime,
    endTime,
    monitoredObjectIds,
    metricCodes,
  };

  const respon = await getData('storedEnergy', 'exportElectricityChargesTableAsExcel', { ...obj });
  return respon;
}
