import { useState, useEffect } from 'react';
import SplitPane from 'react-split-pane';

import styles from './index.scss';

function Index (props) {
  const [firstStyle, setFirstStyle] = useState(undefined);
  const [secondStyle, setSecondStyle] = useState(undefined);
  let reactDom;
  const {
    split = 'vertical',
    minSize = 176,
    maxSize = 400,
    defaultSize = 280,
    children,
    className,
    ...rest
  } = props;


  useEffect(() => {
    let isOpen = true;
    $(reactDom.splitPane).find('.Resizer.vertical').html(`<span class='toggle open'></span>`);
    $(reactDom.splitPane).on('click', '.Resizer.vertical .toggle', (e) => {
      if (isOpen) {
        setFirstStyle({ width: '0' });
        $(e.target).removeClass('open').addClass('close');
      }
      else {
        setFirstStyle(undefined);
        $(e.target).removeClass('close').addClass('open');
      }

      isOpen = !isOpen;
    });
  }, []);

  return (
    <SplitPane
      {...rest}
      className={[styles.wrap, className]}
      ref={ref => reactDom = ref}
      onDragFinished={firstWidth => setSecondStyle({ width: `calc(100% - 16px - ${firstWidth}px)` })}
      pane1Style={firstStyle}
      pane2Style={secondStyle}
      split={split}
      minSize={minSize}
      maxSize={maxSize}
      defaultSize={defaultSize}
    >
      {children}
    </SplitPane>
  );
}

export default Index;
