import * as services from '@api/energyWeb/DeviceManagement';
import base from '../../base';

function maxAndmin (list = []) {
  let max = 0;
  let min = 0;
  let all = [];
  list.forEach((item) => {
    all = all.concat(item.dataList);
  });
  const total = all.sort((a, b) => a.v - b.v);
  if (total.length) {
    min = Number(total[0].v).toFixed(2);
    max = Number(total[total.length - 1].v).toFixed(2);
  }
  return { max, min };
}

export default {
  namespace: 'vTransformer',
  state: {
    datas: [],
    dataHash: {},
    data: {},
    dataAudit: {},
    metricCodes: [
      '020F0001',
      {
        name: '温度',
        unit: '℃',
        dec: 2,
        metricCodes: [
          '0280000B', // A相
          '0280000C', // B相
          '0280000D', // C相
        ],
      },
      {
        name: '温度',
        unit: '℃',
        dec: 2,
        metricCodes: [
          '02800010', // 本体温度
          '0280000F', // 油温
        ],
      },
    ], // ['负载率'，'温度' ]
    countObj: {}, // 变压器各数量
    chartList: [],
    MaxLoadRate: '',
    MinLoadRate: '',
    MaxTemperature: '',
    MinTemperature: '',
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    // 查询数据
    *fetch ({ payload: { queries = {} } = {} }, { put, call, select }) {
      const { currentStation } = yield select(state => state.base);
      const [{ datas, total, countObj }] = yield call(services.fetchTransformList, { stationId: currentStation });
      const dataHash = datas.reduce((obj, item) => {
        obj[item.id] = item;
        return obj;
      }, {});
      yield put({
        type: 'update',
        payload: {
          datas,
          dataHash,
          countObj,
        },
      });

      return { ...queries, total, countObj };
    },
    *fetchDetail ({ payload: { id } = {} }, { put, call }) {
      if (!id) return;
      const [fres] = yield call(services.fetchTransformById, { id });

      yield put({
        type: 'update',
        payload: {
          data: fres,
        },
      });

      return [fres];
    },
    *fetchChart ({ payload: { deviceId, metricCodes = [], startTime, endTime } }, { call, put, select }) {
      const metric = yield select(state => state.metricCode.data);

      yield put({
        type: 'update',
        payload: {
          chartList: [],
        },
      });
      const [points] = yield call(services.queryPoints, {
        deviceId,
        metricCodes,
      });

      const pointHash = points.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      const [list] = yield call(services.queryRawMetricDatum, {
        pointIds: points.map(i => i.id),
        startTime,
        endTime,
      });

      let MaxLoadRate = '';
      let MinLoadRate = '';
      let MaxTemperature = '';
      let MinTemperature = '';

      const { max, min } = maxAndmin(list);

      const chartList = list.map((item) => {
        const { id: pointId, dataList = [] } = item;
        const pointItem = pointHash[pointId];
        const { metricCode, pointName, alias } = pointItem;
        const metricItem = metric[metricCode] || {};
        const { name, dec = 0 } = metricItem;

        return {
          ...pointItem,
          ...metricItem,
          name: name || pointName || alias,
          data: dataList.map((i) => {
            return [+i.t, (+i.v).toFixed(+dec)];
          }),
        };
      });
      if (metricCodes.indexOf('020F0001') !== -1) { // 负载率
        MaxLoadRate = max;
        MinLoadRate = min;
        yield put({
          type: 'update',
          payload: {
            MaxLoadRate,
            MinLoadRate,
            chartList,
          },
        });
      } else {
        MaxTemperature = max;
        MinTemperature = min;
        yield put({
          type: 'update',
          payload: {
            MaxTemperature,
            MinTemperature,
            chartList,
          },
        });
      }
    },
    *deleteSom (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          MaxLoadRate: '',
          MinLoadRate: '',
          MaxTemperature: '',
          MinTemperature: '',
        },
      });
    },
  },

  subscriptions: {
  },
};
