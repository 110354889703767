// 系统指定的客户类型，并非接口定义的客户类型
export const SysCustomerType = {
  // 服务商
  SERVICE_PROVIDER: '0',
  // 企业
  ENTERPRISE: '1',
  // 超级管理员（待定，不知道用不用得到）
  // ADMINISTRATOR: '2',
}

export const isServiceProvider = type => type === SysCustomerType.SERVICE_PROVIDER;
export const isEnterprise = type => type === SysCustomerType.ENTERPRISE;
// export const isServiceProvider = type => type === SysCustomerType.SERVICE_PROVIDER;