import { getData } from '@/services/request';

export async function queryStationByServiceProviderIds ({ providerId }) {
  let fres = [];
  const data = await getData('assetManagement', 'queryStationByEnterpriseId', {
    enterpriseId: providerId,
  });

  if (data.code === null) {
    fres = (data.dataList || []).map((item) => {
      return {
        ...item,
        id: item.stationId,
        key: item.stationId,
      };
    });
  }

  return [fres, data];
}
