import React from 'react';
import { DatePicker } from 'antd';
import YearPicker from './YearPicker';
import SwitchPicker from './SwitchPicker';
import SwitchRangePicker from './SwitchRangePicker';

import HOCWrap from './HOCWrap';

import styles from './index.scss';

class Index extends React.Component {
  render () {
    const { className, dropdownClassName, ...rest } = this.props;

    return (

      <DatePicker
        className={[styles.ui, className]}
        dropdownClassName={[styles.drop, dropdownClassName]}
        format='YYYY/MM/DD'
        getCalendarContainer={triggerNode => triggerNode}
        {...rest}
      />

    );
  }
}

Index.MonthPicker = HOCWrap(DatePicker.MonthPicker, 'month');
Index.RangePicker = HOCWrap(DatePicker.RangePicker, 'range');
Index.YearPicker = YearPicker;

export const MonthPicker = Index.MonthPicker;
export const RangePicker = Index.RangePicker;
export { YearPicker, SwitchPicker, SwitchRangePicker };

export default Index;
