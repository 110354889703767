import PropTypes from 'prop-types';

import { Select } from 'antd';
import styles from './YearPicker.scss';

const { Option } = Select;

class Index extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    start: PropTypes.number,
    end: PropTypes.number,
    onChange: PropTypes.func,
  }

  static defaultProps = {
    className: null,
    start: 2018,
    end: moment().year(),
    onChange: () => { },
  }

  state = {
    value: moment(),
  }

  onChange = (value, ...args) => {
    const { onChange } = this.props;

    const m = moment(String(value), 'YYYY');

    onChange(m, ...args);

    this.setState({
      value: m,
    });
  }

  render () {
    const {
      className,
      start, end,
      value,
      ...rest
    } = this.props;
    const { value: valueState } = this.state;

    if (start > end) {
      console.error('prop end should not be greater than prop start');

      return null;
    }

    const options = Array.from({ length: end - start + 1 }).map((i, idx) => start + idx);

    const valueRender = value || valueState;

    return (
      <Select
        {...rest}
        className={[className, styles.wrap]}
        value={moment.isMoment(valueRender) ? valueRender.year() : undefined}
        onChange={this.onChange}
      >
        {
          options.map(item => <Option key={item} value={item}>{item}</Option>)
        }
      </Select>
    );
  }
}

export default Index;
