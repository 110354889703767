import { Spin } from 'antd';

import styles from './index.scss';

function Index({ wrapperClassName, ...rest }) {
  return (
    <Spin
      {...rest}
      wrapperClassName={[styles.ui, wrapperClassName]}
    />
  );
}

export default Index;
