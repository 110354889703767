import styles from './index.scss';

export default function HOCWrap (Component, type) {
  return class extends React.Component {
    render () {
      const { className, dropdownClassName, ...rest } = this.props;
      const typeHash = {
        'date': 'YYYY/MM/DD',
        'month': 'YYYY/MM',
        'range': 'YYYY/MM/DD HH:mm:ss',
      };

      return (
        <Component
          className={[styles.ui, className]}
          dropdownClassName={[styles.drop, dropdownClassName]}
          format={typeHash[type || 'date']}
          getCalendarContainer={triggerNode => triggerNode}
          {...rest}
        />
      );
    }
  };
}
