import * as servers from '@api/energyWeb/Statistics';

export default {
  namespace: 'vRanking',
  state: {
    loading: false,
    typeList: [],
    dataList: [],
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *queryTree ({ payload, callback = () => { } }, { put, call }) {
      const response = yield call(servers.getEnergyClassificationByStationId, { ...payload });
      yield put({
        type: 'update',
        payload: {
          loading: false,
          typeList: response,
        },
      });
      return callback(response);
    },
    *queryEchartData ({ payload }, { put, call }) {
      const response = yield call(servers.queryeneygrRanking, { ...payload });

      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList: response.dataList || [],
        },
      });
      return response;
    },
    *initInfo (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList: [],
        },
      });
    },
    *deleteInfo (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataMap: [],
        },
      });
    },
  },
};
