import { chunk, propertyOf, isUndefined, isNull, identity, isNil } from 'lodash';
/**
 * 多样式组合
 */
export function composeClass (...args) {
  return args.filter(a => a).join(' ');
}

export function readProp (ctx) {
  const get = propertyOf(ctx);
  return function innerReadProp (propName, defaultVal, decorator = identity) {
    let defaultValue = defaultVal;
    if (arguments.length < 2) {
      defaultValue = '';
    }
    const value = get(propName);
    if (isUndefined(value) || isNull(value)) return defaultValue;
    return decorator(value);
  };
}

export function partOf (obj, ...args) {
  if (!args.length) return {};
  const dataOf = readProp(obj);
  return args.reduce((o, key) => {
    o[key] = dataOf(key, undefined);
    return o;
  }, {});
}

export function download (url, name) {
  const a = document.createElement('a');
  const isSupportDownload = 'download' in a;

  if (isSupportDownload) {
    a.href = url;
    a.download = name || true;
    a.style.display = 'none';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  } else {
    window.open(url);
  }
}

export function fillDatasByType (type = 'year', m = moment(), end) {
  if (!m) return () => [];

  if (end) return fillDatasByTypeRange(type, m, end); // as fillDatasByTypeRange

  const t = type === 'day' ? 'day' : type === 'month' ? 'month' : 'year';

  return fillDatasByTypeRange(type, m.clone().startOf(t), m.clone().endOf(t));
}

export function fillDatasByTypeRange (type = 'year', start, end) {
  if (!start || !end) return () => [];

  const startM = moment(+start);
  const endM = moment(+end);

  function add (type, m) {
    const t = type === 'day' ? 'h' : type === 'month' ? 'd' : 'M';

    return delta => {
      return m.clone().add(delta, t);
    };
  }

  function format (type) {
    const t = type === 'day' ? 'hour' : type === 'month' ? 'date' : 'month';

    return m => {
      const n = m[t]();
      return type === 'year' ? n + 1 : n;
    };
  }

  function getLength (type, startM, endM) {
    const t = type === 'day' ? 'hours' : type === 'month' ? 'days' : 'months';
    return endM.diff(startM, t);
  }

  const arrT = Array.from({
    length: getLength(type, startM, endM) + 1
  });

  const addTyped = add(type, startM);
  const formatTyped = format(type);

  return (datas = []) => {
    const datasParsedT = datas.reduce((acc, item) => {
      const m = moment(new Date(+item.t));
      const t = type === 'day' ? m.hour() : type === 'month' ? m.date() : m.month() + 1;

      acc[t] = {
        ...item,
        st: +item.t,
        t
      };

      return acc;
    }, {});

    return arrT.map((item, index) => {
      const mt = addTyped(index);
      const t = formatTyped(mt);
      const data = datasParsedT[t] || {};

      return {
        ...data,
        mt,
        t
      };
    });
  };
}

export function absStringNumber (value) {
  if (_.isNil(value)) return value;
  return String(value).replace(/-/g, '');
}

export function fixNumber (value, dec = 2, divisor = 1, defaultValue = '--') {
  if (_.isNil(value) || Number.isNaN(+value)) return defaultValue;
  return (+value / divisor).toFixed(dec);
}

export function fixPercentage (value, dec = 2, defaultValue = '--') {
  if (_.isNil(value) || Number.isNaN(+value)) return defaultValue;
  const n = (+value * 100).toFixed(dec);
  return `${n}%`;
}

export function fixClassName (value, styles = {}) {
  if (_.isNil(value) || value === 0) return null;
  return value > 0 ? styles.pos : styles.neg;
}

export function sum (...arr) {
  return arr.reduce((sum, value) => {
    if (!_.isNumber(+value)) return sum;
    return sum + +value;
  }, null);
}

export const sleep = t => new Promise(r => window.setTimeout(r, t));

/**
 * form.validateFields 的async写法
 * @param {Object} antd的Form.create 实例
 * @param  {...any} form.validateFields方法除了最后个回调的全部参数
 * @return {Array} form.validateFields的callback的入参的数组结构
 */
export const asyncValidateFields = (form, ...args) =>
  new Promise(r => form.validateFields(...args, (...p) => r([...p])));

export const decorateCNMoney = (val = 0, decorator = (v, unit) => `${v}${unit}`) =>
  val > 10000 ? decorator((val / 10000).toFixed(2), '万元') : decorator(val.toFixed(2), '元');

export const CommonReg = {
  /**
   * 生成数字型数据的校验正则
   * @param {int} integer 整数位长度
   * @param {int} decimal 小数位长度
   * @return {RegExp}
   */
  number (integer, decimal = 0) {
    const reg = new RegExp(!decimal ? `^\\d{1,${integer}}$` : `^\\d{1,${integer}}(\\.\\d{1,${decimal}})?$`);
    return reg;
  },
  /**
   * 名称正则，用途例如：站点名称，站点简称, 人员姓名，人员角色
   * @return {RegExp}
   */
  name () {
    return /^[()（）a-zA-Z0-9\u4e00-\u9fa5]+$/;
  },
  /**
   * 名称正则，中文、英文字母或数字组成
   * @return {RegExp}
   */
  title () {
    return /^[a-zA-Z0-9\u4e00-\u9fa5]+$/;
  },
  /**
   * 名称正则，中文、英文字母或数字或_组成
   * @return {RegExp}
   */
  name2 () {
    return /^[a-zA-Z0-9_\u4e00-\u9fa5]+$/;
  },
  /**
   * 名称正则，英文字母或数字或_组成
   * @return {RegExp}
   */
  password () {
    return /[A-Za-z0-9]{8,16}$/;
  },
  /**
   * 名称正则，不能为纯数字
   * @return {RegExp}
   */
  notNum () {
    return /\D+$/;
  },
  /**
   * 名称正则，用途例如：经纬度
   * @return {RegExp}
   */
  gps () {
    return /^[+-]?\d{1,3}(\.\d{1,6})?,[+-]?\d{1,3}(\.\d{1,6})?$/;
  },
  /**
   * 名称正则，用途例如：去除空格
   * @return {RegExp}
   */
  filterSpace () {
    return /\s/g;
  },
  /**
   * 名称正则，用途例如：验证手机号
   * @return {RegExp}
   */
  mobilePhone () {
    return /^1[3456789]\d{9}$/; // ^1[3456789]\d{9}$/;
  },
  /**
   * 名称正则，用途例如：密码长度
   * @param {int} min 最小长度
   * @param {int} max 最大长度
   * @return {RegExp}
   */
  passwordLength (min = 1, max) {
    return new RegExp(`\\w{${min},${max}}`);
  },
  /**
   * 名称正则，用途例如：告警启动条件
   * @param {int} max 最大长度
   * @return {RegExp}
   */
  loatingPointNumber (max) {
    return new RegExp(`^(\\d+)(\\.\\d{0,${max}})?$`);
  },
  /**
   * 名称正则，用途例如：验证联系电话
   */
  tellPhone () {
    return new RegExp(/^(\+\d{1,6})?-?\+?\d{3,4}-?\d{7,8}$/);
  },

  /**
   * 邮箱正则
   */
  email () {
    return /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
  }
};

const clientId = `CLIENT:${`${Math.random()}`.slice(2)}`;
export function getCID (key) {
  return `${clientId}_${key}`;
}

//用电量和收益的单位转换
export function powerUnitConversion (value = null) {
  if (Math.abs(value) >= 1000000) {
    return {
      value: fixNumber(value / 1000000),
      unit: 'GW'
    };
  } else if (Math.abs(value) >= 1000) {
    return {
      value: fixNumber(value / 1000),
      unit: 'MW'
    };
  } else {
    return {
      value: fixNumber(value),
      unit: 'kW'
    };
  }
}
export function energyUnitConversion (value = null) {
  if (Math.abs(value) >= 10000) {
    return {
      value: fixNumber(value / 10000),
      unit: '万kWh'
    };
  } else {
    return {
      value: fixNumber(value),
      unit: 'kWh'
    };
  }
}

export function capacityUnitConversion (value = null) {
  if (Math.abs(value) >= 10000) {
    return {
      value: fixNumber(value / 10000),
      unit: '万kW'
    };
  } else {
    return {
      value: fixNumber(value),
      unit: 'kW'
    };
  }
}


export function customUnitConversion (value = null, unit) {
  if (Math.abs(value) >= 10000) {
    return {
      value: fixNumber(value / 10000),
      unit: `万${unit}`
    };
  } else {
    return {
      value: fixNumber(value),
      unit: `${unit}`
    };
  }
}




export function revenueUnitConversion (value = null) {
  if (Math.abs(value) >= 10000) {
    return {
      value: fixNumber(value / 10000),
      unit: '万元'
    };
  } else {
    return {
      value: fixNumber(value),
      unit: '元'
    };
  }
}
export function weightUnitConversion (value = null) {
  if (value > 10000000) {
    return {
      value: (value / 10000000).toFixed(2),
      unit: '万吨'
    }
  } else if (value >= 1000000) {
    return {
      value: fixNumber(value / 1000000),
      unit: '千吨'
    };
  } else if (value >= 1000) {
    return {
      value: fixNumber(value / 1000),
      unit: '吨'
    };
  } else {
    return {
      value: fixNumber(value),
      unit: '千克'
    };
  }
}
export function revenueUnitConversionBatch (datas = [], dec = 2) {
  const min = datas.length ? Math.min(...datas.map(i => i.value?.[1]).filter(Boolean)) : 0;
  let [unit, divisor] = ['元', 1];

  if (min >= 10000) {
    unit = '万元';
    divisor = 10000;
  }

  return [
    datas.map(item => {
      return {
        ...item,
        value: [item.value?.[0], fixNumber(item.value?.[1], dec, divisor, item.value?.[1])]
      };
    }),
    unit
  ];
}

class TreeNode {
  constructor(nodeId, parentNode, name, nodeType, payload = {}) {
    Object.assign(this, {
      key: nodeId,
      title: name,
      payload: { id: nodeId, name, ...payload },
      nodeType,
      parentNode
    });
  }

  get id () {
    return this.payload.id;
  }

  get name () {
    return this.payload.name;
  }

  // get title() { return this.payload.name; }

  // get key() { return this.payload.id; }

  get parentId () {
    return this.payload.parentId;
  }

  get parentNodeId () {
    return this.parentNode && this.parentNode.id;
  }

  get hasChildren () {
    return this.children && this.children.length !== 0;
  }

  hasChildNode = node => {
    return this.children && this.children.find(iNode => iNode === node);
  };

  removeChild = node => {
    if (this.hasChildNode(node)) {
      const idx = this.children.findIndex(iNode => iNode === node);
      this.children.splice(idx, 1);
    }
  };

  append = node => {
    if (!this.children) {
      this.children = [];
    }
    this.children.push(node);
  };

  updatePayload = (newPayload = {}) => {
    this.payload = { ...this.payload, ...newPayload };
  };
}

export function buildTree (list = [], rootId = '0', itemFormat = {}) {
  const hash = {};
  const headNodes = [];
  const nodes = [];
  list.forEach(item => {
    const node = new TreeNode(item[itemFormat.id], null, item[itemFormat.title], undefined, item);
    hash[node.id] = node;

    if (node.parentId === rootId) {
      headNodes.push(node);
    } else {
      nodes.push(node);
    }
  });

  nodes.forEach(node => {
    const parentNode = hash[node.parentId];
    if (parentNode){
      parentNode.append(node);
    }
  });

  nodes.forEach((node) => {
    const parentNode = hash[node.parentId];
    if (parentNode){
      parentNode?.append(node);
    }

  });

  return headNodes;
}

/**
 * 值换算方法
 * @param {Number} v 值
 * @param {Number} position 转换的位数
 * @param {Number} fixedNum 保留小数位
 */
export function valueChangeFn (value, position, fixedNum = 2) {
  switch (true) {
    case _.isNil(value):
      return '--';
    case Math.abs(value) >= position:
      return (value / position).toFixed(fixedNum);
    default:
      return (+value).toFixed(fixedNum);
  }
}

export function requestFullScreen (el) {
  if (el.requestFullscreen) {
    return el.requestFullscreen();
  } else if (el.webkitRequestFullScreen) {
    return el.webkitRequestFullScreen();
  } else if (el.mozRequestFullScreen) {
    return el.mozRequestFullScreen();
  } else if (el.msRequestFullscreen) {
    return el.msRequestFullscreen();
  }
}

export function exitFullscreen () {
  if (document.exitFullscreen) {
    return document.exitFullscreen();
  } else if (document.webkitExitFullscreen) {
    return document.webkitExitFullscreen();
  } else if (document.mozCancelFullScreen) {
    return document.mozCancelFullScreen();
  } else if (document.msExitFullscreen) {
    return document.msExitFullscreen();
  }
}

export function isFullscreen () {
  return document.webkitIsFullScreen || document.mozFullScreen || false;
}

export function rgba (rgb, alpha = 1) {
  const str = rgb.slice(1);
  const len = str.length;
  return `rgba(${chunk(str, len / 3).reduce(
    (s, i) =>
      `${s}${parseInt(
        i
          .concat(i)
          .join('')
          .slice(0, 2),
        16
      )},`,
    ''
  )}${alpha})`;
}

export function clip (value) {
  if (Number(value)) return Number(value).toFixed(2);
  return '--';
}

// 转换数字的小数位数，公共需求说明: https://drive.weixin.qq.com/s?k=AO4AqgflAAcoOuFcyA#/preview?fileId=s.1970324969466606.695640276gTr_f.697599080fRxk
export function convertDecimal (val, fixedNum = 2, symbol = '--') {
  if (isNil(val) || isNaN(val) || val === '' || val === null) return symbol || '--';

  const fixedValue = Number(val).toFixed(fixedNum);

  if (Number(fixedValue) === 0) {
    // 对 0 再做一次精度为 4 的处理，防止精度丢失
    if (fixedNum < 4) {
      return convertDecimal(val, 4, symbol);
    }
    return Number(val).toFixed(1); // 如果数值为整数，返回 `${整数}.0`
  }

  return fixedValue;
}

export function mergeMultipleArrays (arrays) {
    // 获取所有数组中的时间戳
    const allTimes = arrays.reduce((times, arr) => {
      const arrTimes = arr.map(obj => obj.time);
      return [...times, ...arrTimes];
  }, []);

  // 去重并排序时间戳
  const uniqueTimes = [...new Set(allTimes)].sort();

  // 生成新的数组，填充缺失值为0
  const mergedArrays = arrays.map(arr =>
      uniqueTimes.map(time => ({
          time,
          value: arr.find(obj => obj.time === time) ? arr.find(obj => obj.time === time).value : 0
      }))
  );

  return mergedArrays;
}






// municipality directly under the Central Government
export const MDUTCG = ['110000', '310000', '120000', '500000']


export const dealFormData = (object) => {
  const formData = new FormData();
  Object.keys(object).forEach(key => {
    const value = object[key];
    if (Array.isArray(value)) {
      value.forEach((subValue, i) => {
        formData.append(key + `[${i}]`, subValue);
      });
    } else {
      formData.append(key, value);
    }
  });
  return formData;
}

export const formatNumberWithCommas=(value,decimalPlaces = 2)=> {
    // 处理 null 和 undefined 的情况
    if (value === null || value === undefined) {
      return '--';
    }
    let num;
    if (typeof value === 'string') {
        // 尝试将字符串转换为浮点数
        num = parseFloat(value);
        // 如果转换失败，返回 --
        if (isNaN(num)) {
            return '--';
        }
    } else if (typeof value === 'number') {
        num = value;
    } else {
        // 如果不是字符串、数字或有效值，返回 --
        return '--';
    }
    // 使用 toFixed 方法保留指定的小数位数
    num = num.toFixed(decimalPlaces);
    // 格式化为带有千位分隔符的字符串
    return Number(num).toLocaleString();
}