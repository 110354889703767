import { getData } from '@services/request';
import * as storage from '@services/storage';
import { readProp } from '@/utils/tool';


async function fillingLoginData (data) {
  const nData = { ...data };

  nData.id = nData.accountId;


  const personalInfoRes = await getData('applicationPlatform', 'queryPersonalData', { accountId: data.accountId });
  if (personalInfoRes.code === null) {
    const userOf = readProp(personalInfoRes.data);
    nData.phoneValidated = userOf('phoneValidated', undefined, x => `${x}`);
    nData.emailValidated = userOf('emailValidated', undefined, x => `${x}`);
    nData.password = userOf('hasPassword', undefined, x => `${x}`);
    nData.user = userOf('accountName');
  }
  return nData;
}

export async function serviceProviderLogin ({ username, password, exp = 1 }) {
  let fres = {};
  const data = await getData('user', 'serviceProviderLogin', {
    action: 'serviceProviderLogin',
    username,
    password,
    exp,
  });

  if (data.code === null) {
    fres = data.data;
  }

  return [fres, data];
}

export async function login ({ username, password, exp = 1 }) {
  let fres = {};
  const data = await getData('user', 'verify', {
    username,
    password,
    exp,
  });
  if (data.code !== null) return [data.data || {}, data];
  const content = data.data;
  storage.set('token', content.token);
  fres = await fillingLoginData(content);
  return [fres, data];
}

export async function queryUserInfo ({ accountId }) {
  let fres = {};
  const res = await getData('storedEnergy', 'getOrganizationInfoByAccountId', {
    accountId,
  });

  if (res.code === null) {
    fres = {
      ...res.data || {},
    };
  }
  if (fres.organizationType === '00040030') { // 00040030: 如果是服务商    00040035: 是企业  (已经带上serviceProviderId)
    fres.serviceProviderId = fres.id;
  }

  return [fres, res];
}

export async function getUserInfoByToken () {
  const res = await getData('user', 'getUserInfoByToken');
  if (res.code === null && res.data) {
    res.data = await fillingLoginData({ ...res.data, accountId: localStorage.getItem('customerId') });
  }
  return res;
}

// 通过账号id查询该账号(个人和企业账号)拥有的菜单权限
export async function getMenuPermissionsByAccountId (params) {
  const response = await getData('storedEnergy', 'getMenuPermissionsByAccountId', { ...params });
  const dataList = response.dataList || [];
  return dataList;
}

// 发送验证码
export async function sendSmsMessage (params) {
  const response = await getData('storedEnergy', 'sendSmsMessage', { ...params });
  return response;
}

// 发送验证码
export async function updatePhone (params) {
  const response = await getData('storedEnergy', 'updatePhone', { ...params });
  return response;
}

// 修改密码
export async function updatePassword (params) {
  const response = await getData('storedEnergy', 'updatePassword', { ...params });
  return response;
}

// 平台 发送验证码
export async function getCode (params) {
  const response = await getData('customerCenter', 'sendVerificationCodeByAccountUser', { ...params });
  return response;
}
//  平台 修改密码
export async function changePassword (params) {
  const response = await getData('customerCenter', 'changeAccountPasswordByVerificationCode', { ...params });
  return response;
}
export async function logout () {
  const [data] = await Promise.all([
    getData('storedEnergy', 'logout'),
    getData('user', 'logout'),
  ]);
  return data;
}
