import * as ossService from '@/services/oss';


export default {
  namespace: 'upload',

  state: {
    data: {},
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *multipartUpload({ payload: { cb, storeAs, file, opt } }, { call }) {
      const result = yield call(ossService.multipartUpload, storeAs, file, opt);
      yield call(cb, result);
    },
  },

  subscriptions: {
  },
};