import React from 'react';
import { TimePicker } from 'antd';
import styles from './TimePicker.scss';

class CTimePicker extends React.Component {

  render() {
    const { className, ...restProps } = this.props;
    return (
      <TimePicker
        className={[styles['time-picker'], className]}
        getPopupContainer={triggerNode => triggerNode}
        popupClassName={styles.panel}
        {...restProps}
      />
    );
  }
}
export default CTimePicker;
