import * as tableService from '@/services/tableData';
import get from 'lodash/get'
import set from 'lodash/set'

export default {
  namespace: 'tableData',

  state: {
    data: {
      dataSource:[],   //表格数据
      filterParams:null,
      listParams: {
        pageIndex:1,
        pageSize:10,
      },  //查询条件
      selectData:[],   //单选复选的数据
      pagination:{
        total:0
      },
      loading: false,
      otherData:null
    },
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    // 获取表格数据
    *getData({ payload: { callback, requestUrl,isSort, pagination, getValueRules,parameterNest='' } = {}}, {put,select, call }){
      yield put({ type: 'update', payload: { loading: true } });
      const listParams = (yield select(state => state.tableData.listParams))||{};
      const handledPageParams = listParams && JSON.parse(JSON.stringify(listParams))||{};
      // 若没有分页则不传参数
      if(!pagination){
        delete handledPageParams.pageIndex;
        delete handledPageParams.pageSize;
        delete listParams.pageIndex;
        delete listParams.pageSize;
      }

      const filterParams = yield select(state => state.tableData.filterParams)||{};

      const sortParam = isSort? {sort:'', field:''}: {}

      const  res = yield call(tableService.queryAllList, {requestUrl, ...sortParam,  ...filterParams,...listParams,parameterNest});
      yield put({ type: 'update', payload: {dataSource:getValueRules? get(res,getValueRules,[]) : res.data, otherData: res.data,loading: false, pagination:{
        total: res?.data?.totalCount || 0
      }} });
      callback && callback(res)
    },

    *getExtraData({ payload: { callback, requestUrl} = {} }, {put,select, call }){
      yield put({ type: 'update', payload: { loading: true } });
      const listParams = yield select(state => state.tableData.listParams)||{};
      const filterParams = yield select(state => state.tableData.filterParams)||{};
      const  res = yield call(tableService.queryAllList, {requestUrl,...filterParams,...listParams});
       callback && callback(res.data)
    },

    // 更新表格查询条件
    *setParams({ payload: { queryData,callback } = {} }, {put, call }){
      yield put({ type: 'update', payload: { listParams: queryData} });
      callback && callback()
    },
    // 更新表格额外查询外部
    *setFilterParams({ payload: { queryData,listParams,callback } = {} }, {put,select, call }){
      const initListParams = yield select(state => state.tableData.listParams)||{};
      yield put({ type: 'update', payload: { filterParams: queryData, listParams:       
        listParams || initListParams
      } });
      callback && callback()
    },
    // 初始化
    *setReset({ payload: { callback } = {} }, {put, call }){
      yield put({ type: 'update', payload: { 
        dataSource:[],   //表格数据
        filterParams:null,
        listParams: {
          pageIndex:1,
          pageSize:10,
        },  //查询条件
        selectData:[],   //单选复选的数据
        pagination:{
          total:0
        },
        loading: false,
        otherData:null
      } });
      callback && callback()
    },
  },

  subscriptions: {
  },
};