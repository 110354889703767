/**
 * 告警常量
 */
import {
  levelDesc,
  levelCNMap,
  levelENMap,
  alarmStatusCNMap,
  dealStatusCNMap,
  levelColorMap,
  alarmAudioMap,
  levelGifMap,
  fetchAlarmRuleTypeMap,
  dealStatusENToCode,
} from '@services/storage/alarm';

const levelOrEnToColor = Object.entries(levelColorMap).reduce((hash, [level, color]) => {
  hash[level] = color;
  hash[levelENMap[level]] = color;
  return hash;
}, {});

export default {
  namespace: 'alarmDic',
  state: {
    levelDesc,
    levelCNMap,
    levelENMap,
    alarmStatusCNMap,
    dealStatusCNMap,
    levelColorMap,
    alarmAudioMap,
    levelGifMap,
    dealStatusENToCode,
    ruleTypeCodes: [],
    ruleTypeCNMap: {},
    enumEN: Object.entries(levelENMap).reduce((obj, [code, en]) => {
      obj[code] = en;
      obj[en] = code;
      return obj;
    }, {}),
    enumCN: Object.entries(levelCNMap).reduce((obj, [code, cn]) => {
      obj[code] = cn;
      obj[levelENMap[code]] = cn;
      return obj;
    }, {}),
    // 根据告警等级获取中文
    getLevelCH: (key) => {
      return levelCNMap[key] || '';
    },
    // 根据当前状态获取中文
    getAlarmStatusCH: (key) => {
      return dealStatusCNMap[key] || '';
    },
    // 根据事件获取中文
    getEventCH: (key) => {
      return alarmStatusCNMap[key] || '';
    },
    getLevelColor: (codeOrEN) => {
      return levelOrEnToColor[codeOrEN] || '';
    },
    getAudioInfo: (audioType) => {
      return alarmAudioMap[audioType] || { cn: '', src: '' };
    },
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *initial(_, { call, put }) {
      const { data: map } = yield call(fetchAlarmRuleTypeMap);
      yield put({ type: 'update', payload: { ruleTypeCodes: Object.keys(map), ruleTypeCNMap: map } });
    },
  },
  subscriptions: {},
};
