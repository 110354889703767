import * as environmentService from './environment';
import MqttIns from './mqtt.class';


const socket = new MqttIns();
global.window.wmqtt = socket;

export async function init() {
  if (socket.isValid) return socket;
  try {
    const dataRes = await environmentService.getMQTTOption();
    if (!dataRes.code) {
      const { ip, port, query = '', secure, username, password } = dataRes.data;
      // socket = io.connect({ protocol: 'mqtt', hostname: ip, path: '/mqtt', port, query });
      // socket = io.connect(`mqtt://${ip}`);
      socket.connect({ ip, port, query, secure, username, password });
    }
  } catch (e) {
    console.error('establish mqtt failed:', e);
  }

  if (socket.isInvalid) return;
  return socket;
}

export function publish(evname, data) {
  if (socket.isInvalid) { return; }
  socket.publish(evname, data, e => console.log(`Failed to publish, error: ${e}`));
}


export function safeSubChannel(evname, cb) {
  socket.subscribe(evname, cb);
}

export function isObserved(topic, observer) {
  return socket.isObserved(topic, observer);
}

export function isTopicSubscribed(topic) {
  return socket.isTopicSubscribed(topic);
}

export function unsubChannel(evname, callback) {
  socket.unsubscribe(evname, callback);
}

export function unregiterAll(){
  socket.unregiterAll();
}

// 用于 APP启动时 基础数据模型中的subscription中传入通用dispatch
export function watch(cb) {
  socket.registerDispatch(cb);
  // 按subscripton的要求返回一个取消监测的方法
  // TODO: 这里可能会需要销毁很多东西
  return () => {
    socket.end();
  };
}

// 从具体数据模型中传入topic和action
export function registerTopic(topic, action) {
  socket.subscribe(topic, action);
}
