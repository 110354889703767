import * as services from '@api/energyWeb/charge';
import { fillDatasByType } from '@/utils/tool';

const defaultQuery = {
  p: '',
  m: moment(),
  t: 'year',
};

export default {
  namespace: 'chargeBase',

  state: {
    cacheQuery: {
      ...defaultQuery,
    },
    datasTitle: {
      ...defaultQuery,
      v: 0,
    },
    datasGraph: [],
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetch ({ payload: { p, m } = {} }, { select, put, call }) {
      const stationId = yield select(state => state.app.saasStationId);
      const cacheQuery = yield select(state => state.chargeBase.cacheQuery);
      const measurePointsMap = yield select(state => state.charge.measurePointsMap);

      const filter = { p, m };
      const query = {
        ...cacheQuery,
        ...Object.keys(filter).reduce((acc, item) => {
          if (filter[item] !== undefined) acc[item] = filter[item];
          return acc;
        }, {}),
      };
      const { t: queryT, m: queryM } = query;

      const [fres] = yield call(services.queryBaseGraph, { stationId, ...query });

      const datas = fillDatasByType(queryT, queryM)(fres);

      const mn = moment();
      const nowM = mn.date() > measurePointsMap[query.p]?.statDay ? mn.add(1, 'months') : mn;
      const currentMonthItem = datas.find(i => i.t === nowM.month() + 1);

      const title = {
        ...currentMonthItem,
        m: nowM,
      };


      yield put({
        type: 'update',
        payload: {
          cacheQuery: query,
          datasTitle: title,
          datasGraph: datas,
        },
      });
    },
  },

  subscriptions: {
  },
};
