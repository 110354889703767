import { Slider } from 'antd';

import './index.scss';

function Index({ className, ...props }) {
  return (
    <Slider getTooltipPopupContainer={triggerNode => triggerNode} {...props} />
  );
}

export default Index;
