import * as services from '@services/storage/priceScheme';
import { dataProcessing, formatDetails } from '@/utils/toolFunction';
export default {
  namespace: 'vpriceSchemeHistory',
  state: {
    loading: false,
    dataList: [],
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *querPriceList ({ payload: { stationId } }, { call, put }) {
      const dataList = yield call(services.findHistoryFeePolicyByStationId, { stationId });
      yield put({ type: 'update', payload: { loading: false } });
      dataList.map((data) => {
        const feePolicy = data.feePolicy || {};
        const table = feePolicy.details || [];
        const seasonPrice = table[0] || {}; // 季节电价
        const unseasonPrice = table[1] || {}; // 非季节电价
        data.seasonPriceList = dataProcessing(seasonPrice);
        data.seasonPriceMounths = seasonPrice.months ? seasonPrice.months.split(',') : ['6', '7', '8', '9'];
        if (String(feePolicy.cycleBill) === '1') {
          data.unseasonPriceList = dataProcessing(unseasonPrice);
          data.unseasonPriceMounths = unseasonPrice.months ? unseasonPrice.months.split(',') : ['1', '2', '3', '4', '5', '10', '11', '12'];
        } else {
          data.unseasonPriceList = [{
            type: 1,
            num: '01',
            price: 1,
            dischargePrice: 0,
            time: ['00:00', '06:00'],
          }, {
            type: 2,
            num: '02',
            price: 1,
            dischargePrice: 0,
            time: ['06:00', '12:00'],
          }, {
            type: 3,
            num: '03',
            price: 1,
            dischargePrice: 0,
            time: ['12:00', '18:00'],
          }, {
            type: 4,
            num: '04',
            price: 1,
            dischargePrice: 0,
            time: ['18:00', '24:00'],
          }];
          data.unseasonPriceMounths = [];
        }
        return data;
      });
      if (dataList.length) {
        yield put({
          type: 'update',
          payload: {
            dataList,
          },
        });
      }
    },
    *deleteInfo (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          dataList: [],
        },
      });
    },
  },
};
