import * as services from '@api/energyWeb/charge';

export default {
  namespace: 'charge',

  state: {
    statDay: null,
    measurePoints: [],
    measurePointsMap: {},
    linePoints: [],
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetchBase(_, { put, take }) {
      yield put({
        type: 'update',
        payload: {
          measurePoints: [],
          linePoints: [],
        },
      });

      yield put({ type: 'fetchStatDay' });
      yield take('fetchStatDay/@@end');
      yield put({ type: 'fetchMeasurePoints' });
      yield put({ type: 'fetchLinePoints' });
    },

    *fetchStatDay(_, { select, put, call }) {
      const stationId = yield select(state => state.app.saasStationId);
      const [fres] = yield call(services.queryStatDay, { stationId });

      yield put({
        type: 'update',
        payload: {
          statDay: fres,
        },
      });
    },

    *fetchMeasurePoints(_, { select, put, call }) {
      const stationId = yield select(state => state.app.saasStationId);
      const [fres] = yield call(services.queryMeasurePoints, { stationId });

      const measurePointsMap = fres.reduce((acc, item) => {
        acc[item.key] = item;
        return acc;
      }, {});

      yield put({
        type: 'update',
        payload: {
          measurePoints: fres,
          measurePointsMap,
        },
      });
    },

    *fetchLinePoints(_, { select, put, call }) {
      const stationId = yield select(state =>state.app.saasStationId);
      const list = yield call(services.queryLinePoints, { stationId });
      const linePoints = list.map((item) => {
        item.id = item.deviceId;
        item.name = item.deviceName;
        return item;
      });

      yield put({
        type: 'update',
        payload: {
          linePoints,
        },
      });
    },
  },
  subscriptions: {

  },
};
