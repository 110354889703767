import { getData } from '@services/request';

/**
 * 根据 告警id 获取 工单id
 */
export async function queryOrderId ({ alarmRecordId }) {
  let fres = '';
  const data = await getData('repairTask', 'getOrderIdByAlarmId', {
    alarmId: alarmRecordId,
  });

  if (!data.code) {
    try {
      fres = data.data;
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}

/**
 * 根据 工单id 获取 工单详情
 */
export async function getBaseInfoByOrderId ({ orderId }) {
  let fres = {};
  const data = await getData('repairTask', 'getBaseInfoByOrderId', {
    orderId,
  });

  if (!data.code) {
    try {
      const d = (data.dataList[0] || {}).order;
      fres = {
        ...d,
        time: d.createTime,
        number: d.orderNo,
        level: d.faultLevel,
        status: d.orderStatus,
      };
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}

/**
 * 根据  告警规则id 获取 规则判据
 */
export async function queryAlarmRuleDesc ({ alarmId }) {
  let fres = [];
  const data = await getData('alarm', 'queryAlarmRuleByAlarmId', {
    alarmId,
  });

  if (!data.code) {
    try {
      fres = data.data.formulaList || [];
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, data];
}
