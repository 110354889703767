
const initialState = () => ({
  accountId: undefined,
  customerId: undefined,
  data: {},
});

export default {
  state: initialState(),
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
    clear() {
      return initialState();
    },
    injectUserInfo(_, { payload: userInfo = {} }) {
      return {
        accountId: userInfo.accountId,
        customerId: userInfo.customerId,
        data: userInfo,
        // enterpriseList: userInfo.enterpriseList
      };
    },
  },
};