import { getData } from '@services/request';

// 查询变压器数量
export async function fetchTransformCount({ stationId }) {
    let fres = {};

    const res = await getData('energyManagement', 'ylStatisticalTransformerExt', {
        stationId
    });

    if (res.code === null) {
        fres = res.data || {};
    }

    return [fres, res];
}
// 查询变压器列表
export async function fetchTransformList({ stationId }) {
    let fres = {
        datas: [],
        total: 0
    };
    const res = await getData('energyManagement', 'ylqueryAllTransformerExtByStationId', {
        stationId
    });
    const counts = await getData('energyManagement', 'ylStatisticalTransformerExt', {
        stationId
    });
    if (res.code === null && counts.code === null) {
        fres = {
            datas: (res.dataList || []).map(item => {
                return {
                    ...item,
                    key: item.id,
                    recordId: item.id,
                    name: item.name,
                    type: item.deviceType,
                    model: item.equipmentType,
                    productor: item.manufacturer,
                    launchDate: item.commissioningTime ? moment(item.commissioningTime).format('YYYY-MM-DD') : null,
                    managerName: item.personInCharge,
                    stationId: item.stationId,

                    totalRunTime: +item.totalRunTime, // 工作累计时长
                    runningState: item.runningState, // 运行状态
                    loadRate: _.isNil(item.loadRate) ? null : item.loadRate.toFixed(2), // 负载率
                    bodyTemperature: _.isNil(item.bodyTemperature) ? null : +(+item.bodyTemperature).toFixed(2), // 本体温度
                    loadRateAbnormalSituation: item.loadRateAbnormalSituation, // 负载率异常
                    temperatureAbnormalSituation: item.temperatureAbnormalSituation // 温度异常情况
                };
            }),
            total: res.totalCount || 0,
            countObj: {
                totalNumber: counts.data.totalNumber || 0,
                runningNumber: counts.data.runningNumber || 0,
                loadRateAbnormalNumber: counts.data.loadRateAbnormalNumber || 0,
                temperatureAnomalyNumber: counts.data.temperatureAnomalyNumber || 0
            }
        };
    }

    return [fres, res];
}

// 查询变压器列表
export async function queryTransformList(params) {
    const res = await getData('energyManagement', 'ylQueryAllTransformerExtByStationId', {
        ...params
    });

    return res;
}

// 查询重点用能设备
export async function queryAllKeyEnergyDeviceByStationId(params) {

    let data = {}
    let areaList=[]
    const res = await getData('energyManagement', 'pageQueryKeyEnergyDeviceByNameAndType', {
        ...params
    });

    // 风压机
    const list = res?.dataList?.filter(item => item.keyEnergyDeviceType === 700001).map(item => item.id)||[]
   if(list.length){
      const  realValueRes = await getData('dayougroup', 'stationRealValue', {
        deviceIds: list,
        metricCodes: ['00070004', '00070001', '02800007', '00070005'],
        timePeriod: 10 * 60 * 1000
      });
      const { data: deviceListRes } = await getData('energyManagement', 'getDeviceAndNodeTree', {
        deviceIds: list,
    });
      data = realValueRes.data;
      areaList= deviceListRes
   }

    if(res.dataList){
      res.dataList.forEach(item=>{
        item.areaName= areaList.find(_item=> _item.nodeName && _item.deviceId===item.id)?.nodeName||'--',
        item.ssll= `${data?.[`${item.id}@00070004`]}`
        item.ljll= `${data?.[`${item.id}@00070001`]}`
        item.gkwd= `${data?.[`${item.id}@02800007`]}`
        item.gkyl= `${data?.[`${item.id}@00070005`]}`
      })
    }


    return res;
}

// 查询变压器详情
export async function fetchTransformById({ id }) {
    let fres = {};

    const res = await getData('assetManagement', 'ylGetTransformerExtById', {
        id
    });

    const respon = await getData('assetManagement', 'underfind', {
        // 月最大负载率-- % 月最小负载率-- % 月最高温度-- ℃ 月最底温度-- ℃
        id
    });

    if (res.code === null) {
        const d = res.data || {};
        fres = {
            ...d,
            ...(respon.data || {}),
            name: d.deviceName,
            transformId: d.id,
            dateOfProduction: d.dateOfProduction ? moment(d.dateOfProduction) : null,
            commissioningTime: d.commissioningTime ? moment(d.commissioningTime) : null
        };
    }

    return [fres, res];
}

// 查询用能设备列表
export async function fetchEnergyDeviceList({ stationId }) {
    let fres = {
        datas: [],
        total: 0
    };

    // energyManagement/queryAllKeyEnergyDeviceByStationId
    const res = await getData('energyManagement', 'pageQueryKeyEnergyDeviceByNameAndType', {
        stationId,
        name: '',
        type: '',
        page: 1,
        length: 1000,
        platformType: 'm0'
    });
    let data = {}
    let areaList=[]
    // 风压机
    const list = res?.dataList?.filter(item => item.keyEnergyDeviceType === 700001).map(item => item.id)||[]
   if(list.length){
      const  realValueRes = await getData('dayougroup', 'stationRealValue', {
        deviceIds: list,
        metricCodes: ['00070004', '00070001', '02800007', '00070005'],
        timePeriod: 10 * 60 * 1000
      });
      const { data: deviceListRes } = await getData('energyManagement', 'getDeviceAndNodeTree', {
        deviceIds: list,
    });
      data = realValueRes.data;
      areaList= deviceListRes
   }
    if (res.code === null) {
        const airCompressorNumber = res.dataList.filter(item => item.keyEnergyDeviceType === 700001)?.length || 0;
        const fanNumber = res.dataList.filter(item => item.keyEnergyDeviceType === 700003)?.length || 0;
        const motorNumber = res.dataList.filter(item => item.keyEnergyDeviceType === 700004)?.length || 0;
        const waterPumpNumber = res.dataList.filter(item => item.keyEnergyDeviceType === 700002)?.length || 0;
        fres = {
            datas: (res.dataList || []).map(item => {
                return {
                    ...item,
                    ssll: `${data?.[`${item.id}@00070004`]}`,
                    ljll: `${data?.[`${item.id}@00070001`]}`,
                    gkwd: `${data?.[`${item.id}@02800007`]}`,
                    gkyl: `${data?.[`${item.id}@00070005`]}`,
                    areaName: areaList.find(_item=> _item.nodeName && _item.deviceId===item.id)?.nodeName||'--',
                    key: item.id,
                    recordId: item.id,
                    name: item.name,
                    type: item.keyEnergyDeviceType,
                    model: item.equipmentType,
                    productor: item.manufacturer,
                    launchDate: item.commissioningTime ? moment(item.commissioningTime).format('YYYY-MM-DD') : null,
                    managerName: item.personInCharge,
                    stationId: item.stationId,

                    totalRunTime: +item.totalRunTime, // 工作累计时长
                    powerRatio: _.isNil(item.powerRatio) ? null : (+item.powerRatio).toFixed(2), // 功率比(需求改为负载率)
                    cumulativeLoadingTime: +item.cumulativeLoadingTime, // 累积加载时间
                    cumulativeUnloadingTime: +item.cumulativeUnloadingTime, // 累积卸载时间
                    loadRate: _.isNil(item.loadRate) ? null : (+item.loadRate).toFixed(2), // 负载率
                    highLoadRateCumulativeTime: +item.highLoadRateCumulativeTime // 高负载率累积时长
                };
            }),
            total: res.totalCount || 0,
            countObj: {
                totalNumber: airCompressorNumber + fanNumber + motorNumber + waterPumpNumber,
                airCompressorNumber: airCompressorNumber, // 空压机台数
                waterPumpNumber: waterPumpNumber, // 水泵台数
                fanNumber: fanNumber, // 风机台数
                motorNumber: motorNumber // 电动机台数
            }
        };
    }

    return [fres, res];
}
// 查询用能设备详情
export async function fetchEnergyDeviceById({ id, type }) {
    let fres = {};

    const res = await getData('assetManagement', 'getKeyEnergyDeviceById', {
        id,
        type
    });

    if (res.code === null) {
        const d = res.data || {};
        fres = {
            ...d,
            deviceType: `${d.keyEnergyDeviceType || ''}`,
            insulationGrade: `${d.insulationGrade || ''}`,

            dateOfProduction: d.dateOfProduction ? moment(d.dateOfProduction) : null,
            commissioningTime: d.commissioningTime ? moment(d.commissioningTime) : null
        };
    }

    return [fres, res];
}
export async function queryPoints({ deviceId, metricCodes }) {
    let fres = [];
    const res = await getData('metric', 'queryPointByMonitoredObjectIdsAndMetricCodes', {
        monitoredObjectIds: JSON.stringify([deviceId]),
        metricCodes: JSON.stringify(metricCodes)
    });

    // 虚拟
    // res.dataList = [
    //   {
    //     availableMetricId: null,
    //     dataSource: "0",
    //     metricCode: "0211012B",
    //     monitoredObjectId: "61208c120839b6a0",
    //     monitoredObjectName: null,
    //     monitoredObjectType: 40021,
    //     monitoringLocation: "0",
    //     pointId: "79897131124543488",
    //     pointIdString: null,
    //     sensorId: null,
    //     stationId: "61108c12021f36a0",
    //     sysAvailData: 0,
    //     sysCreateTime: null,
    //     sysCreator: null,
    //     sysDeleted: 0,
    //     sysHash: null,
    //     sysUpdateTime: null,
    //     sysUpdater: null,
    //   },
    // ];
    if (res.code === null) {
        fres = (res.dataList || []).map(item => {
            return {
                ...item,
                id: item.pointId
            };
        });
    }
    return [fres, res];
}

export async function queryRawMetricDatum({ pointIds = [], startTime, endTime }) {
    let fres = [];
    if (!pointIds.length) return [fres, {}];

    const res = await getData('metricDatum', 'queryMetricDatum', {
        pointIds: JSON.stringify(pointIds),
        startTime,
        endTime,
        offset: 0,
        limit: -1,
        aggregator: JSON.stringify({
            interval: 1,
            unit: 'm',
            fillPolicy: 'previous'
        })
    });
    // res.code = null;
    // res.dataList = [
    //   {
    //     dataList: [
    //       { t: "1546272000000", v: "22.000000" },
    //       { t: "1548950400000", v: "23.000000" },
    //       { t: "1551369600000", v: "24.000000" },
    //     ],
    //     id: "79897131124543488",
    //   },
    // ];
    if (res.code === null) {
        fres = (res.dataList || [])
            .filter(i => i)
            .map(item => {
                return {
                    ...item,
                    id: item.id
                };
            });
    }
    return [fres, res];
}

export async function fetchChartList(params) {
    const res = await getData('dayougroup', 'stationRealCurve', params);
    return res
}

export const energyDeviceQueryMetricCodesMap = {
    0: [
        // 空压机
        {
            name: '瞬时流量',
            unit: 'm³/h',
            dec: 2,
            metricCodes: ['00070004']
        },
        {
            name: '累积流量',
            unit: 'm³/h',
            dec: 2,
            metricCodes: [
                '00070001', // 日累计加载时长
            ]
        },
        {
            name: '工况温度',
            unit: '℃',
            dec: 2,
            metricCodes: [
                '02800007' // 日加卸载次数
            ]
        },
        {
            name: '工况压力',
            unit: 'kPa',
            dec: 2,
            metricCodes: [
                '00070005' // 日加卸载次数
            ]
        }
    ],
    // 0: [
    //     // 空压机
    //     {
    //         name: '功率',
    //         unit: 'kW',
    //         dec: 2,
    //         metricCodes: ['02030000']
    //     },
    //     {
    //         name: '工作时长',
    //         unit: 'h',
    //         dec: 2,
    //         metricCodes: [
    //             '00070047', // 日累计加载时长
    //             '00070049' // 日累计卸载时长
    //         ]
    //     },
    //     {
    //         name: '加卸载频率',
    //         unit: '次数',
    //         dec: 2,
    //         metricCodes: [
    //             '00070040' // 日加卸载次数
    //         ]
    //     }
    // ],
    1: [
        // 水泵
        {
            name: '负载率',
            unit: '%',
            dec: 2,
            metricCodes: ['020F0001']
        },
        {
            name: '工作时长',
            unit: 'h',
            dec: 2,
            metricCodes: [
                '0007004E', // 日高负载率累计时长
                '0007004B' // 日累计工作时长
            ]
        },
        {
            name: '启停频率',
            unit: '次数',
            dec: 2,
            metricCodes: [
                '00070042' // 日启停频率
            ]
        }
    ],
    2: [
        // 风机
        {
            name: '负载率',
            unit: '%',
            dec: 2,
            metricCodes: ['020F0001']
        },
        {
            name: '工作时长',
            unit: 'h',
            dec: 2,
            metricCodes: [
                '0007004E', // 日高负载率累计时长
                '0007004B' // 日累计工作时长
            ]
        },
        {
            name: '启停频率',
            unit: '次数',
            dec: 2,
            metricCodes: [
                '00070042' // 日启停频率
            ]
        }
    ],
    3: [
        // 电动机
        {
            name: '负载率',
            unit: '%',
            dec: 2,
            metricCodes: ['020F0001']
        },
        {
            name: '工作时长',
            unit: 'h',
            dec: 2,
            metricCodes: [
                '0007004E', // 日高负载率累计时长
                '0007004B' // 日累计工作时长
            ]
        },
        {
            name: '启停频率',
            unit: '次数',
            dec: 2,
            metricCodes: [
                '00070042' // 日启停频率
            ]
        }
    ]
};

