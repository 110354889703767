import { alarmLevelLeftHigherThanRight, levelENMap } from '@services/storage/alarm';

const getKey = () => new Date().getTime();

const getInitialState = () => ({ audioSrc: '', audioKey: getKey(), playTime: 0, alarmLevel: null });

export default {
  namespace: 'alarmAudio',
  state: getInitialState(),
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    clear (state) {
      const playIns = state.playIns;
      if (playIns) window.clearTimeout(playIns);
      return getInitialState();
    },
  },
  effects: {
    *play ({ payload: { audioSrc, playTime, alarmLevel } }, { put, select }) {
      const prevAlarmLevel = yield select(state => state.alarmAudio.alarmLevel);
      if ((alarmLevel in levelENMap) && playTime && (alarmLevel === prevAlarmLevel || alarmLevelLeftHigherThanRight(alarmLevel, prevAlarmLevel))) {
        yield put({ type: 'update', payload: { audioSrc, playTime, alarmLevel, audioKey: getKey() } });
      }
    },
    *monitoring (_, { put, take }) {
      while (true) {
        const action = yield take(['PLAY_ALARM_AUDIO', 'STOP_ALARM_AUDIO']); // action triggered
        if (action.type === 'PLAY_ALARM_AUDIO') {
          yield put({ type: 'play', payload: action.payload });
        }
        if (action.type === 'STOP_ALARM_AUDIO') {
          yield put({ type: 'clear' });
        }
      }
    },
  },
  subscriptions: {
    initial ({ dispatch }) {
      dispatch({ type: 'monitoring' });
    },
  },
};
