import * as services from '@/services/dict';

export default {
  state: {
    // dicType0: { value: name },
    // customName0: { value: name },
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetchByType({ payload: { types } = {} }, { call, put, all }) {
      if (!Array.isArray(types)) return;

      const [typesQuery, updaterCustom] = types.reduce((acc, item) => {
        if (typeof item === 'number' && !Number.isNaN(item)) {
          acc[0].push({ type: item });
          return acc;
        }

        if (Object.prototype.toString.call(item) === '[object Object]') {
          const { type, customName, customMap } = item;

          if (typeof customName === 'string' && Object.prototype.toString.call(customMap) === '[object Object]') {
            acc[1][customName] = customMap;
          } else if (type) {
            acc[0].push({ type, customName });
          }
        }

        return acc;
      }, [[], {}]);

      const res = yield all(typesQuery.map(i => call(services.getListDicConstantCache, { dicType: i.type })));
      const updaterQuery = typesQuery.reduce((acc, item, index) => {
        const [fres] = res[index];
        const { type, customName } = item;

        acc[type] = fres;
        if (customName) acc[customName] = fres;

        return acc;
      }, {});

      yield put({
        type: 'update',
        payload: {
          ...updaterQuery,
          ...updaterCustom,
        },
      });
    },
  },

  subscriptions: {
  },
};
