import { routerRedux } from 'dva';
import * as services from '@services/storage/user';
import * as storage from '@services/storage';
import * as socket from '@services/storage/socketCluster';
import moment from 'moment';

export default {
  namespace: 'stroageUser',

  state: {
    data: {},
    currentUser: '',
    serverInfo: {},
    menuList: [],
    lastLogin: null,
    // spread data
    // userType
  },

  reducers: {
    clearUpdate (_, { payload }) {
      return { ...payload };
    },
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetch (_, { put, call, select }) {
      // const user = storage.get('user');   // 最差的情况是仅有 login 返回的字段
      const token = storage.get('token') || localStorage.getItem('token');
      let user = yield select(state => state.user.data);
      if (!token) {
        yield put({ type: 'logout' });
        return;
      }
      if (!user.accountId) {
        const loginRes = yield call(services.getUserInfoByToken);
        if (loginRes.code) {
          yield put({ type: 'logout' });
          return;
        }
        user = loginRes.data;
      }
      const { accountId } = user;
  
      // 获取更详细的用户信息
      const [fres] = yield call(services.queryUserInfo, { accountId });
      // 获取用户的菜单权限；
      const menuList = yield call(services.getMenuPermissionsByAccountId, { accountId });
      const data = {
        ...user,
        serverInfo: fres || {},
        serviceProviderId: fres?.serviceProviderId,
      };
      yield put({
        type: 'update',
        payload: {
          ...data,
          data,
          menuList,
          lastLogin: storage.get('lastLogin'),
        },
      });

      // 统计登录账号
      // webStat.setAccount(data.user);

      // 重新注入 storage hash，获取更好的性能
      storage.set('user', data);
      storage.set('token', token);
      storage.set('menuList', menuList);

      return data;
    },

    *checkLogin (_, { put }) {
      const user = storage.get('user');
      const token = storage.get('token') || localStorage.getItem('token');
      if (!(typeof user === 'object' && Object.keys(user).length > 0 && token)) {
        yield put(routerRedux.push('/login'));
      }
    },

    *login ({ payload: { username, password, exp } }, { put, call }) {
      const [user, data] = yield call(services.login, {
        username,
        password,
        exp: exp ? 365 : 1,
      });

      if (Object.keys(user).length) {
        const { customerId: id, token } = user;
        yield put({
          type: 'update',
          payload: {
            ...user,
            data: user,
            currentUser: id,
          },
        });

        yield put({
          type: 'base/update',
          payload: {
            currentUser: id,
            currentFacilitator: id,
          },
        });

        storage.set('user', user);
        storage.set('token', token);
        storage.set('lastLogin', user.lastLogin);

        yield put({ type: 'base/reLocate' });
      } else {
        // 处理登录错误
        return data;
      }
    },
    *updateLastLogin () {
      storage.set('lastLogin', moment().valueOf());
    },
    *sendSmsMessage ({ payload, callBack = () => { } }, { call }) {
      const data = yield call(services.sendSmsMessage, { ...payload });
      callBack(data);
    },
    *updatePhone ({ payload, callBack = () => { } }, { call }) {
      const data = yield call(services.updatePhone, { ...payload });
      callBack(data);
    },
    *updatePassword ({ payload, callBack = () => { } }, { call }) {
      const data = yield call(services.updatePassword, { ...payload });
      callBack(data);
    },
    *loglogout (_, { call, put }) {
      yield call(services.logout);
    },
    *logout (_, { put, call }) {
      // 清空 token
      storage.remove('token');
      storage.remove('menuList');

      // 清空 user
      storage.remove('user');
      yield put({
        type: 'clearUpdate',
        payload: {
          data: {},
          currentUser: '',
          serverInfo: {},
        },
      });

      // 清空站点
      yield put({
        type: 'station/update',
        payload: {
          list: [],
          hash: {},
        },
      });

      // 清空 base 中相关信息
      yield put({
        type: 'base/update',
        payload: {
          currentUser: '',
          currentStation: '',
        },
      });

      // 清空
      yield put({
        type: 'home/deleteData',
        payload: {
          topList: [],
          earnings: [],
          recharge: [],
          discharge: [],
        },
      });

      yield put(routerRedux.push('/login'));
      yield call(socket.unregiterAll);
    },
  },

  subscriptions: {
  },
};
