import { getData } from '@/services/request';
import CheckableTag from 'antd/lib/tag/CheckableTag';

function fixNumber (value) {
  if (value === null || value === undefined) return null;
  return (+value).toFixed(2);
}

function fixNumberHundred (value) {
  if (value === null || value === undefined) return null;
  return (+value * 100).toFixed(2);
}

export async function queryOverviewStatDays ({ stationId }) {
  let fres = [];

  const res = await getData('energyManagement', 'ylSelBillDayByStationId', {
    stationId,
  });

  if (res.code === null) {
    const d = res.data || {};
    fres = [
      d.electricityBillDay,
      d.wateBillDay,
      d.gasBillDay,
      d.steamBillDay,
      d.hotBillDay,
    ].filter(Boolean).map(Number);
  }

  return [fres, res];
}

export async function queryMeasurePoints ({ stationId }) {
  let fres = [];

  const res = await getData('energyManagement', 'selMeasurePointDtoByStationId', {
    stationId,
    energyType: '500001'
  });

  if (res.code === null) {
    fres = (res.dataList || []).map((item) => {
      return {
        ...item,
        key: item.id,
        name: item.measurementPointName,
      };
    });
  }

  const res2 = fres.length ? await getData('energyManagement', 'selBillDateByMeasurePointIdList', {
    measurePointIdList: JSON.stringify(fres.map(i => i.key)),
  }) : { dataList: [] };

  if (res2.code === null) {
    const d = res2.dataList || [];
    const map = d.reduce((acc, item) => {
      acc[item.measurePointId] = +item.billDate;
      return acc;
    }, {});
    fres = fres.map((item) => {
      return {
        ...item,
        statDay: map[item.key],
      };
    });
  }

  return [fres, res];
}

export async function queryOverviewDatasMeasurePoint ({ p, m }) {
  const month = () => {
    let mm = Number(m.format('MM')) + 1;
    if (mm >= 12) {
      return 1;
    } else {
      return mm
    }
  };

  const year = () => {
    let mm = Number(m.format('MM')) + 1;
    let yy = Number(m.format('YYYY'));
    if (mm >= 12) {
      return yy += 1;
    } else {
      return yy;
    }
  };

  let fres = {};

  const res = await getData('energyManagement', 'ylElectricityConstitute', {
    measurePointId: p,
    year: year(),
    month: month(),
  });

  if (res.code === null) {
    const d = res.data || {};
    fres = {
      v0: d.monthTotalBills,
      q0: d.monthTotalBillsQOQ,
      v1: d.baseBills,
      q1: d.baseBillsQOQ,
      v2: d.electricityBills,
      q2: d.electricityBillsQOQ,
      v3: d.forceBills,
      q3: d.forceBillsQOQ,

      s0: d.tipBills,
      s1: d.peakBills,
      s2: d.flatBills,
      s3: d.valleyBills,

      t0: +d.category,
      t1: +d.calculateMethod,
    };
  }

  return [fres, res];
}

export async function queryOverviewDatasEnergyType ({ stationId, energyType, m }) {
  const month = () => {
    let mm = Number(m.format('MM')) + 1;
    if (mm >= 12) {
      return 1;
    } else {
      return mm
    }
  };

  const year = () => {
    let mm = Number(m.format('MM')) + 1;
    let yy = Number(m.format('YYYY'));
    if (mm >= 12) {
      return yy += 1;
    } else {
      return yy;
    }
  };

  let fres = {
    data: {},
    graph: [],
    start: null,
    end: null,
  };

  const res = await getData('energyManagement', 'ylTotalBills', {
    stationId,
    energyType: String(energyType),
    year: year(),
    month: month(),
  });

  if (res.code === null) {
    const d = res.data?.historyMetricDataList || [];
    const d2 = res.data || {};
    const start = res.data?.startTime;
    const end = res.data?.endTime;
    const fd = d.filter(i => i.v !== null).map(i => +i.v);
    const avg = fd.reduce((a, i) => a + i, 0) / fd.length;

    fres = {
      data: {
        value: d2.monthTotalBills,
        yoy: d2.monthTotalBillsYOY,
        qoq: d2.monthTotalBillsQOQ,
        statDay: d2.billDate,
      },
      graph: d.map((item) => {
        return {
          ...item,
          t: item.t,
          v: fixNumber(item.v),
          v1: fixNumber(item.v),
          v2: fixNumber(avg),
        };
      }),
      start,
      end,
    };
  }

  return [fres, res];
}

export async function queryBaseGraph ({ stationId, p, t, m }) {
  let fres = [];

  const res = await getData('energyManagement', 'electricityAnalysis', {
    stationId,
    measurePointId: p,
    year: t === 'year' ? m.format('YYYY') : null
  });

  if (res.code === null) {
    fres = (res.dataList || []).map((item) => {
      return {
        ...item,
        id: item.id,
        t: item.t,
        v: fixNumber(item.v),
        v1: fixNumber(item.v),
        v2: fixNumber(item.checkDemand),
        v3: fixNumber(item.capacity),
        occurTime: item.occurTime,
      };
    });
  }

  return [fres, res];
}

export async function queryQuantityGraph0 ({ stationId, p, t, m }) {
  const month = () => {
    let mm = Number(m.format('MM')) + 1;
    if (mm >= 12) {
      return 1;
    } else {
      return mm
    }
  };

  const year = () => {
    let mm = Number(m.format('MM')) + 1;
    let yy = Number(m.format('YYYY'));
    if (mm >= 12) {
      return yy += 1;
    } else {
      return yy;
    }
  };

  let fres = {
    datas: [],
    avg: null,
    start: null,
    end: null,
  };

  const res = await getData('energyManagement', 'electricityRateAnalysis', { // 接口1 入参多了一个月
    stationId,
    measurePointId: p,
    year: year(),
    month: t === 'month' ? month() : null
  });

  if (res.code === null) {
    const d2 = res.data?.pvRatios || {};
    const start = res.data?.startTime;
    const end = res.data?.endTime;
    const avg = res.data?.tolPVRatio || null;

    const d = Object.keys(d2).map((k) => {
      const item = d2[k];
      return {
        t: k,
        v: item,
      };
    });

    fres = {
      datas: d.map((item) => { // 第一个柱状图的数据
        if (t === 'year') {
          return {
            ...item,
            // t: item.t - 86400000,
            t: item.t,
            v: fixNumberHundred(item.v),
            v1: fixNumberHundred(item.v),
            v2: fixNumberHundred(avg),
            dateType: t,
          };
        } else {
          return {
            ...item,
            t: item.t,
            v: fixNumberHundred(item.v),
            v1: fixNumberHundred(item.v),
            v2: fixNumberHundred(avg),
            dateType: t,
          };
        }

      }),
      avg: fixNumberHundred(avg),
      start,
      end,
    };
  }

  return [fres, res];
}

export async function queryQuantityGraph1 ({ stationId, p, t, m }) {
  const month = () => {
    let mm = Number(m.format('MM')) + 1;
    if (mm >= 12) {
      return 1;
    } else {
      return mm
    }
  };

  const year = () => {
    let mm = Number(m.format('MM')) + 1;
    let yy = Number(m.format('YYYY'));
    if (mm >= 12) {
      return yy += 1;
    } else {
      return yy;
    }
  };

  let fres = {
    datas: [],
    avg: null,
    start: null,
    end: null,
  };

  const res = await getData('energyManagement', 'averageTariff', { // 接口2，多传一个月
    stationId,
    measurePointId: p,
    year: year(),
    month: t === 'month' ? month() : null
  });

  if (res.code === null) {
    const d2 = res.data?.avgElecPrices || {};
    const start = res.data?.startTime;
    const end = res.data?.endTime;
    const avg = res.data?.tolAvgElecPrice || null;

    const d = Object.keys(d2).map((k) => {
      const item = d2[k];
      return {
        t: k,
        v: fixNumber(item),
      };
    });

    fres = {
      datas: d.map((item) => {
        if (t === 'year') {
          return {
            ...item,
            // t: item.t - 86400000,
            t: item.t,
            v: fixNumber(item.v),
            v1: fixNumber(item.v),
            v2: fixNumber(avg),
            dateType: t,
          };
        } else {
          return {
            ...item,
            t: item.t,
            v: fixNumber(item.v),
            v1: fixNumber(item.v),
            v2: fixNumber(avg),
            dateType: t,
          };
        }
      }),
      avg: fixNumber(avg),
      start,
      end,
    };
  }

  return [fres, res];
}

export async function queryAdjustGraph ({ stationId, p, t, m }) {
  let fres = [];

  const res = await getData('energyManagement', 'forceAnalysis', {
    stationId,
    measurePointId: p,
    year: t === 'year' ? m.format('YYYY') : null,
  });

  if (res.code === null) {
    fres = (res.dataList || []).map((item) => {
      return {
        ...item,
        id: item.id,
        t: item.t,
        v: fixNumber(item.v),
        v1: fixNumber(item.v),
        v2: fixNumber(item.line),
        v3: fixNumber(item.sum),
      };
    });
  }

  return [fres, res];
}

export async function queryStatDay ({ stationId }) {
  let fres = null;

  const res = await getData('fee', 'queryPowerPriceByOwnerIdAndEndTime', {
    ownerId: stationId,
    endTime: null,
    fillPeriods: false,
  });

  if (res.code === null) {
    const d = res.data || {};
    fres = d.billDay ? d.billDay : null;
  }

  return [fres, res];
}


// 查询进线;
export async function queryLinePoints ({ stationId }) {
  const res = await getData('energyManagement', 'selDeviceByDeviceTypeAndStationId', {
    stationId,
  });
  return res.dataList || [];
}
