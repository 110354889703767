import React from 'react';
import { composeClass } from '@/utils/tool';
import styles from './Text.scss';

class FormText extends React.PureComponent {
  state = {
    value: this.props.value,
  }

  static getStateDerivedFromProps(nextProps) {
    return { value: nextProps.value };
  }

  render() {
    const { className = '', style = {} } = this.props;
    return (<div className={composeClass(styles.text, className)} style={style}>{this.state.value}</div>);
  }
}

export default FormText;
