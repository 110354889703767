import { readProp } from '@/utils/tool';
import * as services from '@/services/energy/station';
import * as storage from '@/services/energy/storage';

export default {
  namespace: 'energy_station',

  state: {
    list: [],
    hash: {},
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    clear () {
      return { list: [], hash: {} };
    },
  },

  effects: {
    *fetch ({ payload: { providerId, accountId } = {} }, { call, put }) {
      if (!providerId) return;

      yield put({ type: 'clear' });

      yield put({ type: 'base/update', payload: { currentStation: '' } });

      const [stations] = yield call(services.queryStationByServiceProviderIds, { providerId });
      const hash = stations.reduce((acc, item) => {
        acc[item.stationId] = item;
        return acc;
      }, {});

      const defaultStation = readProp(storage.get('defaultStation'))(accountId, stations[0]?.id || '');

      storage.set('defaultStation', {});
      yield put({
        type: 'update',
        payload: {
          list: stations,
          hash,
        },
      });

      yield put({
        type: 'base/update',
        payload: {
          currentStation: defaultStation,
        },
      });
    },
  },

  subscriptions: {},
};
