import { Radio, DatePicker, ConfigProvider } from 'antd4';
import zh_CN from 'antd4/es/locale/zh_CN';
import style from './style.scss';
import { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { type } from 'os';
const { RangePicker } = DatePicker;
const timeType = ['date', 'month', 'year'];
export const startdateFormat = 'YYYY-MM-DD 00:00:00';
export const enddateFormat = 'YYYY-MM-DD 23:59:59';

const supportRadioList = [
  {
    label: '日',
    value: 'date'
  },
  {
    label: '月',
    value: 'month'
  },
  {
    label: '年',
    value: 'year'
  }
];
function IemTimeRange (props) {
  const {
    onChange,
    isString = false, //返回格式 true：YYYY-MM-DD 00:00:00   false：时间戳
    postPicker = 'date', //默认时间类型 date | month | year|range
    havaCustom = false, //是否包含自定义时间
    types = ['date', 'month', 'year'],
    defaultValue = [moment(), moment()],
    canUseFutureDate = false,
    widthSize,
    rangeAllowClear = false,//范围选择器是否显示清除按钮
    isBeforeToday = false,//不可以大于今天
  } = props;

  const radioList = supportRadioList.filter(item => types.includes(item.value));

  const list = useMemo(() => {
    let finalArr = _.cloneDeep(radioList);
    if (havaCustom) {
      finalArr.push({ label: '自定义', value: 'range' });
    }
    return finalArr;
  }, [havaCustom, radioList]);

  const [picker, setPicker] = useState(postPicker);

  const renderType = useCallback(value => {
    // 1代表日，2代表月，3代表年
    let index =
      radioList.findIndex(item => {
        return item.value === value;
      }) + 1;
    return index || 1;
  }, []);

  const [time, setTime] = useState(defaultValue);

  const dealParam = useCallback(
    source => {
      let finalArray = [moment().format(startdateFormat), moment().format(enddateFormat)];
      if (source._isAMomentObject) {
        if (picker === 'date') {
          finalArray = [moment(source).format(startdateFormat), moment(source).format(enddateFormat)];
        } else {
          finalArray = moment(source).isSame(new Date(), picker)
            ? [
              moment(source)
                .startOf(picker)
                .startOf('day')
                .format(startdateFormat),
              moment().format(enddateFormat)
            ]
            : [
              moment(source)
                .startOf(picker)
                .startOf('day')
                .format(startdateFormat),
              moment(source)
                .endOf(picker)
                .endOf('day')
                .format(enddateFormat)
            ];
        }
      } else {
        if (source !== 'date') {
          finalArray = [
            moment()
              .startOf(source)
              .startOf('day')
              .format(startdateFormat),
            moment().format(enddateFormat)
          ];
        }
      }
      let finalArrayStamp = [moment(finalArray[0]).valueOf(), moment(finalArray[1]).valueOf()];
      onChange({
        value: isString ? finalArray : finalArrayStamp,
        type: renderType(source._isAMomentObject ? picker : source)
      });
    },
    [picker, onChange, isString]
  );

  const [dates, setDates] = useState(null);

  const disabledDate = useCallback(
    current => {
      if (!dates) {
        return false;
      }
      if (isBeforeToday) {
        return current && current > moment().subtract(1, "days").endOf('day')
      }
      const tooLate = dates[0] && current.diff(dates[0], 'days') > 30;
      const tooEarly = dates[1] && dates[1].diff(current, 'days') > 30;

      return !!tooEarly || !!tooLate;
    }, [dates]);

  const onOpenChange = useCallback(open => {
    if (open) {
      setDates([null, null]);
    } else {
      setDates(null);
    }
  }, []);


  const disabledFutureDate = (current) => {
    // 获取当前日期
    const today = new Date();
    // 只允许选择今天之前的日期
    return canUseFutureDate ? false : (current && current > today);
  }

  const PickerWithType = useMemo(() => {
    let IsType = timeType.includes(picker);
    return IsType ? (
      <DatePicker
        value={moment(time[0])}
        picker={picker}
        allowClear={false}
        onChange={value => {
          dealParam(value);
          setTime([value, value]);
        }}
        disabledDate={disabledFutureDate}
      />
    ) : (
      <RangePicker
        allowClear={rangeAllowClear}
        value={dates || time}
        style={{width: widthSize ? '150px': '100%'}}
        onChange={val => {
          if (val) {
            setTime(val);
            let time = [val[0].format(startdateFormat), val[1].format(enddateFormat)];
            let timeStamp = [
              moment(val[0].format(startdateFormat)).valueOf(),
              moment(val[1].format(enddateFormat)).valueOf()
            ];
            onChange({
              value: isString ? time : timeStamp,
              type: renderType(picker)
            });
          } else {
            setTime([]);
            onChange({
              value: [],
              type: renderType(picker)
            });
          }

        }}
        onOpenChange={onOpenChange}
        disabledDate={disabledDate}
        onCalendarChange={val => setDates(val)}
      />
    );
  }, [picker, timeType, time, dates]);

  return (
    <ConfigProvider locale={zh_CN} prefixCls="antd4">
      <div className={style.mainWrap}>
        {list?.length > 1 ? (
          <Radio.Group
            className={style.radioWrap}
            style={{ minWidth: havaCustom ? '160px' : '100px' }}
            onChange={e => {
              let value = e.target.value;
              setPicker(value);
              setTime([moment(), moment()]);
              dealParam(value);
            }}
            value={picker}
          >
            {list.map(item => {
              return (
                <Radio.Button key={item.value} value={item.value}>
                  {item.label}
                </Radio.Button>
              );
            })}
          </Radio.Group>
        ) : null}
        {PickerWithType}
      </div>
    </ConfigProvider>
  );
}

export default IemTimeRange;
