import { subChannelInModel, unsubChannelInModel } from '@/services/socketCluster';
export default {
  namespace: 'exceptionSocket',
  state: {

  },

  subscriptions: {
  },

  // ...
  effects: {
    *subscribe({ payload }, { call }) {
      yield call(subChannelInModel, `dayou_alarm_test`, payload.receiveFunc);
    },
    *unsub({ payload }, { call }) {
      yield call(unsubChannelInModel, `dayou_alarm_test`, payload.receiveFunc);
    },
  },

  reducers: {
    update(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
  },
};
