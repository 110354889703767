import axios, { AxiosRequestConfig, Canceler } from 'axios';
import storage from './storage-zdy';
import proxy from '../../config/proxy';
import { handleResetEnterpriseId, isScreen } from '@/utils/toolFunction';
import { GROUP_ROUTE_PATHS } from '../constant';
axios.defaults.withCredentials = true;

export default function(config, setting = {}) {
    const CancelToken = axios.CancelToken;
    const { notCheckToken, ...axiosSetting } = setting;

    const headers = {
        'Content-Type': 'application/json',
        'access-control-allow-origin': '*',
        crossDomain: 'true',
        Accept: '*/*',
        Authorization: `Bearer ${isScreen()}`
    };
    const newConfig = {
        ...config,
        headers,
        ...axiosSetting,
        baseURL: window.location.href.split('/')[0]
    };

    const { isFile } = setting;

    let jumpUrl = '';

    if (newConfig?.data) {
        newConfig.data = handleResetEnterpriseId(newConfig.data);
    }

    if (setting.abortFn) {
        newConfig.cancelToken = new CancelToken(function executor(cancelFn) {
            setting.abortFn = cancelFn;
        });
    }
    return axios(newConfig)
        .then(response => {
            if (isFile && response.status === 204) {
                return Promise.reject(false);
            } else if (isFile && !response.data.code) {
                return response.data;
            } else
                switch (response.data.code) {
                    case 200:
                        return response.data;
                    case '200':
                        return response.data;
                    case '999':
                        return response.data;
                    case '500':
                        return response.data;
                    case '-10001':
                        return response.data;
                    case 'ServiceUnavailable':
                        return {};
                    case '-10003':
                        return {};
                    case null:
                        return response.data;
                    case 396: // 未登录，请先登录
                        jumpUrl = '/login';

                        break;
                    case 397: // 登陆已过期，请重新登陆
                        jumpUrl = '/login';
                        break;
                }
            if (!notCheckToken && jumpUrl) {
                setTimeout(() => {
                    window.location.href = jumpUrl;
                }, 2000);
            }

            return Promise.reject(response.data);
        })
        .catch(e => {
            if (e.response && e.response.data) {
                return Promise.reject(e.response.data);
            }
            return Promise.reject(e);
        });
}

