import * as React from 'react';
import styles from './Blank.scss';

function Blank(props) {
  const { children, className, block = false, width, height, style, ...restProps } = props;
  const mergeStyle = { width, height, ...style };
  return (
    <div className={[styles.ui, block ? styles.block : '', className]} style={mergeStyle} {...restProps}>
      <svg
        viewBox="0 0 100 100"
        stroke="#E3E6ED"
        strokeWidth="1"
        fill="none"
        preserveAspectRatio="none"
      >
        <path d="M 0 0 L 100 100 M 0 100 L 100 0" />
      </svg>
      {children ? <span>{children}</span> : null}
    </div>
  );
}

export default Blank;
