import PropTypes from 'prop-types';
import { isUndefined, cloneDeep } from 'lodash';
import Radio from '../Radio';
import DatePicker, { MonthPicker, YearPicker } from './index';

import styles from './SwitchPicker.scss';

class Index extends React.Component {
  static enumMap = {
    day: '日',
    month: '月',
    year: '年',
  }

  static enum = {
    day: 'day',
    month: 'month',
    year: 'year',
  }

  static propTypes = {
    className: PropTypes.string,
    types: PropTypes.arrayOf((propValue, key, componentName) => {
      if (!Index.enumMap[propValue[key]]) {
        return new Error(`${componentName}: types item should be one of SwitchPicker.enum`);
      }
    }),
    selectedType: PropTypes.oneOf(Object.values(Index.enum)),
    onChange: PropTypes.func,
  }

  static defaultProps = {
    className: null,
    types: [Index.enum.day, Index.enum.month],
    selectedType: null,
    onChange: () => { },
  }

  state = {
    value: moment(),
    monthValue: moment(),
    selectedType: this.props.types ?.[0] || Index.enum.day,
  }

  componentDidUpdate(prevProp) {
    if (JSON.stringify(this.props.types) !== JSON.stringify(prevProp.types)) {
      const selectedType = this.props.types ?.[0] || Index.enum.day;
      this.setState({ selectedType });
      this.onChange(this.state.value, this.state.monthValue, selectedType);
    }
  }

  onChange = (value, monthValue, selectedType, ...args) => {
    const { onChange } = this.props;
    onChange(cloneDeep(selectedType === 'month' ? monthValue : value), selectedType, ...args);
    this.setState({
      value,
      monthValue,
      selectedType,
    });
  }

  render() {
    const {
      className,
      style,
      value,
      types,
      selectedType,
      ...rest
    } = this.props;
    const { value: valueState, monthValue: monthValueState, selectedType: selectedTypeState } = this.state;

    const valueRender = isUndefined(value) ? valueState : cloneDeep(value);
    const monthValueRender = isUndefined(value) ? monthValueState : cloneDeep(value);
    const selectedTypeRender = selectedType || selectedTypeState;

    const dateProps = {
      allowClear: false,
      disabledDate: (current) => {
        return current && current.startOf(selectedTypeRender).valueOf() > Date.now();
      },
    };

    return (
      <div className={[className, styles.wrap]} style={style}>
        {
          types.length > 1 ?
            <Radio.Group
            className={styles.switcher} value={selectedTypeRender} onChange={({ target: { value: v } }) => {
                if(v!==selectedType){
                  this.onChange(moment(), moment(), v)
                }else{
                  this.onChange(valueRender, monthValueRender, v)
                }        
            }}>
              {types.map(i => <Radio.Button key={i} tight value={i}>{Index.enumMap[i]}</Radio.Button>)}
            </Radio.Group> :
            null
        }
        {  /* eslint-disable no-unused-expressions, semi */
          do {
            if (selectedTypeRender === Index.enum.day) {
          <DatePicker
            {...dateProps}
            {...rest}
            value={valueRender}
            onChange={v => this.onChange(v, monthValueRender, selectedTypeRender)}
          />
        } else if (selectedTypeRender === Index.enum.month) {
          <MonthPicker
            {...dateProps}
            {...rest}
            value={monthValueRender}
            onChange={v => this.onChange(valueRender, v, selectedTypeRender)}
          />
        } else if (selectedTypeRender === Index.enum.year) {
          <YearPicker
            {...dateProps}
            {...rest}
            value={valueRender}
            onChange={v => this.onChange(v, monthValueRender, selectedTypeRender)}
          />
        } else {
          null
        }
        }  /* eslint-enable */
      }
      </div>
    );
  }
}

export default Index;
