import * as services from '@/services/address';

export default {
  namespace: 'address',
  state: {
    addrProvinceMap: {},
    addrDowntownMap: {},
    addrCountyMap: {},
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetchMap({ payload: { code, type = 0, isClear = true } = {} }, { call, put }) {
      if (type === 0) {
        const [fres] = yield call(services.queryAllProvince);

        const r = isClear ? {
          addrDowntownMap: {},
          addrCountyMap: {},
        } : {};

        yield put({
          type: 'update',
          payload: {
            addrProvinceMap: fres,
            ...r,
          },
        });

        yield put({ type: 'fetchAddrMapType0Finished' });
      } else if (type === 1) {
        const [fres] = yield call(services.querySubArea, { code, type: '01' });

        const r = isClear ? {
          addrCountyMap: {},
        } : {};

        yield put({
          type: 'update',
          payload: {
            addrDowntownMap: fres,
            ...r,
          },
        });

        yield put({ type: 'fetchAddrMapType1Finished' });
      } else if (type === 2) {
        const [fres] = yield call(services.querySubArea, { code, type: '02' });

        yield put({
          type: 'update',
          payload: {
            addrCountyMap: fres,
          },
        });

        yield put({ type: 'fetchAddrMapType2Finished' });
      }
    },

    *batchFetchMap({ payload: { codes = {} } }, { take, put }) {
      yield put({ type: 'fetchMap', payload: { type: 0, isClear: false } });
      yield take('fetchAddrMapType0Finished');

      if (codes['1']) {
        yield put({ type: 'fetchMap', payload: { code: codes['1'], type: 1, isClear: false } });
        yield take('fetchAddrMapType1Finished');
      }
      if (codes['2']) {
        yield put({ type: 'fetchMap', payload: { code: codes['2'], type: 2, isClear: false } });
        yield take('fetchAddrMapType2Finished');
      }

      yield put({ type: 'batchFetchMapFinished' });
    },
  },
  subscriptions: {},
};
