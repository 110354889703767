import * as servers from '@api/energyWeb/OutputValue';

export default {
  namespace: 'outputValue',
  state: {
    loading: false,
    dataList: [],
    page: 1,
    length: 30,
    totalCount: 0,
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *queryOutputValueList ({ payload }, { put, call }) {
      const { page, length } = payload;
      yield put({
        type: 'update',
        payload: {
          loading: true,
          dataList: [],
          page,
          length,
        },
      });
      const response = yield call(servers.getOutputValueByNodeId, { ...payload });

      return yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList: response.dataList,
          totalCount: response.totalCount,
        },
      });
    },
    *creatOutputValue ({ payload }, { call }) {
      const response = yield call(servers.addOutputValue, { productionValueEntry: JSON.stringify({ ...payload }) });
      return response;
    },
    *deleteOutputValue ({ payload }, { call }) {
      const response = yield call(servers.deleteOutputValue, { ...payload });
      return response;
    },
    *updateOutputValue ({ payload }, { call }) {
      const response = yield call(servers.updateOutputValue, { productionValueEntry: JSON.stringify({ ...payload }) });
      return response;
    },
    *editOutputValue ({ payload }, { select }) {
      const { dataList } = yield select(({ outputValue }) => ({
        dataList: outputValue.dataList,
      }));
      const outputValueDetail = dataList.filter((item) => (item.id === payload.id));
      return outputValueDetail;
    },
    *deleteList (_, { put }) {
      return yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList: [],
          page: 1,
          length: 30,
          totalCount: 0,
        },
      });
    },
  },
};
