import * as servers from '@api/energyWeb/Statistics';

export default {
  namespace: 'vContrast',
  state: {
    loading: false,
    dataList: [],
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *queryChartData ({ payload }, { put, call }) {
      yield put({
        type: 'update',
        payload: {
          loading: true,
          dataList: [],
        },
      });
      const response = yield call(servers.queryeneygrContrast, { ...payload });
      const dataList = response.dataList || [];

      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList,
        },
      });
      return response;
    },
    *deleteInfo (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList: [],
        },
      });
    },
  },
};
