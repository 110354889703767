import { getData } from '@services/request';
export async function queryChargeDischargeStrategyOverviewByStationId (params) { // 根据stationId查询充放电策略
  const response = await getData('storedEnergy', 'queryChargeDischargeStrategyOverviewByStationId', { ...params });
  const { dataList } = response;
  const list = [];
  const strategyIds = [];
  (dataList || []).forEach((element) => {
    if (element.strategyName === '策略一') {
      list[0] = element;
      strategyIds.push(element.strategyId);
    }
    if (element.strategyName === '策略二') {
      list[1] = element;
      strategyIds.push(element.strategyId);
    }
    if (element.strategyName === '策略三') {
      list[2] = element;
      strategyIds.push(element.strategyId);
    }
  });
  return {
    schemeList: list,
    strategyIds,
  };
}

export async function queryChargeDischargeStrategyByStrategyIds (params) { // 根据ID查询充放电策略下面的方案
  const response = await getData('storedEnergy', 'queryChargeDischargeStrategyByStrategyIds', { ...params });
  const { dataList } = response;
  const schemeType = [];
  (dataList || []).forEach((element) => {
    if (element.strategyName === '策略一') {
      schemeType[0] = element;
    }
    if (element.strategyName === '策略二') {
      schemeType[1] = element;
    }
    if (element.strategyName === '策略三') {
      schemeType[2] = element;
    }
  });
  return schemeType;
}

export async function getStationInfoById (params) { // 获取站点的总容量
  const response = await getData('storedEnergy', 'getStationInfoById', { ...params });
  return response.data || {};
}


export async function createChargeDischargeStrategy (params) { // 创建充放电策略下面的方案
  const response = await getData('storedEnergy', 'createChargeDischargeStrategy', { ...params });
  return response;
}

export async function updateChargeDischargeStrategy (params) { // 更新充放电策略下面的方案
  const response = await getData('storedEnergy', 'updateChargeDischargeStrategy', { ...params });
  return response;
}

export async function getProtectionParamByStationId (params) { // 查询保护参数
  const response = await getData('storedEnergy', 'getProtectionParamByStationId', { ...params });
  return response;
}
export async function queryDtuStrategyDetail (params) { // 查询实时策略
  const response = await getData('storedEnergy', 'queryDtuStrategyDetail', { ...params });
  return response;
}

export async function pageStrategyHistory (params) { // 查询实时策略
  const response = await getData('storedEnergy', 'queryStrategyHistory', { ...params });
  return response;
}

export async function deleteStrategyHistoryDetail (params) { // 删除实时策略
  const response = await getData('storedEnergy', 'deleteStrategyHistoryDetail', { ...params });
  return response;
}
export async function saveStrategyParams (params) { // 编辑实时策略
  const response = await getData('storedEnergy', 'saveStrategyParams', { ...params });
  return response;
}
export async function queryFeeByStationId (params) { // 查询时段电价
  const response = await getData('storedEnergy', 'queryFeeByStationId', { ...params });
  return response;
}
export async function queryStrategyHistoryDetail (params) { // 策略配置历史-详情
  const response = await getData('storedEnergy', 'queryStrategyHistoryDetailByRecordId', { ...params });
  return response;
}
export async function updateStrategyDetail (params) { // 策略配置历史-编辑
  const response = await getData('storedEnergy', 'updateStrategyDetail', { ...params });
  return response;
}






