import { getData } from '@/services/request';
import axios from '@/utils/request-zdy';
const base = `/api`;

/**
 * 获取表格列表
 */
export async function queryAllList({ requestUrl, parameterNest = '', ...otherparams }) {
    // 传参后端不改，只能前端来改了，坑爹
    if (requestUrl === 'dayougroup/queryEnergyStationCount') {
        otherparams.energyStationIds = otherparams.stationIds;
        if (otherparams.deviceTypes?.[0] === '') {
            otherparams.deviceTypes = ['700011', '700012', '700013', '700014', '700015'];
        }
        delete otherparams.stationIds;
    }

    if (requestUrl === 'dayougroup/queryStationElectricityInfo') {
        otherparams.type = otherparams.type - 1;
    }
    // 表示自定义的日期
    if (otherparams.type === 4) {
        otherparams.type = 2;
    }

    let newParams = JSON.parse(JSON.stringify(otherparams));

    if (parameterNest) {
        newParams = {};
        newParams[parameterNest] = otherparams;
    }

    if (requestUrl.indexOf('eai/') > -1) { 
        return axios({
            url: `/${requestUrl}`,
            method: 'POST',
            data: {
                ...newParams
            }
        });
    } else {
        const data = await getData(requestUrl, '', newParams);
        return data;
    }
}
