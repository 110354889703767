import * as service from '@services/alarm/settings';

const initialData = () => ({
  // 0: {
  //   popup: false,
  //   audio: false,
  //   // 单位: 秒
  //   time: 0,
  //   audioType: 'tick',
  // },
  // 1: {},
  // 2: {},
  // 3: {},
});

export default {
  namespace: 'alarmSettings',
  state: initialData(),
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *fetch({ payload: { accountId } }, { call, put }) {
      const res = yield call(service.fetchAlarmSettings, { accountId });
      yield put({ type: 'update', payload: res.data });
    },
    *set({ payload: { accountId, alarmLevel, key, value, cb } }, { call, put, select }) {
      const settings = yield select(state => state.alarmSettings);
      settings[alarmLevel][key] = value;
      const res = yield call(service.setAlarmSettings, { accountId, data: settings });
      if (!res.code) yield put({ type: 'update', payload: settings });
      if (cb) yield call(cb, res);
    },
    *setDefault({ payload: { accountId, cb } }, { call, put }) {
      const { data: defaultSettings } = yield call(service.fetchDefaultAlarmSettings, { accountId });
      const res = yield call(service.setAlarmSettings, { accountId, data: defaultSettings });
      if (!res.code) yield put({ type: 'update', payload: defaultSettings });
      if (cb) yield call(cb, res);
    },
  },
  subscriptions: {},
};
