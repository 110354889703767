import React from 'react';
import { Tabs } from 'antd';

import styles from './index.scss';

const { TabPane } = Tabs;

class Index extends React.Component {
  static TabPane = ({ className, ...rset }) => {
    return (
      <TabPane
        {...rset}
        className={[styles.ui, className]}
      />
    );
  };

  render() {
    const { className, ...rset } = this.props;
    return (
      <Tabs
        {...rset}
        className={[styles.ui, className]}
      />
    );
  }
}

export default Index;
