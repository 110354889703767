import { getData } from '@services/request';

export async function getStationDataPerssionsByAccountId({ id }) {
    let fres = [];
    const data = await getData('storedEnergy', 'getStationDataPerssionsByAccountId', { accountId: id });

    if (data.code === null) {
        fres = (data.dataList || []).map(item => {
            return {
                ...item,
                stationId: item.id,
                key: item.id,
            };
        });
    }

    return [fres, data];
}

export async function getStorageStationListByServiceProviderId(prmas) {
    let fres = [];
    const data = await getData('storedEnergy', 'getStorageStationListByServiceProviderId', { ...prmas });

    if (data.code === null) {
        fres = (data.dataList || []).map(item => {
            return {
                ...item,
                stationId: item.id,
                key: item.id,
            };
        });
    }

    return [fres, data];
}

/**
 * 根据企业、地区编码得到储能站点列表
 */
export async function queryStationsByArea({ requestPath, ...data }) {
    const res = await getData('dayougroup', requestPath, data);
    if (Array.isArray(res?.data)) return res.data;
}

export async function getStatusList({ requestPath, ...data }) {
    const res = await getData('dayougroup', requestPath, data);
    return (res && res.data) || {
        allStationIds:[],
        earlyWarningStationIds:[],
        warningStationIds:[],
        normalStationIds:[],
        eventStationIds:[],
        malfunctionStationIds:[]
    };
}


/**
 * 根据查询条件获取站点表格数据
 * @param {*}
 * stationIds: 站点 Ids，逗号拼接
 * startTime: 开始时间是 yyyy-MM-dd 00:00:00 的时间戳
 * endTime: 结束时间是 yyyy-MM-dd 23:59:59 的时间戳
 * type: 1代表日，2代表月，3代表年
 */
export async function queryStationTableList({ requestPath, ...data }) {
    const res = await getData('dayougroup', requestPath, data);
    if (Array.isArray(res?.data)) return res.data;
}

/**
 * 根据查询条件获取站点统计数据
 */
export async function queryStationStatistics({ requestPath, ...data }) {
    const res = await getData('dayougroup', requestPath, data);
    if (res?.data) return res.data;
}

// 根据查询条件获取站点所有数据
export async function queryStationAllData({ requestPath, ...params }) {
    const res = await getData('dayougroup', requestPath, { ...params });
    return res.data;
}

// 根据站点查询图表数据
export async function queryStationChartList({ requestPath, ...params }) {
    const res = await getData('dayougroup', requestPath, { ...params });
    if (res?.data) return res.data;
}

// 查询储能站点小时充电、放电量
export async function queryStoredStationElectricity({ stationIds, startTime, endTime }) {
    const res = await getData('dayougroup', 'queryStoredStationElectricity', { stationIds, startTime, endTime });
    if (res?.data) return res.data;
}

// 根据查询条件导出数据
export async function exportDataByquery({ requestPath, ...params }) {
    const res = await getData('dayougroup', requestPath, { ...params });
    if (res?.data) return res.data;
}
