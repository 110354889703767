import * as services from '@api/energyWeb/DeviceManagement';
import { SwitchPicker } from '@ui';
import base from '../../base';
import moment from 'moment';

export default {
    namespace: 'vEnergyDevice',
    state: {
        datas: [],
        data: {},
        dataAudit: {},
        metricCodes: [],
        countObj: {}, // 用能设备各数量
        chartList: []
    },

    reducers: {
        update(state, { payload }) {
            return { ...state, ...payload };
        }
    },

    effects: {
        // 查询数据
        *fetch({ payload: { queries = {}, saasStationId } = {} }, { put, call, select }) {
            const [{ datas, total, countObj }] = yield call(services.fetchEnergyDeviceList, {
                stationId: saasStationId
            });
            yield put({
                type: 'update',
                payload: {
                    datas: datas.filter(item => item.keyEnergyDeviceType !== 700005),
                    countObj
                }
            });

            return { ...queries, total, countObj };
        },
        *fetchDetail({ payload: { id, type } = {} }, { put, call }) {
            if (!id) return;
            const [fres] = yield call(services.fetchEnergyDeviceById, { id, type });
            yield put({
                type: 'update',
                payload: {
                    data: fres
                }
            });

            return [fres];
        },
        *updateMetricCodes({ payload: { deviceType } = {} }, { put }) {
            if (!deviceType) return;

            const type = deviceType;
            let queryType = null;
            switch (+type) {
                case 700001: // 空压机
                    queryType = '0';
                    break;
                case 700002: // 水泵
                    queryType = '1';
                    break;
                case 700003: // 风机
                    queryType = '2';
                    break;
                case 700004: // 电动机
                    queryType = '3';
                    break;
                default:
            }
            const queryMetricCodes = [...(services.energyDeviceQueryMetricCodesMap[queryType] || [])]; // 使用新数组

            yield put({
                type: 'update',
                payload: {
                    metricCodes: queryMetricCodes
                }
            });
        },
        *fetchChart(
            {
                payload: { deviceId, metricCodes = [], selectedDateType, startTime, endTime }
            },
            { call, put, select }
        ) {
            yield put({
                type: 'update',
                payload: {
                    chartList: []
                }
            });
            const { data } = yield call(services.fetchChartList, {
                deviceIds: [deviceId],
                metricCodes: metricCodes,
                startTime: moment(startTime * 1000).format('YYYY-MM-DD HH:mm:ss'),
                endTime: moment(endTime * 1000).format('YYYY-MM-DD HH:mm:ss')
            });
            const chartList = data[`${deviceId}@${metricCodes[0]}`];
            const items = Object.values(services.energyDeviceQueryMetricCodesMap)
            let name = '';
            items.forEach(item=>{
               item.forEach(_item=>{
                  if(_item.metricCodes.includes(metricCodes[0])){
                    name = _item.name
                  }
               })
            })
            yield put({
                type: 'update',
                payload: {
                    chartList: [
                        {
                            name,
                            data: chartList.map(i => {
                                return [moment(i.t, 'YYYY-MM-DD HH:mm:ss').valueOf(), i.v===null ? null: (+i.v).toFixed(+2)];
                            })
                        }
                    ]
                }
            });
        }
    },

    subscriptions: {}
};

