import io from 'mqtt';
import { isFunction, pull, isString, noop } from 'lodash';

const isNotFunction = v => !isFunction(v);
const isNotObserver = v => !isString(v) && !isFunction(v);

export default class MqttIns {
  // mqtt实例
  mqttIns = null

  // { [topic: string]: Array<Observer> }
  topicObservers = {}

  // 对应redux的dispatch
  dispatch = noop

  connect({ ip, port, query = '', secure = false, username = 'yny@hzzh', password = '123456' } = {}) {
    this.mqttIns = io.connect({
      protocol: secure ? 'mqtts' : 'mqtt',
      hostname: ip,
      path: '/mqtt',
      port,
      query,
      reconnectPeriod: 60 * 1000,
      username,
      password,
    });
    this.mqttIns
      .on('connect', () => console.log('MQTT connect'))
      .on('disconnect', () => console.log('MQTT disconnect'))
      .on('message', (topic, message) => {
        // console.log(`mqtt receive:: topic: ${topic} , message: ${message}`);

        const observers = this.topicObservers[topic];
        if (!observers) {
          console.error(`mqtt:: topic:${topic} should not subscribed now but received message: ${message}`);
          return;
        }
        // broadcast
        observers.forEach((observer) => {
          if (isFunction(observer)) observer(message, topic);
          if (isString(observer)) this.dispatch(observer, message, topic);
        });
      });
    return this.mqttIns;
  }

  get isInvalidIns() {
    return !this.mqttIns;
  }

  get isInvalid() {
    return this.isInvalidIns;
  }

  get isValidIns() {
    return !!this.mqttIns;
  }

  get isValid() {
    return this.isValidIns;
  }

  isObserved(topic, observer) {
    if (this.isInvalid) return false;
    const observers = this.topicObservers[topic];
    if (!observers || !observers.find(o => o === observer)) return false;
    return true;
  }

  isTopicSubscribed(topic) {
    return !!this.topicObservers[topic];
  }

  registerDispatch(dispatch) {
    if (isNotFunction(dispatch)) return;
    this.dispatch = dispatch;
  }

  subscribe(topic, observer) {
    if (this.isInvalidIns || isNotObserver(observer)) return;
    const observers = this.topicObservers[topic];
    if (!observers) {
      this.mqttIns.subscribe(topic);
      this.topicObservers[topic] = [observer];
      return;
    }
    if (!observers.find(o => o === observer)) {
      observers.push(observer);
    }

    return this;
  }

  unsubscribe(topic, observer) {
    if (this.isInvalidIns || isNotObserver(observer)) return;
    const observers = this.topicObservers[topic];
    if (!observers) return;
    pull(observers, observer);
    if (!observers.length) {
      delete this.topicObservers[topic];
      this.mqttIns.unsubscribe(topic);
    }

    return this;
  }

  unregiterAll() {
    if (this.isInvalidIns) return;
    const topics = Object.keys(this.topicObservers);
    if (!topics.length) return;
    this.mqttIns.unsubscribe(topics);
  }

  publish(topic, data, callback = noop) {
    if (this.isInvalidIns) return;
    this.mqttIns.publish(topic, data, callback);
  }

  end() {
    if (this.isValidIns) this.mqttIns.end();
    this.dispatch = noop;
    this.topicObservers = {};
    this.mqttIns = null;
  }
}