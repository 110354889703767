import React from 'react';
import Notification from 'rc-notification';
// import { Icon } from 'antd';
import SvgIcon from '../SvgIcon';
import style from './index.scss';
import confirmMsg from './Confirm.caller';

const defaultDuration = 2;
let defaultTop;
let messageInstance;
let key = 1;
const prefixCls = style.message; // 'ant-message';
let getContainer;

async function getMessageInstance() {
  return new Promise((res) => {
    Notification.newInstance({
      prefixCls,
      transitionName: 'move-up',
      style: { top: defaultTop }, // 覆盖原来的样式
      getContainer,
    }, (instance) => {
      res(instance);
    });
  });
}

(async () => {
  messageInstance = await getMessageInstance();
})();

// type NoticeType = 'info' | 'success' | 'error' | 'warning' | 'loading';

function notice(
  content, // : React.ReactNode,
  duration = defaultDuration, // : number
  type, // : NoticeType,
  onClose, // ?: () => void,
) {
  const iconType = ({
    success: (<i><SvgIcon xlinkHref="xinxifankui-mianjichenggong" /></i>),
    error: (<i><SvgIcon xlinkHref="shibai" /></i>),
  })[type];

  messageInstance.notice({
    key,
    duration,
    style: {},
    closable: false,
    content: (
      <div className={`${prefixCls}-custom-content ${prefixCls}-${type}`}>
        <div className={style.icon}>
          {iconType}
        </div>
        <span className={style.content}>{content}</span>
        {/* <span className={style.close} /> */}
      </div>
    ),
    onClose,
  });
  return (function _b() {
    const target = key++;
    return function _i() {
      messageInstance.removeNotice(target);
    };
  }());
}

export default {
  // info(content: ConfigContent, duration?: ConfigDuration, onClose?: ConfigOnClose) {
  // TODO: info, confirm
  // info(content, duration, onClose) {
  //   return notice(content, duration, 'info', onClose);
  // },
  async confirm(...args) {
    return await confirmMsg.confirm(...args);
  },
  async info(...args) {
    return await confirmMsg.info(...args);
  },
  success(content, duration, onClose) {
    return notice(content, duration, 'success', onClose);
  },
  async error(...args) {
    if (typeof args[1] === 'string') {
      return await confirmMsg.error(...args);
    }
    const [content, duration, onClose] = args;
    return notice(content, duration, 'error', onClose);
  },
  async errorIn(...args) {
    return await confirmMsg.error(...args);
  },
};
