import React from 'react';
import { Table as AntdTable } from 'antd';

import OverflowTd from './OverflowTd';

import styles from './index.scss';

class Table extends React.PureComponent {
  state = {
    dataSource: [],
    scrollBarVisible: false,
  }

  ins = React.createRef()

  componentDidUpdate (_, prevState) {
    const { dataSource, scroll } = this.props;
    if (dataSource.length !== prevState.dataSource.length && scroll) {
      const tableDom = this.ins.current;
      const tableHeight = tableDom.clientHeight;
      if (dataSource.length * 31 > tableHeight - 32) {
        this.setState({
          scrollBarVisible: true,
        });
      } else {
        this.setState({
          scrollBarVisible: false,
        });
      }
    }
  }

  render () {
    const { className, wrapperClassName, zebra = false, scroll, originScroll = false, scrollY, ...rest } = this.props;
    const { bordered, borderedWithBoth } = rest;
    return (
      <div ref={this.ins} className={[styles.wrap, wrapperClassName]}>
        <AntdTable
          {...rest}
          className={[styles.ui, zebra ? styles.zebra : null, bordered ? null : styles.noborder, className, borderedWithBoth ? styles.borderedWithBoth : null]}
          pagination={false}
          rowClassName={(record, index) => index % 2 !== 0 ? styles.shadowWrap : ''}
          scroll={scrollY ?
            { y: 'calc(100% - 31px)' }
            : _.isObject(scroll) ?
              {
                ...scroll,
                y: (this.state.scrollBarVisible || originScroll) ? scroll.y :
                  null
              } : undefined}
        />
      </div>
    );
  }
}

Table.OverflowTd = OverflowTd;
Table.Column = AntdTable.Column;
Table.ColumnGroup = AntdTable.ColumnGroup;

export default Table;
