import * as OSS from 'ali-oss';
import { getData } from './request';

const client = new OSS({
  region: 'oss-cn-shanghai',
  accessKeyId: 'LTAI5t6LbhNs3zEnrZd4SBxA',
  accessKeySecret: 'XaKWp8AuTw6W0W04OxQp4qfswGALyn',
  bucket: 'ems-ylzh',
  secure: true,
});
export async function multipartUpload (storeAs, file, opt = {}) {
  try {
    const result = await client.multipartUpload(storeAs, file, opt);
    return result;
  } catch (ex) {
    console.error('file upload failed:', ex);
    return null;
  }
}
export function getFileUrl (objectKey, saveAs) {
  if (objectKey.indexOf('data:') === 0) {
    return objectKey;
  }
  try {
    const result = client.signatureUrl(objectKey, {
      expires: 3600,
      response: {
        'content-disposition': `attachment; filename="${saveAs}"`,
      },
    });
    return result;
  } catch (ex) {
    return null;
  }
}
export async function saveStr (saveAs, str) {
  return await getData('oss', 'saveStr', { saveAs, context: str });
}
export async function getContext (objectKey) {
  const data = await getData('oss', 'getContext', { objectKey });
  if (!data.type && data.code && data.code !== 'error') return null;
  return data.data;
}
