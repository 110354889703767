import { useEffect, useState, useRef } from 'react';
import { connect } from 'dva';
import { ConfigProvider, Form, Select, Button } from 'antd4';
import moment from 'moment';

import TimeRange from '@lib/iem-TimeRange';
import { message } from '@ui';
import { queryAllProvince, querySubAreaByType } from '@/services/address';
import {queryAreaByEnterpriseld} from '@api/photovoltaic'; 

import { queryStationsByArea } from '@/services/storage/station';
import { REQUEST_PATH, LAEBL_NAME, TIME_RANGE_CONFIG, MOMENT_CONFIG } from './config';
import styles from './index.scss';

const ALL = '全部';
const ALL_OPTION = { label: '全部', value: '全部' };

export const MARCH_PARAMETER = {
  storage: {
    label: 'stationName',
    value: 'stationId'
  },
  exchangeElectricity: {
    label: 'chargingPileStationName',
    value: 'chargingPileStationId'
  },
  analyse: {
    label: 'stationName',
    value: 'stationId'
  }
};

function IemQuery (props) {
  const {
    dispatch,
    type, //storage储能 analyse发电分析
    statistic: { tableLoading, exportLoading }
  } = props;
  const { dateType = 'day', startTimeFormat = 'x', endTimeFormat = 'x' } = MOMENT_CONFIG[type] || {};

  // 电站列表
  const [stationList, setStationList] = useState([ALL_OPTION]);
  // 省份列表
  const [provinceList, setProvinceList] = useState([ALL_OPTION]);
  // 城市列表
  const [cityList, setCityList] = useState([ALL_OPTION]);
  const timeRange = useRef({
    startTime: moment()
      .startOf(dateType)
      .format(startTimeFormat),
    endTime: moment()
      .endOf('day')
      .format(endTimeFormat),
    type: 1
  });
  // 初始过数据的标识，避免 [provinceCode, cityCode] useEffect 在初始化时重复调用
  const hasInited = useRef(false);

  const [form] = Form.useForm();
  const stationIds = Form.useWatch('stationIds', form);
  const provinceCode = Form.useWatch('province', form);
  const cityCode = Form.useWatch('city', form);

  const requestPath = REQUEST_PATH[type] || {};
  const { stationLabel = '电站名称' } = LAEBL_NAME[type] || {};
  const timeRangeConfig = TIME_RANGE_CONFIG[type] || {};
  const areaParams = MARCH_PARAMETER[type] || { label: 'stationName', value: 'stationId' };

  const isSellElectric = type === 'sellElectric'; // 是否为售电类型
  const customerId = localStorage.getItem("customerId");
  useEffect(() => {
    queryStationByAreaCode()
      .then(data => {
        if (data?.length) {
          fetchStatisticData(data.map(item => item[areaParams.value]));
        }
      })
      .catch(err => {
        console.error('queryStationByAreaCode err: ', err);
      })
      .finally(() => {
        hasInited.current = true;
      });

      // 光伏只展示有电站的省份
      if(type==='analyse'){
       queryAreaByEnterpriseld({
          enterpriseId: customerId,
        }).then(({data}) => {
          const b1 = data && Array.isArray(data) && data.map(item=>{ 
            return {value: item.provinceCode,
            label: item.provinceName
          }})||[]
          setProvinceList(provinces => provinces.concat(b1));
        })
      }else{
        // 获取省份列表
        queryAllProvince()
          .then(res => {
            if (res?.length && res[0]) {
              const list = Object.keys(res[0]).map(key => ({
                label: res[0][key],
                value: key
              }));
              setProvinceList(provinces => provinces.concat(list));
            }
          })
          .catch(err => {
            console.error('queryAllProvince err: ', err);
          });
      }
    return () => handleResetData();
  }, []);

  // 选择省份后，查询所属城市列表
  useEffect(() => {
    if (!provinceCode) return;

    form.setFieldValue('city', ALL);
    setCityList([]);

    if (provinceCode === ALL) return;

    querySubAreaByType({ code: provinceCode, type: '01' })
      .then(res => {
        if (res?.length && res[0]) {
          const list = Object.keys(res[0]).map(key => ({
            label: res[0][key],
            value: key
          }));
          setCityList(list);
        }
      })
      .catch(err => console.error('querySubAreaByType err: ', err));
  }, [provinceCode]);

  // 更新省份-城市后，查询所属站点列表
  useEffect(() => {
    if (!hasInited.current) return;

    if (provinceCode && cityCode) {
      form.setFieldValue('stationIds', ALL);
      queryStationByAreaCode();
    }
  }, [provinceCode, cityCode]);

  // 清空选项时重置为【全选】&& 选择内容后清除【全选】标记
  useEffect(() => {
    if (!cityCode) return;

    if (!cityCode.length) {
      form.setFieldValue('city', ALL);
    } else if (Array.isArray(cityCode) && cityCode.length > 1 && cityCode.includes(ALL)) {
      form.setFieldValue('city', cityCode.filter(code => code !== ALL));
    }
  }, [cityCode]);

  useEffect(() => {
    if (!stationIds) return;

    // 包含【全部】选项的情况
    if (Array.isArray(stationIds) && stationIds.length > 1 && stationIds.includes(ALL)) {
      const lastOption = stationIds[stationIds.length - 1];
      // 如果最新选中的是【全部】，select 值直接更新为全部
      if (lastOption === ALL) {
        form.setFieldValue('stationIds', ALL);
      } else {
        // 否则过滤掉【全部】选项
        form.setFieldValue('stationIds', stationIds.filter(id => id !== ALL));
      }
    }
  }, [stationIds]);

  // 储能根据地区查询电站
  function queryStationByAreaCode () {
    if (!requestPath.stationListUrl) return Promise.resolve();
    const { province, city } = form.getFieldsValue(['province', 'city']);

    const reqData = {
      requestPath: requestPath.stationListUrl,
      enterpriseId: localStorage.getItem('loginCustomerId'),
      provinceCode: province === ALL ? '' : province
    };

    // 不同类型页面接口查询字段不一致
    if (type === 'storage' || type === 'exchangeElectricity') {
      reqData.cityCode = city === ALL ? '' : city.join(',');
    } else {
      reqData.cityCodes = city === ALL ? [] : city;
    }

    // 售电类型接口需增加 endTime 字段
    if (isSellElectric) {
      reqData.endTime = timeRange.current.endTime;
    }

    return queryStationsByArea(reqData)
      .then(data => {
        if (data?.length) {
          const stationOptions = data.map(item => ({
            label: item[areaParams.label] || '',
            value: item[areaParams.value] || ''
          }));
          setStationList([ALL_OPTION, ...stationOptions]);
        } else {
          setStationList([ALL_OPTION]);
        }
        return Promise.resolve(data);
      })
      .catch(err => {
        console.error('queryStationByAreaCode err: ', err);
        setStationList([ALL_OPTION]);
      });
  }

  function handleTimeChange (data) {
    const { value, type } = data;
    timeRange.current = {
      startTime: value[0],
      endTime: value[1],
      type
    };
  }

  function fetchStatisticData (stationIds) {
    dispatch({
      type: 'statistic/update',
      payload: {
        queryData: {
          stationIds,
          ...timeRange.current
        }
      }
    });

    if (type === 'storage' || type === 'exchangeElectricity' || type === 'sellElectric') {
      //储能请求数据（统计+表格）
      dispatch({
        type: 'statistic/fetchTableData',
        payload: { requestPath: requestPath.tableDataUrl }
      });
      dispatch({
        type: 'statistic/fetchStatisticData',
        payload: { requestPath: requestPath.statisticDataUrl }
      });
      if (type === 'exchangeElectricity') {
        dispatch({
          type: 'statistic/fetchChargeDurationData',
          payload: { requestPath: requestPath.chargeDuration }
        });
        // 充电时长和次数
        dispatch({
          type: 'statistic/fetchChargeTimesData',
          payload: { requestPath: requestPath.chargeTimes }
        });
      }
    } else if (type === 'analyse') {
      //发电分析请求数据
      dispatch({
        type: 'statistic/fetchData',
        payload: { requestPath: requestPath.fetchDataUrl }
      });
    }
  }

  // 导出方法
  function handleExport () {
    dispatch({
      type: 'statistic/exportData',
      payload: {
        requestPath: requestPath.exportUrl,
        type
      }
    });
  }

  function handleSubmit () {
    try {
      const queryStationIds = stationIds.includes(ALL)
        ? stationList.filter(item => item.value !== ALL).map(item => item.value)
        : stationIds;

      // TODO: 错误提示语句是否支持自定义，或者改成更通用一些
      if (!queryStationIds.length) {
        message.error('所选地区无站点，请重新选择地区');
        return;
      }

      fetchStatisticData(queryStationIds);
    } catch (err) {
      message.error('查询出错，请稍后重试');
      console.error('handleSubmit err: ', err);
    }
  }

  function handleResetData () {
    dispatch({
      type: 'statistic/update',
      payload: {
        queryData: {
          stationIds: [],
          startTime: '',
          endTime: '',
          type: 1 // type: 1代表日，2代表月，3代表年
        },
        statisticData: undefined,
        tableData: []
      }
    });
  }

  return (
    <ConfigProvider prefixCls="antd4">
      <div className={styles.wrapper}>
        <Form layout="inline" form={form}>
          <Form.Item label="所在地" name="province" colon={false} initialValue={ALL}>
            <Select className={styles.selector_small} options={provinceList} />
          </Form.Item>
          <Form.Item name="city" colon={false} initialValue={ALL}>
            <Select
              className={styles.selector}
              mode="multiple"
              maxTagCount={3}
              disabled={provinceCode === ALL}
              filterOption={(val, option) => option.label.includes(val)}
              options={cityList}
            />
          </Form.Item>
          <Form.Item label={stationLabel} name="stationIds" colon={false} initialValue={ALL}>
            <Select
              className={styles.selector}
              mode="multiple"
              maxTagCount={3}
              filterOption={(val, option) => option.label.includes(val)}
              options={stationList}
            />
          </Form.Item>
          <Form.Item label="选择时间" colon={false}>
            <TimeRange {...timeRangeConfig} onChange={handleTimeChange} />
          </Form.Item>
        </Form>
        <Button className={styles.query_btn} type="primary" onClick={handleSubmit} loading={tableLoading}>
          查询
        </Button>
        <Button className={`${styles.export_btn} ${styles.radius_4}`} loading={exportLoading} onClick={handleExport}>
          导出
        </Button>
      </div>
    </ConfigProvider>
  );
}

const mapStateToProps = ({ statistic }) => ({
  statistic
});

export default connect(mapStateToProps)(IemQuery);
