import * as services from '@services/storage/ReportCenter';

export default {
  namespace: 'stroageReportCenter',
  state: {
    list: [],   // 报表列表
    page: 1,
    length: 30,
    totalCount: 0,
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询列表
    *fetchList ({ payload: { page = 1, length = 30 } }, { call, put }) {
      const [list, totalCount] = yield call(services.queryList, { page, length });

      yield put({
        type: 'update',
        payload: { list, totalCount, page, length },
      });
    },
  },
  subscriptions: {},
};
