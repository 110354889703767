import { getData } from '@services/request';

// 根据站点id获取所有的用能分类
export async function getEnergyClassificationByStationId (params) {
  const response = await getData('energyManagement', 'ylGetEnergyClassificationByStationId', { ...params });
  const dataList = response.dataList || [];
  return dataList;
}

// 能耗统计-横向对比
export async function horizontalContrast (params) {
  const response = await getData('energyManagement', 'ylHorizontalContrast', { ...params });
  return response;
}

// 能耗统计-同比环比
export async function sequentialCompared (params) {
  const response = await getData('energyManagement', 'ylSequentialCompared', { ...params });
  return response;
}

// 能效统计-能效排名
export async function queryeneygrRanking (params) {
  const response = await getData('energyManagement', 'ylQueryEnergyEfficiencyRankByCondition', { ...params });
  return response;
}

// 能效统计-能效同比环比
export async function queryeneygrContrast (params) {
  const response = await getData('energyManagement', 'ylQueryEnergyEfficiencyYoYAndQoQByCondition', { ...params });
  return response;
}
