import { getData } from '@/services/request';


export async function getMetricCode() {
  let fres = {};
  const data = await getData('environment', 'getSignalConfiguration', {});

  if (!data.code) {
    fres = data.data;
  }

  return [fres, data];
}

// 据后台说: 0:遥信,1:遥测,2:遥调,3:遥控,4:遥视
// 实际: 0:遥测,1:遥信
export async function queryMetricItemByType({ type }) {
  let fres = [];
  const data = await getData('metric', 'queryMetricItemByType', { type });
  if (!data.code) {
    fres = data.dataList || [];
  }
  return [fres, data];
}

export async function queryAllAction() {
  let fres = [];
  const data = await getData('metric', 'queryAllAction');
  if (!data.code) {
    fres = data.dataList || [];
  }
  return [fres, data];
}
