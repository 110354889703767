import React, { isValidElement } from 'react';
import { Button } from 'antd';
import SvgIcon from '../SvgIcon';

import styles from './index.scss';

// Button 其实可以完全重写
// 继续使用 antd 是为了照顾以后 antd 升级
// icon替换原antd的icon功能，若传的字符串，则视为SvgIcon的xlinkHref属性的值
class Index extends React.Component {
  render () {
    const { className, children = '', icon, iconDom, plain, ...rest } = this.props;

    const Newicon = icon || iconDom;
    const v = children || '';

    const classHorizonPadding = ((typeof v === 'string' && v.length > 4) && !plain && !icon) ? styles.horizonPadding : null;

    const iconView = (() => {
      if (typeof Newicon === 'string') {
        return <span className={styles.ico}><SvgIcon xlinkHref={Newicon} /></span>;
      } else if (isValidElement(Newicon)) {
        return <span className={styles.ico}>{Newicon}</span>;
      }
      return null;
    })();
    const classType = iconView ? styles.typeIco : plain ? styles.typePlain : null;
    if (iconView && plain) {
      console.error('Warn: @ui/Button icon和plain属性不能同时生效');
    }

    return (
      <Button
        {...rest}
        className={[styles.ui, classType, classHorizonPadding, className]}
      >
        {iconView}
        {v ? <span>{v}</span> : null}
      </Button>
    );
  }
}

export default Index;
