import React from 'react';
import {
  Router as DefaultRouter,
  Route,
  Switch,
  StaticRouter,
} from 'react-router-dom';
import dynamic from 'umi/dynamic';
import renderRoutes from 'umi/lib/renderRoutes';
import history from '@@/history';
import { routerRedux, dynamic as _dvaDynamic } from 'dva';

const Router = routerRedux.ConnectedRouter;

const routes = [
  {
    path: '/',
    component: __IS_BROWSER
      ? _dvaDynamic({
          component: () => import('../../layouts/index.js'),
        })
      : require('../../layouts/index.js').default,
    routes: [
      {
        path: '/404',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../404.js'),
            })
          : require('../404.js').default,
      },
      {
        path: '/asset-manage/equipment/air-conditioner/component/chartOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/asset-manage/equipment/air-conditioner/models/vEnergyMonitorDatumEquipmentAirConditioner.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumEquipmentAirConditioner',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../asset-manage/equipment/air-conditioner/component/chartOption.js'),
            })
          : require('../asset-manage/equipment/air-conditioner/component/chartOption.js')
              .default,
      },
      {
        path: '/asset-manage/equipment/air-conditioner',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/asset-manage/equipment/air-conditioner/models/vEnergyMonitorDatumEquipmentAirConditioner.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumEquipmentAirConditioner',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../asset-manage/equipment/air-conditioner/index.js'),
            })
          : require('../asset-manage/equipment/air-conditioner/index.js')
              .default,
      },
      {
        path: '/asset-manage/equipment/street-lamp/component/chartOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/asset-manage/equipment/street-lamp/models/vEnergyMonitorDatumEquipmentStreetLamp.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumEquipmentStreetLamp',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../asset-manage/equipment/street-lamp/component/chartOption.js'),
            })
          : require('../asset-manage/equipment/street-lamp/component/chartOption.js')
              .default,
      },
      {
        path: '/asset-manage/equipment/street-lamp',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/asset-manage/equipment/street-lamp/models/vEnergyMonitorDatumEquipmentStreetLamp.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumEquipmentStreetLamp',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../asset-manage/equipment/street-lamp/index.js'),
            })
          : require('../asset-manage/equipment/street-lamp/index.js').default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/baseInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/baseInfo.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/baseInfo.js').default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/deviceList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/deviceList.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/deviceList.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/deviceManage/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/deviceManage/detail.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/deviceManage/detail.js')
              .default,
      },
      {
        path:
          '/asset-manage/ledger/photovoltaic/deviceManage/fileInfoColumnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/deviceManage/fileInfoColumnsConfig.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/deviceManage/fileInfoColumnsConfig.js')
              .default,
      },
      {
        path:
          '/asset-manage/ledger/photovoltaic/deviceManage/fileInfoSearchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/deviceManage/fileInfoSearchConfig.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/deviceManage/fileInfoSearchConfig.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/deviceManage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/deviceManage/index.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/deviceManage/index.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/index.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/index.js').default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/info/devOpsInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/devOpsInfo.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/devOpsInfo.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/info/editDevOpsInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/editDevOpsInfo/index.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/editDevOpsInfo/index.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/info/editRecordInfo/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/editRecordInfo/detail.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/editRecordInfo/detail.js')
              .default,
      },
      {
        path:
          '/asset-manage/ledger/photovoltaic/info/editRecordInfo/fileInfoColumnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/editRecordInfo/fileInfoColumnsConfig.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/editRecordInfo/fileInfoColumnsConfig.js')
              .default,
      },
      {
        path:
          '/asset-manage/ledger/photovoltaic/info/editRecordInfo/fileInfoSearchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/editRecordInfo/fileInfoSearchConfig.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/editRecordInfo/fileInfoSearchConfig.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/info/editRecordInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/editRecordInfo/index.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/editRecordInfo/index.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/info/editStation',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/editStation/index.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/editStation/index.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/info/recordInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/recordInfo.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/recordInfo.js')
              .default,
      },
      {
        path: '/asset-manage/ledger/photovoltaic/info/stationInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../asset-manage/ledger/photovoltaic/info/stationInfo.js'),
            })
          : require('../asset-manage/ledger/photovoltaic/info/stationInfo.js')
              .default,
      },
      {
        path: '/cenenergy-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../cenenergy-control/index.js'),
            })
          : require('../cenenergy-control/index.js').default,
      },
      {
        path: '/cost-analysis',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../cost-analysis/index.js'),
            })
          : require('../cost-analysis/index.js').default,
      },
      {
        path: '/cost-analysis/pay/electricAnalyse/adjust',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../cost-analysis/pay/electricAnalyse/adjust/index.js'),
            })
          : require('../cost-analysis/pay/electricAnalyse/adjust/index.js')
              .default,
      },
      {
        path: '/cost-analysis/pay/electricAnalyse/base',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../cost-analysis/pay/electricAnalyse/base/index.js'),
            })
          : require('../cost-analysis/pay/electricAnalyse/base/index.js')
              .default,
      },
      {
        path: '/cost-analysis/pay/electricAnalyse',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../cost-analysis/pay/electricAnalyse/index.js'),
            })
          : require('../cost-analysis/pay/electricAnalyse/index.js').default,
      },
      {
        path: '/cost-analysis/pay/electricAnalyse/quantity',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../cost-analysis/pay/electricAnalyse/quantity/index.js'),
            })
          : require('../cost-analysis/pay/electricAnalyse/quantity/index.js')
              .default,
      },
      {
        path: '/cost-analysis/pay/electricStatic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/cost-analysis/pay/electricStatic/models/vCostAnalysisPayElectric.js').then(
                  m => {
                    return {
                      namespace: 'vCostAnalysisPayElectric',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../cost-analysis/pay/electricStatic/index.js'),
            })
          : require('../cost-analysis/pay/electricStatic/index.js').default,
      },
      {
        path: '/cost-analysis/pay/gas',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/cost-analysis/pay/gas/models/vCostAnalysisPayGas.js').then(
                  m => {
                    return { namespace: 'vCostAnalysisPayGas', ...m.default };
                  },
                ),
              ],
              component: () => import('../cost-analysis/pay/gas/index.js'),
            })
          : require('../cost-analysis/pay/gas/index.js').default,
      },
      {
        path: '/cost-analysis/pay/water',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/cost-analysis/pay/water/models/vCostAnalysisPayWater.js').then(
                  m => {
                    return { namespace: 'vCostAnalysisPayWater', ...m.default };
                  },
                ),
              ],
              component: () => import('../cost-analysis/pay/water/index.js'),
            })
          : require('../cost-analysis/pay/water/index.js').default,
      },
      {
        path: '/cost-analysis/profit/bess',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/cost-analysis/profit/bess/models/vBess.js').then(
                  m => {
                    return { namespace: 'vBess', ...m.default };
                  },
                ),
              ],
              component: () => import('../cost-analysis/profit/bess/index.js'),
            })
          : require('../cost-analysis/profit/bess/index.js').default,
      },
      {
        path: '/cost-analysis/profit/pv',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/cost-analysis/profit/pv/models/vPv.js').then(
                  m => {
                    return { namespace: 'vPv', ...m.default };
                  },
                ),
              ],
              component: () => import('../cost-analysis/profit/pv/index.js'),
            })
          : require('../cost-analysis/profit/pv/index.js').default,
      },
      {
        path: '/cost-analysis/view',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/cost-analysis/view/models/vCostAnalysisView.js').then(
                  m => {
                    return { namespace: 'vCostAnalysisView', ...m.default };
                  },
                ),
              ],
              component: () => import('../cost-analysis/view/index.js'),
            })
          : require('../cost-analysis/view/index.js').default,
      },
      {
        path: '/demo/helper',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../demo/helper.js'),
            })
          : require('../demo/helper.js').default,
      },
      {
        path: '/demo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../demo/index.js'),
            })
          : require('../demo/index.js').default,
      },
      {
        path: '/emergency-security/fireControl/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/emergency-security/fireControl/overview/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../emergency-security/fireControl/overview/index.js'),
            })
          : require('../emergency-security/fireControl/overview/index.js')
              .default,
      },
      {
        path: '/emergency-security/fireControl/waterPressure',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/emergency-security/fireControl/waterPressure/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../emergency-security/fireControl/waterPressure/index.js'),
            })
          : require('../emergency-security/fireControl/waterPressure/index.js')
              .default,
      },
      {
        path: '/emergency-security',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../emergency-security/index.js'),
            })
          : require('../emergency-security/index.js').default,
      },
      {
        path: '/emergency-security/security/brakeRecord',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/emergency-security/security/brakeRecord/models/vbrakeRecord.js').then(
                  m => {
                    return { namespace: 'vbrakeRecord', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../emergency-security/security/brakeRecord/index.js'),
            })
          : require('../emergency-security/security/brakeRecord/index.js')
              .default,
      },
      {
        path: '/emergency-security/security/camera',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../emergency-security/security/camera/index.js'),
            })
          : require('../emergency-security/security/camera/index.js').default,
      },
      {
        path: '/emergency-security/security/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../emergency-security/security/overview/index.js'),
            })
          : require('../emergency-security/security/overview/index.js').default,
      },
      {
        path: '/energy-analyze/family-splitting/details',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-analyze/family-splitting/details/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-analyze/family-splitting/details/index.js'),
            })
          : require('../energy-analyze/family-splitting/details/index.js')
              .default,
      },
      {
        path: '/energy-analyze/family-splitting/enum',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-analyze/family-splitting/enum.js'),
            })
          : require('../energy-analyze/family-splitting/enum.js').default,
      },
      {
        path: '/energy-analyze/family-splitting',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-analyze/family-splitting/index.js'),
            })
          : require('../energy-analyze/family-splitting/index.js').default,
      },
      {
        path: '/energy-analyze/family-splitting/management/detail/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-analyze/family-splitting/management/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-analyze/family-splitting/management/detail/$id.js'),
            })
          : require('../energy-analyze/family-splitting/management/detail/$id.js')
              .default,
      },
      {
        path: '/energy-analyze/family-splitting/management/edit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-analyze/family-splitting/management/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-analyze/family-splitting/management/edit/index.js'),
            })
          : require('../energy-analyze/family-splitting/management/edit/index.js')
              .default,
      },
      {
        path: '/energy-analyze/family-splitting/management',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-analyze/family-splitting/management/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-analyze/family-splitting/management/index.js'),
            })
          : require('../energy-analyze/family-splitting/management/index.js')
              .default,
      },
      {
        path: '/energy-analyze/family-splitting/measurement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-analyze/family-splitting/measurement/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-analyze/family-splitting/measurement/index.js'),
            })
          : require('../energy-analyze/family-splitting/measurement/index.js')
              .default,
      },
      {
        path: '/energy-analyze',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-analyze/index.js'),
            })
          : require('../energy-analyze/index.js').default,
      },
      {
        path: '/energy-control/airConditionerMng/control/batchCtr',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/control/models/vEnergyControlAirConditionerMngControl.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngControl',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/control/batchCtr.js'),
            })
          : require('../energy-control/airConditionerMng/control/batchCtr.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/control/ctrOne',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/control/models/vEnergyControlAirConditionerMngControl.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngControl',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/control/ctrOne.js'),
            })
          : require('../energy-control/airConditionerMng/control/ctrOne.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/control/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/control/models/vEnergyControlAirConditionerMngControl.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngControl',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/control/detail.js'),
            })
          : require('../energy-control/airConditionerMng/control/detail.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/control/models/vEnergyControlAirConditionerMngControl.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngControl',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/control/index.js'),
            })
          : require('../energy-control/airConditionerMng/control/index.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/airConditionerMng/index.js'),
            })
          : require('../energy-control/airConditionerMng/index.js').default,
      },
      {
        path: '/energy-control/airConditionerMng/insensitive',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/airConditionerMng/insensitive/index.js'),
            })
          : require('../energy-control/airConditionerMng/insensitive/index.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/insensitive/thermometer',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/airConditionerMng/insensitive/thermometer.js'),
            })
          : require('../energy-control/airConditionerMng/insensitive/thermometer.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/record/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/record/models/vEnergyControlAirConditionerMngRecord.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngRecord',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/record/detail.js'),
            })
          : require('../energy-control/airConditionerMng/record/detail.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/record/models/vEnergyControlAirConditionerMngRecord.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngRecord',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/record/index.js'),
            })
          : require('../energy-control/airConditionerMng/record/index.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/total/add',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/total/models/vEnergyControlAirConditionerMngTotal.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngTotal',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/total/add.js'),
            })
          : require('../energy-control/airConditionerMng/total/add.js').default,
      },
      {
        path: '/energy-control/airConditionerMng/total/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/total/models/vEnergyControlAirConditionerMngTotal.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngTotal',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/total/detail.js'),
            })
          : require('../energy-control/airConditionerMng/total/detail.js')
              .default,
      },
      {
        path: '/energy-control/airConditionerMng/total',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airConditionerMng/total/models/vEnergyControlAirConditionerMngTotal.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyControlAirConditionerMngTotal',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airConditionerMng/total/index.js'),
            })
          : require('../energy-control/airConditionerMng/total/index.js')
              .default,
      },
      {
        path: '/energy-control/airSwitch/alarm-monitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/airSwitch/alarm-monitor/index.js'),
            })
          : require('../energy-control/airSwitch/alarm-monitor/index.js')
              .default,
      },
      {
        path: '/energy-control/airSwitch/equipment-monitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/airSwitch/equipment-monitor/index.js'),
            })
          : require('../energy-control/airSwitch/equipment-monitor/index.js')
              .default,
      },
      {
        path: '/energy-control/airSwitch',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-control/airSwitch/index.js'),
            })
          : require('../energy-control/airSwitch/index.js').default,
      },
      {
        path: '/energy-control/airSwitch/scheduled-task',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/airSwitch/scheduled-task/index.js'),
            })
          : require('../energy-control/airSwitch/scheduled-task/index.js')
              .default,
      },
      {
        path: '/energy-control/airSwitch/switch-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airSwitch/switch-control/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airSwitch/switch-control/index.js'),
            })
          : require('../energy-control/airSwitch/switch-control/index.js')
              .default,
      },
      {
        path: '/energy-control/airSwitch/switch-record/detail/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airSwitch/switch-record/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airSwitch/switch-record/detail/$id.js'),
            })
          : require('../energy-control/airSwitch/switch-record/detail/$id.js')
              .default,
      },
      {
        path: '/energy-control/airSwitch/switch-record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/airSwitch/switch-record/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/airSwitch/switch-record/index.js'),
            })
          : require('../energy-control/airSwitch/switch-record/index.js')
              .default,
      },
      {
        path: '/energy-control/flexibleLoadMng/control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/flexibleLoadMng/control/models/vFlexibleLoadMng.js').then(
                  m => {
                    return { namespace: 'vFlexibleLoadMng', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/flexibleLoadMng/control/index.js'),
            })
          : require('../energy-control/flexibleLoadMng/control/index.js')
              .default,
      },
      {
        path: '/energy-control/flexibleLoadMng',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/flexibleLoadMng/index.js'),
            })
          : require('../energy-control/flexibleLoadMng/index.js').default,
      },
      {
        path: '/energy-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-control/index.js'),
            })
          : require('../energy-control/index.js').default,
      },
      {
        path: '/energy-control/load-manage/air/air-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/air/air-control/index.js'),
            })
          : require('../energy-control/load-manage/air/air-control/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/air/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/air/record/index.js'),
            })
          : require('../energy-control/load-manage/air/record/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/air/strategy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/air/strategy/index.js'),
            })
          : require('../energy-control/load-manage/air/strategy/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/charging-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/charging-control/index.js'),
            })
          : require('../energy-control/load-manage/charging-control/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/charging-pile',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/charging-pile/index.js'),
            })
          : require('../energy-control/load-manage/charging-pile/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/empty/empty-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/empty/empty-control/index.js'),
            })
          : require('../energy-control/load-manage/empty/empty-control/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/empty/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/empty/record/index.js'),
            })
          : require('../energy-control/load-manage/empty/record/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/light/light-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/light/light-control/index.js'),
            })
          : require('../energy-control/load-manage/light/light-control/index.js')
              .default,
      },
      {
        path: '/energy-control/load-manage/light/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/load-manage/light/record/index.js'),
            })
          : require('../energy-control/load-manage/light/record/index.js')
              .default,
      },
      {
        path: '/energy-control/power-control/remote-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/power-control/remote-control/index.js'),
            })
          : require('../energy-control/power-control/remote-control/index.js')
              .default,
      },
      {
        path: '/energy-control/power-control/strategy-manage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/power-control/strategy-manage/index.js'),
            })
          : require('../energy-control/power-control/strategy-manage/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/history',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/history/index.js'),
            })
          : require('../energy-control/smartControl/history/index.js').default,
      },
      {
        path: '/energy-control/smartControl/history/modal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/history/modal.js'),
            })
          : require('../energy-control/smartControl/history/modal.js').default,
      },
      {
        path: '/energy-control/smartControl/overView/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/overView/detail.js'),
            })
          : require('../energy-control/smartControl/overView/detail.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/overView/header',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/overView/header.js'),
            })
          : require('../energy-control/smartControl/overView/header.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/overView/incomeAnalysis',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/overView/incomeAnalysis.js'),
            })
          : require('../energy-control/smartControl/overView/incomeAnalysis.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/overView',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/overView/index.js'),
            })
          : require('../energy-control/smartControl/overView/index.js').default,
      },
      {
        path: '/energy-control/smartControl/priceInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/priceInfo/index.js'),
            })
          : require('../energy-control/smartControl/priceInfo/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/configPage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/configPage/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/configPage/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/fixedPage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/fixedPage/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/fixedPage/index.js')
              .default,
      },
      {
        path:
          '/energy-control/smartControl/workStrategy/fixedPage/setFixed/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/fixedPage/setFixed/$id.js'),
            })
          : require('../energy-control/smartControl/workStrategy/fixedPage/setFixed/$id.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/fixedPage/useFixed',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/fixedPage/useFixed.js'),
            })
          : require('../energy-control/smartControl/workStrategy/fixedPage/useFixed.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/policyBack',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyBack/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyBack/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/policyEchart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyEchart/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyEchart/index.js')
              .default,
      },
      {
        path:
          '/energy-control/smartControl/workStrategy/policyEchart/lineConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyEchart/lineConfig.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyEchart/lineConfig.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/policyList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyList/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyList/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/policyMonth',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyMonth/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyMonth/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/policyProtect',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyProtect/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyProtect/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/policySwitch',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policySwitch/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policySwitch/index.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/policyTable',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyTable/index.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyTable/index.js')
              .default,
      },
      {
        path:
          '/energy-control/smartControl/workStrategy/policyTable/overflowTd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/policyTable/overflowTd.js'),
            })
          : require('../energy-control/smartControl/workStrategy/policyTable/overflowTd.js')
              .default,
      },
      {
        path: '/energy-control/smartControl/workStrategy/useWorkStrategy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/smartControl/workStrategy/useWorkStrategy.js'),
            })
          : require('../energy-control/smartControl/workStrategy/useWorkStrategy.js')
              .default,
      },
      {
        path: '/energy-control/underground-lighting',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/underground-lighting/index.js'),
            })
          : require('../energy-control/underground-lighting/index.js').default,
      },
      {
        path: '/energy-control/underground-lighting/lighting-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/underground-lighting/lighting-control/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/underground-lighting/lighting-control/index.js'),
            })
          : require('../energy-control/underground-lighting/lighting-control/index.js')
              .default,
      },
      {
        path: '/energy-control/underground-lighting/lighting-record/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/underground-lighting/lighting-record/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/underground-lighting/lighting-record/detail/index.js'),
            })
          : require('../energy-control/underground-lighting/lighting-record/detail/index.js')
              .default,
      },
      {
        path: '/energy-control/underground-lighting/lighting-record/hash',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/underground-lighting/lighting-record/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/underground-lighting/lighting-record/hash.js'),
            })
          : require('../energy-control/underground-lighting/lighting-record/hash.js')
              .default,
      },
      {
        path: '/energy-control/underground-lighting/lighting-record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/underground-lighting/lighting-record/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-control/underground-lighting/lighting-record/index.js'),
            })
          : require('../energy-control/underground-lighting/lighting-record/index.js')
              .default,
      },
      {
        path: '/energy-control/view',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-control/view/index.js'),
            })
          : require('../energy-control/view/index.js').default,
      },
      {
        path: '/energy-control/bess',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                  m => {
                    return { namespace: 'elecScheme', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                  m => {
                    return { namespace: 'priceScheme', ...m.default };
                  },
                ),
              ],
              component: () => import('../energy-control/bess/_layout.js'),
            })
          : require('../energy-control/bess/_layout.js').default,
        routes: [
          {
            path: '/energy-control/bess/elec-scheme/detail/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/bess/elec-scheme/detail/$id.js'),
                })
              : require('../energy-control/bess/elec-scheme/detail/$id.js')
                  .default,
          },
          {
            path: '/energy-control/bess/elec-scheme/edit/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/bess/elec-scheme/edit/$id.js'),
                })
              : require('../energy-control/bess/elec-scheme/edit/$id.js')
                  .default,
          },
          {
            path: '/energy-control/bess/elec-scheme',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/bess/elec-scheme/index.js'),
                })
              : require('../energy-control/bess/elec-scheme/index.js').default,
          },
          {
            path: '/energy-control/bess',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../energy-control/bess/index.js'),
                })
              : require('../energy-control/bess/index.js').default,
          },
          {
            path: '/energy-control/bess/price-scheme/detail/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/bess/price-scheme/detail/$id.js'),
                })
              : require('../energy-control/bess/price-scheme/detail/$id.js')
                  .default,
          },
          {
            path: '/energy-control/bess/price-scheme/edit/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/bess/price-scheme/edit/$id.js'),
                })
              : require('../energy-control/bess/price-scheme/edit/$id.js')
                  .default,
          },
          {
            path: '/energy-control/bess/price-scheme',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/bess/price-scheme/index.js'),
                })
              : require('../energy-control/bess/price-scheme/index.js').default,
          },
          {
            path: '/energy-control/bess/protectPowerSupply-scheme',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/protectPowerSupply-scheme/models/vProtectPowerSupply.js').then(
                      m => {
                        return {
                          namespace: 'vProtectPowerSupply',
                          ...m.default,
                        };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/elecScheme.js').then(
                      m => {
                        return { namespace: 'elecScheme', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/bess/models/priceScheme.js').then(
                      m => {
                        return { namespace: 'priceScheme', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/bess/protectPowerSupply-scheme/index.js'),
                })
              : require('../energy-control/bess/protectPowerSupply-scheme/index.js')
                  .default,
          },
        ],
      },
      {
        path: '/energy-control/street-lamp',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-control/street-lamp/_layout.js'),
            })
          : require('../energy-control/street-lamp/_layout.js').default,
        routes: [
          {
            path: '/energy-control/street-lamp',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import('../energy-control/street-lamp/index.js'),
                })
              : require('../energy-control/street-lamp/index.js').default,
          },
          {
            path: '/energy-control/street-lamp/lamp-control',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/street-lamp/lamp-control/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/street-lamp/lamp-control/index.js'),
                })
              : require('../energy-control/street-lamp/lamp-control/index.js')
                  .default,
          },
          {
            path: '/energy-control/street-lamp/lamp-record/detail/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/street-lamp/lamp-record/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/street-lamp/lamp-record/detail/$id.js'),
                })
              : require('../energy-control/street-lamp/lamp-record/detail/$id.js')
                  .default,
          },
          {
            path: '/energy-control/street-lamp/lamp-record',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-control/street-lamp/lamp-record/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-control/street-lamp/lamp-record/index.js'),
                })
              : require('../energy-control/street-lamp/lamp-record/index.js')
                  .default,
          },
        ],
      },
      {
        path: '/energy-monitor/datum/electric/component/chartOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/datum/electric/models/vEnergyMonitorDatumElectric.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumElectric',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/datum/electric/component/chartOption.js'),
            })
          : require('../energy-monitor/datum/electric/component/chartOption.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/electric/component/chartOptionAlert',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/datum/electric/models/vEnergyMonitorDatumElectric.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumElectric',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/datum/electric/component/chartOptionAlert.js'),
            })
          : require('../energy-monitor/datum/electric/component/chartOptionAlert.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/electric',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/datum/electric/models/vEnergyMonitorDatumElectric.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumElectric',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/datum/electric/index.js'),
            })
          : require('../energy-monitor/datum/electric/index.js').default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics/comparison',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/comparison/index.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/comparison/index.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/comparison/lineConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/comparison/lineConfig.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/comparison/lineConfig.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/comparison/search',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/comparison/search.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/comparison/search.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics/config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/config.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/config.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics/contrast',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/contrast/index.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/contrast/index.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/contrast/lineConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/contrast/lineConfig.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/contrast/lineConfig.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics/effect',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/effect/index.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/effect/index.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/index.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/index.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics/lateralStrain',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/lateralStrain/index.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/lateralStrain/index.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/lateralStrain/lineConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/lateralStrain/lineConfig.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/lateralStrain/lineConfig.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/lateralStrain/serch',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/lateralStrain/serch.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/lateralStrain/serch.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics/ranking',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/ranking/index.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/ranking/index.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/ranking/lineConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/ranking/lineConfig.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/ranking/lineConfig.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/tendency/chart.option',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/tendency/chart.option.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/tendency/chart.option.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/energyConsumptionStatistics/tendency',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/tendency/index.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/tendency/index.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/energyConsumptionStatistics/tendency/services',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/energyConsumptionStatistics/tendency/services.js'),
            })
          : require('../energy-monitor/datum/energyConsumptionStatistics/tendency/services.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/equipmentManagement/component/contentItem',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/contentItem/index.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/contentItem/index.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.option',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.option.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.option.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.option2',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.option2.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/chart.option2.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/equipmentManagement/component/dataDetailModal/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/detail.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/detail.js')
              .default,
      },
      {
        path:
          '/energy-monitor/datum/equipmentManagement/component/dataDetailModal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/index.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/dataDetailModal/index.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/equipmentManagement/component/detailModal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/detailModal/index.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/detailModal/index.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/equipmentManagement/component/topItem',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/component/topItem/index.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/component/topItem/index.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/equipmentManagement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/datum/equipmentManagement/index.js'),
            })
          : require('../energy-monitor/datum/equipmentManagement/index.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/gas/component/chartOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/datum/gas/models/vEnergyMonitorDatumGas.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumGas',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/datum/gas/component/chartOption.js'),
            })
          : require('../energy-monitor/datum/gas/component/chartOption.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/gas',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/datum/gas/models/vEnergyMonitorDatumGas.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumGas',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () => import('../energy-monitor/datum/gas/index.js'),
            })
          : require('../energy-monitor/datum/gas/index.js').default,
      },
      {
        path: '/energy-monitor/datum',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-monitor/datum/index.js'),
            })
          : require('../energy-monitor/datum/index.js').default,
      },
      {
        path: '/energy-monitor/datum/water/component/chartOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/datum/water/models/vEnergyMonitorDatumWater.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumWater',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/datum/water/component/chartOption.js'),
            })
          : require('../energy-monitor/datum/water/component/chartOption.js')
              .default,
      },
      {
        path: '/energy-monitor/datum/water',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/datum/water/models/vEnergyMonitorDatumWater.js').then(
                  m => {
                    return {
                      namespace: 'vEnergyMonitorDatumWater',
                      ...m.default,
                    };
                  },
                ),
              ],
              component: () => import('../energy-monitor/datum/water/index.js'),
            })
          : require('../energy-monitor/datum/water/index.js').default,
      },
      {
        path: '/energy-monitor/efficiency/ranking',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/efficiency/ranking/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/efficiency/ranking/index.js'),
            })
          : require('../energy-monitor/efficiency/ranking/index.js').default,
      },
      {
        path: '/energy-monitor/efficiency/ranking/lineConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/efficiency/ranking/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/efficiency/ranking/lineConfig.js'),
            })
          : require('../energy-monitor/efficiency/ranking/lineConfig.js')
              .default,
      },
      {
        path: '/energy-monitor/energy/balance',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy/balance/_layout.js'),
            })
          : require('../energy-monitor/energy/balance/_layout.js').default,
        routes: [
          {
            path: '/energy-monitor/energy/balance',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import('../energy-monitor/energy/balance/index.js'),
                })
              : require('../energy-monitor/energy/balance/index.js').default,
          },
          {
            path: '/energy-monitor/energy/balance/lineBalance',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import('../energy-monitor/energy/balance/lineBalance/index.js'),
                })
              : require('../energy-monitor/energy/balance/lineBalance/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/balance/waterBalance',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () =>
                    import('../energy-monitor/energy/balance/waterBalance/index.js'),
                })
              : require('../energy-monitor/energy/balance/waterBalance/index.js')
                  .default,
          },
        ],
      },
      {
        path: '/energy-monitor/energy/energyStorage',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                  m => {
                    return { namespace: 'enEquipment', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                  m => {
                    return { namespace: 'enStation', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/energy/energyStorage/_layout.js'),
            })
          : require('../energy-monitor/energy/energyStorage/_layout.js')
              .default,
        routes: [
          {
            path: '/energy-monitor/energy/energyStorage/equipment',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                      m => {
                        return { namespace: 'enEquipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                      m => {
                        return { namespace: 'enStation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/energyStorage/equipment/index.js'),
                })
              : require('../energy-monitor/energy/energyStorage/equipment/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/energyStorage/equipment/lineConfig',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                      m => {
                        return { namespace: 'enEquipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                      m => {
                        return { namespace: 'enStation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/energyStorage/equipment/lineConfig.js'),
                })
              : require('../energy-monitor/energy/energyStorage/equipment/lineConfig.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/energyStorage',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                      m => {
                        return { namespace: 'enEquipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                      m => {
                        return { namespace: 'enStation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/energyStorage/index.js'),
                })
              : require('../energy-monitor/energy/energyStorage/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/energyStorage/station',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                      m => {
                        return { namespace: 'enEquipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                      m => {
                        return { namespace: 'enStation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/energyStorage/station/index.js'),
                })
              : require('../energy-monitor/energy/energyStorage/station/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/energyStorage/station/info',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                      m => {
                        return { namespace: 'enEquipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                      m => {
                        return { namespace: 'enStation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/energyStorage/station/info.js'),
                })
              : require('../energy-monitor/energy/energyStorage/station/info.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/energyStorage/station/lineConfig',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                      m => {
                        return { namespace: 'enEquipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                      m => {
                        return { namespace: 'enStation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/energyStorage/station/lineConfig.js'),
                })
              : require('../energy-monitor/energy/energyStorage/station/lineConfig.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/energyStorage/station/lineConfig2',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enEquipment.js').then(
                      m => {
                        return { namespace: 'enEquipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/energyStorage/models/enStation.js').then(
                      m => {
                        return { namespace: 'enStation', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/energyStorage/station/lineConfig2.js'),
                })
              : require('../energy-monitor/energy/energyStorage/station/lineConfig2.js')
                  .default,
          },
        ],
      },
      {
        path: '/energy-monitor/energy/photovoltaic',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                  m => {
                    return { namespace: 'equipment', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/energy/photovoltaic/_layout.js'),
            })
          : require('../energy-monitor/energy/photovoltaic/_layout.js').default,
        routes: [
          {
            path: '/energy-monitor/energy/photovoltaic/equipment',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                      m => {
                        return { namespace: 'equipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                      m => {
                        return { namespace: 'station', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/photovoltaic/equipment/index.js'),
                })
              : require('../energy-monitor/energy/photovoltaic/equipment/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/photovoltaic/equipment/lineConfig',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                      m => {
                        return { namespace: 'equipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                      m => {
                        return { namespace: 'station', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/photovoltaic/equipment/lineConfig.js'),
                })
              : require('../energy-monitor/energy/photovoltaic/equipment/lineConfig.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/photovoltaic',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                      m => {
                        return { namespace: 'equipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                      m => {
                        return { namespace: 'station', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/photovoltaic/index.js'),
                })
              : require('../energy-monitor/energy/photovoltaic/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/photovoltaic/station',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                      m => {
                        return { namespace: 'equipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                      m => {
                        return { namespace: 'station', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/photovoltaic/station/index.js'),
                })
              : require('../energy-monitor/energy/photovoltaic/station/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/photovoltaic/station/info',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                      m => {
                        return { namespace: 'equipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                      m => {
                        return { namespace: 'station', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/photovoltaic/station/info.js'),
                })
              : require('../energy-monitor/energy/photovoltaic/station/info.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/photovoltaic/station/lineConfig',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                      m => {
                        return { namespace: 'equipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                      m => {
                        return { namespace: 'station', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/photovoltaic/station/lineConfig.js'),
                })
              : require('../energy-monitor/energy/photovoltaic/station/lineConfig.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/photovoltaic/station/lineConfig2',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/equipment.js').then(
                      m => {
                        return { namespace: 'equipment', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/photovoltaic/models/station.js').then(
                      m => {
                        return { namespace: 'station', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/photovoltaic/station/lineConfig2.js'),
                })
              : require('../energy-monitor/energy/photovoltaic/station/lineConfig2.js')
                  .default,
          },
        ],
      },
      {
        path: '/energy-monitor/energy/tendency',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/electricity.js').then(
                  m => {
                    return { namespace: 'electricity', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/water.js').then(
                  m => {
                    return { namespace: 'water', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/energy/tendency/_layout.js'),
            })
          : require('../energy-monitor/energy/tendency/_layout.js').default,
        routes: [
          {
            path: '/energy-monitor/energy/tendency/electricity',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/electricity.js').then(
                      m => {
                        return { namespace: 'electricity', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/water.js').then(
                      m => {
                        return { namespace: 'water', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/tendency/electricity/index.js'),
                })
              : require('../energy-monitor/energy/tendency/electricity/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/tendency',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/electricity.js').then(
                      m => {
                        return { namespace: 'electricity', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/water.js').then(
                      m => {
                        return { namespace: 'water', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/tendency/index.js'),
                })
              : require('../energy-monitor/energy/tendency/index.js').default,
          },
          {
            path: '/energy-monitor/energy/tendency/water/chartOne',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/electricity.js').then(
                      m => {
                        return { namespace: 'electricity', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/water.js').then(
                      m => {
                        return { namespace: 'water', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/tendency/water/chartOne/index.js'),
                })
              : require('../energy-monitor/energy/tendency/water/chartOne/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/tendency/water/chartOne/lineConfig',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/electricity.js').then(
                      m => {
                        return { namespace: 'electricity', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/water.js').then(
                      m => {
                        return { namespace: 'water', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/tendency/water/chartOne/lineConfig.js'),
                })
              : require('../energy-monitor/energy/tendency/water/chartOne/lineConfig.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/tendency/water',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/electricity.js').then(
                      m => {
                        return { namespace: 'electricity', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/water.js').then(
                      m => {
                        return { namespace: 'water', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/tendency/water/index.js'),
                })
              : require('../energy-monitor/energy/tendency/water/index.js')
                  .default,
          },
          {
            path: '/energy-monitor/energy/tendency/water/infoOne',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/electricity.js').then(
                      m => {
                        return { namespace: 'electricity', ...m.default };
                      },
                    ),
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/energy/tendency/models/water.js').then(
                      m => {
                        return { namespace: 'water', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/energy/tendency/water/infoOne.js'),
                })
              : require('../energy-monitor/energy/tendency/water/infoOne.js')
                  .default,
          },
        ],
      },
      {
        path: '/energy-monitor/energy-use-management/buildManage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/buildManage/index.js'),
            })
          : require('../energy-monitor/energy-use-management/buildManage/index.js')
              .default,
      },
      {
        path: '/energy-monitor/energy-use-management/buildManage/tool',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/buildManage/tool.js'),
            })
          : require('../energy-monitor/energy-use-management/buildManage/tool.js')
              .default,
      },
      {
        path: '/energy-monitor/energy-use-management/classify',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/classify/index.js'),
            })
          : require('../energy-monitor/energy-use-management/classify/index.js')
              .default,
      },
      {
        path: '/energy-monitor/energy-use-management/classify/service',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/classify/service/index.js'),
            })
          : require('../energy-monitor/energy-use-management/classify/service/index.js')
              .default,
      },
      {
        path: '/energy-monitor/energy-use-management/classify/tool/helper',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/classify/tool/helper.js'),
            })
          : require('../energy-monitor/energy-use-management/classify/tool/helper.js')
              .default,
      },
      {
        path: '/energy-monitor/energy-use-management/outputValue/formTable',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/outputValue/formTable.js'),
            })
          : require('../energy-monitor/energy-use-management/outputValue/formTable.js')
              .default,
      },
      {
        path: '/energy-monitor/energy-use-management/outputValue',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/outputValue/index.js'),
            })
          : require('../energy-monitor/energy-use-management/outputValue/index.js')
              .default,
      },
      {
        path: '/energy-monitor/energy-use-management/outputValue/listTable',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../energy-monitor/energy-use-management/outputValue/listTable.js'),
            })
          : require('../energy-monitor/energy-use-management/outputValue/listTable.js')
              .default,
      },
      {
        path: '/energy-monitor/graph/component',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                  m => {
                    return { namespace: 'vEnergyMonitorGraph', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/graph/component/index.js'),
            })
          : require('../energy-monitor/graph/component/index.js').default,
      },
      {
        path: '/energy-monitor/graph/component/:id',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                  m => {
                    return { namespace: 'vEnergyMonitorGraph', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/graph/component/$id.js'),
            })
          : require('../energy-monitor/graph/component/$id.js').default,
      },
      {
        path: '/energy-monitor/graph',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                  m => {
                    return { namespace: 'vEnergyMonitorGraph', ...m.default };
                  },
                ),
              ],
              component: () => import('../energy-monitor/graph/index.js'),
            })
          : require('../energy-monitor/graph/index.js').default,
      },
      {
        path: '/energy-monitor/graph/electric',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                  m => {
                    return { namespace: 'vEnergyMonitorGraph', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/graph/electric/_layout.js'),
            })
          : require('../energy-monitor/graph/electric/_layout.js').default,
        routes: [
          {
            path: '/energy-monitor/graph/electric',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                      m => {
                        return {
                          namespace: 'vEnergyMonitorGraph',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/graph/electric/index.js'),
                })
              : require('../energy-monitor/graph/electric/index.js').default,
          },
          {
            path: '/energy-monitor/graph/electric/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                      m => {
                        return {
                          namespace: 'vEnergyMonitorGraph',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/graph/electric/$id.js'),
                })
              : require('../energy-monitor/graph/electric/$id.js').default,
          },
        ],
      },
      {
        path: '/energy-monitor/graph/pipe',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                  m => {
                    return { namespace: 'vEnergyMonitorGraph', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../energy-monitor/graph/pipe/_layout.js'),
            })
          : require('../energy-monitor/graph/pipe/_layout.js').default,
        routes: [
          {
            path: '/energy-monitor/graph/pipe',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                      m => {
                        return {
                          namespace: 'vEnergyMonitorGraph',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/graph/pipe/index.js'),
                })
              : require('../energy-monitor/graph/pipe/index.js').default,
          },
          {
            path: '/energy-monitor/graph/pipe/:id',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/energy-monitor/graph/models/vEnergyMonitorGraph.js').then(
                      m => {
                        return {
                          namespace: 'vEnergyMonitorGraph',
                          ...m.default,
                        };
                      },
                    ),
                  ],
                  component: () =>
                    import('../energy-monitor/graph/pipe/$id.js'),
                })
              : require('../energy-monitor/graph/pipe/$id.js').default,
          },
        ],
      },
      {
        path: '/energy-monitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-monitor/index.js'),
            })
          : require('../energy-monitor/index.js').default,
      },
      {
        path: '/energy-overview/card1Config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-overview/card1Config.js'),
            })
          : require('../energy-overview/card1Config.js').default,
      },
      {
        path: '/energy-overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-overview/index.js'),
            })
          : require('../energy-overview/index.js').default,
      },
      {
        path: '/energy-station/monitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../energy-station/monitor/index.js'),
            })
          : require('../energy-station/monitor/index.js').default,
      },
      {
        path: '/event-center/event-center',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/event-center/event-center/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () => import('../event-center/event-center/index.js'),
            })
          : require('../event-center/event-center/index.js').default,
      },
      {
        path: '/event-center/exception-center',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/event-center/exception-center/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../event-center/exception-center/index.js'),
            })
          : require('../event-center/exception-center/index.js').default,
      },
      {
        path: '/event-center/exception-statistics',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/event-center/exception-statistics/model.js').then(
                  m => {
                    return { namespace: 'model', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../event-center/exception-statistics/index.js'),
            })
          : require('../event-center/exception-statistics/index.js').default,
      },
      {
        path: '/event-center',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../event-center/index.js'),
            })
          : require('../event-center/index.js').default,
      },
      {
        path: '/found',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../found/index.js'),
            })
          : require('../found/index.js').default,
      },
      {
        path: '/',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../index.js'),
            })
          : require('../index.js').default,
      },
      {
        path: '/login/default',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../login/default/index.js'),
            })
          : require('../login/default/index.js').default,
      },
      {
        path: '/login',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../login/index.js'),
            })
          : require('../login/index.js').default,
      },
      {
        path: '/login-demo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../login-demo/index.js'),
            })
          : require('../login-demo/index.js').default,
      },
      {
        path: '/login-demo/server',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../login-demo/server/index.js'),
            })
          : require('../login-demo/server/index.js').default,
      },
      {
        path: '/n-cenenergy-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-cenenergy-control/index.js'),
            })
          : require('../n-cenenergy-control/index.js').default,
      },
      {
        path: '/n-cenenergy-control/load-manage/air/air-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/air/air-control/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/air/air-control/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/air/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/air/record/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/air/record/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/air/strategy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/air/strategy/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/air/strategy/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/charging-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/charging-control/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/charging-control/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/charging-pile',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/charging-pile/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/charging-pile/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/empty/empty-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/empty/empty-control/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/empty/empty-control/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/empty/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/empty/record/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/empty/record/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/light/light-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/light/light-control/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/light/light-control/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/load-manage/light/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/load-manage/light/record/index.js'),
            })
          : require('../n-cenenergy-control/load-manage/light/record/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/power-control/remote-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/power-control/remote-control/index.js'),
            })
          : require('../n-cenenergy-control/power-control/remote-control/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/power-control/strategy-manage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-cenenergy-control/power-control/strategy-manage/index.js'),
            })
          : require('../n-cenenergy-control/power-control/strategy-manage/index.js')
              .default,
      },
      {
        path: '/n-cenenergy-control/view',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-cenenergy-control/view/index.js'),
            })
          : require('../n-cenenergy-control/view/index.js').default,
      },
      {
        path: '/n-company',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-company/index.js'),
            })
          : require('../n-company/index.js').default,
      },
      {
        path: '/n-company/station-view/card',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-company/station-view/card.js'),
            })
          : require('../n-company/station-view/card.js').default,
      },
      {
        path: '/n-company/station-view',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-company/station-view/index.js'),
            })
          : require('../n-company/station-view/index.js').default,
      },
      {
        path: '/n-company/station-view/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-company/station-view/list/index.js'),
            })
          : require('../n-company/station-view/list/index.js').default,
      },
      {
        path: '/n-company/station-view/modal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-company/station-view/modal/index.js'),
            })
          : require('../n-company/station-view/modal/index.js').default,
      },
      {
        path: '/n-company/station-view/table',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-company/station-view/table.js'),
            })
          : require('../n-company/station-view/table.js').default,
      },
      {
        path: '/n-company/station-view/top/static',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-company/station-view/top/static.js'),
            })
          : require('../n-company/station-view/top/static.js').default,
      },
      {
        path: '/n-company/station-view/useStationView',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-company/station-view/useStationView.js'),
            })
          : require('../n-company/station-view/useStationView.js').default,
      },
      {
        path: '/n-energy-load/energy/efficiency/ranking',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-load/energy/efficiency/ranking/index.js'),
            })
          : require('../n-energy-load/energy/efficiency/ranking/index.js')
              .default,
      },
      {
        path: '/n-energy-load/energy/use/balance',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-load/energy/use/balance/index.js'),
            })
          : require('../n-energy-load/energy/use/balance/index.js').default,
      },
      {
        path: '/n-energy-load/energy/use/storage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-load/energy/use/storage/index.js'),
            })
          : require('../n-energy-load/energy/use/storage/index.js').default,
      },
      {
        path: '/n-energy-load/energy/use/trend',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-load/energy/use/trend/index.js'),
            })
          : require('../n-energy-load/energy/use/trend/index.js').default,
      },
      {
        path: '/n-energy-load',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-energy-load/index.js'),
            })
          : require('../n-energy-load/index.js').default,
      },
      {
        path: '/n-energy-load/monitor/user-data/electric',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-load/monitor/user-data/electric/index.js'),
            })
          : require('../n-energy-load/monitor/user-data/electric/index.js')
              .default,
      },
      {
        path: '/n-energy-load/monitor/user-data/gas',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-load/monitor/user-data/gas/index.js'),
            })
          : require('../n-energy-load/monitor/user-data/gas/index.js').default,
      },
      {
        path: '/n-energy-load/monitor/user-data/water',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-load/monitor/user-data/water/index.js'),
            })
          : require('../n-energy-load/monitor/user-data/water/index.js')
              .default,
      },
      {
        path: '/n-energy-overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-energy-overview/index.js'),
            })
          : require('../n-energy-overview/index.js').default,
      },
      {
        path: '/n-energy-product',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-energy-product/index.js'),
            })
          : require('../n-energy-product/index.js').default,
      },
      {
        path: '/n-energy-product/photovoltaic/analysis',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/analysis/index.js'),
            })
          : require('../n-energy-product/photovoltaic/analysis/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/assets/assetAccount',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/assetAccount/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/assetAccount/index.js')
              .default,
      },
      {
        path:
          '/n-energy-product/photovoltaic/assets/assetAccount/stationInfo/add',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/add/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/add/index.js')
              .default,
      },
      {
        path:
          '/n-energy-product/photovoltaic/assets/assetAccount/stationInfo/communication',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/communication/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/communication/index.js')
              .default,
      },
      {
        path:
          '/n-energy-product/photovoltaic/assets/assetAccount/stationInfo/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/detail/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/detail/index.js')
              .default,
      },
      {
        path:
          '/n-energy-product/photovoltaic/assets/assetAccount/stationInfo/device',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/device/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/device/index.js')
              .default,
      },
      {
        path:
          '/n-energy-product/photovoltaic/assets/assetAccount/stationInfo/edit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/edit/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/edit/index.js')
              .default,
      },
      {
        path:
          '/n-energy-product/photovoltaic/assets/assetAccount/stationInfo/metric',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/metric/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/assetAccount/stationInfo/metric/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/assets/company',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/company/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/company/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/assets',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/index.js').default,
      },
      {
        path: '/n-energy-product/photovoltaic/assets/station/stationInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/station/stationInfo/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/station/stationInfo/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/assets/station/stationlist',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/assets/station/stationlist/index.js'),
            })
          : require('../n-energy-product/photovoltaic/assets/station/stationlist/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/eleGen',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/eleGen/index.js'),
            })
          : require('../n-energy-product/photovoltaic/eleGen/index.js').default,
      },
      {
        path: '/n-energy-product/photovoltaic/eleGen/useElegen',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/eleGen/useElegen.js'),
            })
          : require('../n-energy-product/photovoltaic/eleGen/useElegen.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/equipmentMonitor/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/equipmentMonitor/detail.js'),
            })
          : require('../n-energy-product/photovoltaic/equipmentMonitor/detail.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/equipmentMonitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/equipmentMonitor/index.js'),
            })
          : require('../n-energy-product/photovoltaic/equipmentMonitor/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/equipmentMonitor/initOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/equipmentMonitor/initOption.js'),
            })
          : require('../n-energy-product/photovoltaic/equipmentMonitor/initOption.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/index.js'),
            })
          : require('../n-energy-product/photovoltaic/index.js').default,
      },
      {
        path: '/n-energy-product/photovoltaic/monitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/monitor/index.js'),
            })
          : require('../n-energy-product/photovoltaic/monitor/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/profit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/profit/index.js'),
            })
          : require('../n-energy-product/photovoltaic/profit/index.js').default,
      },
      {
        path: '/n-energy-product/photovoltaic/run/abnormal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/run/abnormal/index.js'),
            })
          : require('../n-energy-product/photovoltaic/run/abnormal/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/run',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/run/index.js'),
            })
          : require('../n-energy-product/photovoltaic/run/index.js').default,
      },
      {
        path: '/n-energy-product/photovoltaic/run/rules',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/run/rules/index.js'),
            })
          : require('../n-energy-product/photovoltaic/run/rules/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/run/station',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/run/station/index.js'),
            })
          : require('../n-energy-product/photovoltaic/run/station/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/statistics',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/statistics/index.js'),
            })
          : require('../n-energy-product/photovoltaic/statistics/index.js')
              .default,
      },
      {
        path: '/n-energy-product/photovoltaic/totalAnalyze',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-energy-product/photovoltaic/totalAnalyze/index.js'),
            })
          : require('../n-energy-product/photovoltaic/totalAnalyze/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/accountSystem/account',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/accountSystem/account/index.js'),
            })
          : require('../n-flexible-storage/battery/accountSystem/account/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/accountSystem/accountEdit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/accountSystem/accountEdit/index.js'),
            })
          : require('../n-flexible-storage/battery/accountSystem/accountEdit/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/accountSystem/accountInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/accountSystem/accountInfo/index.js'),
            })
          : require('../n-flexible-storage/battery/accountSystem/accountInfo/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/component/priceInfoItem',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/component/priceInfoItem/index.js'),
            })
          : require('../n-flexible-storage/battery/component/priceInfoItem/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/component/schemeInfoItem',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/component/schemeInfoItem/index.js'),
            })
          : require('../n-flexible-storage/battery/component/schemeInfoItem/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/data/battery-cluster',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/data/battery-cluster/index.js'),
            })
          : require('../n-flexible-storage/battery/data/battery-cluster/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/data/battery-stack',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/data/battery-stack/index.js'),
            })
          : require('../n-flexible-storage/battery/data/battery-stack/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/data/customize',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/data/customize/index.js'),
            })
          : require('../n-flexible-storage/battery/data/customize/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/data',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/data/index.js'),
            })
          : require('../n-flexible-storage/battery/data/index.js').default,
      },
      {
        path: '/n-flexible-storage/battery/data/pcs',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/data/pcs/index.js'),
            })
          : require('../n-flexible-storage/battery/data/pcs/index.js').default,
      },
      {
        path: '/n-flexible-storage/battery/data/station',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/data/station/index.js'),
            })
          : require('../n-flexible-storage/battery/data/station/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-flexible-storage/battery/index.js'),
            })
          : require('../n-flexible-storage/battery/index.js').default,
      },
      {
        path: '/n-flexible-storage/battery/overView/content',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/overView/content.js'),
            })
          : require('../n-flexible-storage/battery/overView/content.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/overView',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/overView/index.js'),
            })
          : require('../n-flexible-storage/battery/overView/index.js').default,
      },
      {
        path: '/n-flexible-storage/battery/overView/modalChart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/overView/modalChart.js'),
            })
          : require('../n-flexible-storage/battery/overView/modalChart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/overView/option/zyggl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/overView/option/zyggl.js'),
            })
          : require('../n-flexible-storage/battery/overView/option/zyggl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/overView/useOver',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/overView/useOver.js'),
            })
          : require('../n-flexible-storage/battery/overView/useOver.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/quantity-analysis/demand-analysis',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/quantity-analysis/demand-analysis/index.js'),
            })
          : require('../n-flexible-storage/battery/quantity-analysis/demand-analysis/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/quantity-analysis/energy-analysis',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/quantity-analysis/energy-analysis/index.js'),
            })
          : require('../n-flexible-storage/battery/quantity-analysis/energy-analysis/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/quantity-analysis',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/quantity-analysis/index.js'),
            })
          : require('../n-flexible-storage/battery/quantity-analysis/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/quantity-analysis/search-criteria',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/quantity-analysis/search-criteria/index.js'),
            })
          : require('../n-flexible-storage/battery/quantity-analysis/search-criteria/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/report',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/report/index.js'),
            })
          : require('../n-flexible-storage/battery/report/index.js').default,
      },
      {
        path: '/n-flexible-storage/battery/roleSystem/role',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/roleSystem/role/index.js'),
            })
          : require('../n-flexible-storage/battery/roleSystem/role/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/roleSystem/roleEdit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/roleSystem/roleEdit/index.js'),
            })
          : require('../n-flexible-storage/battery/roleSystem/roleEdit/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/roleSystem/roleInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/roleSystem/roleInfo/index.js'),
            })
          : require('../n-flexible-storage/battery/roleSystem/roleInfo/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/move',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/move/index.js'),
            })
          : require('../n-flexible-storage/battery/station/move/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/auxiliary',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/auxiliary/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/auxiliary/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/auxiliary/intance',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/auxiliary/intance.js'),
            })
          : require('../n-flexible-storage/battery/station/work/auxiliary/intance.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/chart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/filter.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/filter.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/option/cfdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/option/cfdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/option/cfdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/option/glsoc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/option/glsoc.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/option/glsoc.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/option/glys',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/option/glys.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/option/glys.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/option/jlcdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/option/jlcdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/option/jlcdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/option/jlcdy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/option/jlcdy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/option/jlcdy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/option/sy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/option/sy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/option/sy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/realInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/realInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/realInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/subList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/subList.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/subList.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/tabs.config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/tabs.config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/tabs.config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/bwd/topInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/bwd/topInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/bwd/topInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/chart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/filter.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/filter.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/option/gl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/option/gl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/option/gl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/option/glys',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/option/glys.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/option/glys.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/option/jlcdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/option/jlcdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/option/jlcdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/option/jlcdy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/option/jlcdy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/option/jlcdy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/option/zfxdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/option/zfxdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/option/zfxdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/realInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/realInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/realInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/tabs.config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/tabs.config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/tabs.config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/byq/topInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/byq/topInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/byq/topInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/chart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/filter.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/filter.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/option/cfdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/option/cfdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/option/cfdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/option/glsoc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/option/glsoc.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/option/glsoc.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/option/jlcdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/option/jlcdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/option/jlcdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/option/jlcdy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/option/jlcdy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/option/jlcdy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/option/sd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/option/sd.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/option/sd.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/option/wd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/option/wd.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/option/wd.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/realInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/realInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/realInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/roomStatus',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/roomStatus.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/roomStatus.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/subList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/subList.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/subList.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/tabs.config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/tabs.config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/tabs.config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/cns/topInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/cns/topInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/cns/topInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/chart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd/exportCell',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/exportCell.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/exportCell.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/filter.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/filter.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd/option/soc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/option/soc.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/option/soc.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd/realInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/realInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/realInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd/tabs.config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/tabs.config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/tabs.config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcd/topInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcd/topInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcd/topInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/chart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/exportCell',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/exportCell.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/exportCell.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/filter.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/filter.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/itemBlock',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/itemBlock.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/itemBlock.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/ladder',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/ladder.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/ladder.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/modalChart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/modalChart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/modalChart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/monomerInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/monomerInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/monomerInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/dtdy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/dtdy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/dtdy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/dtnz',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/dtnz.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/dtnz.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/dtsoc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/dtsoc.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/dtsoc.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/dtwd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/dtwd.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/dtwd.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/dxyl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/dxyl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/dxyl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/dy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/dy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/dy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/nz',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/nz.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/nz.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/soc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/soc.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/soc.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/option/wd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/option/wd.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/option/wd.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/rangeInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/rangeInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/rangeInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/tabs.config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/tabs.config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/tabs.config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/dcz/topInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/dcz/topInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/dcz/topInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/chart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/exportCell',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/exportCell.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/exportCell.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/filter.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/filter.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/option/cfdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/option/cfdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/option/cfdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/option/glsoc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/option/glsoc.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/option/glsoc.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/option/jgwd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/option/jgwd.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/option/jgwd.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/option/jlcdl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/option/jlcdl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/option/jlcdl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/option/jlcdy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/option/jlcdy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/option/jlcdy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/realInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/realInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/realInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/subList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/subList.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/subList.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/tabs.config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/tabs.config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/tabs.config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/nbq/topInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/nbq/topInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/nbq/topInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/chart.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/filter.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/filter.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/index.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/option/zldl',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/option/zldl.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/option/zldl.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/option/zldy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/option/zldy.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/option/zldy.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/option/zlglsoc',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/option/zlglsoc.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/option/zlglsoc.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/option/zlwd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/option/zlwd.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/option/zlwd.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/realInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/realInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/realInfo.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/subList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/subList.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/subList.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/tabs.config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/tabs.config.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/tabs.config.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/station/work/zl/topInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/station/work/zl/topInfo.js'),
            })
          : require('../n-flexible-storage/battery/station/work/zl/topInfo.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/chargeOrDischarge/historyList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/chargeOrDischarge/historyList/index.js'),
            })
          : require('../n-flexible-storage/battery/strategy/chargeOrDischarge/historyList/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/strategy/chargeOrDischarge',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/chargeOrDischarge/index.js'),
            })
          : require('../n-flexible-storage/battery/strategy/chargeOrDischarge/index.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/chargeOrDischarge/lineConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/chargeOrDischarge/lineConfig.js'),
            })
          : require('../n-flexible-storage/battery/strategy/chargeOrDischarge/lineConfig.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/strategy/chargeOrDischarge/modal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/chargeOrDischarge/modal.js'),
            })
          : require('../n-flexible-storage/battery/strategy/chargeOrDischarge/modal.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/history/historyItem',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/history/historyItem.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/history/historyItem.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/strategy/electricityPrice/history',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/history/index.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/history/index.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/history/line',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/history/line.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/history/line.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/strategy/electricityPrice',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/index.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/index.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/formBody',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/formBody.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/formBody.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/index.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/index.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/unSeasonFormBody',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/unSeasonFormBody.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/editItem/unSeasonFormBody.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/form',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/form.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/form.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/index.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/index.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/infoItem',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/infoItem/index.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/infoItem/index.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/infoItem/line',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/infoItem/line.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/infoItem/line.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/price',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/price.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/price.js')
              .default,
      },
      {
        path:
          '/n-flexible-storage/battery/strategy/electricityPrice/priceScheme/unprice',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/unprice.js'),
            })
          : require('../n-flexible-storage/battery/strategy/electricityPrice/priceScheme/unprice.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/system/warn-setting',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/system/warn-setting/index.js'),
            })
          : require('../n-flexible-storage/battery/system/warn-setting/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/top',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/top/index.js'),
            })
          : require('../n-flexible-storage/battery/top/index.js').default,
      },
      {
        path: '/n-flexible-storage/battery/top/index1',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/top/index1.js'),
            })
          : require('../n-flexible-storage/battery/top/index1.js').default,
      },
      {
        path: '/n-flexible-storage/battery/top/index2',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/top/index2.js'),
            })
          : require('../n-flexible-storage/battery/top/index2.js').default,
      },
      {
        path: '/n-flexible-storage/battery/volume-fee/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/volume-fee/chart.js'),
            })
          : require('../n-flexible-storage/battery/volume-fee/chart.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/volume-fee',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/volume-fee/index.js'),
            })
          : require('../n-flexible-storage/battery/volume-fee/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/volume-fee/lineElectric',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/volume-fee/lineElectric.js'),
            })
          : require('../n-flexible-storage/battery/volume-fee/lineElectric.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/volume-fee/linePrice',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/volume-fee/linePrice.js'),
            })
          : require('../n-flexible-storage/battery/volume-fee/linePrice.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/volume-fee/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/volume-fee/list.js'),
            })
          : require('../n-flexible-storage/battery/volume-fee/list.js').default,
      },
      {
        path: '/n-flexible-storage/battery/volume-fee/listTable',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/volume-fee/listTable.js'),
            })
          : require('../n-flexible-storage/battery/volume-fee/listTable.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/warning/record/filter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/warning/record/filter/index.js'),
            })
          : require('../n-flexible-storage/battery/warning/record/filter/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/warning/record',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/warning/record/index.js'),
            })
          : require('../n-flexible-storage/battery/warning/record/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/battery/warning/record/table',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/battery/warning/record/table/index.js'),
            })
          : require('../n-flexible-storage/battery/warning/record/table/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/dataAnalyse',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/dataAnalyse/index.js'),
            })
          : require('../n-flexible-storage/smartControl/dataAnalyse/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/dataAnalyse/tools',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/dataAnalyse/tools.js'),
            })
          : require('../n-flexible-storage/smartControl/dataAnalyse/tools.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/detail.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/detail.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse/incomeTrend',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/incomeTrend.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/incomeTrend.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/index.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse/initOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/initOption.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/initOption.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse/runCurve',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/runCurve.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/runCurve.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse/runEvent',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/runEvent.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/runEvent.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse/tools',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/tools.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/tools.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/runAnalyse/useWorkIncome',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/runAnalyse/useWorkIncome.js'),
            })
          : require('../n-flexible-storage/smartControl/runAnalyse/useWorkIncome.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/workIncome/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/workIncome/detail.js'),
            })
          : require('../n-flexible-storage/smartControl/workIncome/detail.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/workIncome',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/workIncome/index.js'),
            })
          : require('../n-flexible-storage/smartControl/workIncome/index.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/workIncome/initOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/workIncome/initOption.js'),
            })
          : require('../n-flexible-storage/smartControl/workIncome/initOption.js')
              .default,
      },
      {
        path: '/n-flexible-storage/smartControl/workIncome/useWorkIncome',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-flexible-storage/smartControl/workIncome/useWorkIncome.js'),
            })
          : require('../n-flexible-storage/smartControl/workIncome/useWorkIncome.js')
              .default,
      },
      {
        path: '/n-network',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-network/index.js'),
            })
          : require('../n-network/index.js').default,
      },
      {
        path: '/n-network/one-shot/diagram',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-network/one-shot/diagram/index.js'),
            })
          : require('../n-network/one-shot/diagram/index.js').default,
      },
      {
        path: '/n-network/one-shot/wiring-diagram',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-network/one-shot/wiring-diagram/index.js'),
            })
          : require('../n-network/one-shot/wiring-diagram/index.js').default,
      },
      {
        path: '/n-nvr/energy-equipment',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-nvr/energy-equipment/index.js'),
            })
          : require('../n-nvr/energy-equipment/index.js').default,
      },
      {
        path: '/n-nvr',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-nvr/index.js'),
            })
          : require('../n-nvr/index.js').default,
      },
      {
        path: '/n-nvr/load-equipment/data/air',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-nvr/load-equipment/data/air/index.js'),
            })
          : require('../n-nvr/load-equipment/data/air/index.js').default,
      },
      {
        path: '/n-nvr/load-equipment/data/light',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-nvr/load-equipment/data/light/index.js'),
            })
          : require('../n-nvr/load-equipment/data/light/index.js').default,
      },
      {
        path: '/n-nvr/network-equipment',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-nvr/network-equipment/index.js'),
            })
          : require('../n-nvr/network-equipment/index.js').default,
      },
      {
        path: '/n-operation/emergency/fire/fire-view',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/emergency/fire/fire-view/index.js'),
            })
          : require('../n-operation/emergency/fire/fire-view/index.js').default,
      },
      {
        path: '/n-operation/emergency/fire/water-control',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/emergency/fire/water-control/index.js'),
            })
          : require('../n-operation/emergency/fire/water-control/index.js')
              .default,
      },
      {
        path: '/n-operation/emergency/security/video',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/emergency/security/video/index.js'),
            })
          : require('../n-operation/emergency/security/video/index.js').default,
      },
      {
        path: '/n-operation/event-center/abnormal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/event-center/abnormal/index.js'),
            })
          : require('../n-operation/event-center/abnormal/index.js').default,
      },
      {
        path: '/n-operation/event-center/event',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/event-center/event/index.js'),
            })
          : require('../n-operation/event-center/event/index.js').default,
      },
      {
        path: '/n-operation/event-center/statistics',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/event-center/statistics/index.js'),
            })
          : require('../n-operation/event-center/statistics/index.js').default,
      },
      {
        path: '/n-operation',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-operation/index.js'),
            })
          : require('../n-operation/index.js').default,
      },
      {
        path: '/n-operation/iot/account/account-manage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/account/account-manage/index.js'),
            })
          : require('../n-operation/iot/account/account-manage/index.js')
              .default,
      },
      {
        path: '/n-operation/iot/account/role-manage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/account/role-manage/index.js'),
            })
          : require('../n-operation/iot/account/role-manage/index.js').default,
      },
      {
        path: '/n-operation/iot/company/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/company/list/index.js'),
            })
          : require('../n-operation/iot/company/list/index.js').default,
      },
      {
        path: '/n-operation/iot/data-view/real-time',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/data-view/real-time/index.js'),
            })
          : require('../n-operation/iot/data-view/real-time/index.js').default,
      },
      {
        path: '/n-operation/iot/data-view/station',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/data-view/station/index.js'),
            })
          : require('../n-operation/iot/data-view/station/index.js').default,
      },
      {
        path: '/n-operation/iot/station/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/station/list/index.js'),
            })
          : require('../n-operation/iot/station/list/index.js').default,
      },
      {
        path: '/n-operation/iot/template/network',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/template/network/index.js'),
            })
          : require('../n-operation/iot/template/network/index.js').default,
      },
      {
        path: '/n-operation/iot/template/sensor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/iot/template/sensor/index.js'),
            })
          : require('../n-operation/iot/template/sensor/index.js').default,
      },
      {
        path: '/n-operation/report-center/center/month',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/report-center/center/month/index.js'),
            })
          : require('../n-operation/report-center/center/month/index.js')
              .default,
      },
      {
        path: '/n-operation/report-center/center/year',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/report-center/center/year/index.js'),
            })
          : require('../n-operation/report-center/center/year/index.js')
              .default,
      },
      {
        path: '/n-operation/report-center/export',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/report-center/export/index.js'),
            })
          : require('../n-operation/report-center/export/index.js').default,
      },
      {
        path: '/n-operation/report-center/import',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-operation/report-center/import/index.js'),
            })
          : require('../n-operation/report-center/import/index.js').default,
      },
      {
        path: '/n-value-added/cost-electri',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/cost-electri/index.js'),
            })
          : require('../n-value-added/cost-electri/index.js').default,
      },
      {
        path: '/n-value-added',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/index.js'),
            })
          : require('../n-value-added/index.js').default,
      },
      {
        path: '/n-value-added/response',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/response/index.js'),
            })
          : require('../n-value-added/response/index.js').default,
      },
      {
        path: '/n-value-added/response/left/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-value-added/response/left/overview.js'),
            })
          : require('../n-value-added/response/left/overview.js').default,
      },
      {
        path: '/n-value-added/response/left/sure',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/response/left/sure.js'),
            })
          : require('../n-value-added/response/left/sure.js').default,
      },
      {
        path: '/n-value-added/response/right/lineTime',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-value-added/response/right/lineTime.js'),
            })
          : require('../n-value-added/response/right/lineTime.js').default,
      },
      {
        path: '/n-value-added/response/right/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-value-added/response/right/overview.js'),
            })
          : require('../n-value-added/response/right/overview.js').default,
      },
      {
        path: '/n-value-added/response/utils',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/response/utils.js'),
            })
          : require('../n-value-added/response/utils.js').default,
      },
      {
        path: '/n-value-added/total-analyze',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-value-added/total-analyze/index.js'),
            })
          : require('../n-value-added/total-analyze/index.js').default,
      },
      {
        path: '/n-value-added/trade/bottom',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/trade/bottom.js'),
            })
          : require('../n-value-added/trade/bottom.js').default,
      },
      {
        path: '/n-value-added/trade/center',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/trade/center.js'),
            })
          : require('../n-value-added/trade/center.js').default,
      },
      {
        path: '/n-value-added/trade',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/trade/index.js'),
            })
          : require('../n-value-added/trade/index.js').default,
      },
      {
        path: '/n-value-added/trade/top',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/trade/top.js'),
            })
          : require('../n-value-added/trade/top.js').default,
      },
      {
        path: '/n-value-added/trade/util',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-value-added/trade/util.js'),
            })
          : require('../n-value-added/trade/util.js').default,
      },
      {
        path: '/n-volume-total/cost-analysis/benefits/photovoltaic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-volume-total/cost-analysis/benefits/photovoltaic/index.js'),
            })
          : require('../n-volume-total/cost-analysis/benefits/photovoltaic/index.js')
              .default,
      },
      {
        path: '/n-volume-total/cost-analysis/benefits/power',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-volume-total/cost-analysis/benefits/power/index.js'),
            })
          : require('../n-volume-total/cost-analysis/benefits/power/index.js')
              .default,
      },
      {
        path: '/n-volume-total/cost-analysis/cost/electric',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-volume-total/cost-analysis/cost/electric/index.js'),
            })
          : require('../n-volume-total/cost-analysis/cost/electric/index.js')
              .default,
      },
      {
        path: '/n-volume-total/cost-analysis/cost/gas',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-volume-total/cost-analysis/cost/gas/index.js'),
            })
          : require('../n-volume-total/cost-analysis/cost/gas/index.js')
              .default,
      },
      {
        path: '/n-volume-total/cost-analysis/cost/water',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-volume-total/cost-analysis/cost/water/index.js'),
            })
          : require('../n-volume-total/cost-analysis/cost/water/index.js')
              .default,
      },
      {
        path: '/n-volume-total/cost-analysis/view',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../n-volume-total/cost-analysis/view/index.js'),
            })
          : require('../n-volume-total/cost-analysis/view/index.js').default,
      },
      {
        path: '/n-volume-total/electric',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-volume-total/electric/index.js'),
            })
          : require('../n-volume-total/electric/index.js').default,
      },
      {
        path: '/n-volume-total',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../n-volume-total/index.js'),
            })
          : require('../n-volume-total/index.js').default,
      },
      {
        path: '/operation/detail/topstatic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../operation/detail/topstatic.js'),
            })
          : require('../operation/detail/topstatic.js').default,
      },
      {
        path: '/operation/detail/:strategicId',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../operation/detail/$strategicId.js'),
            })
          : require('../operation/detail/$strategicId.js').default,
      },
      {
        path: '/operation/income',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../operation/income/index.js'),
            })
          : require('../operation/income/index.js').default,
      },
      {
        path: '/operation/strategic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../operation/strategic/index.js'),
            })
          : require('../operation/strategic/index.js').default,
      },
      {
        path: '/operation/strategic/monthChart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../operation/strategic/monthChart.js'),
            })
          : require('../operation/strategic/monthChart.js').default,
      },
      {
        path: '/report-center/export-report',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/report-center/export-report/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../report-center/export-report/index.js'),
            })
          : require('../report-center/export-report/index.js').default,
      },
      {
        path: '/report-center/import-report/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/report-center/import-report/models/vImportReport.js').then(
                  m => {
                    return { namespace: 'vImportReport', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../report-center/import-report/detail.js'),
            })
          : require('../report-center/import-report/detail.js').default,
      },
      {
        path: '/report-center/import-report',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/report-center/import-report/models/vImportReport.js').then(
                  m => {
                    return { namespace: 'vImportReport', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../report-center/import-report/index.js'),
            })
          : require('../report-center/import-report/index.js').default,
      },
      {
        path: '/report-center/import-report/upload',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/report-center/import-report/models/vImportReport.js').then(
                  m => {
                    return { namespace: 'vImportReport', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../report-center/import-report/upload.js'),
            })
          : require('../report-center/import-report/upload.js').default,
      },
      {
        path: '/report-center',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../report-center/index.js'),
            })
          : require('../report-center/index.js').default,
      },
      {
        path: '/reportCenter/billManage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/reportCenter/billManage/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../reportCenter/billManage/index.js'),
            })
          : require('../reportCenter/billManage/index.js').default,
      },
      {
        path: '/reportCenter',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../reportCenter/index.js'),
            })
          : require('../reportCenter/index.js').default,
      },
      {
        path: '/reportCenter/monthReport',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/reportCenter/monthReport/models/index.js').then(
                  m => {
                    return { namespace: 'index', ...m.default };
                  },
                ),
              ],
              component: () => import('../reportCenter/monthReport/index.js'),
            })
          : require('../reportCenter/monthReport/index.js').default,
      },
      {
        path: '/screen/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/chart/index.js'),
            })
          : require('../screen/chart/index.js').default,
      },
      {
        path: '/screen/chatLightStorage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/chatLightStorage/index.js'),
            })
          : require('../screen/chatLightStorage/index.js').default,
      },
      {
        path: '/screen/components-light-storage/chargingStation',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/chargingStation/index.js'),
            })
          : require('../screen/components-light-storage/chargingStation/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/energyStorage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/energyStorage/index.js'),
            })
          : require('../screen/components-light-storage/energyStorage/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/faultSort',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/faultSort/index.js'),
            })
          : require('../screen/components-light-storage/faultSort/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/middleBottom',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/middleBottom/index.js'),
            })
          : require('../screen/components-light-storage/middleBottom/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/middleTop',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/middleTop/index.js'),
            })
          : require('../screen/components-light-storage/middleTop/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/normalSelect',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/normalSelect/index.js'),
            })
          : require('../screen/components-light-storage/normalSelect/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/numberSort',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/numberSort/index.js'),
            })
          : require('../screen/components-light-storage/numberSort/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/photovoltaic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/photovoltaic/index.js'),
            })
          : require('../screen/components-light-storage/photovoltaic/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/powerGeneration',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/powerGeneration/index.js'),
            })
          : require('../screen/components-light-storage/powerGeneration/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/realTimePower/bottom',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/realTimePower/bottom.js'),
            })
          : require('../screen/components-light-storage/realTimePower/bottom.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/realTimePower',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/realTimePower/index.js'),
            })
          : require('../screen/components-light-storage/realTimePower/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/realTimePower/top',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/realTimePower/top.js'),
            })
          : require('../screen/components-light-storage/realTimePower/top.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/stationList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/stationList/index.js'),
            })
          : require('../screen/components-light-storage/stationList/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/utils',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/utils.js'),
            })
          : require('../screen/components-light-storage/utils.js').default,
      },
      {
        path: '/screen/components-light-storage/weatherBox',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/weatherBox/index.js'),
            })
          : require('../screen/components-light-storage/weatherBox/index.js')
              .default,
      },
      {
        path: '/screen/components-light-storage/workSort',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/components-light-storage/workSort/index.js'),
            })
          : require('../screen/components-light-storage/workSort/index.js')
              .default,
      },
      {
        path: '/screen/comprehensive',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/comprehensive/index.js'),
            })
          : require('../screen/comprehensive/index.js').default,
      },
      {
        path: '/screen/comprehensive/initOptions',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/comprehensive/initOptions.js'),
            })
          : require('../screen/comprehensive/initOptions.js').default,
      },
      {
        path: '/screen/enChatLightStorage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/enChatLightStorage/index.js'),
            })
          : require('../screen/enChatLightStorage/index.js').default,
      },
      {
        path: '/screen/enComponents-light-storage/chargingStation',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/chargingStation/index.js'),
            })
          : require('../screen/enComponents-light-storage/chargingStation/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/energyStorage',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/energyStorage/index.js'),
            })
          : require('../screen/enComponents-light-storage/energyStorage/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/faultSort',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/faultSort/index.js'),
            })
          : require('../screen/enComponents-light-storage/faultSort/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/middleBottom',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/middleBottom/index.js'),
            })
          : require('../screen/enComponents-light-storage/middleBottom/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/middleTop',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/middleTop/index.js'),
            })
          : require('../screen/enComponents-light-storage/middleTop/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/normalSelect',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/normalSelect/index.js'),
            })
          : require('../screen/enComponents-light-storage/normalSelect/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/numberSort',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/numberSort/index.js'),
            })
          : require('../screen/enComponents-light-storage/numberSort/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/photovoltaic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/photovoltaic/index.js'),
            })
          : require('../screen/enComponents-light-storage/photovoltaic/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/powerGeneration',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/powerGeneration/index.js'),
            })
          : require('../screen/enComponents-light-storage/powerGeneration/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/realTimePower/bottom',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/realTimePower/bottom.js'),
            })
          : require('../screen/enComponents-light-storage/realTimePower/bottom.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/realTimePower',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/realTimePower/index.js'),
            })
          : require('../screen/enComponents-light-storage/realTimePower/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/realTimePower/top',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/realTimePower/top.js'),
            })
          : require('../screen/enComponents-light-storage/realTimePower/top.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/stationList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/stationList/index.js'),
            })
          : require('../screen/enComponents-light-storage/stationList/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/utils',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/utils.js'),
            })
          : require('../screen/enComponents-light-storage/utils.js').default,
      },
      {
        path: '/screen/enComponents-light-storage/weatherBox',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/weatherBox/index.js'),
            })
          : require('../screen/enComponents-light-storage/weatherBox/index.js')
              .default,
      },
      {
        path: '/screen/enComponents-light-storage/workSort',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComponents-light-storage/workSort/index.js'),
            })
          : require('../screen/enComponents-light-storage/workSort/index.js')
              .default,
      },
      {
        path: '/screen/enComprehensive',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/enComprehensive/index.js'),
            })
          : require('../screen/enComprehensive/index.js').default,
      },
      {
        path: '/screen/enComprehensive/initOptions',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/enComprehensive/initOptions.js'),
            })
          : require('../screen/enComprehensive/initOptions.js').default,
      },
      {
        path: '/screen',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/index.js'),
            })
          : require('../screen/index.js').default,
      },
      {
        path: '/screen/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/list/index.js'),
            })
          : require('../screen/list/index.js').default,
      },
      {
        path: '/screen/newStationScreen',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen/newStationScreen/index.js'),
            })
          : require('../screen/newStationScreen/index.js').default,
      },
      {
        path: '/screen/newStationScreen/initOptions',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/newStationScreen/initOptions.js'),
            })
          : require('../screen/newStationScreen/initOptions.js').default,
      },
      {
        path: '/screen/newStationScreen/useStationScreen',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen/newStationScreen/useStationScreen.js'),
            })
          : require('../screen/newStationScreen/useStationScreen.js').default,
      },
      {
        path: '/screen-property/chart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen-property/chart/index.js'),
            })
          : require('../screen-property/chart/index.js').default,
      },
      {
        path: '/screen-property/chart/useChart',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen-property/chart/useChart.js'),
            })
          : require('../screen-property/chart/useChart.js').default,
      },
      {
        path: '/screen-property',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../screen-property/index.js'),
            })
          : require('../screen-property/index.js').default,
      },
      {
        path: '/screen-property/newGroupScreen',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen-property/newGroupScreen/index.js'),
            })
          : require('../screen-property/newGroupScreen/index.js').default,
      },
      {
        path: '/screen-property/newGroupScreen/initOptions',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../screen-property/newGroupScreen/initOptions.js'),
            })
          : require('../screen-property/newGroupScreen/initOptions.js').default,
      },
      {
        path: '/sysConfig/account/add',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/account/add.js'),
            })
          : require('../sysConfig/account/add.js').default,
      },
      {
        path: '/sysConfig/account/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/account/detail.js'),
            })
          : require('../sysConfig/account/detail.js').default,
      },
      {
        path: '/sysConfig/account/edit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/account/edit.js'),
            })
          : require('../sysConfig/account/edit.js').default,
      },
      {
        path: '/sysConfig/account',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/account/index.js'),
            })
          : require('../sysConfig/account/index.js').default,
      },
      {
        path: '/sysConfig/account/tableList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/account/tableList.js'),
            })
          : require('../sysConfig/account/tableList.js').default,
      },
      {
        path: '/sysConfig/index copy',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/index copy.js'),
            })
          : require('../sysConfig/index copy.js').default,
      },
      {
        path: '/sysConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/index.js'),
            })
          : require('../sysConfig/index.js').default,
      },
      {
        path: '/sysConfig/role/component/card',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../sysConfig/role/component/card/index.js'),
            })
          : require('../sysConfig/role/component/card/index.js').default,
      },
      {
        path: '/sysConfig/role/component/tree',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../sysConfig/role/component/tree/index.js'),
            })
          : require('../sysConfig/role/component/tree/index.js').default,
      },
      {
        path: '/sysConfig/role',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/role/index.js'),
            })
          : require('../sysConfig/role/index.js').default,
      },
      {
        path: '/sysConfig/role/roleDetails',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/role/roleDetails/index.js'),
            })
          : require('../sysConfig/role/roleDetails/index.js').default,
      },
      {
        path: '/sysConfig/role/roleEdit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/role/roleEdit/index.js'),
            })
          : require('../sysConfig/role/roleEdit/index.js').default,
      },
      {
        path: '/sysConfig/role/tableList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/role/tableList.js'),
            })
          : require('../sysConfig/role/tableList.js').default,
      },
      {
        path: '/sysConfig/users',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/users/index.js'),
            })
          : require('../sysConfig/users/index.js').default,
      },
      {
        path: '/sysConfig/users/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../sysConfig/users/list/index.js'),
            })
          : require('../sysConfig/users/list/index.js').default,
      },
      {
        path: '/z-carbonAssets',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-carbonAssets/index.js'),
            })
          : require('../z-carbonAssets/index.js').default,
      },
      {
        path: '/z-changeElectric/equipmentMonitor/cardList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/equipmentMonitor/cardList.js'),
            })
          : require('../z-changeElectric/equipmentMonitor/cardList.js').default,
      },
      {
        path: '/z-changeElectric/equipmentMonitor/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/equipmentMonitor/columnsConfig.js'),
            })
          : require('../z-changeElectric/equipmentMonitor/columnsConfig.js')
              .default,
      },
      {
        path: '/z-changeElectric/equipmentMonitor/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/equipmentMonitor/detail.js'),
            })
          : require('../z-changeElectric/equipmentMonitor/detail.js').default,
      },
      {
        path: '/z-changeElectric/equipmentMonitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/equipmentMonitor/index.js'),
            })
          : require('../z-changeElectric/equipmentMonitor/index.js').default,
      },
      {
        path: '/z-changeElectric/equipmentMonitor/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/equipmentMonitor/searchConfig.js'),
            })
          : require('../z-changeElectric/equipmentMonitor/searchConfig.js')
              .default,
      },
      {
        path: '/z-changeElectric/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-changeElectric/overview/index.js'),
            })
          : require('../z-changeElectric/overview/index.js').default,
      },
      {
        path: '/z-changeElectric/overview/useChange',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/overview/useChange.js'),
            })
          : require('../z-changeElectric/overview/useChange.js').default,
      },
      {
        path: '/z-changeElectric/projectLedger/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/projectLedger/columnsConfig.js'),
            })
          : require('../z-changeElectric/projectLedger/columnsConfig.js')
              .default,
      },
      {
        path: '/z-changeElectric/projectLedger/detail/:stationId',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/projectLedger/detail/$stationId.js'),
            })
          : require('../z-changeElectric/projectLedger/detail/$stationId.js')
              .default,
      },
      {
        path: '/z-changeElectric/projectLedger',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/projectLedger/index.js'),
            })
          : require('../z-changeElectric/projectLedger/index.js').default,
      },
      {
        path: '/z-changeElectric/projectLedger/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-changeElectric/projectLedger/searchConfig.js'),
            })
          : require('../z-changeElectric/projectLedger/searchConfig.js')
              .default,
      },
      {
        path: '/z-changeElectric/trend',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-changeElectric/trend/index.js'),
            })
          : require('../z-changeElectric/trend/index.js').default,
      },
      {
        path: '/z-energyPower/equipmentMonitor/cardList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/equipmentMonitor/cardList.js'),
            })
          : require('../z-energyPower/equipmentMonitor/cardList.js').default,
      },
      {
        path: '/z-energyPower/equipmentMonitor/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/equipmentMonitor/columnsConfig.js'),
            })
          : require('../z-energyPower/equipmentMonitor/columnsConfig.js')
              .default,
      },
      {
        path: '/z-energyPower/equipmentMonitor/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/equipmentMonitor/detail.js'),
            })
          : require('../z-energyPower/equipmentMonitor/detail.js').default,
      },
      {
        path: '/z-energyPower/equipmentMonitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/equipmentMonitor/index.js'),
            })
          : require('../z-energyPower/equipmentMonitor/index.js').default,
      },
      {
        path: '/z-energyPower/equipmentMonitor/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/equipmentMonitor/searchConfig.js'),
            })
          : require('../z-energyPower/equipmentMonitor/searchConfig.js')
              .default,
      },
      {
        path: '/z-energyPower/exceptionManagement/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/exceptionManagement/columnsConfig.js'),
            })
          : require('../z-energyPower/exceptionManagement/columnsConfig.js')
              .default,
      },
      {
        path: '/z-energyPower/exceptionManagement/detail/useException',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/exceptionManagement/detail/useException.js'),
            })
          : require('../z-energyPower/exceptionManagement/detail/useException.js')
              .default,
      },
      {
        path: '/z-energyPower/exceptionManagement/detail/:stationId',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/exceptionManagement/detail/$stationId.js'),
            })
          : require('../z-energyPower/exceptionManagement/detail/$stationId.js')
              .default,
      },
      {
        path: '/z-energyPower/exceptionManagement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/exceptionManagement/index.js'),
            })
          : require('../z-energyPower/exceptionManagement/index.js').default,
      },
      {
        path: '/z-energyPower/exceptionManagement/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/exceptionManagement/searchConfig.js'),
            })
          : require('../z-energyPower/exceptionManagement/searchConfig.js')
              .default,
      },
      {
        path: '/z-energyPower/generateAnalyse',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/generateAnalyse/index.js'),
            })
          : require('../z-energyPower/generateAnalyse/index.js').default,
      },
      {
        path: '/z-energyPower/generationOverview/batteryDetail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/generationOverview/batteryDetail.js'),
            })
          : require('../z-energyPower/generationOverview/batteryDetail.js')
              .default,
      },
      {
        path: '/z-energyPower/generationOverview/cardList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/generationOverview/cardList.js'),
            })
          : require('../z-energyPower/generationOverview/cardList.js').default,
      },
      {
        path: '/z-energyPower/generationOverview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/generationOverview/index.js'),
            })
          : require('../z-energyPower/generationOverview/index.js').default,
      },
      {
        path: '/z-energyPower/generationOverview/initOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/generationOverview/initOption.js'),
            })
          : require('../z-energyPower/generationOverview/initOption.js')
              .default,
      },
      {
        path: '/z-energyPower/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-energyPower/overview/index.js'),
            })
          : require('../z-energyPower/overview/index.js').default,
      },
      {
        path: '/z-energyPower/overview/usePhotovoltaic',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/overview/usePhotovoltaic.js'),
            })
          : require('../z-energyPower/overview/usePhotovoltaic.js').default,
      },
      {
        path: '/z-energyPower/projectLedger/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/projectLedger/columnsConfig.js'),
            })
          : require('../z-energyPower/projectLedger/columnsConfig.js').default,
      },
      {
        path: '/z-energyPower/projectLedger/detail/:stationId',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/projectLedger/detail/$stationId.js'),
            })
          : require('../z-energyPower/projectLedger/detail/$stationId.js')
              .default,
      },
      {
        path: '/z-energyPower/projectLedger',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/projectLedger/index.js'),
            })
          : require('../z-energyPower/projectLedger/index.js').default,
      },
      {
        path: '/z-energyPower/projectLedger/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/projectLedger/searchConfig.js'),
            })
          : require('../z-energyPower/projectLedger/searchConfig.js').default,
      },
      {
        path: '/z-energyPower/settlementManagement/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/settlementManagement/columnsConfig.js'),
            })
          : require('../z-energyPower/settlementManagement/columnsConfig.js')
              .default,
      },
      {
        path: '/z-energyPower/settlementManagement/detail/useException',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/settlementManagement/detail/useException.js'),
            })
          : require('../z-energyPower/settlementManagement/detail/useException.js')
              .default,
      },
      {
        path: '/z-energyPower/settlementManagement/detail/:stationId',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/settlementManagement/detail/$stationId.js'),
            })
          : require('../z-energyPower/settlementManagement/detail/$stationId.js')
              .default,
      },
      {
        path: '/z-energyPower/settlementManagement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/settlementManagement/index.js'),
            })
          : require('../z-energyPower/settlementManagement/index.js').default,
      },
      {
        path: '/z-energyPower/settlementManagement/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/settlementManagement/searchConfig.js'),
            })
          : require('../z-energyPower/settlementManagement/searchConfig.js')
              .default,
      },
      {
        path: '/z-energyPower/staticReport/configModal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/staticReport/configModal.js'),
            })
          : require('../z-energyPower/staticReport/configModal.js').default,
      },
      {
        path: '/z-energyPower/staticReport',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-energyPower/staticReport/index.js'),
            })
          : require('../z-energyPower/staticReport/index.js').default,
      },
      {
        path: '/z-energyPower/staticReport/useStaticReport',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyPower/staticReport/useStaticReport.js'),
            })
          : require('../z-energyPower/staticReport/useStaticReport.js').default,
      },
      {
        path: '/z-energyStation/list/cardList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-energyStation/list/cardList.js'),
            })
          : require('../z-energyStation/list/cardList.js').default,
      },
      {
        path: '/z-energyStation/list/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStation/list/columnsConfig.js'),
            })
          : require('../z-energyStation/list/columnsConfig.js').default,
      },
      {
        path: '/z-energyStation/list/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-energyStation/list/detail.js'),
            })
          : require('../z-energyStation/list/detail.js').default,
      },
      {
        path: '/z-energyStation/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-energyStation/list/index.js'),
            })
          : require('../z-energyStation/list/index.js').default,
      },
      {
        path: '/z-energyStation/list/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStation/list/searchConfig.js'),
            })
          : require('../z-energyStation/list/searchConfig.js').default,
      },
      {
        path: '/z-energyStation/monitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-energyStation/monitor/index.js'),
            })
          : require('../z-energyStation/monitor/index.js').default,
      },
      {
        path: '/z-energyStorage/electricity/operationManagement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/electricity/operationManagement/index.js'),
            })
          : require('../z-energyStorage/electricity/operationManagement/index.js')
              .default,
      },
      {
        path: '/z-energyStorage/electricity/operationManagement/tableConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/electricity/operationManagement/tableConfig.js'),
            })
          : require('../z-energyStorage/electricity/operationManagement/tableConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/electricity/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/electricity/overview/index.js'),
            })
          : require('../z-energyStorage/electricity/overview/index.js').default,
      },
      {
        path: '/z-energyStorage/electricity/overview/useElectricity',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/electricity/overview/useElectricity.js'),
            })
          : require('../z-energyStorage/electricity/overview/useElectricity.js')
              .default,
      },
      {
        path: '/z-energyStorage/emsManagement/batchModal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/emsManagement/batchModal/index.js'),
            })
          : require('../z-energyStorage/emsManagement/batchModal/index.js')
              .default,
      },
      {
        path: '/z-energyStorage/emsManagement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/emsManagement/index.js'),
            })
          : require('../z-energyStorage/emsManagement/index.js').default,
      },
      {
        path: '/z-energyStorage/emsManagement/setModal',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/emsManagement/setModal/index.js'),
            })
          : require('../z-energyStorage/emsManagement/setModal/index.js')
              .default,
      },
      {
        path: '/z-energyStorage/emsManagement/setModal/policyMonth',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/emsManagement/setModal/policyMonth/index.js'),
            })
          : require('../z-energyStorage/emsManagement/setModal/policyMonth/index.js')
              .default,
      },
      {
        path: '/z-energyStorage/emsManagement/setModal/policySwitch',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/emsManagement/setModal/policySwitch/index.js'),
            })
          : require('../z-energyStorage/emsManagement/setModal/policySwitch/index.js')
              .default,
      },
      {
        path: '/z-energyStorage/emsManagement/setModal/policyTable',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/emsManagement/setModal/policyTable/index.js'),
            })
          : require('../z-energyStorage/emsManagement/setModal/policyTable/index.js')
              .default,
      },
      {
        path: '/z-energyStorage/emsManagement/setModal/policyTable/overflowTd',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/emsManagement/setModal/policyTable/overflowTd.js'),
            })
          : require('../z-energyStorage/emsManagement/setModal/policyTable/overflowTd.js')
              .default,
      },
      {
        path: '/z-energyStorage/equipmentMonitor/cardList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/equipmentMonitor/cardList.js'),
            })
          : require('../z-energyStorage/equipmentMonitor/cardList.js').default,
      },
      {
        path: '/z-energyStorage/equipmentMonitor/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/equipmentMonitor/columnsConfig.js'),
            })
          : require('../z-energyStorage/equipmentMonitor/columnsConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/equipmentMonitor/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/equipmentMonitor/detail.js'),
            })
          : require('../z-energyStorage/equipmentMonitor/detail.js').default,
      },
      {
        path: '/z-energyStorage/equipmentMonitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/equipmentMonitor/index.js'),
            })
          : require('../z-energyStorage/equipmentMonitor/index.js').default,
      },
      {
        path: '/z-energyStorage/equipmentMonitor/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/equipmentMonitor/searchConfig.js'),
            })
          : require('../z-energyStorage/equipmentMonitor/searchConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/exceptionManagement/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/exceptionManagement/columnsConfig.js'),
            })
          : require('../z-energyStorage/exceptionManagement/columnsConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/exceptionManagement/detail/useException',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/exceptionManagement/detail/useException.js'),
            })
          : require('../z-energyStorage/exceptionManagement/detail/useException.js')
              .default,
      },
      {
        path: '/z-energyStorage/exceptionManagement/detail/:stationId',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/exceptionManagement/detail/$stationId.js'),
            })
          : require('../z-energyStorage/exceptionManagement/detail/$stationId.js')
              .default,
      },
      {
        path: '/z-energyStorage/exceptionManagement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/exceptionManagement/index.js'),
            })
          : require('../z-energyStorage/exceptionManagement/index.js').default,
      },
      {
        path: '/z-energyStorage/exceptionManagement/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/exceptionManagement/searchConfig.js'),
            })
          : require('../z-energyStorage/exceptionManagement/searchConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/policyManagement/cardList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/policyManagement/cardList.js'),
            })
          : require('../z-energyStorage/policyManagement/cardList.js').default,
      },
      {
        path: '/z-energyStorage/policyManagement/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/policyManagement/columnsConfig.js'),
            })
          : require('../z-energyStorage/policyManagement/columnsConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/policyManagement/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/policyManagement/detail.js'),
            })
          : require('../z-energyStorage/policyManagement/detail.js').default,
      },
      {
        path: '/z-energyStorage/policyManagement',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/policyManagement/index.js'),
            })
          : require('../z-energyStorage/policyManagement/index.js').default,
      },
      {
        path: '/z-energyStorage/policyManagement/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/policyManagement/searchConfig.js'),
            })
          : require('../z-energyStorage/policyManagement/searchConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/projectLedger/columnsConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/projectLedger/columnsConfig.js'),
            })
          : require('../z-energyStorage/projectLedger/columnsConfig.js')
              .default,
      },
      {
        path: '/z-energyStorage/projectLedger/detail/:stationId',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/projectLedger/detail/$stationId.js'),
            })
          : require('../z-energyStorage/projectLedger/detail/$stationId.js')
              .default,
      },
      {
        path: '/z-energyStorage/projectLedger',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/projectLedger/index.js'),
            })
          : require('../z-energyStorage/projectLedger/index.js').default,
      },
      {
        path: '/z-energyStorage/projectLedger/searchConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-energyStorage/projectLedger/searchConfig.js'),
            })
          : require('../z-energyStorage/projectLedger/searchConfig.js').default,
      },
      {
        path: '/z-operate',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-operate/index.js'),
            })
          : require('../z-operate/index.js').default,
      },
      {
        path: '/z-operate/station-view/card',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-operate/station-view/card.js'),
            })
          : require('../z-operate/station-view/card.js').default,
      },
      {
        path: '/z-operate/station-view',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-operate/station-view/index.js'),
            })
          : require('../z-operate/station-view/index.js').default,
      },
      {
        path: '/z-operate/station-view/table',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-operate/station-view/table.js'),
            })
          : require('../z-operate/station-view/table.js').default,
      },
      {
        path: '/z-sellElectric',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-sellElectric/index.js'),
            })
          : require('../z-sellElectric/index.js').default,
      },
      {
        path: '/z-sellElectric/trade/marketInfo',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/marketInfo/index.js'),
            })
          : require('../z-sellElectric/trade/marketInfo/index.js').default,
      },
      {
        path: '/z-sellElectric/trade/marketInfo/useChange',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/marketInfo/useChange.js'),
            })
          : require('../z-sellElectric/trade/marketInfo/useChange.js').default,
      },
      {
        path: '/z-sellElectric/trade/onlineMonitor/config',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/onlineMonitor/config.js'),
            })
          : require('../z-sellElectric/trade/onlineMonitor/config.js').default,
      },
      {
        path: '/z-sellElectric/trade/onlineMonitor/executionCharge',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/onlineMonitor/executionCharge/index.js'),
            })
          : require('../z-sellElectric/trade/onlineMonitor/executionCharge/index.js')
              .default,
      },
      {
        path: '/z-sellElectric/trade/onlineMonitor/executionCharge/initOption',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/onlineMonitor/executionCharge/initOption.js'),
            })
          : require('../z-sellElectric/trade/onlineMonitor/executionCharge/initOption.js')
              .default,
      },
      {
        path: '/z-sellElectric/trade/onlineMonitor/executionCharge/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/onlineMonitor/executionCharge/list.js'),
            })
          : require('../z-sellElectric/trade/onlineMonitor/executionCharge/list.js')
              .default,
      },
      {
        path: '/z-sellElectric/trade/onlineMonitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/onlineMonitor/index.js'),
            })
          : require('../z-sellElectric/trade/onlineMonitor/index.js').default,
      },
      {
        path: '/z-sellElectric/trade/onlineMonitor/monthOrYear',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/onlineMonitor/monthOrYear/index.js'),
            })
          : require('../z-sellElectric/trade/onlineMonitor/monthOrYear/index.js')
              .default,
      },
      {
        path: '/z-sellElectric/trade/onlineMonitor/realtimeMonitor',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () =>
                import('../z-sellElectric/trade/onlineMonitor/realtimeMonitor/index.js'),
            })
          : require('../z-sellElectric/trade/onlineMonitor/realtimeMonitor/index.js')
              .default,
      },
      {
        path: '/z-station/overview',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../z-station/overview/index.js'),
            })
          : require('../z-station/overview/index.js').default,
      },
      {
        path: '/z-sysConfig/account/add',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/account/add.js'),
            })
          : require('../z-sysConfig/account/add.js').default,
      },
      {
        path: '/z-sysConfig/account/detail',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/account/detail.js'),
            })
          : require('../z-sysConfig/account/detail.js').default,
      },
      {
        path: '/z-sysConfig/account/edit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/account/edit.js'),
            })
          : require('../z-sysConfig/account/edit.js').default,
      },
      {
        path: '/z-sysConfig/account',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/account/index.js'),
            })
          : require('../z-sysConfig/account/index.js').default,
      },
      {
        path: '/z-sysConfig/account/tableList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/account/tableList.js'),
            })
          : require('../z-sysConfig/account/tableList.js').default,
      },
      {
        path: '/z-sysConfig',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/index.js'),
            })
          : require('../z-sysConfig/index.js').default,
      },
      {
        path: '/z-sysConfig/role/component/card',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../z-sysConfig/role/component/card/index.js'),
            })
          : require('../z-sysConfig/role/component/card/index.js').default,
      },
      {
        path: '/z-sysConfig/role/component/tree',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../z-sysConfig/role/component/tree/index.js'),
            })
          : require('../z-sysConfig/role/component/tree/index.js').default,
      },
      {
        path: '/z-sysConfig/role',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/role/index.js'),
            })
          : require('../z-sysConfig/role/index.js').default,
      },
      {
        path: '/z-sysConfig/role/roleDetails',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () =>
                import('../z-sysConfig/role/roleDetails/index.js'),
            })
          : require('../z-sysConfig/role/roleDetails/index.js').default,
      },
      {
        path: '/z-sysConfig/role/roleEdit',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/role/roleEdit/index.js'),
            })
          : require('../z-sysConfig/role/roleEdit/index.js').default,
      },
      {
        path: '/z-sysConfig/role/tableList',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/role/tableList.js'),
            })
          : require('../z-sysConfig/role/tableList.js').default,
      },
      {
        path: '/z-sysConfig/users',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/users/index.js'),
            })
          : require('../z-sysConfig/users/index.js').default,
      },
      {
        path: '/z-sysConfig/users/list',
        exact: true,
        component: __IS_BROWSER
          ? _dvaDynamic({
              app: require('@tmp/dva').getApp(),
              models: () => [
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/accountList.js').then(
                  m => {
                    return { namespace: 'accountList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleInfo.js').then(
                  m => {
                    return { namespace: 'roleInfo', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/roleList.js').then(
                  m => {
                    return { namespace: 'roleList', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/station.js').then(
                  m => {
                    return { namespace: 'station', ...m.default };
                  },
                ),
                import('/var/jenkins_home/workspace/iems/src/pages/z-sysConfig/models/users.js').then(
                  m => {
                    return { namespace: 'users', ...m.default };
                  },
                ),
              ],
              component: () => import('../z-sysConfig/users/list/index.js'),
            })
          : require('../z-sysConfig/users/list/index.js').default,
      },
      {
        path: '/hall',
        exact: false,
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../hall/_layout.js'),
            })
          : require('../hall/_layout.js').default,
        routes: [
          {
            path: '/hall/aircon',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/aircon/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../hall/aircon/index.js'),
                })
              : require('../hall/aircon/index.js').default,
          },
          {
            path: '/hall/cockpit',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/cockpit/index.js'),
                })
              : require('../hall/cockpit/index.js').default,
          },
          {
            path: '/hall/current',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/current/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../hall/current/index.js'),
                })
              : require('../hall/current/index.js').default,
          },
          {
            path: '/hall/energy-overview',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/energy-overview/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../hall/energy-overview/index.js'),
                })
              : require('../hall/energy-overview/index.js').default,
          },
          {
            path: '/hall/fire-safety',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/fire-safety/index.js'),
                })
              : require('../hall/fire-safety/index.js').default,
          },
          {
            path: '/hall',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/index.js'),
                })
              : require('../hall/index.js').default,
          },
          {
            path: '/hall/introduce',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/introduce/index.js'),
                })
              : require('../hall/introduce/index.js').default,
          },
          {
            path: '/hall/link-system/cover',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/link-system/cover.js'),
                })
              : require('../hall/link-system/cover.js').default,
          },
          {
            path: '/hall/link-system/coverConfig',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/link-system/coverConfig.js'),
                })
              : require('../hall/link-system/coverConfig.js').default,
          },
          {
            path: '/hall/link-system',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/link-system/index.js'),
                })
              : require('../hall/link-system/index.js').default,
          },
          {
            path: '/hall/link-system/left',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/link-system/left.js'),
                })
              : require('../hall/link-system/left.js').default,
          },
          {
            path: '/hall/link-system/middle',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/link-system/middle.js'),
                })
              : require('../hall/link-system/middle.js').default,
          },
          {
            path: '/hall/link-system/point',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/link-system/point/index.js'),
                })
              : require('../hall/link-system/point/index.js').default,
          },
          {
            path: '/hall/link-system/right',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  component: () => import('../hall/link-system/right.js'),
                })
              : require('../hall/link-system/right.js').default,
          },
          {
            path: '/hall/load-control',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/load-control/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../hall/load-control/index.js'),
                })
              : require('../hall/load-control/index.js').default,
          },
          {
            path: '/hall/load-control/service',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/load-control/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () =>
                    import('../hall/load-control/service/index.js'),
                })
              : require('../hall/load-control/service/index.js').default,
          },
          {
            path: '/hall/load-control/text/draw',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/load-control/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../hall/load-control/text/draw.js'),
                })
              : require('../hall/load-control/text/draw.js').default,
          },
          {
            path: '/hall/load-control/text',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/load-control/models/index.js').then(
                      m => {
                        return { namespace: 'index', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../hall/load-control/text/index.js'),
                })
              : require('../hall/load-control/text/index.js').default,
          },
          {
            path: '/hall/pv-se',
            exact: true,
            component: __IS_BROWSER
              ? _dvaDynamic({
                  app: require('@tmp/dva').getApp(),
                  models: () => [
                    import('/var/jenkins_home/workspace/iems/src/pages/hall/pv-se/model.js').then(
                      m => {
                        return { namespace: 'model', ...m.default };
                      },
                    ),
                  ],
                  component: () => import('../hall/pv-se/index.js'),
                })
              : require('../hall/pv-se/index.js').default,
          },
        ],
      },
      {
        component: __IS_BROWSER
          ? _dvaDynamic({
              component: () => import('../404.js'),
            })
          : require('../404.js').default,
      },
    ],
  },
];
window.g_routes = routes;
const plugins = require('umi/_runtimePlugin');
plugins.applyForEach('patchRoutes', { initialValue: routes });

export { routes };

export default class RouterWrapper extends React.Component {
  unListen() {}

  constructor(props) {
    super(props);

    // route change handler
    function routeChangeHandler(location, action) {
      plugins.applyForEach('onRouteChange', {
        initialValue: {
          routes,
          location,
          action,
        },
      });
    }
    this.unListen = history.listen(routeChangeHandler);
    // dva 中 history.listen 会初始执行一次
    // 这里排除掉 dva 的场景，可以避免 onRouteChange 在启用 dva 后的初始加载时被多执行一次
    const isDva =
      history.listen
        .toString()
        .indexOf('callback(history.location, history.action)') > -1;
    if (!isDva) {
      routeChangeHandler(history.location);
    }
  }

  componentWillUnmount() {
    this.unListen();
  }

  render() {
    const props = this.props || {};
    return <Router history={history}>{renderRoutes(routes, props)}</Router>;
  }
}
