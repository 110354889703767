// 可以使用 render 属性，渲染为 render || NAME
import { SvgIcon } from "@ui";
import styles from "./NavSub.scss";
import { NEW_ACCESS_CODE } from "@/services/openapi/right";
import IemIcon from "../../components/lib/iem-icon";

export const createTitle = ({ xlinkHref, text, ...props }) => (
  <>
    <IemIcon type={xlinkHref} className={styles.svgClass} />
    <span>{text}</span>
  </>
);
export const originMenu = [
  {
    name: '总览', //
    render: createTitle({ xlinkHref: 'icon-zonglan', text: '总览' }),
    link: '/n-company',
    access_code: NEW_ACCESS_CODE.VIEW_COMPANY,
    level: 1,
    children: [
        {
            name: '场站概览',
            level: 2,
            link: '/n-company/station-view',
            access_code: NEW_ACCESS_CODE.VIEW_STATION
        }
        // {
        //   name: "光伏概览",
        //   link: "/n-company/station-guanghu",
        //   access_code: NEW_ACCESS_CODE.VIEW_GUANGFU,
        // },
        // {
        //   name: "储能概览",
        //   link: "/n-company/station-chuneng",
        //   access_code: NEW_ACCESS_CODE.VIEW_CHUNENG,
        // },
        // {
        //   name: "充电桩概览",
        //   link: "/n-company/station-dianzhuang",
        //   access_code: NEW_ACCESS_CODE.VIEW_DIANZHUANG,
        // },
        // {
        //   name: "资产总览",
        //   link: "/n-company/station-zichan",
        //   access_code: NEW_ACCESS_CODE.VIEW_ZICHAN,
        // },
        // {
        //   name: "运营报告",
        //   link: "/n-company/station-yunying",
        //   access_code: NEW_ACCESS_CODE.VIEW_YUNYING,
        // },
    ]
},
{
    name: '新能源发电',
    render: createTitle({ xlinkHref: 'icon-xinnengyuanfadian', text: '新能源发电' }),
    link: '/z-energyPower',
    level: 1,
    access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY,
    children: [
        {
            name: '光伏总览',
            level: 2,
            link: '/z-energyPower/overview',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_OVERVIEW
        },
        {
            name: '发电分析',
            level: 2,
            link: '/z-energyPower/generateAnalyse',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_GENERATEANALYSE
        },
        {
            name: '发电概况',
            level: 2,
            link: '/z-energyPower/generationOverview',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_FDGK
        },
        {
            name: '结算管理',
            link: '/z-energyPower/settlementManagement',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_SETTLEMENTMANAGEMENT
        },
        {
            name: '统计报表',
            link: '/z-energyPower/staticReport',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_STATICREPORT
        },

        {
            name: '设备监测',
            link: '/z-energyPower/equipmentMonitor',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_EQUIPMENTMONITOR
        },
        {
            name: '项目台账',
            link: '/z-energyPower/projectLedger',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_PROJECTLEDGER
        },
        {
            name: '异常管理',
            link: '/z-energyPower/exceptionManagement',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGY_EXCEPTIONMANAGEMENT
        }
    ]
},
{
    name: '储能',
    level: 1,
    render: createTitle({ xlinkHref: 'icon-chuneng', text: '储能' }),
    link: '/z-energyStorage',
    access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE,
    children: [
        {
            name: '监测大厅',
            level: 2,
            link: '/z-energyStorage/electricity/overview',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE_OVERVIEW
        },
        {
            name: '运行管理',
            level: 2,
            link: '/z-energyStorage/electricity/operationManagement',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE_OPOPERATEMANAGE
        },

        {
            name: '设备监测',
            link: '/z-energyStorage/equipmentMonitor',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE_EQUIPMENTMONITOR
        },
        {
            name: 'ems管理',
            link: '/z-energyStorage/emsManagement',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE_EMSMANAGE
        },
        {
            name: '策略管理',
            link: '/z-energyStorage/policyManagement',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE_POLICYMANA
        },
        {
            name: '项目台账',
            link: '/z-energyStorage/projectLedger',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE_PROJECTLEDGER
        },
        {
            name: '异常管理',
            link: '/z-energyStorage/exceptionManagement',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_STORAGE_EXCEPTIONMANAGEMENT
        }
    ]
},

{
    name: '充换电',
    level: 1,
    render: createTitle({ xlinkHref: 'icon-chonghuandian', text: '充换电' }),
    link: '/z-changeElectric',
    access_code: NEW_ACCESS_CODE.VIEW_ZX_CHARGEREPLACE,
    children: [
        {
            name: '监测大厅',
            level: 2,
            link: '/z-changeElectric/overview',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_CHARGEREPLACE_OVERVIEW
        },
        {
            name: '充电趋势',
            level: 2,
            link: '/z-changeElectric/trend',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_CHARGEREPLACE_TREND
        },
        {
            name: '设备监测',
            link: '/z-changeElectric/equipmentMonitor',
            access_code: NEW_ACCESS_CODE.VIEW_JHD_ENERGY_EQUIPMENTMONITOR
        },
        {
            name: '项目台账',
            link: '/z-changeElectric/projectLedger',
            access_code: NEW_ACCESS_CODE.VIEW_JHD_ENERGY_PROJECTLEDGER
        }
    ]
},
{
    name: '售电',
    level: 1,
    link: '/z-sellElectric',
    render: createTitle({ xlinkHref: 'icon-shoudian', text: '售电' }),
    access_code: NEW_ACCESS_CODE.VIEW_ZX_SELLELECTRICITY,
    children: [
        {
            name: '市场信息',
            level: 2,
            link: '/z-sellElectric/trade/marketInfo',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_SELLELECTRICITY_TRADE_MARKETINFO
        },
        {
            name: '在线监测',
            level: 2,
            link: '/z-sellElectric/trade/onlineMonitor',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_SELLELECTRICITY_TRADE_ONLINEMONITOR
        }
        // {
        //   name: "在线监测执行电量",
        //   link: "/z-sellElectric/trade/onlineMonitor/checkBattery",
        //   access_code: NEW_ACCESS_CODE.VIEW_ZX_SELLELECTRICITY_TRADE_ONLINEMONITOR,
        // },
    ]
},
{
    name: '能源站',
    render: createTitle({ xlinkHref: 'icon-xinnengyuanfadian', text: '能源站' }),
    link: '/z-energyStation',
    level: 1,
    access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGYSTATION,
    children: [
        {
            name: '设备监控',
            level: 2,
            link: '/z-energyStation/monitor',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGYSTATION_MONITOR
        },
        {
            name: '设备清单',
            level: 2,
            link: '/z-energyStation/list',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ENERGYSTATION_LIST
        }
    ]
},
{
    name: '碳资产',
    level: 1,
    render: createTitle({ xlinkHref: 'icon-tanzichan', text: '碳资产' }),
    link: '/z-carbonAssets',
    access_code: NEW_ACCESS_CODE.VIEW_ZX_CARBONASSETS
},
{
    name: '运维', //
    render: createTitle({ xlinkHref: 'icon-zonglan', text: '运维' }),
    link: '/z-operate',
    access_code: NEW_ACCESS_CODE.VIEW_ZX_YUNWEIMANAGE,
    level: 1,
    children: [
        {
            name: '我的场站',
            level: 2,
            link: '/z-operate/station-view',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_YUNWEIMANAGE_MY
        }
    ]
},
// 集团设置
{
    name: '设置',
    level: 1,
    render: createTitle({ xlinkHref: 'icon-shezhi', text: '设置' }),
    link: '/z-sysConfig',
    access_code: NEW_ACCESS_CODE.VIEW_ZX_XTSZ,
    children: [
        {
            name: '账号管理',
            level: 2,
            link: '/z-sysConfig/account',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_ZHGL
        },
        {
            name: '角色管理',
            level: 2,
            link: '/z-sysConfig/role',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_JSGL
        },
        {
            name: '日志管理',
            level: 2,
            link: '/z-sysConfig/users',
            access_code: NEW_ACCESS_CODE.VIEW_ZX_RZGL
        }
    ]
},
{
  name: "能源总览",
  level: 1,
  render: createTitle({ xlinkHref: "icon-nengyuanzonglan", text: "能源总览" }),
  link: "/n-energy-overview",
  access_code: NEW_ACCESS_CODE.VIEW_NYZL,
},
{
  name: "能源生产",
  level: 1,
  link: "/n-energy-product",
  render: createTitle({ xlinkHref: "icon-nengyuanshengchan", text: "能源生产" }),
  access_code: NEW_ACCESS_CODE.VIEW_NYSC,
  children: [
    {
      name: "电站监测",
      level: 2,
      link: "/n-energy-product/photovoltaic/monitor",
      access_code: NEW_ACCESS_CODE.VIEW_GFDZJZ,
    },
    {
      name: "发电分析",
      level: 2,
      link: "/n-energy-product/photovoltaic/eleGen",
      access_code: NEW_ACCESS_CODE.VIEW_GFFDFX,
    },
    {
      name: "收益分析",
      level: 2,
      link: "/n-energy-product/photovoltaic/profit",
      access_code: NEW_ACCESS_CODE.VIEW_SYFX,
    },
    {
      name: "统计报表",
      level: 2,
      link: "/n-energy-product/photovoltaic/totalAnalyze",
      access_code: NEW_ACCESS_CODE.VIEW_TJBB,
    },
    {
      name: "设备监测",
      level: 2,
      link: "/n-energy-product/photovoltaic/equipmentMonitor",
      access_code: NEW_ACCESS_CODE.VIEW_SBJC,
    },
    {
      name: "异常监测",
      level: 2,
      link: "/n-energy-product/photovoltaic/monitor1",
      access_code: NEW_ACCESS_CODE.VIEW_GFYCJC,
    },
    {
      name: "告警管理",
      level: 2,
      link: "/event-center/exception-statistics",
      access_code: NEW_ACCESS_CODE.VIEW_GFGJGL,
    },
  ],
},
{
  name: "用能负荷",
  level: 1,
  render: createTitle({ xlinkHref: "icon-yongnengfuhe", text: "用能负荷" }),
  link: "/energy-monitor",
  access_code: NEW_ACCESS_CODE.VIEW_YNFH,
  children: [
    {
      name: "能耗分析",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_NXPM,
      link: "/energy-monitor/efficiency/ranking",
    },
    {
      name: "能量平衡",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_NLPH,
      link: "/energy-monitor/energy/balance/lineBalance",
    },
    {
      name: "趋势分析",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_QSFX,
      link: "/energy-monitor/energy/tendency/electricity",
    },
    {
      name: "能耗统计",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_NHTJ,
      link: "/energy-monitor/datum/energyConsumptionStatistics/tendency",
    },
    {
      name: "能效统计",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_NXTJ,
      link: "/energy-monitor/datum/energyConsumptionStatistics/ranking",
    },
  
    {
      name: "用电数据",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_YDSJ,
      link: "/energy-monitor/datum/electric",
    },
    {
      name: "用水数据",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_YSSJ,
      link: "/energy-monitor/datum/water",
    },
    {
      name: "燃气数据",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_RQSJ,
      link: "/energy-monitor/datum/gas",
    },
   
    {
      name: "设备管理",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_SBGL,
      link: "/energy-monitor/datum/equipmentManagement",
    },
    {
      name: "用能配置",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_YNPZ,
      link: "/energy-monitor/energy-use-management/classify",
      children: [
        {
          name: "用能分类管理",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_FLGL,
          link: "/energy-monitor/energy-use-management/classify",
        },
        {
          name: "建筑管理",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_JZGL,
          link: "/energy-monitor/energy-use-management/buildManage",
        },
        {
          name: "产量产值录入",
          level: 2,
          access_code: NEW_ACCESS_CODE.VIEW_CLCZLR,
          link: "/energy-monitor/energy-use-management/outputValue",
        },
      ]
    },
  ],
},
{
  name: "柔性储能",
  level: 1,
  render: createTitle({ xlinkHref: "icon-rouxingchuneng", text: "柔性储能" }),
  link: "/n-flexible-storage",
  access_code: NEW_ACCESS_CODE.VIEW_RXCN,
  children: [
    {
      name: "电站总览",
      level: 2,
      link: "/n-flexible-storage/battery/overview",
      access_code: NEW_ACCESS_CODE.VIEW_DZZL,
    },
    {
      name: "数据监测",
      level: 2,
      link: "/n-flexible-storage/battery/station/work",
      access_code: NEW_ACCESS_CODE.VIEW_SJJC,
    },
    // {
    //   name: "收益分析",
    //   level: 2,
    //   link: "/operation/income",
    //   access_code: NEW_ACCESS_CODE.VIEW_YDSTFX,
    // },
    {
      name: "优化分析",
      level: 2,
      link: "/operation/strategic",
      access_code: NEW_ACCESS_CODE.VIEW_YDYHFX,
    },
    {
      name: "电价策略",
      level: 2,
      link: "/n-flexible-storage/battery/strategy/electricityPrice",
      access_code: NEW_ACCESS_CODE.VIEW_CLDJFX,
    },
    {
      name: "充放电策略",
      level: 2,
      link: "/n-flexible-storage/battery/strategy/chargeOrDisCharge",
      access_code: NEW_ACCESS_CODE.VIEW_CLCFDFX,
    },
    {
      name: "告警管理",
      level: 2,
      link: "/n-flexible-storage/battery/warning/record",
      access_code: NEW_ACCESS_CODE.VIEW_GJGL,
    },
    {
      name: "量费总览",
      level: 2,
      link: "/n-flexible-storage/battery/volume-fee",
      access_code: NEW_ACCESS_CODE.VIEW_LFZL,
    },
    {
      name: "量费分析",
      level: 2,
      link: "/n-flexible-storage/battery/quantity-analysis",
      access_code: NEW_ACCESS_CODE.VIEW_LFFX,
    },

    {
      name: "运行收益分析",
      level: 2,
      link: "/n-flexible-storage/smartControl/workIncome",
      access_code: NEW_ACCESS_CODE.VIEW_RXCNSYFX,
    },
    {
      name: "数据分析",
      level: 2,
      link: "/n-flexible-storage/smartControl/dataAnalyse",
      access_code: NEW_ACCESS_CODE.VIEW_RXCNSJFX,
    },
    {
      name: "策略运行分析",
      level: 2,
      link: "/n-flexible-storage/smartControl/runAnalyse",
      access_code: NEW_ACCESS_CODE.VIEW_CLRXCNSJFX,
    },
  ],
},
{
  name: "能源站",
  render: createTitle({ xlinkHref: "icon-xinnengyuanfadian", text: "能源站" }),
  link: "/energy-station",
  level: 1,
  access_code: NEW_ACCESS_CODE.VIEW_ENERGYSTATION,
  children: [
    {
      name: "设备监控",
      level: 2,
      link: "/energy-station/monitor",
      access_code: NEW_ACCESS_CODE.VIEW_ENERGYSTATION_MONITOR,
    },
    // {
    //   name: "设备清单",
    //   level: 2,
    //   link: "/energy-station/list",
    //   access_code: NEW_ACCESS_CODE.VIEW_ENERGYSTATION_LIST,
    // }
  ],
},
{
  name: "能源中控",
  level: 1,
  render: createTitle({ xlinkHref: "icon-nengyuanzhongkong", text: "能源中控" }),
  link: "/energy-control",
  access_code: NEW_ACCESS_CODE.VIEW_NYZK,
  children: [

    {
      name: "储能智控",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_NYZKCNZK,
      link: "/energy-control/smartControl/overView",
    },

    {
      name: "运行策略",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_NYZKYXCL,
      link: "/energy-control/smartControl/workStrategy",
    },
    {
      name: "电价信息",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_NYZKDJXX,
      link: "/energy-control/smartControl/priceInfo",
    },
    {
      name: "充电桩管控",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_CDZGK,
      link: "/energy-control/load-manage/charging-pile",
      children: [
        {
          name: "充电趋势分析",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_CDZQSFX,
          link: "/energy-control/load-manage/charging-pile",
        },
        {
          name: "充电桩控制",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_CDZKZ,
          link: "/energy-control/load-manage/charging-control",
        },
      ]
    },
    {
      name: "空调管控",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_KTGK,
      link: "/energy-control/load-manage/air",
      children: [
        {
          name: "无感调节",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_WGTJ,
          link: "/energy-control/airConditionerMng/insensitive",
        },
        {
          name: "总控策略",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_ZKCL,
          link: "/energy-control/airConditionerMng/total",
        },
        {
          name: "空调控制",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_KTKZ,
          link: "/energy-control/airConditionerMng/control",
        },
        {
          name: "管控记录",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_KTGKJL,
          link: "/energy-control/airConditionerMng/record",
        },
      ],
    },
    {
      name: "路灯管控",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_LDGK,
      link: "/energy-control/street-lamp",
      children: [
        {
          name: "路灯控制",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_LDKZ,
          link: "/energy-control/street-lamp/lamp-control",
        },
        {
          name: "管控记录",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_LDGKJL,
          link: "/energy-control/street-lamp/lamp-record",
        },
      ],
    },
    {
      name: "空开管控",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_KKGK,
      link: "/energy-control/airSwitch",
      children: [
        {
          name: "设备监控",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_SBJK,
          link: "/energy-control/airSwitch/equipment-monitor",
        },
        {
          name: "定时任务",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_DSRW,
          link: "/energy-control/airSwitch/scheduled-task",
        },
        {
          name: "报警监控",
          level: 3,
          access_code: NEW_ACCESS_CODE.VIEW_BJJK,
          link: "/energy-control/airSwitch/alarm-monitor",
        },
      ],
    }
  ],
},

{
  name: "量费统计",
  level: 1,
  render: createTitle({ xlinkHref: "icon-liangfeitongji", text: "量费统计" }),
  link: "/cost-analysis/",
  access_code: NEW_ACCESS_CODE.VIEW_LFTJ,
  children: [
    {
      name: "电费统计",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_DFTJ,
      link: "/cost-analysis/pay/electricStatic",
    },
    {
      name: "电费分析",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_DFFX,
      link: "/cost-analysis/pay/electricAnalyse/base",
    },
    {
      name: "水费分析",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_SFFX,
      link: "/cost-analysis/pay/water",
    },
    {
      name: "燃气费分析",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_RQFFX,
      link: "/cost-analysis/pay/gas",
    },
  ],
},
{
  name: "资产管理",
  level: 1,
  render: createTitle({ xlinkHref: "icon-zichanguanli", text: "资产管理" }),
  link: "/asset-manage",
  access_code: NEW_ACCESS_CODE.VIEW_ZCGL,
  children: [
    {
      name: "空调数据",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_KTSJ,
      link: "/asset-manage/equipment/air-conditioner",
    },
    {
      name: "路灯数据",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_LDSJ,
      link: "/asset-manage/equipment/street-lamp",
    },
    {
      name: "台账管理-光伏",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_GFZS,
      link: "/asset-manage/ledger/photovoltaic",
    },
  ],
},
{
  name: "增值业务",
  level: 1,
  render: createTitle({ xlinkHref: "icon-zengzhiyewu", text: "增值业务" }),
  link: "/n-value-added",
  access_code: NEW_ACCESS_CODE.VIEW_ZZYW,
  children: [
    {
      name: "需求响应",
      level: 2,
      link: "/n-value-added/response",
      access_code: NEW_ACCESS_CODE.VIEW_XQXY,
    },
    {
      name: "碳交易",
      level: 2,
      link: "/n-value-added/trade",
      access_code: NEW_ACCESS_CODE.VIEW_TJY,
    },
    {
      name: "售电",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_ZZYWSD,
      link: "/energy-control/cost-electri",
      children: [
        {
          name: "售电分析",
          level: 2,
          link: "/n-value-added/cost-electri",
          access_code: NEW_ACCESS_CODE.VIEW_SDFX,
        },
        {
          name: "统计分析",
          level: 2,
          link: "/n-value-added/total-analyze",
          access_code: NEW_ACCESS_CODE.VIEW_CZTTJFX,
        }
      ]
    }
  ]
},
{
  name: "运维维护",
  level: 1,
  render: createTitle({ xlinkHref: "icon-yunweiweihu", text: "运维维护" }),
  link: "/event-center/",
  access_code: NEW_ACCESS_CODE.VIEW_YWWH,
  children: [
    {
      name: "异常统计",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_YCTJ,
      link: "/event-center/exception-statistics",
    },
    {
      name: "异常清单",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_YCZX,
      link: "/event-center/exception-center",
    },
    {
      name: "事件中心",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_ZSJZX,
      link: "/event-center/event-center",
    },
    {
      name: "视频实况",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_SPSK,
      link: "/emergency-security/security/camera",
    },
    {
      name: "消防总览",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_XFZL,
      link: "/emergency-security/fireControl/overview",
    },
    {
      name: "消防水压控制",
      level: 2,
      access_code: NEW_ACCESS_CODE.VIEW_XFSYKZ,
      link: "/emergency-security/fireControl/waterPressure",
    },

  ],
},
{
  name: "报告中心",
  level: 1,
  render: createTitle({ xlinkHref: "icon-baogaozhongxin", text: "报告中心" }),
  link: "/reportCenter/",
  access_code: NEW_ACCESS_CODE.VIEW_BGZX,
  children: [
    {
      name: "月度报告",
      level: 2,
      link: "/reportCenter/monthReport",
      access_code: NEW_ACCESS_CODE.VIEW_YDBG,
    },
    {
      name: "账单管理",
      level: 2,
      link: "/reportCenter/billManage",
      access_code: NEW_ACCESS_CODE.VIEW_ZDGL,
    },
  ],
},

// 企业设置
{
  name: '设置',
  level: 1,
  render: createTitle({ xlinkHref: "", text: "设置" }),
  link: "/sysConfig",
  access_code: NEW_ACCESS_CODE.VIEW_XTSZ,
  children: [
    {
      name: "账号管理",
      level: 2,
      link: "/sysConfig/account",
      access_code: NEW_ACCESS_CODE.VIEW_ZHGL,
    },
    {
      name: "角色管理",
      level: 2,
      link: "/sysConfig/role",
      access_code: NEW_ACCESS_CODE.VIEW_JSGL,
    },
    {
      name: "日志管理",
      level: 2,
      link: "/sysConfig/users",
      access_code: NEW_ACCESS_CODE.VIEW_RZGL,
    },
  ],
}
];

export default (rightMap = {}) => {
  const list = recursionReduce(originMenu, rightMap);
  list.forEach((item) => {
    let iItem = item;
    while (iItem.children && iItem.children.length !== 0) {
      iItem = iItem.children[0];
    }
    item.rightLink = iItem.link;
  });
  return list;
};

export const linkAndAccesses = (() => {
  const arr = [];

  function recurstion (list) {
    list.forEach((item) => {
      if (item.children) {
        recurstion(item.children);
      }
      arr.push([item.link, item.access_code]);
    });
  }

  recurstion(originMenu);
  return arr;
})();

export function recursionReduce (menuList, rightMap) {
  return menuList.reduce((resArr, item) => {
    item = {
      ...item,
      title: item.name,
      key: item.access_code,
    }
    if (item.children && item.children.length) {
      const filteredChildren = recursionReduce(item.children, rightMap);
      if (filteredChildren.length) {
        resArr.push({
          ...item,
          children: filteredChildren,
        });
      }
      return resArr;
    }
    if (item.access_code && !rightMap[item.access_code]) return resArr;
    resArr.push(item);
    return resArr;
  }, []);
}
