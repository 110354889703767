import { getData } from '@services/request';
// 根据站点id获取所有的用能分类
export async function getEnergyClassificationByStationIdApi (params) {
  const response = await getData('energyManagement', 'ylGetEnergyClassificationByStationId', { ...params });
  const dataList = response.dataList || [];
  return dataList;
}

// 获取整个树结构
let hash = {};
let index = 0;
function formartTree (tree = []) {
  index += 1;
  const res = [];
  if (tree.length === 0) return res;
  tree.forEach((item) => {
    if (!_.isObject(item)) return;
    if (Object.keys(item).length) {
      hash[item.nodeId] = item;
      const obj = {
        // ...item,
        key: item.nodeId,
        title: item.name,
        disableCheckbox: index === 1 ? true : false,
        children: formartTree(item.childNodes)[0],
      };
      res.push(obj);
    }
  });
  return [res];
}

export async function getTreeNodeByStationIdAndTypeId (params) {
  hash = {};
  index = 0;
  const response = await getData('energyManagement', 'ylGetTreeNodeByStationIdAndTypeId', { ...params });
  const [treeData] = formartTree([response.data || {}]);
  return [treeData, hash];
}
