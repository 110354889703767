import * as servers from '@api/energyWeb/Statistics';

export default {
  namespace: 'vLateralStrain',
  state: {
    loading: false,
    energyType: '',
    dataMap: {},
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *queryChartData ({ payload }, { put, call }) {
      yield put({
        type: 'update',
        payload: {
          loading: true,
        },
      });
      const response = yield call(servers.horizontalContrast, { ...payload });
      yield put({
        type: 'update',
        payload: {
          loading: false,
          energyType: payload.energyType,
          dataMap: response.data || {},
        },
      });
      return response;
    },
    *deleteInfo (_, { put }) {
      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataMap: {},
        },
      });
    },
  },
};
