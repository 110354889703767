import React from 'react';
import { Modal, Button, Radio } from 'antd';
import { readProp } from '@/utils/tool';
import { noop } from 'lodash';
import enterpriseImg from './assets/enterprise.png';
import styles from './CustomerPickerModal.scss';

const RadioGroup = Radio.Group;


class CustomerPickerModal extends React.PureComponent {
  // static propTypes = {
  //   list: PropTypes.array.isRequired,
  //   visible: PropTypes.bool.isRequired,
  //   onOk: PropTypes.func.isRequired,
  // }

  state = {
    pickId: this.props.pickId || undefined,
    list: this.props.list || [],
    onOk: this.props.onOk || noop,
    // visible: this.props.visible || false,  // 隐藏登录入页面展示的弹窗
    visible: false
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.visible && this.props.visible) { // 打开时
      this.setState({ pickId: readProp(this.props.list)('0.id'), list: this.props.list, visible: false });
    }

    if (prevProps.visible && !this.props.visible) { // 关闭时
      this.setState({ pickId: undefined, visible: false });
    }
  }

  onOk = () => {
    const { onOk, pickId } = this.state;
    if (onOk) onOk(pickId);
    this.setState({ visible: false });
  }

  onChangePick = ({ target: { value } }) => this.setState({ pickId: value })

  show = async (pickId, list) => new Promise((resolve) => {
    if (list.length < 1) {
      resolve(undefined);
      return;
    }
    if (list.length === 1) {
      resolve(list[0].id);
      return;
    }
    const validItem = list.find(item => item.id === pickId);
    this.setState({ visible: true, pickId: validItem ? pickId : list[0].id, list, onOk: resolve });
  })

  render() {
    const { visible, pickId, list } = this.state;
    return (
      <Modal
        visible={false}
        maskClosable={false}
        closable={false}
        wrapClassName={styles.modalWrapper}
        title={
          <div style={{ textAlign: 'center' }}>
            <img src={enterpriseImg} alt="" style={{ marginRight: 5, verticalAlign: 'sub' }} />
            请选择登录的企业
          </div>
        }
        width={400}
        footer={[
          <div style={{ textAlign: 'center' }}>
            <span>
              <Button
                style={{ width: 280 }}
                type="primary"
                onClick={this.onOk()}
              >
                确定
              </Button>
            </span>
          </div>,
        ]}
      >
        <div className={styles.content}>
          <div className={styles['content-scroll']}>
            <RadioGroup value={pickId} onChange={this.onChangePick}>
              {list.map(({ id, name }) => <Radio className={styles.radio} key={id} value={id}>{name}</Radio>)}
            </RadioGroup>
          </div>
        </div>
      </Modal>
    );
  }
}

export default CustomerPickerModal;
