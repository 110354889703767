import { useState, useEffect } from 'react';
import { Breadcrumb } from 'antd';
import SvgIcon from '../SvgIcon';
import styles from './breadcrumb.scss';

const noop = () => { };
const { Item, Separator } = Breadcrumb;

function TreeBreadcrumb(props) {
  const { treeData, selectedKey, className, style, onSelect = () => { } } = props;
  const [[keyToParentKey, keyToNode], setTreeHash] = useState(analyzeTreeData(treeData));

  useEffect(() => {
    setTreeHash(analyzeTreeData(treeData));
  }, [treeData]);

  const list = recursionMap(selectedKey, keyToParentKey, keyToNode, node => node);
  const len = list.length;
  const needHide = len > 8;

  return (
    <div className={[styles.crumbWrapper, className]}>
      <Breadcrumb className={styles.crumb} style={style} separator="">
        <Item className="">当前节点位置：</Item>
        {
          list.map((node, idx) => {
            if (!node) return null;
            if (needHide && idx === 3) {
              return (
                <>
                  <Item className={styles.spread}>...</Item>
                  <Separator><SvgIcon xlinkHref="icon-Chevron" /></Separator>
                </>
              );
            }
            if (needHide && idx > 3 && idx < len - 4) {
              return null;
            }
            const isEnd = idx === len - 1;
            return (
              <>
                <Item
                  className={['ant-breadcrumb-link', node.disabled ? styles.disabled : '']}
                  onClick={isEnd || node.disabled === true ? noop : () => onSelect(node.key)}
                >{node.title}</Item>
                {isEnd ? null : <Separator><SvgIcon xlinkHref="icon-Chevron" /></Separator>}
              </>
            );
          })
        }
      </Breadcrumb>
    </div>
  );
}

export default TreeBreadcrumb;

function recursionMap(selectedKey, keyToParentKey, keyToNode, f) {
  const list = [];
  let iterateKey = selectedKey;
  while (iterateKey) {
    list.unshift(f(keyToNode[iterateKey], iterateKey === selectedKey));
    iterateKey = keyToParentKey[iterateKey];
  }
  return list;
}


function analyzeTreeData(treeData) {
  const keyToParentKey = {};
  const keyToNode = {};
  recursion(treeData, null, keyToParentKey, keyToNode);
  return [keyToParentKey, keyToNode];
}

function recursion(list, parentKey, keyToParentKey, keyToNode) {
  list?.forEach((item) => {
    keyToParentKey[item.key] = parentKey;
    keyToNode[item.key] = item;
    if (item.children && item.children.length) {
      recursion(item.children, item.key, keyToParentKey, keyToNode);
    }
  });
}