import { detect } from 'detect-browser';
import { routerRedux } from 'dva/router';
import queryString from 'query-string';

import { listenTokenRolling, listenUnauthorized } from '@/utils/request';
import * as storage from '@/services/storage';
import * as wsocket from '@/services/socketCluster';
import * as services from '@/services/openapi/energy_account';
import { readProp } from '@/utils/tool';


const energyTypes = {
  0: '电',
  1: '水',
  2: '气',
  3: '热',
};

const defaultMinWidth = 1903;  // 1920 - 17
const defaultMinHeight = 888;  // 1080 - (chrome tab+地址栏+书签栏+底部下载栏),

const defaultLoadingKeys = {
  models: [],
  effects: [
    'base/init',
  ],
};

export default {
  namespace: 'base',

  state: {
    browser: {},
    toLocation: '/',

    token: undefined,
    // 当前企业ID
    currentEnterpriseId: undefined,
    // 当前
    currentStation: undefined,

    energyTypes,
    currentEnergyType: '0',

    minWidth: defaultMinWidth,
    minHeight: defaultMinHeight,

    loadingKeys: defaultLoadingKeys,
    // 用户信息
    user: {},

    enterpriseList: [],

    // 
    enableStationPicker: false,
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    verifiedPhone (state, { payload: { phone } }) {
      const user = state.user;
      return { ...state, user: { ...user, phoneValidated: true, phone } };
    },
    addPass (state) {
      const user = state.user;
      return { ...state, user: { ...user, hasPassword: true } };
    },
    updateUser (state, { payload }) {
      const user = state.user;
      return { ...state, user: { ...user, payload } };
    },
    enableStationPicker (state, { payload: flag }) {
      return { ...state, enableStationPicker: flag };
    },
  },

  effects: {
    *init (_, { put, select, take }) {
      // yield put({ type: 'checkLogin' });
      // yield take('checkLogin/@@end');
      const { user: { accountId }, currentEnterpriseId } = yield select(state => state.base);

      yield put({ type: 'metricCode/fetch' });
      yield take('metricCode/fetch/@@end');

      yield put({ type: 'alarm/fetch' });
      yield take('alarm/fetch/@@end');

      yield put({ type: 'station/fetch', payload: { providerId: currentEnterpriseId, accountId } });
      yield take('station/fetch/@@end');

      yield put({ type: 'alarmStat/sub' });
      yield put({ type: 'alarmpopups/subscribe' });
    },

    *unmount (_, { put, call }) {
      yield put({ type: 'station/clear' });

      yield put({ type: 'alarmStat/unsub' });

      yield call(wsocket.unregiterAll);
    },

    *updateCurrentStation ({ payload: { id } = {} }, { put }) {
      if (id) {
        yield put({
          type: 'update',
          payload: { currentEnterpriseId: id },
        });
        storage.set('defaultStation', '');
        // yield put(routerRedux.replace('/usercenter'));
        window.location.reload();
        // console.log(routerRedux)
      }
    },

    *updateCurrentEnterprise ({ payload: { id, isAddingLog } = {} }, { put, select }) {
      const { accountId } = yield select(({ base: { user } }) => user);
      yield put({ type: 'right/fetchAccess', payload: { accountId, enterpriseId: id } });
      // 切换企业后会重新走一遍base/init
      // yield put({ type: 'station/fetch', payload: { providerId: id, accountId } });

      if (id) {
        yield put({
          type: 'update',
          payload: { currentEnterpriseId: id },
        });
      }

      if (isAddingLog) {
        yield put({ type: 'bizlogUser/createItemLogin' });
      }
    },

    *reLocate (_, { put, select }) {
      const toLocation = yield select(state => state.base.toLocation);
      yield put(routerRedux.push(toLocation));
    },

    *requestMinView ({ payload: { minHeight, minWidth, reset = false } = {} }, { put }) {
      if (reset) {
        yield put({
          type: 'update',
          payload: {
            minWidth: defaultMinWidth,
            minHeight: defaultMinHeight,
          },
        });

        return;
      }
      if (minWidth) yield put({ type: 'update', payload: minWidth });
      if (minHeight) yield put({ type: 'update', payload: minHeight });
    },

    *requestLoadingKeys ({ payload: { models = [], effects = [] } = {} }, { put }) {
      if (Array.isArray(models) && Array.isArray(effects)) {
        yield put({
          type: 'update',
          payload: {
            loadingKeys: {
              models: [
                ...models,
                ...defaultLoadingKeys.models,
              ],
              effects: [
                ...effects,
                ...defaultLoadingKeys.effects,
              ],
            },
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            loadingKeys: defaultLoadingKeys,
          },
        });
      }
    },

    *storeCurrentState (_, { select }) {
      const { user: { accountId }, currentStation, currentEnterpriseId } = yield select(({ base }) => base);
      if (!accountId || !currentEnterpriseId) return;
      // 记录站点
      if (currentStation) {
        const mayObj = storage.get('defaultStation') || {};
        mayObj[accountId] = currentStation;
        storage.set('defaultStation', mayObj);
      }

      // 记录企业
      const defalutEPObj = storage.get('defaultEnterprise') || {};
      defalutEPObj[accountId] = currentEnterpriseId;
      storage.set('defaultEnterprise', defalutEPObj);
    },

    *checkLogin (_, { put, select, call }) {
      // 1. 已经登录
      const { token, user, currentEnterpriseId } = yield select(({ base }) => base);
      if (token && user && user.accountId) return;

      // 2. storage中存在token，检测token
      const tokenDataRes = yield call(services.getUserInfoByToken);
      const tokenDataOf = readProp(tokenDataRes);
      const accountId = tokenDataOf('data.accountId');
      if (tokenDataRes.code === null && accountId) {
        const userInfo = yield call(services.fetchEntireUserInfo, accountId);

        const userOf = readProp(userInfo);
        const epList = userOf('enterpriseList', []);
        const validEpId = epList.find(ep => ep.id === currentEnterpriseId) ? currentEnterpriseId : userOf('enterpriseList.0.id');
        if (userInfo.name) {
          const defalutEPObj = storage.get('defaultEnterprise');
          const defaultEPId = readProp(defalutEPObj)(`${accountId}`, validEpId);
          yield put({
            type: 'login',
            payload: {
              data: {
                ...tokenDataRes.data,
                ...userInfo,
                token: tokenDataRes.data.tokenInfo.token
              }
            }
          });
          yield put({ type: 'updateCurrentEnterprise', payload: { id: defaultEPId } });
          return;
        }
      }
      // 2.1 storageToken不存在或无效，退出登录
      yield put({ type: 'logout' });
    },

    *login ({ payload: { data: user, isAddingLog } }, { put }) {
      // if user.token === undefined
      storage.set('token', user.token);
      const { enterpriseList, ...userObj } = user;
      yield put({
        type: 'update',
        payload: {
          token: user.token,
          user: userObj,
          enterpriseList,
        },
      });

      if (isAddingLog) {
        yield put({ type: 'bizlogUser/createItemLogin' });
      }
    },

    *logout (_, { put, call, take }) {
      // 记录用户当前选中的站点
      yield put({ type: 'storeCurrentState' });
      yield take('storeCurrentState/@@end');
      // 通知服务端 退登
      yield call(services.logout);
      // 清空 token
      storage.remove('token');
      // 清空 user
      storage.remove('user');
      // 清空 customerId
      storage.remove('customerId');

      // 清空 defaultStation
      storage.set('defaultStation', {});

      // 清空 base 中相关信息
      yield put({
        type: 'update',
        payload: {
          token: undefined,
          currentStation: undefined,
          currentEnterpriseId: undefined,
          user: {},
        },
      });

      yield call(wsocket.unregiterAll);

      yield put(routerRedux.push('/login'));
    },
  },

  subscriptions: {
    loadStorageToken ({ dispatch }) {
      const token = storage.get('token');
      const customerId = storage.get('customerId');
      if (token) dispatch({ type: 'update', payload: { token, currentEnterpriseId: customerId } });
    },
    whenWindowUnload ({ dispatch }) {
      window.onunload = function onWindowUnload () {
        dispatch({ type: 'storeCurrentState' });
      };
    },
    initUserAgent ({ dispatch }) {
      const browser = detect() || {};
      const body = document.body;
      browser.version = window.parseInt(browser.version);

      dispatch({ type: 'update', payload: { browser } });

      // add class for style hacks
      // IE does not support multiple parameters for the add() & remove() methods
      body.classList.add(browser.name);
      body.classList.add(`${browser.name}${browser.version}`);
    },

    initTokenRolling () {
      // @utils/request 中循环触发了心跳
      // 如果服务器返回了相应的响应头，则会带上 token 传到这里
      listenTokenRolling((token) => {
        storage.set('token', token);
      });
    },

    initUnauthorized ({ dispatch }) {
      listenUnauthorized(() => {
        // console.log('Unauthorized');
        wsocket.unregiterAll();
        dispatch({ type: 'base/logout' });
        dispatch(routerRedux.push('/login'));
      });
    },

    insertHistoryLocationQuery ({ history }) {
      history.listen((location) => {
        const { search = '' } = location;
        const query = queryString.parse(search);
        location.query = query;
      });
    },

    initWSDispatch ({ dispatch }) {
      return wsocket.watch((action, data) => {
        dispatch({ type: action, payload: data });
      });
    },
  },
};
