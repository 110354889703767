import * as service from '@/services/metricCode';


export default {
  state: {
    data: {},
    action: {},
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetch({ payload }, { call, put, all }) {
      const [[measureCode], [signCode], [signAction]] = yield all([
        call(service.queryMetricItemByType, { type: 1 }),
        call(service.queryMetricItemByType, { type: 0 }),
        call(service.queryAllAction),
      ]);

      const metricCodeHashType1 = measureCode.reduce((acc, item) => {
        const { originCode, name, unit, resolution, alias } = item;
        acc[originCode] = {
          ...item,
          code: originCode,
          name,
          unit,
          alias,
          dec: resolution,
        };
        return acc;
      }, {});

      const metricCodeHashType0 = signCode.reduce((acc, item) => {
        const { originCode, name, unit, resolution, alias } = item;
        acc[originCode] = {
          ...item,
          code: originCode,
          name,
          unit,
          alias,
          dec: resolution,
        };
        return acc;
      }, {});

      const metricCodeHashAction = signAction.reduce((acc, item) => {
        const { metricCode, actionDesc, actionValue } = item;
        if (!acc[metricCode]) acc[metricCode] = {};
        acc[metricCode][actionValue] = actionDesc;
        return acc;
      }, {});

      yield put({
        type: 'update',
        payload: {
          data: {
            ...metricCodeHashType1,
            ...metricCodeHashType0,
          },
          action: metricCodeHashAction,
        },
      });
    },
  },

  subscriptions: {},
};
