import React from 'react';
import { Form } from 'antd';

import styles from './index.scss';

class CForm extends React.Component {
  render() {
    const { className, children, helpIsInline, ...rest } = this.props;

    return (
      <Form
        className={[className, helpIsInline ? styles.help : null].join(' ')}
        {...rest}
      >
        {children}
      </Form>
    );
  }
}

CForm.Item = Form.Item;
CForm.create = Form.create;

export default CForm;
