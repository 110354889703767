import moment from 'moment';
import * as service from '@services/storage/alarm';
import * as servicesUnhandleAside from '@services/storage/alarm/unhandleAside';
import * as wsocket from '@services/storage/socketCluster';

const responseSuccess = res => res && res.code === null;

const sleep = time => new Promise(resolve => global.setTimeout(() => resolve(), time));

const initialState = () => ({
  visible: false,
  data: {},
  relatedList: [],
  listLoading: false,
  order: {},
  // flag 表示触发的位置，1:记录查询，其它为其它
  flag: '',
});

export default {
  namespace: 'vUnhandleAside',
  state: initialState(),
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    clear () {
      return initialState();
    },
  },
  effects: {
    *showPanel ({ payload: alarmInfo, flag = '' }, { call, put }) {
      const { monitoredObjectId, stationId, dealStatus, alarmRecordId, alarmRuleId } = alarmInfo;

      const dealStatusENToCode = service.dealStatusENToCode;
      // const isRecovered = !!recoverTime;

      yield put({ type: 'update', payload: { data: {}, relatedList: [], visible: true, listLoading: true, order: {}, flag } });

      const [alarmRuleDesc, { data: alarmRuleRes }] = yield call(servicesUnhandleAside.queryAlarmRuleDesc, {
        alarmId: alarmRuleId,
      });
      const assignedType = (alarmRuleRes || { assignedType: 1 }).assignedType;
      // 根据告警处理状态查询不同的信息
      if (dealStatus === dealStatusENToCode.UNHANDLE) {
        // 查询关联告警
        const res = yield call(service.queryAlarmRecord, {
          stationIds: [stationId],
          deviceCodes: [monitoredObjectId],
          endTime: moment().endOf('day').unix(),
          startTime: moment(new Date('2016-01-01')).unix(),
          page: 1,
          length: 6,
          exceptionTypes: JSON.stringify([]),
          firstHappenTimeOrderType: 1,
          recoverTimeOrderType: 1,
          orderPriority: 0,
        });

        const dataList = (res.dataList || []).filter(obj => obj.alarmRecordId !== alarmRecordId).slice(0, 5);
        yield put({ type: 'update', payload: { relatedList: dataList } });
      } else if (dealStatus === dealStatusENToCode.HANDLED) {
        // 查询工单信息
        const [orderId] = yield call(servicesUnhandleAside.queryOrderId, {
          alarmRecordId,
        });
        let order = {};
        if (orderId) {
          [order] = yield call(servicesUnhandleAside.getBaseInfoByOrderId, {
            orderId,
          });
        }
        yield put({ type: 'update', payload: { order } });
      }

      yield put({ type: 'update', payload: { data: { ...alarmInfo, alarmRuleDesc, assignedType }, listLoading: false, flag } });
    },
    *closePanel (_, { put, call }) {
      yield put({ type: 'update', payload: { visible: false } });
      yield call(sleep, 200);
      yield put({ type: 'clear' });
    },
    *updateAlarmStatus ({ payload: { data, cb } }, { call, select, put }) {
      const { alarmRecordId } = data;
      const { alarmData: { stationId, level: alarmLevel }, serviceProvidorId } = yield select(state => ({
        alarmData: state.alarmStat.alarmList.filter(({ id }) => id === alarmRecordId)[0] || {},
        serviceProvidorId: state.user.companyCode,
      }));
      const map = {};
      map[alarmRecordId] = 2;
      const res = yield call(service.updateAlarmRecordDealStatus, { updateMap: map });
      if (responseSuccess(res)) {
        yield put({ type: 'update', payload: { data: { ...data, dealStatus: 2 } } });
        yield call(wsocket.publish, `alarmHandled_${serviceProvidorId}`, { alarmRecordId, stationId, alarmLevel, dealStatus: 2 });
      }
      if (cb) yield call(cb, res);
    },
  },
  subscriptions: {},
};
