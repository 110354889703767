import * as services from '@services/storage/alarm/history';
import moment from 'moment';

const defaultPage = 1;
const defaultPageSize = 30;
const defaultSelectedDate = [moment().subtract(30, 'days'), moment()];

const initialData = () => ({
  loading: true,
  cacheFilter: {
    selectedStation: [],
    selectedDevices: [],
    selectedLevelTypes: [],
    selectedDateStart: defaultSelectedDate[0],
    selectedDateEnd: defaultSelectedDate[1],
    page: defaultPage,
    pageSize: defaultPageSize,
  },
  list: [],
  hash: {},
  srcHash: {},
  totalCount: 0,
  deviceMap: {},
  recordDetail: {
    alarm: {},
    order: {},
  },
  page: defaultPage,
  pageSize: defaultPageSize,
});

export default {
  namespace: 'vAlarmHistory',
  state: initialData(),
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    clear () {
      return initialData();
    },
  },
  effects: {
    *fetch ({ payload: {
      selectedStation,
      selectedDevices,
      selectedLevelTypes,
      selectedDateStart,
      selectedDateEnd,
      page,
      pageSize,
      flagFromOther,
      noLoading,
      callback,
    } = {} }, { call, put, select }) {
      if (!noLoading) {
        yield put({
          type: 'update',
          payload: {
            loading: true,
          },
        });
      }

      if (flagFromOther) {
        yield put({
          type: 'fetchDevices',
          payload: {
            stationId: selectedStation[0],
          },
        });
      }

      // const stationMap = yield select(state => state.stations.byId);
      const cacheFilter = yield select(state => state.vAlarmHistory.cacheFilter);
      const filter = {
        selectedStation,
        selectedDevices,
        selectedLevelTypes,
        selectedDateStart,
        selectedDateEnd,
        page: +page,
        pageSize: +pageSize,
      };
      const req = {
        ...cacheFilter,
        ...Object.keys(filter).reduce((acc, item) => {
          if (filter[item]) acc[item] = filter[item];
          return acc;
        }, {}),
      };

      const [fres] = yield call(services.queryAlarmRecord, req);

      yield put({
        type: 'update',
        payload: {
          loading: false,
          cacheFilter: req,
          list: fres.list,
          hash: fres.hash,
          srcHash: fres.srcHash,
          totalCount: fres.totalCount,
          page,
          pageSize,
        },
      });

      if (typeof callback === 'function') {
        callback();
      }
    },

    *export ({ payload: {
      callback,
    } }, { call, select }) {
      const stationMap = yield select(state => state.stations.byId);
      const serviceProvidorName = yield select(state => state.user.serviceProvidorName);
      const cacheFilter = yield select(state => state.vAlarmHistory.cacheFilter);

      const [file, res] = yield call(services.queryAlarmRecordExportUrl, {
        serviceProvidorName,
        ...cacheFilter,
        selectedStation: cacheFilter.selectedStation.reduce((acc, stationId) => {
          const { shortName } = stationMap[stationId];
          acc[stationId] = shortName;
          return acc;
        }, {}),
      });

      if (file) {
        const a = document.createElement('a');
        a.href = file;
        a.download = `异常记录明细表-${moment().format('YYYYMMDD')}`;
        a.click();
      }

      if (typeof callback === 'function') {
        if (res.code === 'TooMuchData') {
          res.message = '超出1000条的历史纪录无法导出';
        }
        callback(res);
      }
    },

    *fetchDevices ({ payload: { stationId, callback } }, { call, put }) {
      yield put({
        type: 'update',
        payload: {
          deviceMap: {},
        },
      });

      if (!stationId) {
        return;
      }

      const [fres] = yield call(services.queryTopologyStructureTreeHash, {
        monitoredObjectId: stationId,
      });

      yield put({
        type: 'update',
        payload: {
          deviceMap: fres,
        },
      });

      if (typeof callback === 'function') {
        callback();
      }
    },

    *fetchDetail ({ payload: { recordId, callback } }, { call, put, select }) {
      const hash = yield select(state => state.vAlarmHistory.hash);
      const record = hash[recordId] || {};

      yield put({
        type: 'update',
        payload: {
          recordDetail: {
            alarm: {},
            order: {},
          },
        },
      });

      const [alarmRuleDesc] = yield call(services.queryAlarmRuleDesc, {
        ruleId: record.alarmRuleInstanceId,
        stationCode: record.stationCode,
      });
      const [order] = yield call(services.queryOrder, {
        recordId,
      });

      yield put({
        type: 'update',
        payload: {
          recordDetail: {
            alarm: {
              ...record,
              alarmRuleDesc,
            },
            order,
          },
        },
      });

      if (typeof callback === 'function') {
        callback();
      }
    },
  },
  subscriptions: {},
};
