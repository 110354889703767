import * as sellElectricService from '@/services/sellElectric';


export default {
  namespace: 'sellElectric',

  state: {
    stationInfo:{}
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    // 获取表格数据
    *getData({ payload: { callback, queryData} = {} }, {put,select, call }){
     const  res = yield call(sellElectricService.queryTransformerList, {...queryData});
      callback && callback(res)
    },
    // 获取售电树结构
    *getSellTreeNodeByStationId({ payload: { callback, queryData} = {} }, {put,select, call }){
      const  res = yield call(sellElectricService.querySellTreeNodeByStationId, {...queryData});
       callback && callback(res)
     },
     // 获取能源生产--统计报表树结构
     *queryInverterGridENV({ payload: { callback, queryData} = {} }, {put,select, call }){
      const  res = yield call(sellElectricService.queryInverterGridENV, {...queryData});
       callback && callback(res)
     },

    *setStationInfo({ payload: {queryData} = {} }, {put,select, call }){
      yield put({ type: 'update', payload: {stationInfo:queryData } });
     },
  },

  subscriptions: {
  },
};