import * as energyStationService from '@/services/energyStation';


export default {
  namespace: 'energyStation',

  state: {
    data: {
      dataSource:[]
    },
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    // 获取卡片信息
    *getData({ payload: { callback, queryData} = {} }, {put,select, call }){
     const  res = yield call(energyStationService.queryEnergyDeviceInfos, {...queryData});
      callback && callback(res)
    },
    // 详情
    *getDeviceDetail({ payload: { callback, queryData} = {} }, {put,select, call }){
      const  res = yield call(energyStationService.queryEnergyDeviceDetail, {...queryData});
       callback && callback(res)
     },
     *getDeviceList({ payload: { callback, queryData} = {} }, {put,select, call }){
      const  res = yield call(energyStationService.queryEnergyDeviceList, {...queryData});
       callback && callback(res)
     },
     *getDeviceStatus({ payload: { callback, queryData} = {} }, {put,select, call }){
      const  res = yield call(energyStationService.queryDeviceStatus, {...queryData});
       callback && callback(res)
     },
     *queryEnergyStationListByArea({ payload: { callback, queryData} = {} }, {put,select, call }){
      const  res = yield call(energyStationService.queryEnergyStationListByArea, {...queryData});
       callback && callback(res)
     },
     
  },

  subscriptions: {
  },
};