import { useState, useEffect } from 'react';
import { Tree } from 'antd';
import { Search } from '@ui';
import styles from './index.scss';
import TreeBradcrumb from './breadcrumb';

function getFormatTitle(title, searchValue) {
  if (typeof title !== 'string') return title;

  const index = title.indexOf(searchValue);

  if (!(index > -1 && searchValue)) return title;

  const beforeStr = title.substr(0, index);
  const afterStr = title.substr(index + searchValue.length);

  return (
    <span className={[styles.title]}>
      {beforeStr}
      <span className={[styles.match]}>{searchValue}</span>
      {afterStr}
    </span>
  );
}

function traverse(tree, searchValue) {
  return tree.reduce((acc, item) => {
    const formatTitle = getFormatTitle(item.title, searchValue);
    const isMatch = formatTitle !== item.title;
    if (isMatch) acc[2].push(item.key);

    const [children, expandedKeys, matchkeys] = Array.isArray(item.children) ? traverse(item.children, searchValue) : [[], [], []];

    if (
      isMatch ||
      (item.children?.length && matchkeys.length)
    ) {
      acc[0].push({
        ...item,
        title: formatTitle,
        ...(children.length ? { children } : {}),
      });
    }

    if (matchkeys.length) { acc[1].push(item.key); acc[2].push(...matchkeys); };
    if (expandedKeys.length) acc[1].push(...expandedKeys);

    return acc;
  }, [[], [], []]);
}



// 设计上 Tree 不区分处理 check 和 select 2种状态，只使用 select
// multiple，false 为单选，true 为多选。多选时显示 checkbox。
// checkable/onCheck would be ignore, use multiple/onSelect
function UITree(props) {
  const {
    className,
    showSearch = false,
    multiple = false,

    selectedKeys: selectedKeysProps,
    expandedKeys: expandedKeysProps,

    treeData: treeDataProps,

    onSelect: onSelectProps,
    iconInfo,
    ...rest
  } = props;
  const [keyTree, setkeyTree] = useState();
  const [keySearch, setkeySearch] = useState();
  const [treeData, settreeData] = useState();
  const [selectedKeys, setselectedKeys] = useState([]);
  const [expandedKeys, setexpandedKeys] = useState([]);
  const [searchValue, setsearchValue] = useState();

  const onSearch = _.debounce((v) => {
    setsearchValue(v);
  }, 300);

  function onSelect(v, ...args) {
    const selectedKeysIn = v.checked || v;
    let selectedKeysFixed = selectedKeysIn;
    if (!multiple && !selectedKeysIn.length) {  // 单选模式，取消点击同一节点会切换的效果（切换会导致 selectKeys 为空）
      selectedKeysFixed = selectedKeys;
    }

    if (selectedKeysProps === undefined) {
      setselectedKeys(selectedKeysFixed);
    }

    if (typeof onSelectProps === 'function') onSelectProps(selectedKeysFixed, ...args);
  }

  useEffect(() => {
    if (selectedKeysProps !== undefined) setselectedKeys(selectedKeysProps);
  }, [selectedKeysProps]);

  useEffect(() => {
    setkeySearch(_.uniqueId('key-search-'));
    setsearchValue(undefined);
  }, [treeDataProps]);

  useEffect(() => {
    if (!searchValue) {  // for defaultExpandAll
      setkeyTree(_.uniqueId('key-tree-'));
      settreeData(treeDataProps);
      setexpandedKeys(expandedKeysProps);
    } else {
      const [treeData, expandedKeys] = traverse(treeDataProps, searchValue);

      settreeData(treeData);
      setexpandedKeys(expandedKeys);
    }
  }, [searchValue, treeDataProps, expandedKeysProps]);

  return (
    <div className={[className, styles.wrap]}>
      {
        showSearch ?
          <Search key={keySearch} className={styles.search} onSearch={onSearch} /> :
          null
      }

      <div className={styles.content}>
        <Tree
          key={keyTree}
          className={styles.tree}
          treeData={treeData}

          checkStrictly={true}
          {...rest}

          selectedKeys={selectedKeys}
          checkedKeys={selectedKeys}
          {...(expandedKeys ? { expandedKeys } : {})}
          checkable={multiple}
          multiple={multiple}
          switcherIcon={<div />}
          showIcon={true}
          titleRender={(node)=>{
            return (
              <span style={{marginLeft:iconInfo ?16:0}}>
               {iconInfo && node.status && node.parentId ?<img src={iconInfo[node.status]} alt="Icon" style={{ marginRight: 8,width:12}} />:null }  
                {node.title}
              </span>
            );
          }}

          onCheck={onSelect}
          onSelect={onSelect}
        />
      </div>
    </div>
  );
};

UITree.Breadcrumb = TreeBradcrumb;

export default UITree;
