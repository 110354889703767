import React from 'react';

import p0 from './assets/0.png';
import p1 from './assets/1.png';
import p2 from './assets/2.png';
import p3 from './assets/3.png';
import p4 from './assets/4.png';
import p5 from './assets/5.png';
import p6 from './assets/6.png';
import p7 from './assets/7.png';

import styles from './index.scss';


export default (props) => {
  const {
    className,
    children,
    type,
    noTips,
  } = props;

  let i = '';
  const t = '暂无数据';
  switch (type) {
    default:
    case '0':
      i = p0;
      break;
    case '1':
      i = p1;
      break;
    case '2':
      i = p2;
      break;
    case '3':
      i = p3;
      break;
    case '4':
      i = p4;
      break;
    case '5':
      i = p5;
      break;
    case '6':
      i = p6;
      break;
    case '7':
      i = p7;
      break;
  }

  return (
    <div className={`${styles.wrap} ${className}  ${type === '5' ? styles.clacHeight : null}`} style={{ backgroundImage: `url(${i})` }}>{noTips ? '' : (children || t)}</div>
  );
};
