export default {
    namespace: 'global',

    state: {
        enterpriseData: {}
    },

    reducers: {
        update(state, { payload }) {
            return { ...state, ...payload };
        }
    },

    effects: {
        *enterpriseInfo({ payload: { data, callback } = {} }, { put, call }) {
            yield put({ type: 'update', payload: { enterpriseData: data } });
        }
    },

    subscriptions: {}
};

