import moment from 'moment';
import 'moment/locale/zh-cn';
import * as wsocket from '@/services/socketCluster';
import * as mqtt from '@/services/mqtt';
import 'normalize.css';
import 'font-awesome/less/font-awesome.less';
import './index.scss';

moment.locale('zh-cn');

export const dva = {
  config: {
    onError (err) {
      err.preventDefault();
      console.error(err.message);
    },
  },
};

// 启动websocket
// wsocket.init();
// 启动mqtt
// mqtt.init();
