import PropTypes from 'prop-types';
import { isUndefined, cloneDeep } from 'lodash';
import Radio from '../Radio';
import DatePicker, { MonthPicker } from './index';

import styles from './SwitchPicker.scss';

const dateProps = {
  showToday: false,
  allowClear: false,

};

class Index extends React.Component {
  static enumMap = {
    day: '日',
    month: '月',
    year: '年',
  }

  static enum = {
    day: 'day',
    month: 'month',
    year: 'year',
  }

  static propTypes = {
    className: PropTypes.string,
    types: PropTypes.arrayOf((propValue, key, componentName) => {
      if (!Index.enumMap[propValue[key]]) {
        return new Error(`${componentName}: types item should be one of SwitchPicker.enum`);
      }
    }),
    selectedType: PropTypes.oneOf(Object.values(Index.enum)),
    onChange: PropTypes.func,
  }

  static defaultProps = {
    className: null,
    types: [Index.enum.day, Index.enum.month],
    selectedType: null,
    onChange: () => { },
  }

  state = {
    value: moment(),
    dayStartValue: moment().subtract(30, 'days'),
    dayEndValue: moment(),
    monthStartValue: moment().subtract(11, 'months'),
    monthEndValue: moment(),
    selectedType: this.props.types ?.[0] || Index.enum.day,
  }

  componentDidMount() {
    const { value, dayStartValue, monthStartValue, selectedType } = this.state;
    this.tabsOnChange(selectedType, value, dayStartValue, monthStartValue);
  }

  componentDidUpdate(prevProp) {
    if (JSON.stringify(this.props.types) !== JSON.stringify(prevProp.types)) {
      const selectedType = this.props.types ?.[0] || Index.enum.day;
      const { value, dayStartValue, monthStartValue } = this.state;
      this.setState({ selectedType });
      this.tabsOnChange(selectedType, value, dayStartValue, monthStartValue);
    }
  }

  tabsOnChange = (selectedType, v1, v2, v3) => {
    if (selectedType === Index.enum.day) {
      this.onChange(v1, selectedType);
    } else if (selectedType === Index.enum.month) {
      this.onDayStartChange(v2, selectedType);
    } else if (selectedType === Index.enum.year) {
      this.onMonthStartChange(v3, selectedType);
    }
  }

  onChange = (value, selectedType, ...args) => {
    const { onChange } = this.props;

    onChange({
      startTime: cloneDeep(value).startOf('day').valueOf(),
      endTime: cloneDeep(value).endOf('day').valueOf(),
    }, selectedType, ...args);

    this.setState({ value, selectedType });
  }

  onDayStartChange = (value, selectedType, ...args) => {
    const { onChange } = this.props;
    const { dayEndValue } = this.state;
    const startTime = cloneDeep(value).startOf('day');
    let endTime = cloneDeep(dayEndValue).endOf('day');

    // 如果选择开始时间比结束时间小31天以上
    if (value < cloneDeep(dayEndValue).subtract(30, 'days').startOf('day')) {
      endTime = cloneDeep(value).add(30, 'days').endOf('day');
    }

    onChange({ startTime: startTime.valueOf(), endTime: endTime.valueOf() }, selectedType, ...args);

    this.setState({ dayStartValue: startTime, dayEndValue: endTime, selectedType });
  }

  onDayEndChange = (value, selectedType, ...args) => {
    const { onChange } = this.props;
    const { dayStartValue } = this.state;
    const endTime = cloneDeep(value).startOf('day');
    let startTime = cloneDeep(dayStartValue).endOf('day');

    // 如果选择开始时间比结束时间大31天以上
    if (value > cloneDeep(dayStartValue).add(30, 'days').endOf('day')) {
      startTime = cloneDeep(value).subtract(30, 'days').startOf('day');
    }

    onChange({ startTime: startTime.valueOf(), endTime: endTime.valueOf() }, selectedType, ...args);

    this.setState({ dayStartValue: startTime, dayEndValue: endTime, selectedType });
  }

  onMonthStartChange = (value, selectedType, ...args) => {
    const { onChange } = this.props;
    const { monthEndValue } = this.state;
    const startTime = cloneDeep(value).startOf('month');
    let endTime = cloneDeep(monthEndValue).endOf('month');

    // 如果选择开始时间比结束时间小31天以上
    if (value < cloneDeep(monthEndValue).subtract(35, 'months').startOf('month')) {
      endTime = cloneDeep(value).add(35, 'month').endOf('month');
    }

    onChange({ startTime: startTime.valueOf(), endTime: endTime.valueOf() }, selectedType, ...args);

    this.setState({ monthStartValue: startTime, monthEndValue: endTime, selectedType });
  }

  onMonthEndChange = (value, selectedType, ...args) => {
    const { onChange } = this.props;
    const { monthStartValue } = this.state;
    const endTime = cloneDeep(value).startOf('month');
    let startTime = cloneDeep(monthStartValue).endOf('month');

    // 如果选择开始时间比结束时间大31天以上
    if (value > cloneDeep(monthStartValue).add(35, 'months').endOf('month')) {
      startTime = cloneDeep(value).subtract(35, 'months').startOf('month');
    }

    onChange({ startTime: startTime.valueOf(), endTime: endTime.valueOf() }, selectedType, ...args);

    this.setState({ monthStartValue: startTime, monthEndValue: endTime, selectedType });
  }

  render() {
    const {
      className,
      style,
      value,
      types,
      selectedType,
      ...rest
    } = this.props;
    const { value: valueState, selectedType: selectedTypeState, dayStartValue, dayEndValue, monthStartValue, monthEndValue } = this.state;

    const selectedTypeRender = selectedType || selectedTypeState;
    const valueRender = (selectedTypeRender === Index.enum.day) && !isUndefined(value) && !Array.isArray(value) ? value : valueState;
    const dStartValue = (selectedTypeRender === Index.enum.month) && !isUndefined(value) && Array.isArray(value) && value[0] ? value[0] : dayStartValue;
    const dEndValue = (selectedTypeRender === Index.enum.month) && !isUndefined(value) && Array.isArray(value) && value[1] ? value[1] : dayEndValue;
    const mStartValue = (selectedTypeRender === Index.enum.year) && !isUndefined(value) && Array.isArray(value) && value[0] ? value[0] : monthStartValue;
    const mendValue = (selectedTypeRender === Index.enum.year) && !isUndefined(value) && Array.isArray(value) && value[1] ? value[1] : monthEndValue;

    return (
      <div className={[className, styles.wrap]} style={style}>
        {
          types.length > 1 ?
            <Radio.Group
              className={styles.switcher}
              value={selectedTypeRender}
              onChange={({ target: { value: v } }) => this.tabsOnChange(v, valueRender, dStartValue, mStartValue)}
            >
              {types.map(i => <Radio.Button key={i} tight value={i}>{Index.enumMap[i]}</Radio.Button>)}
            </Radio.Group> :
            null
        }
        {  /* eslint-disable no-unused-expressions, semi */
          do {
            if (selectedTypeRender === Index.enum.day) {
          <DatePicker
            {...dateProps}
            {...rest}
            value={valueRender}
            onChange={v => this.onChange(v, selectedTypeRender)}
            disabledDate={current => current && current.startOf('day').valueOf() > moment().endOf('day').valueOf()}
          />
        } else if (selectedTypeRender === Index.enum.month) {
          <>
            <DatePicker
              {...dateProps}
              {...rest}
              value={dStartValue}
              onChange={v => this.onDayStartChange(v, selectedTypeRender)}
              disabledDate={(startDate) => {
                const endDate = cloneDeep(dEndValue);
                return (startDate && endDate) && (
                  (startDate > moment().endOf('day'))
                  || (startDate > endDate.endOf('day'))
                );
              }}
            />
            <span className={styles.w20}>~</span>
            <DatePicker
              {...dateProps}
              {...rest}
              value={dEndValue}
              onChange={v => this.onDayEndChange(v, selectedTypeRender)}
              disabledDate={(endDate) => {
                const startDate = cloneDeep(dStartValue);
                return (startDate && endDate) && (
                  (endDate >= moment().startOf('day'))
                  || (endDate < startDate.startOf('day'))
                );
              }}
            />
          </>
        } else if (selectedTypeRender === Index.enum.year) {
          <>
            <MonthPicker
              {...dateProps}
              {...rest}
              value={mStartValue}
              onChange={v => this.onMonthStartChange(v, selectedTypeRender)}
              disabledDate={(startDate) => {
                const endDate = cloneDeep(mendValue);
                return (startDate && endDate) && (
                  (startDate > moment().endOf('month'))
                  || (startDate > endDate.endOf('month'))
                );
              }}
            />
            <span className={styles.w20}>~</span>
            <MonthPicker
              {...dateProps}
              {...rest}
              value={mendValue}
              onChange={v => this.onMonthEndChange(v, selectedTypeRender)}
              disabledDate={(endDate) => {
                const startDate = cloneDeep(mStartValue);
                return (startDate && endDate) && (
                  (endDate > moment().endOf('month'))
                  || (endDate < startDate.startOf('month'))
                );
              }}
            />
          </>
        } else {
          null
        }
        }  /* eslint-enable */
      }
      </div>
    );
  }
}

export default Index;
