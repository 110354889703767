import { getData } from '@/services/request';
/**
 * 售电相关接口
 */

/**
 * 查询计划电量
 * @param {
 *      stationId,
 *      year
 * } data
 */
export async function querySellPlanByStationAndTime(data) {
    const res = await getData('dayougroup', 'querySellPlanByStationAndTime', data);
    if (Array.isArray(res?.data)) return res.data;
    return [];
}

/**
 * 新增计划电量
 * @param {
 *      sellYearPlanDTOList: Array<{
 *          stationId,
 *          monthElectricity,
 *          planTime: '2023-01-01'
 *      }>
 * } data
 */
export async function insertSellPlan(data) {
    await getData('dayougroup', 'insertSellPlan', data);
}

/**
 * 更新计划电量
 * @param {
 *      sellYearPlanDTOList: Array<{ id, monthElectricity }>
 * } data
 */
export async function updateSellPlan(data) {
    await getData('dayougroup', 'updateSellPlan', data);
}

/**
 * 根据查询条件获取电站列表
 * @param {
 *    "enterpriseId": "",
 *    "provinceCode": "",
 *    "cityCodes": string[],
 * } data
 * @returns
 */
export async function querySellStationListByArea(data) {
    const res = await getData('dayougroup', 'querySellStationListByArea', data);
    if (res?.data) return res.data;
}

/**
 * 获取售电列表数据
 * @param {
 *    "stationIds": string[],
 *    "startTime": "2023-09-01 00:00:00",
 *    "endTime": "2023-10-30 23:59:59",
 *    "field": "",
 *    "sort": ""
 * } data
 * @returns
 */
export async function querySellStationInfoList(data) {
    const res = await getData('dayougroup', 'querySellStationInfoList', data);
    if (Array.isArray(res?.data)) return res.data;
    return [];
}

// 获取电站变压器列表
export async function queryTransformerList(data) {
    const res = await getData('dayougroup', 'queryStationAndDevice', data);
    return res?.data;
}

/**
 * 查询电能实时
 * @param {
 *   "enterpriseId":"",
 *   "stationIds":[],
 *   "deviceIds":[],
 *   "codes":[], //标签 code
 *   "startTime":"2023-10-25 00:00:00",
 *   "endTime":"2023-10-25 23:59:59"
 * } data
 * @returns
 */
export async function queryDataByDeviceIdsAndMetricCodes(data) {
    const res = await getData('dayougroup', 'queryDataByDeviceIdsAndMetricCodes', data);
    return Array.isArray(res?.data) ? res.data : [];
}

/**
 * 查询电量实时
 * @param {
 *   "deviceId":"",
 *   "startTime":"2023-10-25 00:00:00",
 *   "endTime":"2023-10-25 23:59:59",
 *   "type": 1: 时刻电量, 2: 累计电量
 * } data
 * @returns
 */
export async function queryElectricityForCurrent(data) {
    const res = await getData('dayougroup', 'queryElectricityForCurrent', data);
    return Array.isArray(res?.data) ? res.data : [];
}

export async function querySellTreeNodeByStationId(data) {
    const res = await getData('dayougroup', 'querySellTreeNodeByStationId', data);
    return Array.isArray(res?.data) ? res.data : [];
}

// 能源生产--统计报表
export async function queryInverterGridENV(data) {
    const res = await getData('dayougroup', 'queryInverterGridENV', data);
    return Array.isArray(res?.data) ? res.data : [];
}