import * as storage from '@/services/storage';

export async function fetchAlarmSettings({ accountId }) {
  const data = await storage.get(`alarmSettings.${accountId}`);
  if (!data) return await fetchDefaultAlarmSettings();
  return { code: null, data };
}

export async function setAlarmSettings({ accountId, data }) {
  await storage.set(`alarmSettings.${accountId}`, data);
  return { code: null };
}

export async function fetchDefaultAlarmSettings() {
  return await {
    code: null,
    data: {
      102004: { voice: true, popup: true, time: 30, audioType: 'alarm' },
      102003: { voice: true, popup: true, time: 15, audioType: 'ticktock' },
      102002: { voice: true, popup: false, time: 5, audioType: 'ticktick' },
      102001: { voice: false, popup: false, time: 0, audioType: '' },
    },
  };
}

