import * as services from '@api/energyWeb/DeviceManagement';

export default {
  namespace: 'vTransformerList',
  state: {
    loading: true,
    dataList: [],
    totalCount: 0,
    page: 1,
    length: 30,


    datas: [],
    dataHash: {},
    data: {},
    dataAudit: {},
    metricCodes: [
      '020F0001',
      {
        name: '温度',
        unit: '℃',
        dec: 2,
        metricCodes: [
          '0280000B', // A相
          '0280000C', // B相
          '0280000D', // C相
        ],
      },
    ], // ['负载率'，'温度' ]
    countObj: {}, // 变压器各数量
    chartList: [],
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    // 查询数据
    *fetch ({ payload }, { put, call }) {
      yield put({ type: 'update', payload: { loading: true } });
      const res = yield call(services.queryTransformList, { ...payload });
      const list = (res.dataList || []).map((item) => {
        const { totalRunTime, loadRateAbnormalSituation, temperatureAbnormalSituation } = item;
        const hour = moment.duration(totalRunTime).hours();
        const day = moment.duration(totalRunTime).days();
        const month = moment.duration(totalRunTime).months();
        const year = moment.duration(totalRunTime).years();

        return {
          ...item,
          totalRunTime: `${year}年${month}月${day}日${hour}小时`,
          abnormal: [loadRateAbnormalSituation, temperatureAbnormalSituation].filter(a => a).join(','),
        };
      });

      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList: list,
          page: payload.page,
          length: payload.length,
          totalCount: res.totalCount || 0,
        },
      });

      return res;
    },
    *fetchDetail ({ payload: { id } = {} }, { put, call }) {
      if (!id) return;
      const [fres] = yield call(services.fetchTransformById, { id });

      yield put({
        type: 'update',
        payload: {
          data: fres,
        },
      });

      return [fres];
    },
    *fetchChart ({ payload: { deviceId, metricCodes = [], startTime, endTime } }, { call, put, select }) {
      const metric = yield select(state => state.metricCode.data);

      yield put({
        type: 'update',
        payload: {
          chartList: [],
        },
      });

      const [points] = yield call(services.queryPoints, {
        deviceId,
        metricCodes,
      });

      const pointHash = points.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      const [chartList] = yield call(services.queryRawMetricDatum, {
        pointIds: points.map(i => i.id),
        startTime,
        endTime,
      });

      yield put({
        type: 'update',
        payload: {
          chartList: chartList.map((item) => {
            const { id: pointId, dataList = [] } = item;
            const pointItem = pointHash[pointId];
            const { metricCode, pointName, alias } = pointItem;
            const metricItem = metric[metricCode] || {};
            const { name, dec = 0 } = metricItem;

            return {
              ...pointItem,
              ...metricItem,
              name: name || pointName || alias,
              data: dataList.map((i) => {
                return [+i.t, (+i.v).toFixed(+dec)];
              }),
            };
          }),
        },
      });
    },
  },

  subscriptions: {
  },
};
