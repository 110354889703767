import { Badge } from 'antd';

import styles from './index.scss';

function Index({ className, text, color, ...props }) {
  return (
    <div className={[styles.ui, className].join(' ')}>
      <Badge color={color} text='' {...props} />
      <span className={[styles.text, text === '' ? styles.hide : null].join(' ')} style={{ color }}>{text}</span>
    </div>
  );
}

export default Index;
