import { getData } from '@/services/request';

export async function queryEnergyDeviceInfos(data) {
    // data.test =true;
    const res = await getData('dayougroup', 'queryEnergyDeviceNum', data);
    return res?.data;
}

export async function queryEnergyDeviceDetail(data) {
    // data.test =true;
    const res = await getData('assetManagement', 'queryDeviceByDeviceIdAndType', data);
    return res?.dataList;
}


export async function queryEnergyDeviceList(data) {
    // data.test =true
    data={"ownerId": data.id,"energyCategory":"500007","ownerType":27001,"platformType":"m0"}
    const res = await getData('assetManagement', 'queryTreeNodeByOwnerIdAndTypeAndEnergyCategory', data);
    return res?.dataList ||[];
}
// 设备运行状态
export async function queryDeviceStatus(data) {
    const res = await getData('dayougroup', 'queryEnergyCoolStatus', data);
    return res?.data ||{};
}

// 设备列表
export async function queryEquipmentTabs(data) {
    const res = await getData('metric', 'queryPointAndSensorByMonitoredObjectIdsAndCategory', data);
    return res?.dataList?.[0] ||{};
}
// 设备数据
export async function queryEquipmentData(data) {
    const res = await getData('metricDatum', 'queryMetricDatum', data);
    return res?.dataList ||{};
}
// 站点列表
export async function queryEnergyStationListByArea(data) {
    const res = await getData('dayougroup', 'queryEnergyStationListByArea', data);
    return res?.data ||{};
}







