import * as servers from '@services/storage/informationAnalyze';

const metricCodesD = [
  '02110133', // 日充电量
  '02110136', // 日放电量
  '0211015E', // 日充电电费
  '0211015F', // 日放电电费
  '020D5013', // 日收益
];
const metricCodesM = [
  '02110134', // 月充电量
  '02110137', // 月放电量
  '0211015A', // 月充电电费
  '0211015B', // 月放电电费
  '020D5014', // 月收益
];

export default {
  namespace: 'vInformationAnalyze',
  state: {
    loading: true,
    defaultValueType: 'day',

    treeData: [],
    treeDataHash: {},

    monthChange: '', // 本月充电电量
    monthUnChange: '', // 本月放电电量
    allChange: '', // 累计充电量
    allUnChange: '', // 累计放电量
    monthElectricity: '', // 本月充电电费
    monthUnElectricity: '', // 本月放电电费
    monthIncome: '', // 本月收益
    allElectricity: '', // 累计充电电费
    allUnElectricity: '', // 累计放电电费
    allIncome: '', // 累计收益

    dataListDayChange: [], // 日充电量列表
    dataListDayUnChange: [], // 日放电量列表
    dataListDayElectricity: [], // 日充电电费列表
    dataListDayUnElectricity: [], // 日放电电费列表

    dataListMonthChange: [], // 月充电量列表
    dataListMonthUnChange: [], // 月放电量列
    dataListMonthElectricity: [], // 月充电电费列表
    dataListMonthUnElectricity: [], // 月放电电费列表

    stationNum: '', // 电站数量
    totalInstalledPower: 0, // 装机容量
    totalCapacity: 0, // 装机功率

    dataList: [],
    page: 1,
    length: 30,
    totalCount: 1,
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 更新时间类型
    *updateDefaultValueType ({ payload: { defaultValueType } }, { put }) {
      yield put({
        type: 'update',
        payload: { defaultValueType },
      });
      return defaultValueType;
    },
    *queryTreeData ({ }, { call, put, select }) {
      const { stationList } = yield select(({ station, user }) => { // 获取左边设备树
        return {
          stationList: station.list,
        };
      });
      const stationListId = stationList.map((item) => item.stationId);
      try {
        const [treeData, treeDataHash] = yield call(servers.getAssetTreeByStationId, { stationId: stationListId.join(',') || (localStorage.getItem("ext") && JSON.parse(localStorage.getItem("ext")).ownerId) || (localStorage.getItem("stationInfo") && JSON.parse(localStorage.getItem("stationInfo")).storedEnergyStationId)});
     
        return yield put({
          type: 'update',
          payload: {
            treeData,
            treeDataHash,
          },
        }); 
      } catch (error) {
        
      }
    },
    *queryAllInfo ({ payload: { id, type } }, { call, put, select }) { // 获取电量&电费

      yield put({ type: 'update', payload: { defaultValueType: 'day' } });
      const { stationList } = yield select(({ station }) => {
        return {
          stationList: station?.list,
        };
      });
      const stationListId = [JSON.parse(localStorage.getItem("ext")).ownerId || JSON.parse(localStorage.getItem("stationInfo")).storedEnergyStationId];
      const requirdId = id[0] === 'all' ? stationListId : id;

      // 变压器： 40003

      // 储能室： 40021
      // 逆变器： 40022
      let isStation = '';
      (stationListId.indexOf(id[0]) > -1 || id[0] === 'all') ? isStation = true : isStation = false;
      // 累计充电量应该是02110100

      // 累计放电量应该是02110101
      const metricCodes = [ // 电站用
        '02110134', // 本月充电电量
        '02110137', // 本月放电电量
        '02110100', // 累计充电量
        '02110101', // 累计放电量

        '0211015A', // 本月充电电费
        '0211015B', // 本月放电电费
        '020D5014', // 本月收益
        '0211015C', // 累计充电电费
        '0211015D', // 累计放电电费
        '02110132', // 累计收益
      ];
      // 储能站点： 40012
      const metricCodes2 = [
        '02110134', // 本月充电电量
        '02110137', // 本月放电电量
        '02110100', // 累计充电量
        '02110101', // 累计放电量

        '0211015A', // 本月充电电费
        '0211015B', // 本月放电电费
        '020D5014', // 本月收益
        '0211015C', // 累计充电电费
        '0211015D', // 累计放电电费
        '02110132', // 累计收益
      ];
      // 并网点： 40027
      // 00010000充   00020000放    
      const metricCodes3 = [
        '02110134', // 本月充电电量
        '02110137', // 本月放电电量
        '00010000', // 累计充电量
        '00020000', // 累计放电量

        '0211015A', // 本月充电电费
        '0211015B', // 本月放电电费
        '020D5014', // 本月收益
        '0211015C', // 累计充电电费
        '0211015D', // 累计放电电费
        '02110132', // 累计收益
      ];


      const res = yield call(servers.queryLatestElectricityAndElectricityCharges, {
        metricCodes: isStation && (type === null || type === undefined) ?
          metricCodes.join(',') :
          (type === 40012 ? metricCodes2.join(',') : metricCodes3.join(',')),
        monitoredObjectIds: JSON.stringify(requirdId)
      });
      if (!res.code) {
        const dataHash = res.data;
        let allChange = dataHash['02110100']
        let allUnChange = dataHash['02110101']
        if (isStation && type === null) {

          allChange = dataHash['02110100']
          allUnChange = dataHash['02110101']
        } else if (!isStation && type === 40012) {
          allChange = dataHash['02110100'];
          allUnChange = dataHash['02110101'];
        } else if (!isStation && type === 40027) {
          allChange = dataHash['00010000'];
          allUnChange = dataHash['00020000'];
        }
        yield put({
          type: 'update',
          payload: {
            // loading: false,
            monthChange: typeof dataHash['02110134'] === 'number' ? (dataHash['02110134'] / 1000).toFixed(2) : '',
            monthUnChange: typeof dataHash['02110137'] === 'number' ? (dataHash['02110137'] / 1000).toFixed(2) : '',
            allChange: typeof allChange === 'number' ? (allChange / 1000).toFixed(2) : '',
            allUnChange: typeof allUnChange === 'number' ? (allUnChange / 1000).toFixed(2) : '',

            monthElectricity: typeof dataHash['0211015A'] === 'number' ? ((dataHash['0211015A'] || 0) / 10000).toFixed(2) : '',
            monthUnElectricity: typeof dataHash['0211015B'] === 'number' ? ((dataHash['0211015B'] || 0) / 10000).toFixed(2) : '',
            monthIncome: typeof dataHash['020D5014'] === 'number' ? ((dataHash['020D5014'] || 0) / 10000).toFixed(2) : '',
            allElectricity: typeof dataHash['0211015C'] === 'number' ? ((dataHash['0211015C'] || 0) / 10000).toFixed(2) : '',
            allUnElectricity: typeof dataHash['0211015D'] === 'number' ? ((dataHash['0211015D'] || 0) / 10000).toFixed(2) : '',
            allIncome: typeof dataHash['02110132'] === 'number' ? ((dataHash['02110132'] || 0) / 10000).toFixed(2) : '',
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            loading: false,
            monthChange: '',
            monthUnChange: '',
            allChange: '',
            allUnChange: '',
            monthElectricity: '',
            monthUnElectricity: '',
            monthIncome: '',
            allElectricity: '',
            allUnElectricity: '',
            allIncome: '',
          },
        });
      }
    },
    *queryChartData ({ payload: { id, startTime, endTime, defaultValueType } }, { call, put, select }) { // 电量分析&电费分析
      yield put({
        type: 'update',
        payload: {
          loading: true,
          dataListDayChange: [],
          dataListDayUnChange: [],
          dataListDayElectricity: [],
          dataListDayUnElectricity: [],

          dataListMonthChange: [],
          dataListMonthUnChange: [],
          dataListMonthElectricity: [],
          dataListMonthUnElectricity: [],
        },
      });
      // const { stationList } = yield select(({ station }) => {
      //   return {
      //     stationList: station?.list,
      //   };
      // });
      const stationListId = [JSON.parse(localStorage.getItem("ext")).ownerId || JSON.parse(localStorage.getItem("stationInfo")).storedEnergyStationId];
      const requirdId = id[0] === 'all' ? stationListId : id;
      const metricCodes = [
        '02110133', // 日充电量
        '02110136', // 日放电量
        '0211015E', // 日充电电费
        '0211015F', // 日放电电费
      ];
      const metricCodesM = [
        '02110134', // 月充电量
        '02110137', // 月放电量
        '0211015A', // 月充电电费
        '0211015B', // 月放电电费
      ];
      const metric = defaultValueType === 'day' ? metricCodes : metricCodesM;
      // console.log(metric, requirdId, startTime, endTime);
      const fres = yield call(servers.queryElectricityAndElectricityCharges,
        {
          defaultValueType,
          startTime,
          endTime,
          metricCodes: metric.join(','),
          monitoredObjectIds: JSON.stringify(requirdId),
        });
      if (defaultValueType === 'day' && Object.keys(fres).length > 0) {
        return yield put({
          type: 'update',
          payload: {
            loading: false,
            dataListDayChange: fres['02110133'] || [], // 日充电量列表
            dataListDayUnChange: fres['02110136'] || [], // 日放电量列表
            dataListDayElectricity: fres['0211015E'] || [], // 日充电电费列表
            dataListDayUnElectricity: fres['0211015F'] || [], // 日放电电费列表
          },
        });
      }

      if (defaultValueType === 'month' && Object.keys(fres).length > 0) {
        return yield put({
          type: 'update',
          payload: {
            loading: false,
            dataListMonthChange: fres['02110134'] || [], // 月充电量列表
            dataListMonthUnChange: fres['02110137'] || [], // 月放电量列
            dataListMonthElectricity: fres['0211015A'] || [], // 月充电电费列表
            dataListMonthUnElectricity: fres['0211015B'] || [], // 月放电电费列表
          },
        });
      }
      yield put({ type: 'update', payload: { loading: false } });
    },
    *queryReportList ({ payload: { id, page, length, startTime, endTime, defaultValueType } }, { call, put, select }) { // 数据报表
      yield put({
        type: 'update',
        payload: {
          loading: true,
          page,
          length,
        },
      });
      const { stationList, stationHash, treeData, treeDataHash } = yield select(({ station, vInformationAnalyze }) => {
        return {
          stationList: station.list,
          stationHash: station.hash,
          treeData: vInformationAnalyze.treeData,
          treeDataHash: vInformationAnalyze.treeDataHash,
        };
      });
      const stationListId = JSON.parse(localStorage.getItem("ext")).ownerId || JSON.parse(localStorage.getItem("stationInfo")).storedEnergyStationId;
      const requirdId = id[0] === 'all' ? [stationListId] : id;
      const metric = defaultValueType === 'day' ? metricCodesD : metricCodesM;
      const stationNum = id[0] === 'all' ? requirdId.length : false; // 电站数量
      let totalInstalledPower = 0; // 装机容量
      let totalCapacity = 0; // 装机功率
      let patentId = '';
      treeData.forEach((station) => {
        return (station.children).forEach((item) => {
          if (item.key === id[0]) return patentId = station.key;
        });
      });

      if (id[0] === 'all') {
        const { stationInfo } = yield select(({ stationMonitor, }) => {
    
          return {
            stationInfo: stationMonitor.stationInfo
          }
        });
        const stationItme = stationInfo[id] || stationHash[patentId];
        totalCapacity += stationInfo.capacity || 0;
        totalInstalledPower += stationInfo.installedPower || 0;

      } else {
        [{ installedPower: totalInstalledPower, capacity: totalCapacity }] = yield call(servers.getCapacityAndPower,
          { id: id[0], type: treeDataHash[id[0]].displayType });
      }

      // console.log(id, page, length, startTime, endTime, defaultValueType);
      const dataList = yield call(servers.queryElectricityChargesTable, {
        startTime,
        endTime,
        monitoredObjectIds: JSON.stringify(requirdId),
        metricCodes: metric.join(','),
      });
      const listArr = _.chunk(dataList, length);
      yield put({
        type: 'update',
        payload: {
          stationNum,
          totalCapacity: totalCapacity.toFixed(2),
          totalInstalledPower,
          dataList: listArr[page - 1],
          totalCount: dataList.length,
          loading: false,
        },
      });
    },
    *exportReport ({ payload: { id, defaultValueType, startTime, endTime }, cb = () => { } }, { select, call }) {
      const { name, stationList, treeDataHash } = yield select(({ station, user, vInformationAnalyze }) => {
        return {
          stationList: station.list,
          name: user.serverInfo.name,
          treeDataHash: vInformationAnalyze.treeDataHash,
        };
      });
      const stationListId = JSON.parse(localStorage.getItem("ext")).ownerId || JSON.parse(localStorage.getItem("stationInfo")).storedEnergyStationId;
      const metric = defaultValueType === 'day' ? metricCodesD : metricCodesM;
      const requirdId = id[0] === 'all' ? stationListId : id;
      const facility = treeDataHash[id[0]];
      const data = yield call(servers.exportReport, {
        id,
        facility,
        startTime,
        endTime,
        name,
        defaultValueType,
        monitoredObjectIds: JSON.stringify(requirdId),
        metricCodes: metric.join(','),
      });
      cb(data);
    },
    *chageDefaultValueType ({ payload: { defaultValueType } }, { put }) {
      yield put({
        type: 'update',
        payload: {
          defaultValueType,
          page: 1,
          length: 30,
          totalCount: 1,
        },
      });
    },
  },
};
