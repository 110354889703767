import { startdateFormat, enddateFormat } from '@lib/iem-TimeRange';

// 接口路径配置
export const REQUEST_PATH = {
    // 储能
    storage: {
        tableDataUrl: 'queryStoredStationsList',
        statisticDataUrl: 'queryStoredStationStatistics',
        stationListUrl: 'queryStoredStationsByArea',
        exportUrl: 'exportStoredStationsList'
    },
    // 交换电
    exchangeElectricity: {
        tableDataUrl: 'queryChargeStationsList', //表格接口
        statisticDataUrl: 'queryChargeStationsStatistics', // 卡片统计数据
        stationListUrl: 'queryChargeStationsByArea', // 电站列表
        chargeTimes: 'queryChargeNumsList', //充电次数
        chargeDuration: 'queryChargeTimesList', // 充电时长
        exportUrl: 'exportChargeStationsList'
    },
    //发电
    analyse: {
        stationListUrl: 'queryStationListByArea',
        fetchDataUrl: 'queryStationInfoList',
        exportUrl: 'exportStationInfoList'
    },
    // 售电
    sellElectric: {
        stationListUrl: 'querySellStationListByArea',
        statisticDataUrl: 'queryTotalByArea',
        tableDataUrl: 'querySellStationInfoList',
        exportUrl: 'exportSellStationInfoList'
    }
};

export const LAEBL_NAME = {
    // 售电
    sellElectric: {
        stationLabel: '客户名称'
    }
};

export const TIME_RANGE_CONFIG = {
    sellElectric: {
        postPicker: 'month',
        isString: true,
        types: ['month']
    }
};

export const MOMENT_CONFIG = {
    analyse: {
        startTimeFormat: startdateFormat,
        endTimeFormat: enddateFormat
    },
    sellElectric: {
        dateType: 'month',
        startTimeFormat: startdateFormat,
        endTimeFormat: enddateFormat
    }
};
