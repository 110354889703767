import * as services from '@services/storage/elecScheme';
import dictionary from '@/utils/dictionary';
// import historyList from '../../../pages/n-flexible-storage/battery/strategy/chargeOrDischarge/historyList';

const { INITALTABLEVALUE } = dictionary;

export default {
  namespace: 'velecScheme',
  state: {
    capacity: 1,
    loading: true,
    size: 'default',
    tip: '',
    schemeList: [{
      effectiveTime: '',
    }, {
      effectiveTime: '',
    }, {
      effectiveTime: '',
    }],
    schemeType1: {
      effectiveTime: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
      strategyId: '',
      strategyName: '策略一',
      schemes: [{
        schemeName: '充放电周期一',
        schemeId: '',
        months: '1,2,3,4,5,6,7,8,9,10,11,12',
        myId: Math.random().toString(36).substr(2),
        segments: INITALTABLEVALUE,
      }],
    },
    schemeType2: {
      effectiveTime: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
      strategyId: '',
      strategyName: '策略二',
      schemes: [{
        schemeName: '周期一',
        schemeId: '',
        months: '1,2,3,4,5,6,7,8,9,10,11,12',
        myId: Math.random().toString(36).substr(2),
        segments: INITALTABLEVALUE,
      }],
    },
    schemeType3: {
      effectiveTime: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
      strategyId: '',
      strategyName: '策略三',
      schemes: [{
        schemeName: '周期一',
        schemeId: '',
        months: '1,2,3,4,5,6,7,8,9,10,11,12',
        myId: Math.random().toString(36).substr(2),
        segments: INITALTABLEVALUE,
      }],
    },
    strategyDetail: {},
    protection: {},
    historyData: {},
  },
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *strategyInfo({ payload: { stationId } }, { call, put }) {
      const protection = yield call(services.getProtectionParamByStationId, { stationId });
      const strategyDetail = yield call(services.queryDtuStrategyDetail, { stationId });

      yield put({ type: 'update', payload: { loading: false } });
      if (strategyDetail) {
        yield put({
          type: 'update',
          payload: {
            strategyDetail,
            protection
          },
        });
      }
    },
    *historyList({ payload: { param }, callBack = () => { } }, { call, put }) {
      const historyData = yield call(services.pageStrategyHistory, { ...param });
      yield put({ type: 'update', payload: { loading: false } });
      if (historyData) {
        yield put({
          type: 'update',
          payload: {
            historyData
          },
        });
      }
      return callBack(historyData);
    },
    *deleteStrategyHistoryDetail({ payload: { recordId }, callBack = () => { } }, { call, put }) {
      const response = yield call(services.deleteStrategyHistoryDetail, { recordId });
      return callBack(response);
    },
    *saveStrategyParams({ payload: { params }, callBack = () => { } }, { call, put }) {
      const response = yield call(services.saveStrategyParams, { ...params });
      return callBack(response);
    },
    *queryFeeByStationId({ payload: { params }, callBack = () => { } }, { call, put }) {
      const response = yield call(services.queryFeeByStationId, { ...params });
      return callBack(response);
    },
    *queryStrategyHistoryDetail({ payload: { recordId }, callBack = () => { } }, { call, put }) {
      const strategyDetail = yield call(services.queryStrategyHistoryDetail, { recordId });
      yield put({ type: 'update', payload: { loading: false } });
      if (strategyDetail) {
        yield put({
          type: 'update',
          payload: {
            strategyDetail,
          },
        });
      }
      return callBack(strategyDetail);
    },
    *updateStrategyDetail({ payload: { params }, callBack = () => { } }, { call, put }) {
      const response = yield call(services.updateStrategyDetail, { ...params });
      return callBack(response);
    },
    *querySchemeInfo({ payload: { stationId } }, { call, put }) {
      const { schemeList, strategyIds } = yield call(services.queryChargeDischargeStrategyOverviewByStationId, { stationId });
      const respon = yield call(services.getStationInfoById, { stationId });
      const schemeType = yield call(services.queryChargeDischargeStrategyByStrategyIds, { strategyIds: JSON.stringify(strategyIds) });
      yield put({
        type: 'update',
        payload: { loading: false, size: 'default', tip: '' },
      });
      const obj = {
        capacity: (respon.capacity && respon.capacity * 1000) || 1,
        schemeList,
      };
      if (schemeType[0]) obj.schemeType1 = { status: (schemeList[0] || {}).status, ...schemeType[0] };
      if (schemeType[1]) obj.schemeType2 = { status: (schemeList[1] || {}).status, ...schemeType[1] };
      if (schemeType[2]) obj.schemeType3 = { status: (schemeList[2] || {}).status, ...schemeType[2] };

  
      yield put({
        type: 'update',
        payload: {
          loading: false,
          size: 'default',
          tip: '',
          ...obj,
        },
      });
    },
    *updateOrcreatScheme({ payload, callBack = () => { } }, { call, put }) {
      yield put({
        type: 'update',
        payload: { loading: true, size: 'large', tip: '策略下发中，请稍后...' },
      });
      if (payload.status === 2) { // 未启用，跟新
        const response = yield call(services.updateChargeDischargeStrategy, { ...payload });
        if (response) yield put({ type: 'update', payload: { loading: false } });
        return callBack(response);
      } else { // 过期新建
        const response = yield call(services.createChargeDischargeStrategy, { ...payload });
        if (response) yield put({ type: 'update', payload: { loading: false } });
        return callBack(response);
      }
    },
    *resertSchemeType({ payload: schemeType }, { put }) {
      // console.log(schemeType)
      yield put({
        type: 'update',
        payload: {
          capacity: 1,
          loading: true,
          size: 'default',
          tip: '',
          schemeList: [{
            effectiveTime: '',
          }, {
            effectiveTime: '',
          }, {
            effectiveTime: '',
          }],
          schemeType1: {
            effectiveTime: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
            strategyId: '',
            strategyName: '策略一',
            schemes: [{
              schemeName: '周期一',
              schemeId: '',
              months: '1,2,3,4,5,6,7,8,9,10,11,12',
              myId: Math.random().toString(36).substr(2),
              segments: INITALTABLEVALUE,
            }],
          },
          schemeType2: {
            effectiveTime: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
            strategyId: '',
            strategyName: '策略二',
            schemes: [{
              schemeName: '周期一',
              schemeId: '',
              months: '1,2,3,4,5,6,7,8,9,10,11,12',
              myId: Math.random().toString(36).substr(2),
              segments: INITALTABLEVALUE,
            }],
          },
          schemeType3: {
            effectiveTime: moment().add(1, 'days').format('YYYY-MM-DD HH:mm'),
            strategyId: '',
            strategyName: '策略三',
            schemes: [{
              schemeName: '周期一',
              schemeId: '',
              months: '1,2,3,4,5,6,7,8,9,10,11,12',
              myId: Math.random().toString(36).substr(2),
              segments: INITALTABLEVALUE,
            }],
          },
        },
      });
    },
  },
};
