import React from 'react';
import ReactDOM from 'react-dom';
import CustomerPickerModal from './CustomerPickerModal';

const instances = {};

const modalKey = 'globalOnlyCustomerPickerModalKey';

function newInstance(properties) {
  return new Promise((resolve) => {
    const { getContainer, ...props } = properties || {};
    const div = document.createElement('div');
    if (getContainer) {
      const root = getContainer();
      root.appendChild(div);
    } else {
      document.body.appendChild(div);
    }
    let called = false;
    function ref(modalIns) {
      if (called) {
        return;
      }
      called = true;
      resolve({
        async show(...insProps) {
          return await modalIns.show(...insProps);
        },
        component: modalIns,
        destroy() {
          ReactDOM.unmountComponentAtNode(div);
          div.parentNode.removeChild(div);
        },
      });
    }
    ReactDOM.render(<CustomerPickerModal {...props} ref={ref} />, div);
  });
}

function getInstance(key) {
  return new Promise(async (resolve) => {
    const existedIns = instances[key];
    if (existedIns) {
      resolve(existedIns);
      return;
    }
    const ins = await newInstance();
    resolve(ins);
    instances[key] = ins;
  });
}

export const choose = async (...args) => {
  const ins = await getInstance(modalKey);
  return await ins.show(...args);
};
