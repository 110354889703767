import { readProp } from '@/utils/tool';
import * as storage from '@/services/storage';
import { getData, getDataParti } from '@/services/request';

export const LoginType = {
  PASS: 'password',
  IDCODE: 'smscode',
};
/**
 * 验证码使用场景枚举
 */
export const VerifySceneType = {
  // 注册
  REGISTER_ACCOUNT: '01',
  // 登录
  LOGIN_ACCOUNT: '02',
  // 修改密码
  RESET_PASSWORD: '03',
  // 手机号或邮箱绑定认证
  AUTH_PHONE_OR_EMAIL: '04',
  // 密码找回
  RETRIEVE_PASSWORD: '05',
};

/**
 * 登录接口
 * @param {string} username
 * @param {string} password
 * @param {LoginType} verifyType
 * @param {number} exp
 */
export async function login (username, password, verifyType = LoginType.PASS, exp = 1) {
  const res = await getData('user', 'verify', { username, password, verifyType, exp });
  if (res.code) return res;
  const userOf = readProp(res.data);
  storage.set('token', userOf('token'));

  const userInfo = await fetchEntireUserInfo(userOf('accountId'));
  if (!userInfo.name) {
    storage.remove('token');
  }

  return {
    ...res,
    data: {
      ...res.data,
      ...userInfo,
    },
  };
}
/**
 * 查询用户相关的企业列表
 * @param {string} accountId 账号ID
 */
export const getEnterpriseByAccountId = getDataParti('applicationPlatform', 'getEnterpriseByAccountId');
/**
 * 查询账户名称
 * @param {string} accountId 账号ID
 */
export const queryPersonalData = getDataParti('applicationPlatform', 'queryPersonalData', accountId => ({ accountId }));
/**
 * 查询雇员信息
 * @param {Array} accountIds
 */
export const queryEmployeesByAccountIds = getDataParti('customerManagement', 'queryEmployeesByAccountIds');
/**
 * 获取账号完整的信息
 * @param {string} accountId
 */
export async function fetchEntireUserInfo (accountId) {
  const userInfoRes = await queryPersonalData(accountId);
  const userOf = readProp(userInfoRes.data);
  // const res2 = await queryEmployeesByAccountIds({ accountIds: [accountId] });
  // const userInfo = readProp(res2)('dataList.0', {});
  // 这里需要重写为具有id, name字段的对象
  const res3 = await getEnterpriseByAccountId({ accountId });
  const epList = readProp(res3)('dataList', [])
    .map(({ id, enterpriseName: name, enterpriseTaxNumber: taxNumber, enterpriseType: type, enterpriseLogoImg: logo }) => ({ id, name, taxNumber, type, logo }));
  return {
    ...(userInfoRes.data || {}),
    hasPassword: userOf('hasPassword') === 1,
    phoneValidated: userOf('phoneValidated') === 1,
    emailValidated: userOf('emailValidated') === 1,
    // ...pick(userInfo, ['cellphone', 'name']),
    enterpriseList: epList,
  };
}
/**
 * 根据本地token查询用户信息
 */
export const getUserInfoByToken = getDataParti('user', 'getUserInfoByToken');
/**
 * 验证码校验
 * @param {VerifySceneType} sendScene 使用场景 枚举值: '01'-注册  '02'-登录 '03'-修改密码
 * @param {string} channelCode
 * @param {string} verificationCode
 */
export const verificateCode = getDataParti('customerCenter', 'verificateCode', (sendScene, channelCode, verificationCode) => ({ verificationCode, sendScene, channelCode }));
/**
 * 验证手机号是否已使用
 * @param {string} 手机号码
 */
export const checkPhoneIsUsed = getDataParti('customerCenter', 'validateAccountPhone', phone => ({ phone }));
/**
 * 校验邮箱是否已被使用
 * @param {string} 邮箱号码
 */
export const checkEmailIsUsed = getDataParti('customerCenter', 'validateAccountEmail', email => ({ email }));

/**
 * 发送验证码
 * @param {VerifySceneType} sendScene 使用场景 枚举值: '01'-注册  '02'-登录 '03'-修改密码
 * @param {string} channelCode 手机号
 * @param {string} sendChannel 枚举值 '00': 手机， '01'： 邮箱
 */
export const sendVerificationCode = getDataParti('customerCenter', 'sendVerificationCode', (sendScene, channelCode, sendChannel = '00') => ({ channelCode, sendScene, sendChannel }));
/**
 * 账号注册
 */
export const register = getDataParti(
  'customerCenter',
  'register',
  (verificationCode, userDto) => ({ verificationCode, userDto }),
);
/**
 * 根据手机号，验证码修改密码
 * @param {VerifySceneType} sendScene 发送场景
 * @param {string} channelCode 手机号
 * @param {string} verificationCode 验证码
 * @param {string} newPassword 新密码
 */
export const changeAccountPasswordByVerificationCode = getDataParti(
  'customerCenter',
  'changeAccountPasswordByVerificationCodeWithoutUsername',
  (sendScene, channelCode, verificationCode, newPassword) => ({ sendScene, channelCode, verificationCode, newPassword }),
);
/**
 * 手机号/邮箱绑定认证
 * @param {string} channelCode 手机号
 * @param {string} verificationCode 验证码
 * @param {string} sendChannel 枚举值 '00': 手机， '01'： 邮箱
 */
export const authAccountPhoneOrEmail = getDataParti(
  'customerCenter',
  'authAccountPhoneOrEmail',
  (channelCode, verificationCode, sendChannel = '00') => ({ channelCode, verificationCode, sendChannel }),
);

/**
 * 个人信息编辑接口
 * @param {string} opt.userId 用户id
 * @param {string} opt.name 姓名
 * @param {string} opt.sex 性别
 * @param {string} opt.wechat 微信号
 * @param {string} opt.telephone 固定电话
 */
export const editUser = getDataParti(
  'applicationPlatform',
  'editUser',
);

export const logout = async () => {
  if (storage.get('token')) {
    return await getData('user', 'logout');
  }
  return { code: null };
};
