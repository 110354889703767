
// export default Index;
import { Input as OriginInput } from 'antd';
import Input from './Input';
import SearchComponent from './Search';

Input.TextArea = OriginInput.TextArea;
Input.Search = SearchComponent;

export default Input;

export const Search = SearchComponent;
