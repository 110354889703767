const initialData = () => ({});

export default {
  namespace: 'vAlarmUnhandle',
  state: initialData(),
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *query({ payload: { ...args } }, { call }) {

    },
  },
  subscriptions: {},
};
