import { getData } from '@services/request';

// 异常告警 - 查询列表
export async function queryList ({ stationId, alarmLevel, abnormalStatus, handleStatus, startTime, endTime, page, length }) {
  let fres = [];
  let totalCount = 0;
  const res = await getData('storedEnergy', 'pageQueryEnergyAlarmRecord', {
    stationIds: JSON.stringify([JSON.parse(localStorage.getItem("ext")).ownerId || JSON.parse(localStorage.getItem("stationInfo")).storedEnergyStationId,]),
    infoCategories: JSON.stringify(alarmLevel),
    alarmStatuses: abnormalStatus === '' ? null : JSON.stringify([abnormalStatus]),
    dealStatuses: handleStatus.length ? JSON.stringify(handleStatus) : null,
    startTime,
    endTime,
    page,
    length,
  });

  if (res.code === null) {
    fres = (res.dataList || []).map((item, idx) => {
      return {
        ...item,
        key: idx,
        id: item.alarmRecordId,
        rowNum: idx + 1,
        shortName: item.stationName,
        equipmentName: item.monitoredObjectName,
        alarmLevel: item.infoCategory,
        alarmCon: item.alarmRuleName,
        handleStatus: item.dealStatus,
        abnormalStatus: item.alarmStatus,
        recoveryTime: item.recoverTime,
        optName: item.operatorName,
        remark: item.comment,
      };
    });
    totalCount = res.totalCount;
  }

  return [fres, totalCount, res];
}

export async function saveStatusAndRemark ({ id, handleStatus, remark, dealTime, operatorId, operatorName }) {
  return await getData('storedEnergy', 'updateEnergyAlarmReocrdStatusById', {
    alarmRecordId: id,
    dealStatus: handleStatus,
    comment: remark,
    dealTime,
    operatorId,
    operatorName,
  });
}

// 更新备注
export async function saveRemark ({ id, remark }) {
  return await getData('storedEnergy', 'updateEnergyAlarmReocrdCommentById', { alarmRecordId: id, comment: remark });
}
