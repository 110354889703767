import { dicFromMap } from '@/utils/dicGenerator.js';
import * as _customer from './customer';

// 客户相关常量集合
export const customer = _customer;

export const AssignedTypeEnum = {
  sign: '0', // 遥信
  measure: '1' // 遥测
};
export const AssignedType = {
  [AssignedTypeEnum.sign]: { name: '遥信', desc: '遥信告警规则' },
  [AssignedTypeEnum.measure]: { name: '遥测', desc: '遥测告警规则' }
};
export const AssignedTypeDic = dicFromMap(AssignedType);

export const OwnerTypeEnum = {
  platForm: '0',
  serviceProvider: '1',
  station: '2'
};
export const OwnerType = {
  [OwnerTypeEnum.platForm]: {
    name: '平台规则库',
    iconHref: '#icon-yunnengyuan',
    iconColor: '#F08300'
  },
  [OwnerTypeEnum.serviceProvider]: {
    name: '我的规则库',
    iconHref: '#icon-fuwushangguanli',
    iconColor: '#6E90FF'
  },
  [OwnerTypeEnum.station]: {
    name: '站点规则',
    iconHref: '#icon-zhandianjieru',
    iconColor: '#666666'
  }
};
export const OwnerTypeDic = dicFromMap(OwnerType);

export const RuleCategoryEnum = {
  event: '102001',
  warn: '102002',
  alarm: '102003',
  fatal: '102004',
  disConnect: '102005'
};
export const RuleCategory = {
  [RuleCategoryEnum.event]: {
    name: '事件',
    color: '#ccd304'
  },
  [RuleCategoryEnum.warn]: {
    name: '预警',
    color: '#EC6CFF'
  },
  [RuleCategoryEnum.alarm]: {
    name: '告警',
    color: '#FEC929'
  },
  [RuleCategoryEnum.fatal]: {
    name: '故障',
    color: '#F75348'
  },
  [RuleCategoryEnum.disConnect]: {
    name: '中断',
    color: '#999999'
  }
};
export const RuleCategoryDic = dicFromMap(RuleCategory);

export const EvaluateUnitEnum = {
  time: '0',
  count: '1'
};
export const EvaluateUnit = {
  [EvaluateUnitEnum.time]: {
    name: '时间'
  },
  [EvaluateUnitEnum.count]: {
    name: '次数'
  }
};
export const EvaluateUnitDic = dicFromMap(EvaluateUnit);

export const RuleTypeEnum = {
  // 断相103001
  // 失电103002
  // 低电压103003
  // 过电压103004
  // 过载103005
  // 重载103006
  // 轻载103007
  // 需量超容103008
  // 功率因数异常103009
  // 温度异常103010
  // 湿度异常103011
  // 电压谐波总畸变率越限103012
  // 电压谐波含有率越限103013
  // 剩余电流越限103014
  // 过电流103015
  // 电流三相不平衡103016
  // 电压三相不平衡103017
  duanxiang: '103001',
  shidian: '103002',
  didianya: '103003',
  guodianya: '103004',
  guozai: '103005',
  zhongzai: '103006',
  qingzai: '103007',
  xuliangchaorong: '103008',
  gonglvyinshuyichang: '103009',
  wenduyichang: '103010',
  shiduyichang: '103011',
  dianyxiebozongjibianlvyuexian: '103012',
  dianyaxiebozonghanyoulvyuexian: '103013',
  shengyudianliuyuexian: '103014',
  guodianliu: '103015',
  dianyliusanxiangbupingheng: '103016',
  dianyasanxiangbupingheng: '103017'
};

export const RuleType = {
  [RuleTypeEnum.duanxiang]: {
    name: '断相'
  },
  [RuleTypeEnum.shidian]: {
    name: '失电'
  },
  [RuleTypeEnum.didianya]: {
    name: '低电压'
  },
  [RuleTypeEnum.guodianya]: {
    name: '过电压'
  },
  [RuleTypeEnum.guozai]: {
    name: '过载'
  },
  [RuleTypeEnum.zhongzai]: {
    name: '重载'
  },
  [RuleTypeEnum.qingzai]: {
    name: '轻载'
  },
  [RuleTypeEnum.xuliangchaorong]: {
    name: '需量超容'
  },
  [RuleTypeEnum.gonglvyinshuyichang]: {
    name: '功率因数异常'
  },
  [RuleTypeEnum.wenduyichang]: {
    name: '温度异常'
  },
  [RuleTypeEnum.shiduyichang]: {
    name: '湿度异常'
  },
  [RuleTypeEnum.dianyxiebozongjibianlvyuexian]: {
    name: '电压谐波总畸变率越限'
  },
  [RuleTypeEnum.dianyaxiebozonghanyoulvyuexian]: {
    name: '电压谐波含有率越限'
  },
  [RuleTypeEnum.shengyudianliuyuexian]: {
    name: '剩余电流越限'
  },
  [RuleTypeEnum.guodianliu]: {
    name: '过电流'
  },
  [RuleTypeEnum.dianyliusanxiangbupingheng]: {
    name: '电流三相不平衡'
  },
  [RuleTypeEnum.dianyasanxiangbupingheng]: {
    name: '电压三相不平衡'
  }
};
export const RuleTypeDic = dicFromMap(RuleType);

export const OperatorEnum = {
  add: '0',
  subtract: '1',
  multiply: '2',
  divide: '3',
  equal: '4',
  notEqual: '5'
};
export const Operator = {
  [OperatorEnum.add]: { name: '+' },
  [OperatorEnum.subtract]: { name: '-' },
  [OperatorEnum.multiply]: { name: '*' },
  [OperatorEnum.divide]: { name: '÷' },
  [OperatorEnum.equal]: { name: '=' },
  [OperatorEnum.notEqual]: { name: '≠' }
};
export const OperatorDic = dicFromMap(Operator);
// 0无,1and,2 or
export const LogicalOperatorEnum = {
  empty: '0',
  and: '1',
  or: '2'
};
export const LogicalOperator = {
  [LogicalOperatorEnum.empty]: { name: '' },
  [LogicalOperatorEnum.and]: { name: '并且' },
  [LogicalOperatorEnum.or]: { name: '或' }
};
export const LogicalOperatorDic = dicFromMap(LogicalOperator);
export const DevicePropertiesEnum = {
  // edingdianya: '8041899b3d934d81',
  // edingrongliang: '8041899b3df36ee1',
  // edingdianliu: '8041899b3c747001',
  // xuliangxianzhi: '8041899b3e5e3ea1',
  // gonglvyinshukaohezhi: '8041899b3e909941',
  edingdianya: '1S10000B',
  edingrongliang: '1S10000C',
  edingdianliu: '1S10000A',
  xuliangxianzhi: '1S10000D',
  gonglvyinshukaohezhi: '1S10000E'
};
export const DeviceProperties = {
  [DevicePropertiesEnum.edingdianya]: { name: '额定电压', unit: 'kV' },
  [DevicePropertiesEnum.edingrongliang]: { name: '额定容量', unit: 'kVA' },
  [DevicePropertiesEnum.edingdianliu]: { name: '额定电流', unit: 'A' },
  [DevicePropertiesEnum.xuliangxianzhi]: { name: '需量限值', unit: 'kW' },
  [DevicePropertiesEnum.gonglvyinshukaohezhi]: { name: '功率因数考核值', unit: '' }
};
export const DevicePropertiesDic = dicFromMap(DeviceProperties);

// 企业性质
export const CompanyTypeEnum = {
  guoyou: '01',
  jiti: '02',
  minying: '03',
  hezi: '04',
  waizifeio: '05',
  waizi: '06',
  zhengfu: '07',
  shehui: '08'
};
export const CompanyType = {
  [CompanyTypeEnum.guoyou]: { name: '国有企业' },
  [CompanyTypeEnum.jiti]: { name: '集体企业' },
  [CompanyTypeEnum.minying]: { name: '民营企业' },
  [CompanyTypeEnum.hezi]: { name: '合资企业' },
  [CompanyTypeEnum.waizifeio]: { name: '外资企业（非欧美）' },
  [CompanyTypeEnum.waizi]: { name: '外资企业（欧美）' },
  [CompanyTypeEnum.zhengfu]: { name: '政府机构' },
  [CompanyTypeEnum.shehui]: { name: '社会机构' }
};
// 企业性质
export const CustomerCharacterEnum = {
  yongneng: '01',
  anzhuang: '02',
  shoudian: '04',
  jieneng: '05',
  jinrong: '06',
  nengyuan: '07',
  dianneng: '08',
  dianrongqi: '09',
  fadianji: '10',
  zhengfu: '11',
  huanbao: '12'
};
export const CustomerCharacter = {
  [CustomerCharacterEnum.yongneng]: { name: '用能企业' },
  [CustomerCharacterEnum.anzhuang]: { name: '安装运维商' },
  [CustomerCharacterEnum.shoudian]: { name: '售电服务' },
  [CustomerCharacterEnum.jieneng]: { name: '节能服务' },
  [CustomerCharacterEnum.jinrong]: { name: '金融地产' },
  [CustomerCharacterEnum.nengyuan]: { name: '能源分析组织' },
  [CustomerCharacterEnum.dianneng]: { name: '电能质量服务' },
  [CustomerCharacterEnum.dianrongqi]: { name: '电容器厂家' },
  [CustomerCharacterEnum.fadianji]: { name: '发电机厂家' },
  [CustomerCharacterEnum.zhengfu]: { name: '政府' },
  [CustomerCharacterEnum.huanbao]: { name: '环保企业' }
};
// 规模
export const scale = {
  '01': '1~49人',
  '02': '50~99人',
  '03': '100~499人',
  '04': '500~999人',
  '05': '1000~2000人',
  '06': '2000~5000人',
  '07': '5000~9999人',
  '08': '10000人以上'
};
// 接入渠道
export const accessChannel = {
  '01': '直销',
    /* '02': '服务商', */ '03': '代理',
  '04': '网上订阅',
  '05': '推荐',
  '06': '其他'
};
export const companyAccessChannel = {
  '01': '直销',
  '02': '服务商',
  '03': '代理',
  '04': '网上订阅',
  '05': '推荐',
  '06': '其他'
};

// 签约状态
export const ifSign = {
  0: '否',
  1: '是'
};

// 联系人类型
export const contactType = { 1: '用电联系人', 2: '帐务联系人' };
// 岗位
export const position = { '01': '系统管理岗', '02': '运维岗', '03': '监控岗', '04': '抢修岗' };
// 性别
export const sexType = { 1: '男', 2: '女' };
// 告警状态
export const abnormalStatus = { 0: '未恢复', 1: '已恢复' };
// 处理状态
export const handleStatus = { 0: '待处理', 1: '已忽略', 2: '已处理', 3: '已确认' };
// 告警级别
export const alarmLevel = { 102001: '事件', 102003: '告警', 102004: '故障', 102005: '中断' };
// 电站状态
export const stationStatus = { '-1': '异常', 0: '运行', 1: '离线', 2: '故障', 3: '停机' };
// 逆变器,支路状态
export const ocsOrBranchStatus = {
  '-1': '异常',
  1: '离线',
  2: '故障',
  3: '充电',
  4: '放电',
  5: '待机',
  6: '运行',
  7: '停机'
};
// 电池类型
export const batteryType = { 400001: '铅碳电池', 400002: '磷酸铁锂电池', 400003: '三元电池' };

// 集团版路由 path 列表
export const GROUP_ROUTE_PATHS = {
  energyPower: '/z-energyPower',
  energyPowerOverview: '/z-energyPower/overview',
  energyPowerGenerateAnalyse: '/z-energyPower/generateAnalyse',
  energyPowerGenerationOverview: '/z-energyPower/generationOverview', //发电概况

  energyPowerProjectLedger: '/z-energyPower/projectLedger',//发电-台账列表
  energyPowerLedgerDetail: '/z-energyPower/projectLedger/detail/:stationId',//发电-台账详情

  energyPowerSettlementManagement: '/z-energyPower/settlementManagement',//结算管理
  energyPowerStaticReport: '/z-energyPower/staticReport',//统计报表

  energyPowerEquipmentMonitor: '/z-energyPower/equipmentMonitor',//设备监测
  energyPowerExceptionManagement: "/z-energyPower/exceptionManagement",//异常管理
  energyPowerExceptionManagementDetail: "/z-energyPower/exceptionManagement/detail/:stationId",//异常管理详情

  station: '/z-station',
  stationOverview: '/z-station/overview',
  energyStorage: '/z-energyStorage',
  energyStorageElectricityOverview: '/z-energyStorage/electricity/overview',
  energyStorageOperationManagement: '/z-energyStorage/electricity/operationManagement',

  energyStorageProjectLedger: '/z-energyStorage/projectLedger',//发电-台账列表
  energyStorageLedgerDetail: '/z-energyStorage/projectLedger/detail/:stationId',//发电-台账详情
  energyStorageEquipmentMonitor: '/z-energyStorage/equipmentMonitor',//设备监测
  energyStorageExceptionManagement: "/z-energyStorage/exceptionManagement",//异常管理
  energyStorageExceptionManagementDetail: "/z-energyStorage/exceptionManagement/detail/:stationId",//异常管理详情
  energyStoragePolicyManagement: "/z-energyStorage/policyManagement",//策略管理

  changeElectric: '/z-changeElectric',
  changeElectricOverview: '/z-changeElectric/overview',
  changeElectricTrend: '/z-changeElectric/trend',

  changeElectricProjectLedger: '/z-changeElectric/projectLedger',//发电-台账列表
  changeElectricLedgerDetail: '/z-changeElectric/projectLedger/detail/:stationId',//发电-台账详情
  changeElectricEquipmentMonitor: '/z-changeElectric/equipmentMonitor',//设备监测


  sellElectric: '/z-sellElectric',
  sellElectricMarketInfo: '/z-sellElectric/trade/marketInfo',
  sellElectricOnlineMonitor: '/z-sellElectric/trade/onlineMonitor',
  sellElectricRealtimeMonitor: '/z-sellElectric/trade/onlineMonitor/realtimeMonitor', // 售电 - 在线监测 - 实时监测
  // 能源站
  energyStationMonitor: '/z-energyStation/monitor',
  energyStationList: '/z-energyStation/list',

  carbonAssets: '/z-carbonAssets',
  screenProperty: '/screen-property',
  checkBattery: '/z-sellElectric/trade/onlineMonitor/executionCharge',

  sysconfig: 'z-sysConfig',
  account: '/z-sysConfig/account',
  role: '/z-sysConfig/role',
  roleCard: '/z-sysConfig/role/component/card',
  roleTree: '/z-sysConfig/role/component/tree',
  roleDetails: '/z-sysConfig/role/roleDetails',
  roleEdit: '/z-sysConfig/role/roleEdit',
  roleTable: '/z-sysConfig/role/tableList',
  user: '/z-sysConfig/users',
  userList: '/z-sysConfig/users/list',


  operate: '/z-operate',
  operateOverView: '/z-operate/station-view',
};


export const echartColorList = [
  '#57BE8A',
  '#886CFF',
  '#0094AD',
  '#EB5870',
  '#FF8147',
  '#FFC840',
  '#C7D1D9'
];