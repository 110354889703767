import * as services from '@api/energyWeb/charge';
import { fillDatasByType } from '@/utils/tool';

const defaultQuery = {
  p: '',
  m: moment(),
  t: 'month',
};

export default {
  namespace: 'chargeQuantity',

  state: {
    cacheQuery0: {
      ...defaultQuery,
    },
    datasTitle0: {
      ...defaultQuery,
      v: null,
      avg: null,
      maxItem: {},
    },
    datasGraph0: [],

    cacheQuery1: {
      ...defaultQuery,
    },
    datasTitle1: {
      ...defaultQuery,
      v: null,
      avg: null,
      maxItem: {},
    },
    datasGraph1: [],
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetch (_, { put }) {
      yield put({ type: 'fetch0' });
      yield put({ type: 'fetch1' });
    },

    *fetch0 ({ payload: { p, t, m } = {} }, { select, put, call }) {
      const stationId = yield select(state => state.app.saasStationId);
      const cacheQuery = yield select(state => state.chargeQuantity.cacheQuery0);

      const filter = { p, t, m };
      const query = {
        ...cacheQuery,
        ...Object.keys(filter).reduce((acc, item) => {
          if (filter[item] !== undefined) acc[item] = filter[item];
          return acc;
        }, {}),
      };
      const { t: queryT } = query;

      const [{ datas: fres, avg, start, end }] = yield call(services.queryQuantityGraph0, { stationId, ...query });

      const datas = queryT === 'month' ? fillDatasByType(queryT, start, end)(fres) : fillDatasByType(queryT, m)(fres);

      const maxItem = fres.reduce((acc, item) => {
        if (+item.v >= acc.v) {
          acc = item;
        }
        return acc;
      }, { st: null, v: 0 });

      const title = {
        ...query,
        avg,
        maxItem,
      };


      yield put({
        type: 'update',
        payload: {
          cacheQuery0: query,
          datasTitle0: title,
          datasGraph0: datas,
        },
      });
    },

    *fetch1 ({ payload: { p, t, m } = {} }, { select, put, call }) {
      const stationId = yield select(state => state.app.saasStationId);
      const cacheQuery = yield select(state => state.chargeQuantity.cacheQuery1);

      const filter = { p, t, m };
      const query = {
        ...cacheQuery,
        ...Object.keys(filter).reduce((acc, item) => {
          if (filter[item] !== undefined) acc[item] = filter[item];
          return acc;
        }, {}),
      };
      const { t: queryT } = query;

      const [{ datas: fres, avg, start, end }] = yield call(services.queryQuantityGraph1, { stationId, ...query });

      const datas = queryT === 'month' ? fillDatasByType(queryT, start, end)(fres) : fillDatasByType(queryT, m)(fres);

      const maxItem = fres.reduce((acc, item) => {
        if (+item.v >= acc.v) {
          acc = item;
        }
        return acc;
      }, { st: null, v: 0 });

      const title = {
        ...query,
        avg,
        maxItem,
      };


      yield put({
        type: 'update',
        payload: {
          cacheQuery1: query,
          datasTitle1: title,
          datasGraph1: datas,
        },
      });
    },
  },

  subscriptions: {
  },
};
