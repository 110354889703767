
import IemIcon from '../../../../../components/lib/iem-icon';
import Query from "@lib/iem-query";
import { connect } from "dva";
import styles from './index.scss';

export const defaultStaticInfo = [
  {
    img: 'icon-shandian',
    label: '发电量',
    value: '--',
    unit: 'MWh',
    color: '#0094AD'
  },
  {
    img: 'icon-a-24xiaoshiyongdianliangbili',
    label: '等效发电小时数',
    value: '--',
    unit: 'h',
    color: '#577980'
  },
  {
    img: 'icon-fushe',
    label: '电表电量',
    value: '--',
    unit: 'MWh',
    color: '#FA8220'
  },
  {
    img: 'icon-dianbiao',
    label: '自用电量',
    value: '--',
    unit: 'MWh',
    color: '#886CFF'
  },
  {
    img: 'icon-shangwangdianliang',
    label: '上网电量',
    value: '--',
    unit: 'MWh',
    color: '#886CFF'
  },
  {
    img: 'icon-chunengxinxi',
    label: '装机容量',
    value: '--',
    unit: 'MWp',
    color: '#588AF4'
  },
];


function ChangeTrendPage (props) {

  const { statistic: {
    analyseData: {
      staticInfo
    }
  } } = props;


  return <div className={styles.mainWrap}>
    {/* 渲染搜索条件 */}
    <Query
      type={'analyse'}
    />

    {/* 渲染静态统计 */}
    <div className={styles.listWrap}>
      {staticInfo.map((item) => {
        return <div className={styles.cardWrap} key={item.label}>
          <div className={styles.iconBgWrap}>
            <IemIcon
              className={styles.iconWrap}
              type={item.img}
            />
          </div>
          <div className={styles.cardRightWrap}>
            <div className={styles.lableWrap}> {item.label} </div>
            <div className={styles.valueWrap} style={{ color: item.color }}> {item.value} </div>
            <div className={styles.unitWrap}> {item.unit} </div>
          </div>
        </div>
      })}
    </div>
  </div>
}


const mapStateToProps = ({ statistic }) => ({ statistic });
export default connect(mapStateToProps)(ChangeTrendPage);;