
import * as servers from '@api/energyWeb/SwitchableTree';
export default {
  namespace: 'tree',
  state: {
    loading: true,
    stationId: '',
    selectTreeName: '',
    treeNme: [],
    treeData: [],
    treeDataHash: {},
    defaultSelect: {},
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    *getTreeNameAndTree ({ payload: { stationId } }, { select, put, call }) { // 获取当前所有树的名字 52108a739f80f1e1
      const lastStationId = yield select(({ tree }) => (tree.stationId));


      yield put({ type: 'update', payload: { stationId } });

      const treeNme = yield call(servers.getEnergyClassificationByStationIdApi, { stationId });


      const selectTreeName = treeNme[0] && treeNme[0].id;
      let treeData = [];
      let treeDataHash = {};
      if (selectTreeName) {
        [treeData, treeDataHash] = yield call(servers.getTreeNodeByStationIdAndTypeId, { typeId: selectTreeName, stationId });
      }

      let defaultSelectKey = treeData[0]?.key
      return yield put({
        type: 'update',
        payload: {
          loading: false,
          treeNme,
          selectTreeName,
          treeData,
          treeDataHash,
          defaultSelect: defaultSelectKey ? treeDataHash[defaultSelectKey] : {}
        },
      });

    },
    *getTreeBytypeId ({ payload: { typeId } }, { select, put, call }) { // 根据树的名字获取树
      yield put({
        type: 'update',
        payload: {
          loading: true,
          selectTreeName: typeId,
        },
      });
      const { stationId } = yield select(({ app }) => {
        return {
          stationId: app.saasStationId
        };
      });

      const [treeData, treeDataHash] = yield call(servers.getTreeNodeByStationIdAndTypeId, { typeId, stationId });

      let defaultSelectKey = treeData[0]?.key

      return yield put({
        type: 'update',
        payload: {
          loading: false,
          treeData,
          treeDataHash,
          defaultSelect: defaultSelectKey ? treeDataHash[defaultSelectKey] : {}
        },
      });
    },
  },
};
