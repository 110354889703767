let hash = {};

export function set(key, value) {
  if (!value) return;
  hash[key] = value;
  if (typeof value === 'object') {
    const str = JSON.stringify(value);
    global.localStorage.setItem(key, str);
    global.sessionStorage.setItem(key, str);
    return;
  }
  global.localStorage.setItem(key, value);
  global.sessionStorage.setItem(key, value);
}

export function get(key) {
  if (hash[key]) {
    return hash[key];
  }
  const value = global.localStorage.getItem(key);
  try {
    return JSON.parse(value);
  } catch (ex) {
    return value;
  }
}

export function remove(key) {
  delete hash[key];
  global.localStorage.removeItem(key);
  global.sessionStorage.removeItem(key);
}

export function clear(key) {
  hash = {};
  global.localStorage.clear(key);
  global.sessionStorage.clear(key);
}

export function cache() {
  return hash;
}
