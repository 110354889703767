import { getDataParti as getData } from '@services/request';

/**
 * facilitatorIds -> stations
 * 根据多个服务商ID获取站点列表
 */
export const fetchStationsBySPIds = getData('assetManagement', 'queryStationByServiceProviderIds');
/**
 * companyId -> stations
 * 根据企业ID获取站点列表
 */
export const fetchStationsByEnterpriseId = getData('assetManagement', 'queryStationByEnterpriseId');
/**
 * 根据站点ID查询站点变压器数量
 * stationId -> transformer count
 */
export const queryCountOfTransformer = getData('assetManagement', 'queryTransformerCountByStationId');
/**
 * 根据站点ID查询站点配电房数量
 * stationId -> distributionRoom count
 */
export const queryCountOfSubstation = getData('assetManagement', 'querySubstationCountByStationId');
/**
 * station -> devicesTree
 * 根据站点ID获取设备树
 */
export const fetchDeviceTree = async (stationId, ownerType = 27001) => {
  const res = await getData('assetManagement', 'queryTreeNodeByOwnerIdAndType')({ ownerId: stationId, ownerType });
  const dataList = (res.dataList || []).map((o) => {
    const { displayType: type, displayName: name, displayId: id, currentId: nodeId, parentId: parentNodeId } = o;
    return {
      id,
      nodeId,
      parentNodeId,
      type,
      name,
    };
  });
  const byId = dataList.reduce((hash, obj) => ({ ...hash, [obj.id]: obj }), {});
  const byNodeId = dataList.reduce((hash, obj) => ({ ...hash, [obj.nodeId]: obj }), {});
  Object.values(byNodeId).forEach((node) => {
    const { parentNodeId } = node;
    if (parentNodeId) {
      const parentNode = byNodeId[parentNodeId];
      node.parentId = parentNode.id;
      if (parentNode.children) parentNode.children.push(node);
      else (parentNode.children = [node]);
    }
  });
  return {
    ...res,
    // dataList,
    // byId,
    data: byId[stationId],
  };
};

/**
 * 根据服务商id查询下级服务商
 * @param {string} option.customerId
 * @param {string} option.status
 */
export const queryTrusteeshipByCustomerId = getData('businessCustom', 'queryTrusteeshipByCustomerId');
/**
 * 查询常量字典表
 * @param {string} option.dicType
 */
export const getListDicConstant = getData('assetManagement', 'getListDicConstant');
/**
 * 根据监控目标和信号编码查询point
 * @param {Array<string>} option.monitoredObjectIds 监控目标ID列表
 * @param {Array<string>} option.metricCodes 信号编码列表
 */
// export const fetchPoints = getData('assetManagement', 'queryPointByMonitoredObjectIdsAndMetricCodes');
export async function fetchPoints ({ monitoredObjectIds, metricCodes }) {
  const res = await getData('assetManagement', 'queryDetailPointByMonitoredObjectIdsAndMetricCodes')({ monitoredObjectIds, metricCodes });
  return {
    ...res,
    dataList: res.dataList && res.dataList.map((point) => {
      return {
        ...point,
        pointId: point.pointIdString || `${point.pointId}`,
      };
    }),
  };
}
/**
 * 查询监控目标所有的point
 * @param {string} monitoredObjectId 监控目标ID
 */
export async function queryPointByMonitoredObjectId (monitoredObjectId) {
  const res = await getData('assetManagement', 'queryDetailPointByMonitoredObjectId')({ monitoredObjectId });
  return {
    ...res,
    dataList: res.dataList && res.dataList.map((point) => {
      return {
        ...point,
        pointId: point.pointIdString || `${point.pointId}`,
      };
    }),
  };
}
/**
 * 查询监控目标所有的point
 * @param {Array<string>} monitoredObjectIds 监控目标ID列表
 */
export const queryPointByMonitoredObjectIds = getData('assetManagement', 'queryPointByMonitoredObjectIds', monitoredObjectIds => ({ monitoredObjectIds }));
/**
 * 根据设备查询当前生效的计费新
 * @param {Array<string>} option.deviceIds
 */
export const queryEffectivePriceDataByDevice = getData('fee', 'queryEffectivePriceDataByDevice');

