import * as services from '@services/storage/stationMonitor';

export default {
  namespace: 'stationMonitor',
  state: {
    activeTab: '1',
    monitorId: '',  // 这个是点击底下更多存储的设备id

    companyList: [],  // 企业列表
    companyHash: {},  // 企业列表
    list: [],   // 站点列表
    hash: {},   // 站点列表
    stationInfo: {},  // 站点信息
    alarmInfo: {},    // 告警信息
    storageRoomList: [], // 储能室信息
    viewChartData: {},    // 电站总览图表信息
    stationMetricCodes: {}, // 电站的数据项
    stationChargeAndUncharge: {}, // 电站可充放电量

    resTreeData: [],
    treeData: [[], {}, {}],   // 树数据
    byqInfo: {},  // 变压器信息
    bwdInfo: {},  // 并网点信息
    storageRoomInfo: {},  // 储能室信息
    storageRoomStatus: {}, // 储能室状态信息
    pcsInfo: {},  // 逆变器信息
    branchInfo: {},   // 支路信息
    batteryGroupInfo: {}, // 电池组信息
    batteryHeapInfo: {},  // 电池堆信息
    subList: [],  // 下级信息
    dataMonitorChartData: {},   // 数据监测图表信息
    subTableList: [], // 电池组列表信息
    alertChartData: {},   // 电池组弹出框图表

    params: {
      name: '',
      enterpriseId: null,
      status: [0, 1, 2, 3],
      page: 1,
      length: 30,
      totalCount: 0,
    },

    // 电池组参数
    subParams: {
      keywords: '',
      monitorId: '',
      page: 1,
      length: 30,
      totalCount: 0,
    },

    profit: {
      yesterday: '',  // 昨日收益
      month: '',  // 本月收益
      total: '',  // 累计收益
    },

    runStatus: {
      soc: '',  // soc
      yggl: '', // 有功功率
      kcdl: '', // 可充电量
      kfdl: '', // 可放电量
      ljcdl: '', // 累计充电量
      ljfdl: '', // 累计放电量
    },

    byqBwdInfo: [],  // 变压器 并网点信息
    byqData: {
      yggl: '', // 变压器有功功率
      wggl: '', // 变压器无功功率
    },
    bwdData: {},

    stationPowerChartData: {},  // 电站有功功率图表数据
    batteryGroupChart: {},  // 电池组图表数据
    dataItem: '', // voltage=电压，temperature=温度，resistance=内阻，soc=soc   pressure=电芯压力
    batteryGroupMonomerInfo: [], // 电池组电芯信息
    batteryGroupDetailInfo: {}, // 电池组详情(电压上下线, 电池箱每组数量)

    batteryGroupItemOrder: [],  // 电池组电芯序列
    runDataInfo: {
      MONTHLY_EFFICIENCY: "--",
      TOTAL_CHARGE: "--",
      YEAR_EFFICIENCY: "--",
      TOTAL_DISCHARGE: "--",
      MONTHLY_DISCHARGE: "--",
      TOTAL_EFFICIENCY: "--",
      YEAR_DISCHARGE: "--",
      MONTHLY_CHARGE: "--",
      YEAR_CHARGE: "--",
    }
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询企业列表
    *fetchCompanyList (_, { select, call, put }) {
      let companyList = [];
      let companyHash = {};
      const serverInfo = yield select(state => state.user.serverInfo);
      const { statics: { serviceProviderId } } = yield select(state => state.app);

      if (serverInfo && serverInfo.organizationType === '00040035') {
        companyList = [{ key: serverInfo.id, value: serverInfo.name }];
        companyHash = { [serverInfo.id]: { value: serverInfo.name } };
      } else {

        [companyList] = yield call(services.queryCompanyList, { serviceProviderId });
        companyHash = companyList.reduce((acc, item) => {
          acc[item.key] = item;
          return acc;
        }, {});
      }

      yield put({
        type: 'update',
        payload: { companyList, companyHash },
      });
    },

    // 查询列表
    *fetchList ({ payload: { name, enterpriseId, status, page = 1, length = 30 } }, { select, call, put }) {
      const stationIds = JSON.stringify(Object.keys(yield select(state => state.station.hash)));
      const [list, totalCount] = yield call(services.queryList, { stationIds, name, enterpriseId, status: JSON.stringify(status), page, length });
      const hash = (list || []).reduce((acc, item) => {
        acc[item.stationId] = item;
        return acc;
      }, {});

      yield put({
        type: 'update',
        payload: {
          list,
          hash,
          params: { page, length, totalCount, name, enterpriseId, status },
        },
      });
    },

    // 电站总览 - 根据站点id查询站点信息
    *fetchStationInfo ({ payload: { id } }, { call, put }) {
      const [stationInfo] = yield call(services.queryStationInfo, { stationId: id });
      const {data} = yield call(services.queryPvStationInfo, { stationId: stationInfo.pvStationId||'' });

      stationInfo.shortName = data.shortName || ''
      stationInfo.stationParamBufferVO = data.stationParamBufferVO || {}
      const [[stationStatus]] = yield call(services.queryStationStatus, { stationIds: JSON.stringify([id]) });
      stationInfo.stationStatus = stationStatus.status;
      const [runStrategy] = yield call(services.queryRunStrategy, { stationId: id });
      if (!runStrategy) {
        stationInfo.runStrategy = '';
      } else {
        runStrategy.forEach((item) => {
          if (item.status === 1) {
            stationInfo.runStrategy = item.strategyName;
          }
        });
      }
      yield put({
        type: 'update',
        payload: { stationInfo },
      });
    },

    // 电站总览 - 查询电站告警信息
    *fetchAlarmInfo ({ payload: { id } }, { call, put }) {
      const [alarmInfo] = yield call(services.queryStationAlarmInfo, { stationId: id });
      yield put({
        type: 'update',
        payload: { alarmInfo },
      });
    },

    // 电站总览 - 查询该站的可充放电量
    *fetchStationChargeAndUncharge ({ payload: { id } }, { call, put }) {
      const [stationChargeAndUncharge] = yield call(services.queryStationChargeAndUncharge, { stationId: JSON.stringify([id]) });
      yield put({
        type: 'update',
        payload: { stationChargeAndUncharge },
      });
    },

    // 电站总览 - 查询该站下面的数据项
    *fetchDataItem ({ payload: { id, stationId, deviceType } }, { call, put }) {
      const [stationMetricCodes, stationMetricCodesHash] = yield call(services.queryDataItem, { ids: JSON.stringify([id]), stationId, deviceType });
      yield put({
        type: 'update',
        payload: { stationMetricCodes },
      });

      return { stationMetricCodes, stationMetricCodesHash };
    },

    // 电站总览 - 查询该电站下储能室信息
    *fetchLowLevelList ({ payload: { id } }, { call, put }) {
      const [storageRoomListIdName] = yield call(services.queryLowLevelList, { stationId: id });
      const [storageRoomList] = yield call(services.getRealData, { idNames: storageRoomListIdName });
      yield put({
        type: 'update',
        payload: { storageRoomList },
      });
    },

    // 电站总览 - 图表数据
    *fetchViewChartData ({ payload: { startTime, endTime, metric, monitorId } }, { call, put }) {
      const [viewChartData] = yield call(services.queryChartData, { startTime, endTime, metric, monitorId: [monitorId] });
      yield put({
        type: 'update',
        payload: { viewChartData },
      });
      return viewChartData;
    },

    *fetchViewChartData1 ({ payload: { startTime, endTime, metric, monitorId } }, { call, put }) {
      const [viewChartData] = yield call(services.queryChartData1, { startTime, endTime, metric, monitorId: [monitorId] });
      return viewChartData;
    },

    // 数据监测 - 充放电量 - 峰时电量
    *fetchJfpgCfdlData ({ payload: { startTime, endTime, metric, monitorId } }, { call, put }) {
      const [viewChartData] = yield call(services.queryJfpgCfdlData, { startTime, endTime, metric, monitorId: [monitorId] });
      yield put({
        type: 'update',
        payload: { viewChartData },
      });
      return viewChartData;
    },

    // 数据监测 - 树数据
    *fetchTreeData ({ payload: { id } }, { call, put }) {

      const [treeData, resTreeData] = yield call(services.queryTreeData, { monitorId: id });
      yield put({
        type: 'update',
        payload: { treeData, resTreeData: resTreeData.dataList },
      });
      return treeData;
    },

    // 数据监测-新增-变压器信息
    *fetchByqInfo ({ payload: { monitorId } }, { call, put }) {
      const [byqInfo] = yield call(services.queryByqInfo, { monitorId });

      yield put({
        type: 'update',
        payload: { byqInfo },
      });
      return byqInfo;
    },

    // 数据监测-新增-并网点信息
    *fetchBwdInfo ({ payload: { monitorId } }, { call, put }) {
      const [bwdInfo] = yield call(services.queryBwdInfo, { monitorId });
      yield put({
        type: 'update',
        payload: { bwdInfo },
      });
      return bwdInfo;
    },

    // 数据监测 - 储能室信息
    *fetchStorageRoomInfo ({ payload: { monitorId } }, { call, put }) {
      const [storageRoomInfo] = yield call(services.queryStorageRoomInfo, { monitorId });
      yield put({
        type: 'update',
        payload: { storageRoomInfo },
      });
      return storageRoomInfo;
    },

    // 数据监测 - 储能室信息 - 一些状态
    *fetchStorageRoomStatus ({ payload: { storageRoomId } }, { call, put }) {
      const [storageRoomStatus] = yield call(services.queryStorageRoomStatus, { storageRoomId });
      yield put({
        type: 'update',
        payload: { storageRoomStatus },
      });
    },

    // 数据监测 - 逆变器信息
    *fetchPcsInfo ({ payload: { monitorId } }, { call, put }) {
      const [pcsInfo] = yield call(services.queryPcsInfo, { monitorId });
      yield put({
        type: 'update',
        payload: { pcsInfo },
      });
      return pcsInfo;
    },

    // 数据监测 - 支路信息
    *fetchBranchInfo ({ payload: { monitorId } }, { select, call, put }) {
      const resTreeData = yield select(state => state.stationMonitor.resTreeData);
      const batteryGroupIds = resTreeData.reduce((acc, item) => {
        if (item.parentId === resTreeData.filter((item1) => { return item1.displayId === monitorId; })[0].id) {
          acc.push(item.displayId);
        }
        return acc;
      }, []);
      const [branchInfo] = yield call(services.queryBranchInfo, { monitorId, batteryGroupIds: JSON.stringify(batteryGroupIds) });
      yield put({
        type: 'update',
        payload: { branchInfo },
      });
      return branchInfo;
    },

    // 数据监测 - 电池堆信息
    *fetchBatteryHeapInfo ({ payload: { monitorId } }, { call, put }) {
      const [batteryHeapInfo] = yield call(services.queryBatteryHeapInfo, { monitorId });
      yield put({
        type: 'update',
        payload: { batteryHeapInfo },
      });
      return batteryHeapInfo;
    },

    // 数据监测 - 下级信息 - 底下部分
    *fetchSubList ({ payload: { selectId } }, { select, call, put }) {
      const resTreeData = yield select(state => state.stationMonitor.resTreeData);
      const sltTreeItem = resTreeData.filter(item => item.id === selectId)[0];
      const subInfoList = resTreeData.reduce((acc, item) => {
        if (item.parentId === selectId && (sltTreeItem.displayType !== 40003) && (sltTreeItem.displayType !== 40025) && (item.displayType !== 40025)) {
          acc.push({
            id: item.displayId,
            name: item.displayName,
            type: item.displayType,
          });
        }
        return acc;
      }, []);
      const [subList] = yield call(services.getRealData, { idNames: subInfoList });
      yield put({
        type: 'update',
        payload: { subList },
      });
    },

    // 数据监测 - 图表数据
    *fetchDataMonitorChartData ({ payload: { startTime, endTime, metric, monitorId } }, { call, put }) {
      const [dataMonitorChartData] = yield call(services.queryChartData, { startTime, endTime, metric, monitorId: [monitorId] });
      yield put({
        type: 'update',
        payload: { dataMonitorChartData },
      });
    },

    // 查询列表 - 电池组
    *fetchSubTableList ({ payload: { monitorId, keywords, page = 1, length = 30 } }, { call, put }) {
      const [subTableList, totalCount] = yield call(services.querySubTableList, { monitorId, keywords, page, length });
      yield put({
        type: 'update',
        payload: {
          subTableList,
          subParams: { page, length, totalCount, keywords, monitorId },
        },
      });
      return totalCount;
    },

    // 电芯报表导出
    *exportData ({ payload: { excelName, batteryGroupId, startTime, endTime, min, max, exportItems, precision } }, { call }) {
      const [path] = yield call(services.exportData, { excelName, batteryGroupId, startTime, endTime, min, max, exportItems: JSON.stringify(exportItems), interval: precision });
      return path;
    },
    // 电池堆报表导出
    *exportDcdData ({ payload: { monitorId, startTime, endTime, precision } }, { call }) {
      const [path] = yield call(services.exportDcdData, { batteryHeapId: monitorId, startTime, endTime, interval: precision * 60 * 1000 });
      return path;
    },
    // 逆变器导出
    *exportNbqData ({ payload: { monitorId, startTime, endTime, precision } }, { call }) {
      const [path] = yield call(services.exportNbqData, { pcsId: monitorId, startTime, endTime, interval: precision * 60 * 1000 });
      return path;
    },
    // 电站总览-新-电站运营收益
    *fetchProfit ({ payload: { id } }, { call, put }) {
      const [profit] = yield call(services.queryProfit, { id });
      yield put({
        type: 'update',
        payload: { profit },
      });
    },

    // 电站总览-新-电站运行状态
    *fetchRunStatus ({ payload: { id } }, { call, put }) {
      const [runStatus] = yield call(services.queryRunStatus, { id });
      yield put({
        type: 'update',
        payload: { runStatus },
      });
    },

    // 电站总览-新-变压器,并网点信息
    *fetchByqBwdInfo ({ payload: { id } }, { call, put }) {
      const [byqBwdInfo] = yield call(services.queryByqBwdInfo, { id });
      yield put({
        type: 'update',
        payload: { byqBwdInfo },
      });
      return byqBwdInfo;
    },

    // 电站总览-新-变压器数据(有功功率, 无功功率)
    *fetchByqData ({ payload: { id } }, { call, put }) {
      const [byqData] = yield call(services.queryByqData, { id });
      yield put({
        type: 'update',
        payload: { byqData },
      });
    },

    // 电站总览-新-并网点数据(有功功率, 可充电量, 可放电量)
    *fetchBwdData ({ payload: { id } }, { call, put }) {
      const [bwdData] = yield call(services.queryBwdData, { id });
      yield put({
        type: 'update',
        payload: { bwdData },
      });
    },

    // 电站总览 - 有功功率图表
    *fetchStationPowerChartData ({ payload: { startTime, endTime, metric, monitorId } }, { call, put }) {
      const [stationPowerChartData] = yield call(services.queryChartData, { startTime, endTime, metric, monitorId });
      yield put({
        type: 'update',
        payload: { stationPowerChartData },
      });

      return stationPowerChartData;
    },

    // 数据监测 - 电池组相关信息(如电压范围, 电芯分组)
    *fetchBatteryGroupDetailInfo ({ payload: { monitorId } }, { call, put }) {
      const [batteryGroupDetailInfo] = yield call(services.queryBatteryGroupDetailInfo, { monitorId });
      yield put({
        type: 'update',
        payload: { batteryGroupDetailInfo },
      });
      return batteryGroupDetailInfo;
    },

    // 数据监测 - 电池组信息
    *fetchBatteryGroupInfo ({ payload: { monitorId } }, { call, put, select }) {
      const { voltageCellNumber, temperatureCellNumber } = yield select(state => state.stationMonitor.batteryGroupDetailInfo);
      const batteryGroupItemOrder = yield select(state => state.stationMonitor.batteryGroupItemOrder);
      const [batteryGroupInfo] = yield call(services.queryBatteryGroupInfo, { monitorId, voltageCellNumber, temperatureCellNumber, batteryGroupItemOrder });
      yield put({
        type: 'update',
        payload: { batteryGroupInfo },
      });

      return batteryGroupInfo;
    },

    // 数据监测 - 电池组 - 图表
    *fetchBatteryGroupChart ({ payload: { startTime, endTime, dataItem, monitorId, dateType } }, { call, put, select }) {
      // 注意 此处是为了loading才提前, 如果没有loading则可以和下面的合并
      // yield put({
      //   type: 'update',
      //   payload: { dataItem },
      // });
      const { voltageCellNumber, temperatureCellNumber } = yield select(state => state.stationMonitor.batteryGroupDetailInfo);
      const batteryGroupItemOrder = yield select(state => state.stationMonitor.batteryGroupItemOrder);
      const [batteryGroupChart] = yield call(services.queryBatteryGroupChart, { startTime, endTime, dataItem, monitorId, dateType, voltageCellNumber, temperatureCellNumber, batteryGroupItemOrder });
      yield put({
        type: 'update',
        payload: { batteryGroupChart, dataItem },
      });
      return batteryGroupChart;
    },

    // 数据监测 - 电池组 - 电芯信息
    *fetchBatteryGroupMonomerInfo ({ payload: { dataItem, monitorId } }, { call, put }) {
      const [batteryGroupMonomerInfo] = yield call(services.queryBatteryGroupMonomerInfo, { dataItem, monitorId });
      yield put({
        type: 'update',
        payload: { batteryGroupMonomerInfo },
      });
      return batteryGroupMonomerInfo;
    },

    // 数据监测 - 电池组 - 弹出框图表
    *fetchAlertChartData ({ payload: { startTime, endTime, location, monitorId,replaceRequestUrl } }, { call, put }) {
      const [alertChartData] = yield call(services.queryAlertChartData, { startTime, endTime, location, monitorId,replaceRequestUrl });
      yield put({
        type: 'update',
        payload: { alertChartData },
      });
      return alertChartData;
    },

    // 数据监测 - 电池组 - 电芯序列
    *fetchBatteryGroupItemOrder ({ payload: { monitorId } }, { call, put }) {
      const [batteryGroupItemOrder] = yield call(services.queryBatteryGroupItemOrder, { monitorId });
      yield put({
        type: 'update',
        payload: { batteryGroupItemOrder },
      });
      return batteryGroupItemOrder;
    },


    *fetchRunDataByStationId ({ payload: { stationIds } }, { call, put }) {
      const res = yield call(services.queryRunDataByStationIdApi, { stationIds });
      yield put({
        type: 'update',
        payload: {
          runDataInfo: JSON.stringify(res) == '{}' ? {
            MONTHLY_EFFICIENCY: "--",
            TOTAL_CHARGE: "--",
            YEAR_EFFICIENCY: "--",
            TOTAL_DISCHARGE: "--",
            MONTHLY_DISCHARGE: "--",
            TOTAL_EFFICIENCY: "--",
            YEAR_DISCHARGE: "--",
            MONTHLY_CHARGE: "--",
            YEAR_CHARGE: "--",
          } : res
        },
      });
      return runDataInfo;
    },
  },
  subscriptions: {},
};
