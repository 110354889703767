import * as services from '@api/energyWeb/DeviceManagement';
import { SwitchPicker } from '@ui';

export default {
  namespace: 'vEnergyDeviceList',
  state: {
    loading: true,
    dataList: [],
    totalCount: 0,
    page: 1,
    length: 30,

    datas: [],
    data: {},
    dataAudit: {},
    metricCodes: [],
    countObj: {}, // 用能设备各数量
    chartList: [],
  },

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *fetch ({ payload }, { put, call }) {
      yield put({ type: 'update', payload: { loading: true } });

      const res = yield call(services.queryAllKeyEnergyDeviceByStationId, { ...payload });

      yield put({
        type: 'update',
        payload: {
          loading: false,
          dataList: res.dataList?.filter(item => item.keyEnergyDeviceType !== 700005) || [],
          page: payload.page,
          length: payload.length,
          totalCount: res.totalCount || 0,
        },
      });

      return res;
    },
    *fetchDetail ({ payload: { id, type } = {} }, { put, call }) {
      if (!id) return;
      const [fres] = yield call(services.fetchEnergyDeviceById, { id, type });
      yield put({
        type: 'update',
        payload: {
          data: fres,
        },
      });

      return [fres];
    },
    *updateMetricCodes ({ payload: { deviceType } = {} }, { put }) {
      if (!deviceType) return;

      const type = deviceType;
      let queryType = null;
      switch (+type) {
        case 700001:  // 空压机
          queryType = '0';
          break;
        case 700002:  // 水泵
          queryType = '1';
          break;
        case 700003:  // 风机
          queryType = '2';
          break;
        case 700004:  // 电动机
          queryType = '3';
          break;
        default:
      }
      const queryMetricCodes = [...(services.energyDeviceQueryMetricCodesMap[queryType] || [])];  // 使用新数组

      yield put({
        type: 'update',
        payload: {
          metricCodes: queryMetricCodes,
        },
      });
    },
    *fetchChart ({ payload: { deviceId, metricCodes = [], selectedDateType, startTime, endTime } }, { call, put, select }) {
      const metric = yield select(state => state.metricCode.data);

      yield put({
        type: 'update',
        payload: {
          chartList: [],
        },
      });
      let trueMetricCodes = metricCodes;
      if (selectedDateType === SwitchPicker.enum.year) {
        if (metricCodes.indexOf('00070047') !== -1) {
          // 日累计加载时长，日累计卸载时长-->月累计加载时长，月累计卸载时长
          trueMetricCodes = ['00070048', '0007004A'];
        } else if (metricCodes.indexOf('00070040') !== -1) {
          // 日加卸载次数--->月加卸载次数
          trueMetricCodes = ['0007004D'];
        } else if (metricCodes.indexOf('0007004E') !== -1) {
          // 日高负载率累计时长，日累计工作时长-->月高负载率累计时长，月累计工作时长
          trueMetricCodes = ['0007004F', '0007004C'];
        } else if (metricCodes.indexOf('00070042') !== -1) {
          // 日启停频率-->月启停频率
          trueMetricCodes = ['00070050'];
        }
      }
      const [points] = yield call(services.queryPoints, {
        deviceId,
        metricCodes: trueMetricCodes,
      });

      const pointHash = points.reduce((acc, item) => {
        acc[item.id] = item;
        return acc;
      }, {});

      const [chartList] = yield call(services.queryRawMetricDatum, {
        pointIds: points.map(i => i.id),
        startTime,
        endTime,
      });

      yield put({
        type: 'update',
        payload: {
          chartList: chartList.map((item) => {
            const { id: pointId, dataList = [] } = item;
            const pointItem = pointHash[pointId];
            const { metricCode, pointName, alias } = pointItem;
            const metricItem = metric[metricCode] || {};
            const { name, dec = 0 } = metricItem;

            return {
              ...pointItem,
              ...metricItem,
              name: name || pointName || alias,
              data: dataList.map((i) => {
                return [+i.t, (+i.v).toFixed(+dec)];
              }),
            };
          }),
        },
      });
    },
  },

  subscriptions: {
  },
};
