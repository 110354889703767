/**
 * 用户权限model
 */
import * as services from '@openapi/right';
import { readProp } from '@/utils/tool';
import routeConfig from '@/layouts/LayoutBase/Nav.config';

const { ACCESS_CODE } = services;

const getInitState = (accountId, customerId) => ({
  accountId,
  customerId,
  defaultAccessKey: ACCESS_CODE.VIEW_OVERVIEW,
  defaultAccessRoute: '/',
  defaultAccessOpen: '/',
  rightMap: {},
});

export default {
  namespace: 'right',

  state: getInitState(),

  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    clean () {
      return getInitState();
    },
  },

  effects: {
    *showChang ({ payload: { callback } = {} }, { call }) {
      const res = yield call(services.getRightAllow);
      callback(res)
    },
    *fetchAccess ({ payload: { accountId, customerId, type } }, { put, call }) {
      if (!customerId) {
        yield put({ type: 'update', payload: getInitState(accountId) });
        return;
      }

      const res = yield call(services.getRightList, accountId, customerId, type);
      if (res.code) {
        console.error('获取权限列表失败');
        yield put({ type: 'update', payload: getInitState(accountId, customerId) });
        return;
      }

      const resOf = readProp(res);
      const rightList = resOf('dataList', []).map(o => o.id);

      // 找出默认显示的页面
      // const defaultAccessKey = rightList[0] || ACCESS_CODE.VIEW_OVERVIEW;
      // let defaultAccessRoute = '/';

      const rightMap = rightList.reduce((h, str) => { h[str] = true; return h; }, {
        // [ACCESS_CODE.VIEW_OVERVIEW]: true,

        // [ACCESS_CODE.VIEW_DATUM_GAS]: true,

        // [ACCESS_CODE.VIEW_ENERGY_CONTROL_AIRCONTROL_TOTAL]: true,
        // [ACCESS_CODE.VIEW_ENERGY_CONTROL_AIRCONTROL_RECORD]: true,
        // [ACCESS_CODE.VIEW_ENERGY_CONTROL_AIRCONTROL]: true,

      });
      // 找出默认显示的页面
      const defaultRoute = routeConfig(rightMap)[0] || {};
      const defaultAccessKey = defaultRoute.access_code || ACCESS_CODE.VIEW_OVERVIEW;
      const defaultAccessRoute = defaultRoute.rightLink || '/';
      const defaultAccessOpen = defaultRoute.link;
      yield put({ type: 'update', payload: { accountId, customerId, defaultAccessKey, defaultAccessRoute, defaultAccessOpen, rightMap } });
    },
  },

  subscriptions: {},
};
