import * as services from '@services/storage/alarmNew';
import * as wsocket from '@services/storage/socketCluster';
import * as storage from "@/services/storage";
export default {
  namespace: 'alarm',
  state: {
    list: [],   // 站点列表
    hash: {},

    params: {
      stationId: (localStorage.getItem("ext") && JSON.parse(localStorage.getItem("ext")).ownerId) || '',
      alarmLevel: '',
      abnormalStatus: '',
      handleStatus: ['0'],
      startTime: null,
      endTime: null,
      page: 1,
      length: 30,
      totalCount: 0,
    },

  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
  },
  effects: {
    // 查询列表
    *fetchList ({ payload: { stationId, alarmLevel, abnormalStatus,
      handleStatus, startTime, endTime, page = 1, length = 30 } }, { select, call, put }) {
      const stationHash = yield select(state => state.station.hash);
      const [list, totalCount] = yield call(services.queryList, { stationId: stationId || Object.keys(stationHash), alarmLevel: alarmLevel === '' ? ['102003', '102004', '102005'] : [alarmLevel], abnormalStatus, handleStatus, startTime: startTime && startTime.valueOf(), endTime: endTime && endTime.valueOf(), page, length });
      const hash = list.reduce((acc, item) => {
        acc[item.alarmRecordId] = item;
        return acc;
      }, {});
      yield put({
        type: 'update',
        payload: {
          list,
          hash,
          params: {
            page, length, totalCount, stationId,
            alarmLevel, abnormalStatus, handleStatus,
            startTime: startTime && moment(startTime),
            endTime: endTime && moment(endTime)
          },
        },
      });
    },

    // 更新状态和备注
    *saveStatusAndRemark ({ payload: { id, handleStatus, remark } }, { select, call }) {

      const { statics: { serviceProviderId } } = yield select(state => state.app);

      const alarmHash = yield select(state => state.alarm.hash);
      const serviceProvidorId = serviceProviderId;
      const resData = yield call(services.saveStatusAndRemark, {
        id,
        handleStatus,
        remark,
        dealTime: moment().valueOf(),
        operatorId: storage.get('customerId'),
        operatorName: storage.get('emsStationName'),
      });
      yield call(wsocket.publish, `alarmHandled_${serviceProvidorId}`, { alarmRecordId: id, stationId: alarmHash[id].stationId, alarmLevel: alarmHash[id].infoCategory, dealStatus: handleStatus });
      return resData;
    },

    // 更新备注
    *saveRemark ({ payload: { id, remark } }, { call }) {
      const resData = yield call(services.saveRemark, { id, remark });
      return resData;
    },
  },
  subscriptions: {},
};
