import React from 'react';
import SvgIcon from '../SvgIcon';
import { Tooltip } from 'antd';
import styles from './index.scss';

/** 当前仅支持placement为left和right，若有其他需要，请自行修改样式 */
class Tip extends React.Component {
  render() {
    const { iconName, className, iconClassName, ...rest } = this.props;
    return (
      <Tooltip
        overlayClassName={[styles.tip, className]}
        {...rest}
      >
        <SvgIcon xlinkHref={iconName || 'icon-CombinedShape'} className={[styles.icon, iconClassName]}></SvgIcon>
      </Tooltip>
    );
  }
}
export default Tip;
