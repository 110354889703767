// import moment from 'moment';
import * as wsocket from '@services/storage/socketCluster';
import * as service from '@services/storage/alarm';

const sleep = time => new Promise(resolve => global.setTimeout(() => resolve(), time));

const responseSuccess = res => res && res.code === null;

// 接受到的告警推送数据转为平台需要的告警格式
const transformAlarmItem = obj => ({
  id: obj.alarmRecordId,
  stationId: obj.stationId,
  level: obj.infoCategory,
  stationName: obj.stationName,
  deviceId: obj.monitoredObjectId,
  deviceName: obj.monitoredObjectName,
  ruleId: obj.alarmRuleId,
  ruleName: obj.alarmRuleName,
  date: obj.happenTime,
  levelEN: service.levelENMap[obj.infoCategory],
  originData: obj,
});

export default {
  namespace: 'alarmpopups',
  state: {
    // 当前告警条数list
    alarmList: [],
    // 当前展示的下标
    curNum: 0,
    //
    timers: [],
    // 记录用户是否正在查看弹窗的告警信息
    isVisiting: false,
  },
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    changeIndex (state, { payload: { step } }) {
      const { alarmList, curNum } = state;
      const len = alarmList.length;
      const nextNum = curNum + step;
      return { ...state, curNum: nextNum < 0 || nextNum >= len ? curNum : nextNum };
    },
    // clearList(state) {
    //   return { ...state, alarmList: [], curNum: 0, remainCount: 0, isVisiting: false };
    // },
    // removeAlarmFromList(state, { payload: alarmRecordId }) {
    //   if (!state.alarmList.some(({ id }) => id === alarmRecordId)) return state;
    //   const nextAlarmList = state.alarmList.filter(({ id }) => id !== alarmRecordId);
    //   const lastListIdx = nextAlarmList.length - 1;
    //   const nextCurNum = Math.min(state.curNum, lastListIdx);
    //   return {
    //     ...state,
    //     alarmList: nextAlarmList,
    //     curNum: nextCurNum,
    //   };
    // },
  },
  effects: {
    *clearList (_, { select, put, cancel }) {
      const cState = yield select(state => state.alarmpopups);
      const timers = cState.timers;
      yield timers.map(({ task }) => cancel(task));
      yield put({ type: 'update', payload: { ...cState, alarmList: [], curNum: 0, timers: [], isVisiting: false } });
    },
    *removeAlarmFromList ({ payload: alarmRecordId }, { select, put, cancel }) {
      const cState = yield select(state => state.alarmpopups);
      const { alarmList, curNum, timers } = cState;
      if (!alarmList.some(({ id }) => id === alarmRecordId)) return;
      const cancelIds = alarmList.filter(({ id }) => id === alarmRecordId).map(({ id }) => id);
      yield timers.filter(({ alarmRecordId: id }) => cancelIds.indexOf(id) !== -1).map(({ task }) => cancel(task));
      const nextAlarmList = alarmList.filter(({ id }) => id !== alarmRecordId);
      const lastListIdx = Math.max(nextAlarmList.length - 1, 0);
      const nextCurNum = Math.min(curNum, lastListIdx);

      yield put({
        type: 'update',
        payload: {
          ...cState,
          alarmList: nextAlarmList,
          curNum: nextCurNum,
          timers: timers.filter(({ alarmRecordId: id }) => cancelIds.indexOf(id) === -1),
        },
      });
    },
    *updateAlarmStatus ({ payload: { id: alarmRecordId, cb } }, { call, select }) {
      const { alarmData: { stationId, level: alarmLevel }, serviceProviderId } = yield select(state => ({
        alarmData: state.alarmpopups.alarmList.filter(({ id }) => id === alarmRecordId)[0] || {},
        serviceProviderId: state.user.serviceProviderId,
      }));
      const map = {};
      map[alarmRecordId] = 2;
      const res = yield call(service.updateAlarmRecordDealStatus, { updateMap: map });
      if (responseSuccess(res)) yield call(wsocket.publish, `alarmHandled_${serviceProviderId}`, { alarmRecordId, stationId, alarmLevel, dealStatus: 2 });
      if (cb) yield call(cb, res);
    },
    *removeAlarm ({ payload: { id } }, { put }) {
      yield put({ type: 'removeAlarmFromList', payload: id });
    },
    // 订阅websocket
    *subscribe ({ payload }, { call, select, take }) {
      let id = yield select(state => state.user.serviceProviderId);
      // if (!id) {
      //   yield take('USER_CHANGE');
      // }
      id = yield select(state => state.user.serviceProviderId);
      yield call(wsocket.registerTopic, id, 'alarmpopups/receiveAlarmPush');
      yield call(wsocket.registerTopic, `alarmHandled_${id}`, 'alarmpopups/receiveAlarmHandledMsg');

      // let serviceProviderIds = yield select(state => state.facilitators.codes);

      // if (!serviceProviderIds) {
      //   yield take('USER_CHANGE');
      // }
      // serviceProviderIds = yield select(state => state.facilitators.codes);
      // const idList = serviceProviderIds.split(',');
      // yield idList.reduce((arr, id) => {
      //   arr.push(call(wsocket.registerTopic, id, 'alarmpopups/receiveAlarmPush'));
      //   arr.push(call(wsocket.registerTopic, `alarmHandled_${id}`, 'alarmpopups/receiveAlarmHandledMsg'));
      //   return arr;
      // }, []);
    },
    // 接收到告警信息
    *receiveAlarmPush ({ payload }, { put, select, call, fork, cancel }) {
      const { hash: stationHash } = yield select(state => state.station);
      const originAlarmData = JSON.parse(payload);
      const alarmData = transformAlarmItem(originAlarmData);
      const { stationId, id: alarmRecordId } = alarmData;
      const { curList, ts } = yield select(({ alarmpopups: { alarmList, timers: ts } }) => ({ curList: alarmList, ts }));
      let timers = ts;
      if (!stationHash[stationId]) return;

      // 读取告警设置
      const { settings = {}, getAudioInfo } = yield select(state => ({ settings: state.alarmSettings, getAudioInfo: state.alarmDic.getAudioInfo }));
      const { level: alarmLevel } = alarmData;
      const setting = settings[alarmLevel] || {};
      const { popup: showPopup = false, voice: playAudio = false, time = 0, audioType = '' } = setting;

      if (playAudio) {
        yield put({ type: 'PLAY_ALARM_AUDIO', payload: { audioSrc: getAudioInfo(audioType).src, playTime: time, alarmLevel: `${alarmLevel}` } });
      }

      if (showPopup && time) {
        const getSameAlarmIdx = (list, data) => {
          const flagList = list.map(({ deviceId, ruleId }) => `${deviceId}||${ruleId}`);
          const flag = `${data.deviceId}||${data.ruleId}`;
          return flagList.indexOf(flag);
        };
        // const logStartTime = moment();
        // 如果弹窗中有同设备同规则名称的告警，则合并然后重置弹窗计时及铃声
        const nextIdx = getSameAlarmIdx(curList, alarmData);
        let newList;
        if (nextIdx !== -1) {
          newList = curList.map((o, idx) => (idx === nextIdx ? alarmData : o));
          const prevAlarmDataId = curList[nextIdx].id;
          const targetTimers = timers.filter(timer => timer.alarmRecordId === prevAlarmDataId);
          // console.log(prevAlarmDataId);
          yield [
            targetTimers.map(timer => cancel(timer.task)),
          ];
          timers = timers.filter(timer => timer.alarmRecordId !== prevAlarmDataId);
        } else {
          newList = [alarmData, ...curList];
        }

        const task = yield fork(function* microtask () {
          yield call(sleep, time * 1000);
          // console.log(`${alarmData.id} ${alarmData.level} start:${logStartTime.format('HH:mm:ss')} end:${moment().format('HH:mm:ss')} spend: ${(moment() - logStartTime) / 1000}`);
          const { list: nextTimers, isVisiting } = yield select(({ alarmpopups }) => ({ list: alarmpopups.timers.filter(timer => timer.alarmRecordId !== alarmRecordId), isVisiting: alarmpopups.isVisiting }));

          if (nextTimers.length === 0 && !isVisiting) {
            yield put({ type: 'clearList' });
          } else {
            yield put({ type: 'update', payload: { timers: nextTimers } });
          }
        });

        // 更新当前告警list
        yield put({ type: 'update', payload: { alarmList: newList, timers: [...timers, { alarmRecordId, task }] } });
      }
    },
    *receiveAlarmHandledMsg ({ payload: { alarmRecordId, stationId, alarmLevel } }, { put }) {
      yield put({ type: 'removeAlarmFromList', payload: alarmRecordId });
    },
  },
  subscriptions: {},
};
