import React from 'react';
import { Modal } from 'antd';
import { Button } from '@ui';
import css from './index.scss';

class ModalComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { type, loading = false } = this.props;
    let footer = [];
    // 显示单个按钮
    if (type === 'single') {
      footer = (
        <span className={css.selfBtnContainer}>
          <span>
            <Button
              type="default"
              onClick={this.props.onCancelBtn || this.props.onCancel}
            >{this.props.cancelText}</Button>
          </span>
        </span>);
    } else {
      footer = (
        <span className={css.selfBtnContainer}>
          <span>
            <Button
              type="primary"
              onClick={this.props.onOk}
              disabled={loading}
            >{this.props.okText}</Button>
          </span>
          <span>
            <Button
              type="default"
              onClick={this.props.onCancelBtn || this.props.onCancel}
              disabled={loading}
            >{this.props.cancelText}</Button>
          </span>
        </span>);
    }
    return (
      <Modal
        centered
        onCancel={loading ? () => { } : this.props.onCancel}
        maskClosable={false}
        wrapClassName={`${css.modalComponent} ${this.props.wrapClassName || ''}`
        }
        // 如果用户自己传了footer就用用户自己传的，不传就用默认的两个按钮
        footer={this.props.footer === null ? null : this.props.footer || footer}
        {...this.props}
      />
    );
  }
}
export default ModalComponent;
