import React from 'react';
import { Modal } from 'antd';
import { noop } from 'lodash';
import SvgIcon from '../SvgIcon';
import Button from '../Button';
import css from './Confirm.scss';

class Confirm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      callback: noop,
      type: '',
      title: '',
      message: '',
    };
  }

  onOk = () => {
    this.state.callback(true);
    this.setState({ visible: false, callback: noop });
  }

  onCancel = () => {
    this.state.callback(false);
    this.setState({ visible: false, callback: noop });
  }

  typedModal = type => (title, message) => {
    return new Promise((resolve) => {
      this.setState({ type, visible: true, title, message, callback: resolve });
    });
  }

  mapTypeToResources = (type) => {
    switch (type) {
      case 'confirm':
        return {
          icon: <SvgIcon className={[css.icon, css.confirm]} xlinkHref="xunwen" />,
          footer: [
            <Button type="primary" onClick={this.onOk} className={css.btn}>确定</Button>,
            <Button onClick={this.onCancel} className={css.btn}>取消</Button>,
          ],
        };
      case 'error':
        return {
          icon: <SvgIcon className={[css.icon, css.error]} xlinkHref="shibai" />,
          footer: [
            <Button onClick={this.onCancel} className={css.btn}>关闭</Button>,
          ],
        };
      case 'info':
      default:
        return {
          icon: <SvgIcon className={[css.icon, css.info]} xlinkHref="xinxitishi" />,
          footer: [
            <Button onClick={this.onCancel} className={css.btn}>关闭</Button>,
          ],
        };
    }
  }

  render() {
    const { icon, footer } = this.mapTypeToResources(this.state.type);
    return (
      <Modal
        visible={this.state.visible}
        wrapClassName={css.messageComponent}
        width={384}
        maskClosable={false}
        closable={false}
        footer={footer}
      >
        {contentTemplate(icon, this.state.title, this.state.message)}
        <span onClick={this.onCancel} className={css.customClose}><SvgIcon xlinkHref="gongnengtubiao_guanbi" /></span>
      </Modal>);
  }
}

export default Confirm;

function contentTemplate(icon, title, content) {
  return (
    <div className={css.container}>
      <div className={css.left}>{icon}</div>
      <div className={css.right}>
        <div className={css.title}>{title}</div>
        <div className={css.content}>{content}</div>
      </div>
    </div>
  );
}
