import { getData } from '@/services/request';

/**
 * 获取 服务端维护的字段表
 */
const hash = {};
export async function getListDicConstantCache({ dicType }) {
  let fres = {};

  if (!dicType) return [fres, {}];
  if (hash[dicType]) return [hash[dicType], {}];

  let res = {};

  if (dicType === 'queryAllIndustry') {
    [fres] = await queryAllIndustry();
  } else {
    res = await getData('assetManagement', 'getListDicConstant', {
      dicType,
    });

    if (res.code === null) {
      fres = res.dataList.reduce((acc, item) => {
        acc[item.dicValue] = item.dicName;
        return acc;
      }, {});
    }
  }

  hash[dicType] = fres;

  return [fres, res];
}

export async function queryAllIndustry() {
  let fres = [];

  const res = await getData('assetManagement', 'queryAllIndustry');

  if (res.code === null) {
    try {
      const d = JSON.parse(res.data || '[]');
      fres = d.map((item) => {
        return {
          name: item.industryName,
          value: item.industryCode,
          children: (item.childrenIndustrys || []).map((item2) => {
            return {
              name: item2.industryName,
              value: item2.industryCode,
              parent: item,
            };
          }),
        };
      });
    } catch (e) {
      console.log(e);
    }
  }

  return [fres, res];
}
