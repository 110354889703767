import { getData, getDataParti } from "@/services/request";
import * as storage from "@/services/storage";
import { readProp } from "@/utils/tool";
import { choose as pickCustomer } from "@/components/CustomerPicker";

export const logout = async () => {
  if (storage.get("token")) {
    return await getData("user", "logout");
  }
  return { code: null };
};

export const getUserInfoByToken = async () => await getData("user", "getUserInfoByToken");

export async function login(param) {
  let res;
  let customerId;
  if (!param) {
    // 视为从本地token发起的登录
    res = await getUserInfoByToken();
    customerId = storage.get("customerId") || undefined;
    localStorage.setItem("emsCustomerId", storage.get("loginCustomerId"));
  } else {
    const { username, password, exp } = param;
    res = await getData("user", "verify", { username, password, verifyType: "password", exp });
  }
  if (res.code !== null) return res;
  const userOf = readProp(res.data);
  storage.set("token", userOf("token"));
  const userInfo = await fetchEntireUserInfo(userOf("accountId"), customerId);
  localStorage.setItem("emsCustomerId", customerId);
  const data = await fillLoginInfo({ ...res.data, ...userInfo });
  if (!userInfo.name) {
    storage.remove("token");
  }
  return {
    ...res,
    data,
  };
}

async function fillLoginInfo(unfilledData = {}) {
  const data = { ...unfilledData };
  data.accoutId = data.accountId;
  data.employeeId = data.userId;
  data.employeeName = data.name;
  data.cellphone = data.phone;
  data.user = data.accountName || data.phone;

  /* 填充员工信息 */
  const employeeRes = await getData("customerManagement", "queryEmployeeInfoByAccount", { accountId: data.accountId });
  if (employeeRes.data) {
    const empOf = readProp(employeeRes.data);
    data.position = empOf("position");
    data.departmentName = empOf("departmentName");
    data.contactType = empOf("contactType");
  }
  /* 填充企业信息 */
  const customerInfo = (data.enterpriseList || []).find((customer) => customer.id === data.customerId);
  if (customerInfo) {
    const customerRes = await getData("customerManagement", "queryEnterprise", { customerId: customerInfo.id });
    const resOf = readProp(customerRes.data);
    data.companyName = customerInfo.name;
    data.shortName = customerInfo.name;
    data.companyCharacter = resOf("customerCharacter", "").split(",");
    data.isStation =
      !!data.companyCharacter.find((v) => v === "10002" || v === "10014") &&
      !data.companyCharacter.find((v) =>
        ["10003", "10004", "10005", "10006", "10007", "10008", "10010"].find((t) => t === v)
      );
    data.address = resOf("address");
    data.logo = resOf("logo");
    if (data.isStation) {
      // 企业
      data.serviceProvidorId = resOf("serviceProviderId");
      data.serviceProvidorName = resOf("serviceProviderName", resOf("serviceProviderShortName"));
      data.serviceProvidorShortName = resOf("serviceProviderShortName");
    } else {
      data.serviceProvidorId = resOf("customerId");
      data.serviceProvidorName = resOf("serviceProviderName", resOf("serviceProviderShortName"));
      data.serviceProvidorShortName = resOf("serviceProviderShortName");
    }
  }

  return data;
}

/**
 * 查询用户相关的企业列表
 * @param {string} accountId 账号ID
 */
export const getEnterpriseByAccountId = getDataParti("applicationPlatform", "getEnterpriseByAccountId");
/**
 * 查询账户名称
 * @param {string} accountId 账号ID
 */
export const queryPersonalData = getDataParti("applicationPlatform", "queryPersonalData", (accountId) => ({
  accountId,
}));
/**
 * 获取账号完整的信息
 * @param {string} accountId
 */
export async function fetchEntireUserInfo(accountId, customerId, customerType = "all") {
  const userInfoRes = await queryPersonalData(accountId);
  const userOf = readProp(userInfoRes.data);
  // const res2 = await queryEmployeesByAccountIds({ accountIds: [accountId] });
  // const userInfo = readProp(res2)('dataList.0', {});
  // 这里需要重写为具有id, name字段的对象
  const res3 = await getEnterpriseByAccountId({ accountId });
  const epList = readProp(res3)("dataList", [])
    // .filter(v => customerType === true)
    .map(
      ({
        id,
        enterpriseName: name,
        enterpriseTaxNumber: taxNumber,
        enterpriseType: type,
        enterpriseLogoImg: logo,
      }) => ({ id, name, taxNumber, type, logo })
    );

  const validCustomerId =
    (!customerId && epList.length > 0) || !epList.find((item) => item.id === customerId)
      ? await pickCustomer(undefined, epList)
      : customerId;

  return {
    ...(userInfoRes.data || {}),
    hasPassword: userOf("hasPassword") === 1,
    phoneValidated: userOf("phoneValidated") === 1,
    emailValidated: userOf("emailValidated") === 1,
    // ...pick(userInfo, ['cellphone', 'name']),
    enterpriseList: epList,
    customerId: validCustomerId,
  };
}
