const getKey = () => new Date().getTime();

const getInitialState = () => ({ audioSrc: '', audioKey: getKey(), playTime: 0, level: 0 });

const levelGTE = (levelA, levelB) => levelA >= levelB;

const MODEL_NAME = 'audio';

export default {
  namespace: MODEL_NAME,
  state: getInitialState(),
  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
    clear(state) {
      const playIns = state.playIns;
      if (playIns) window.clearTimeout(playIns);
      return getInitialState();
    },
  },
  effects: {
    *play({ payload: { audioSrc, playTime, level } }, { put, select }) {
      const prevLevel = yield select(state => state[MODEL_NAME].level);
      if(!audioSrc || !playTime) return;
      if(levelGTE(level, prevLevel)) {
        yield put({ type: 'update', payload: { audioSrc, playTime, level, audioKey: getKey() } });
      }
    },
    *monitoring(_, { put, take }) {
      while (true) {
        const action = yield take(['PLAY_AUDIO', 'STOP_AUDIO']); // action triggered
        if (action.type === 'PLAY_AUDIO') {
          yield put({ type: 'play', payload: action.payload });
        }
        if (action.type === 'STOP_AUDIO') {
          yield put({ type: 'clear' });
        }
      }
    },
  },
  subscriptions: {
    initial({ dispatch }) {
      dispatch({ type: 'monitoring' });
    },
  },
};
