const dictionary = {
  PRICETYPR: {
    0: '非季节电价',
    1: '季节电价',
  },
  ACCOUNTSTATUAS: {
    '0': '已启用',
    '1': '已禁用',
  },
  ACCOUNTTYPE: {
    '00040035': '企业',
    '00040030': '服务商',
  },
  ROLETYPE: {
    '0': '平台角色',
    '1': '用户角色',
  },
  ELEC: {
    '1': '尖',
    '2': '峰',
    '3': '平',
    '4': '谷',
  },
  SCHEMES: {
    1: '充放电周期一',
    2: '充放电周期二',
    3: '充放电周期三',
  },
  HOURS: {
    '00:00': '00:00',
    '01:00': '01:00',
    '02:00': '02:00',
    '03:00': '03:00',
    '04:00': '04:00',
    '05:00': '05:00',
    '06:00': '06:00',
    '07:00': '07:00',
    '08:00': '08:00',
    '09:00': '09:00',
    '10:00': '10:00',
    '11:00': '11:00',
    '12:00': '12:00',
    '13:00': '13:00',
    '14:00': '14:00',
    '15:00': '15:00',
    '16:00': '16:00',
    '17:00': '17:00',
    '18:00': '18:00',
    '19:00': '19:00',
    '20:00': '20:00',
    '21:00': '21:00',
    '22:00': '22:00',
    '23:00': '23:00',
    '24:00': '24:00',
  },
  INITALTABLEVALUE: [{
    lowerSoc: 0,
    upperSoc: 20,
    startTime: '00:00',
    endTime: '06:00',
    power: 80,
  }, {
    lowerSoc: 20,
    upperSoc: 40,
    startTime: '06:00',
    endTime: '12:00',
    power: 85,
  }, {
    lowerSoc: 40,
    upperSoc: 60,
    startTime: '12:00',
    endTime: '18:00',
    power: 90,
  }, {
    lowerSoc: 60,
    upperSoc: 80,
    startTime: '18:00',
    endTime: '24:00',
    power: 95,
  }],
  INITALTABLEVALUELINEONE: [
    {
      lowerSoc: '',
      upperSoc: '',
      startTime: '00:00',
      endTime: '06:00',
      power: '',
    },
  ],
};

export const energyColor = {
  500001: { name: '电', color: '#8ECCF9', unitLabel: '电量', unit: 'kWh' },
  500002: { name: '水', color: '#9CE9FC', unitLabel: '水量', unit: 'T' },
  500003: { name: '燃气', color: '#9DAEFC', unitLabel: '', unit: 'm³' },
  500004: { name: '蒸汽', color: '#9DAEFC', unitLabel: '', unit: 'T' },
  500005: { name: '热', color: '#DF9CFC', unitLabel: '', unit: 'kJ' },
  500007: { name: '能源站', color: '#DF9CFC', unitLabel: '', unit: '' }
};


export default dictionary;

