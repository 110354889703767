import Promise from 'promise';
import { getListDicConstant } from '@stroageopenapi/assets';

import alarmFaultGif from '@/assets/alarm.fault.gif';
import alarmAlarmGif from '@/assets/alarm.alarm.gif';
// import alarmWarningGif from '@assets/alarm.warning.gif';
import alarmEspecialGif from '@/assets/alarm.especial.gif';

import alarmAlertAudio from '@/assets/audio/alert.mp3';
import alarmTicktockAudio from '@/assets/audio/ticktock.mp3';
import alarmTicktickAudio from '@/assets/audio/ticktick.mp3';

export {
  queryAlarmRecord,
  queryUnDealAlarmRecord,
  updateAlarmRecordDealStatus,
  queryUnFinishAlarmRecordCountByStationIds as fetchStat,
} from '@stroageopenapi/alarm';

// 获取告警规则类型
export async function fetchAlarmRuleTypeMap () {
  const [res1, res2] = await Promise.all([
    getListDicConstant({ dicType: 103 }),
    getListDicConstant({ dicType: 104 }),
  ]);
  const list = (res1.dataList || []).concat(res2.dataList || []);
  return {
    data: list.reduce((obj, { dicValue, dicName }) => {
      obj[dicValue] = dicName;
      return obj;
    }, {}),
    code: res1.code || res2.code,
  };
}


// 告警级别(降序)
export const levelDesc = ['102005','102004', '102003', '102002', '102001', '102000'];

// 根据告警等级（事件）将告警类型置为空
export const filterAlarmStatusAccoLevels = [102001];

export const levelCNMap = {
  102001: '事件',
  // 102002: '预警',
  102003: '告警',
  102004: '故障',
  102000: '未知',
  102005: '中断'
};

export const levelENMap = {
  102000: 'unknow',
  102001: 'incident',
  // 102002: 'warning',
  102003: 'alarm',
  102004: 'fault',
  102005: 'disConnect'
};

export const levelColorMap = {
  102000: '#bbb',
  102001: '#ccd304',
  // 102002: '#ffbe0d',
  102003: '#ff862d',
  102004: '#fc3950',
  102005: '#999999'
};

export const levelGifMap = {
  102001: alarmEspecialGif,
  // 102002: alarmWarningGif,
  102003: alarmAlarmGif,
  102004: alarmFaultGif,
};

export const alarmStatusCNMap = {
  0: '未恢复',
  1: '已恢复',
};

export const dealStatusCNMap = {
  0: '未处理',
  1: '已处理',
  2: '已忽略',
};

export const dealStatusENToCode = {
  UNHANDLE: 0,
  HANDLED: 1,
  IGNORED: 2,
};

export const alarmAudioMap = {
  alarm: {
    cn: '报警',
    src: alarmAlertAudio,
  },
  ticktock: {
    cn: '滴答',
    src: alarmTicktockAudio,
  },
  ticktick: {
    cn: '滴滴',
    src: alarmTicktickAudio,
  },
};

// 判断告警优先级
export const alarmLevelLeftHigherThanRight = (levelA, levelB) => {
  const levelPriority = [...levelDesc].reverse();
  return levelPriority.indexOf(levelA) > levelPriority.indexOf(levelB);
};
