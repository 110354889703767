import { getData } from '@services/request';

export async function findCurrentAndPlaningFeePolicyByStationId (params) {
  const response = await getData('storedEnergy', 'findCurrentAndPlaningFeePolicyByStationId', { ...params });
  const dataList = response.dataList || [];
  return dataList;
}

export async function deletePriceByStationIdAndFeePolicyId (params) {
  const response = await getData('storedEnergy', 'deletePriceByStationIdAndFeePolicyId', { ...params });
  return response;
}

export async function updateFeePolicyById (params) {
  const response = await getData('storedEnergy', 'updateFeePolicyById', { ...params });
  return response;
}

export async function createFeePolicy (params) {
  const response = await getData('storedEnergy', 'createFeePolicy', { ...params });
  return response;
}

export async function findHistoryFeePolicyByStationId (params) {
  const response = await getData('storedEnergy', 'findHistoryFeePolicyByStationId', { ...params });
  const dataList = response.dataList || [];
  return dataList;
}
