import React from 'react';
import { Checkbox } from 'antd';
import styles from './Checkbox.scss';


class CheckboxComponent extends React.Component {

  static Group = Checkbox.Group;

  render() {
    const { className, ...restProps } = this.props;
    const classString = [
      restProps.disabled ? 'ant-checkbox-wrapper-disabled' : '',
      styles.checkbox,
      restProps.children ? styles.addHoverBackground : '',
      className];
    return (
      <Checkbox className={classString} {...restProps} />
    );
  }
}
export default CheckboxComponent;
