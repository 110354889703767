import axios from '@/utils/request-zdy';


const base = `/api`

export async function queryStationInfoForMoney (params) {
  return axios({
    url: `${base}/latest/dayougroup/queryStationInfoForMoney`,
    method: 'POST',
    data: {
      ...params
    }
  });
}

export async function queryStationInfoForCustomerDefinitionApi (params) {
  return axios({
    url: `${base}/latest/dayougroup/queryStationInfoForCustomerDefinition`,
    method: 'POST',
    data: {
      ...params
    }
  });
}


export async function saveJsonApi (params) {
  return axios({
    url: `${base}/latest/dayougroup/saveJson`,
    method: 'POST',
    data: {
      ...params
    }
  });
}

// 查询所有省份
export async function queryAllProvinceApi () {
  return axios({
    url: `${base}/latest/address/queryAllProvince`,
    method: 'POST',
    // data: {
    //   ...params
    // }
  });
}

// 查询光伏下面场站所在的省份
export async function queryAreaByEnterpriseld (params) {
  return axios({
    url: `${base}/latest/dayougroup/queryAreaByEnterpriseId`,
    method: 'POST',
    data: {
      ...params
    }
  });
}



//企业底下光伏站点总览
export async function queryPVTotalViewApi (params) {
  return axios({
    url: `${base}/latest/dayougroup/queryPVTotalView`,
    method: 'POST',
    data: {
      ...params
    }
  });
}


export async function queryPVDistributionApi (params) {
  return axios({
    url: `${base}/latest/dayougroup/queryPVDistribution`,
    method: 'POST',
    data: {
      ...params
    }
  });
}

// 获取天气信息


// 企业底下光伏站点告警查询
export async function queryWeatherDataApi (params) {
  return axios({
    url: `${base}/latest/weather/queryToDayWeather`,
    method: 'POST',
    data: {
      ...params
    }
  });
}


// 企业底下光伏站点告警查询
export async function queryAlarmListByStationIdsApi (params) {
  if (Array.isArray(params.stationIds) && params.stationIds.length && params.stationIds[0] === null) {
    params.stationIds = []
  }
  return axios({
    url: `${base}/latest/dayougroup/queryAlarmList`,
    method: 'POST',
    data: {
      ...params
    }
  });
}

//统计报表
export async function stationStatisticsReportApi (params) {
  return axios({
    url: `${base}/latest/dayougroup/stationStatisticsReport`,
    method: 'POST',
    data: {
      ...params
    }
  });
}

//统计报表-导出
export async function exportStationStatisticsReportApi (params) {
  return axios({
    url: `${base}/latest/dayougroup/exportStationStatisticsReport`,
    method: 'POST',
    data: {
      ...params
    }
  });
}
