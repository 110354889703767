/**
 * 告警统计数据
 */
import moment from 'moment';
import * as service from '@services/storage/alarm';
import * as wsocket from '@services/storage/socketCluster';

const responseSuccess = res => res && res.code === null;

const itemReducer = (srcArr) => {
  const destArr = [];
  let hashMap = {};
  // 告警统计接口中用的字段名称对应的告警级别编码
  const interfaceCountNameToCode = {
    malfunctionCount: 102004,
    warningCount: 102003,
    earlyWarningCount: 102002,
    eventCount: 102001,
  };
  // const interfaceCountNameToEN = Object.entries(interfaceCountNameToCode)
  //   .reduce((obj, [key, code]) => {
  //     obj[key] = service.levelENMap[code];
  //     return obj;
  //   }, {});
  // const levelEnum = { 1: 'prewarnCount', 2: 'warnCount', 3: 'dangerCount' };
  srcArr.forEach((obj) => {
    const key = obj.stationId;
    if (!hashMap[key]) {
      hashMap[key] = {
        stationId: key,
        countMap: { count: 0 },
      };
      destArr.push(hashMap[key]);
    }
    const hashItem = hashMap[key];
    hashItem.countMap.count += obj.count || 0;
  });
  hashMap = null;
  return destArr;
};

const transformAlarmItem = obj => ({
  id: obj.alarmRecordId,
  stationId: obj.stationId,
  level: obj.infoCategory,
  stationName: obj.stationName,
  deviceName: obj.monitoredObjectName,
  ruleName: obj.alarmRuleTypeName,
  date: obj.lastHappenTime * 1,
  levelEN: service.levelENMap[obj.infoCategory],
  originData: obj,
  isOperationStations: obj.isOperationStations,
});

let init = false;
let referenceCount = 0;

const initialData = () => ({
  statisticList: [],
  alarmList: [],
  haveNewAlarm: false,
});

export default {
  namespace: 'alarmStat',
  state: initialData(),
  reducers: {
    update (state, { payload }) {
      return { ...state, ...payload };
    },
    updateStatistic (state, { payload: list }) {
      return { ...state, statisticList: list };
    },
    updateAlarmList (state, { payload: list }) {
      return {
        ...state,
        alarmList: list,
      };
    },
    clear () {
      return initialData();
    },
  },
  effects: {
    *fetch (_, { put, take, select }) {
      while (true) {
        const { list } = yield select(state => state.station);
        const { type, payload } = yield take(['POWERSTATION_CHANGE', 'SOCKET_ALARM_RECEIVED']); // action triggered
        if (referenceCount === 0) {
          yield put({ type: 'clear' });
        } else {
          if (type === 'SOCKET_ALARM_RECEIVED' && payload) {
            const { stationId } = JSON.parse(payload);
            const isAuthorizedStation = list.some(item => item.stationId === stationId);
            if (isAuthorizedStation) {
              const { statisticList } = yield select(state => state.alarmStat);
              yield put({ type: 'updateStatistic', payload: [...statisticList] });
              yield put({ type: 'update', payload: { haveNewAlarm: true } });
            }
          }

          yield put({ type: 'fetchLatest' });
          // yield put({ type: 'fetchLatestAlarmRecords' });
        }
      }
    },
    // 告警查询
    fetchLatest: [
      function* fetchLatest ({ payload }, { call, put, select }) {
        // console.info(`[${moment().format('HH:mm:ss SSS')}]try to update model: alarmStat`);
        const { isStation, customerId } = yield select(state => state.user);
        const { list: stations } = yield select(state => state.station);
        if (!stations.length) {
          yield put({ type: 'updateStatistic', payload: itemReducer([]) });
          return;
        }
        const res = yield call(service.fetchStat, { isStation, id: customerId, stationIds: stations.map(({ id }) => id) });
        // if (!dataList) dataList = [];
        yield put({ type: 'updateStatistic', payload: itemReducer(res.dataList || []) });
      },
      { type: 'throttle', ms: 500 },
      // { type: 'takeLatest' },
    ],
    // fetchLatestAlarmRecords: [
    //   function* fetchLatestAlarmRecords(_, { call, put, select }) {
    //     const { list: stations, byId } = yield select(state => state.station);
    //     const res = yield call(
    //       service.queryUnDealAlarmRecord,
    //       {
    //         stationIds: stations.map(s => s.id),
    //         startTime: moment(new Date('2000-01-01')).unix(),
    //         endTime: moment().unix(),
    //         page: 1,
    //         length: 5,
    //         fristHappenTimeOrderType: 1,
    //         currentTimeOrderType: 1,
    //         orderPriority: 1,
    //       },
    //     );
    //     const dataList = (res.dataList || [])
    //       .map(o => ({ ...o, isOperationStations: byId[o.stationId].isOperationStations }))
    //       .filter(({ dealStatus }) => dealStatus === service.dealStatusENToCode.UNHANDLE);
    //     yield put({ type: 'updateAlarmList', payload: dataList.map(transformAlarmItem) });
    //   },
    //   { type: 'throttle', ms: 500 },
    //   // { type: 'takeLatest' },
    // ],
    *updateAlarmStatus ({ payload: { id: alarmRecordId, cb } }, { call, select }) {
      const { alarmData: { stationId, level: alarmLevel }, serviceProviderId } = yield select(state => ({
        alarmData: state.alarmStat.alarmList.filter(({ id }) => id === alarmRecordId)[0] || {},
        serviceProviderId: state.user.serviceProviderId,
      }));
      const map = {};
      map[alarmRecordId] = 2;
      const res = yield call(service.updateAlarmRecordDealStatus, { updateMap: map });
      if (responseSuccess(res)) yield call(wsocket.publish, `alarmHandled_${serviceProviderId}`, { alarmRecordId, stationId, alarmLevel, dealStatus: 2 });
      if (cb) yield call(cb, res);
    },
    *receiveAlarmHandledMsg ({ payload: { alarmRecordId, stationId, alarmLevel } }, { select, put }) {
      // const { alarmList/* , statisticList */ } = yield select(state => state.alarmStat);
      // if (alarmList.some(({ id }) => id === alarmRecordId)) {
      //   yield put({ type: 'fetchLatestAlarmRecords' });
      // }
      // statisticList.some(({ stationId: sId, countMap }) => {
      //   if (sId === stationId) {
      //     countMap[alarmLevel] -= 1;
      //     return true;
      //   }
      //   return false;
      // });
      // yield put({ type: 'updateStatistic', payload: [...statisticList] });
      yield put({ type: 'fetchLatest' });
    },
    // 订阅websocket
    *subscribe ({ payload }, { call, select, take }) {
      let id = yield select(state => state.user.serviceProviderId);
      if (!id) {
        yield take('USER_CHANGE');
      }
      id = yield select(state => state.user.serviceProviderId);
      // yield call(wsocket.registerTopic, id, 'SOCKET_ALARM_RECEIVED');
      yield call(wsocket.registerTopic, `alarmHandled_${id}`, 'alarmStat/receiveAlarmHandledMsg');
      yield call(wsocket.registerTopic, `/storedEnergy/alarmChanged/${id}`, 'SOCKET_ALARM_RECEIVED'); // 该服务商下是否有产生告警      

      // let serviceProvidorIds = yield select(state => state.facilitators.codes);
      // if (!serviceProvidorIds) {
      //   yield take('USER_CHANGE');
      // }
      // serviceProvidorIds = yield select(state => state.facilitators.codes);
      // const idList = serviceProvidorIds.split(',');
      // yield idList.reduce((arr, id) => {
      //   arr.push(call(wsocket.registerTopic, id, 'SOCKET_ALARM_RECEIVED'));
      //   arr.push(call(wsocket.registerTopic, `alarmHandled_${id}`, 'alarmStat/receiveAlarmHandledMsg'));
      //   return arr;
      // }, []);
    },
    *sub ({ payload }, { put, select }) {
      referenceCount += 1;
      if (init === false) {
        yield put({ type: 'fetchLatest' });
        yield put({ type: 'subscribe' });
        yield put({ type: 'fetch' });
        init = true;
        return;
      }
      const stateArr = yield select(state => state.alarmStat);
      if (stateArr.length === 0) {
        yield put({ type: 'fetchLatest' });
      }
    },
    *unsub () {
      referenceCount -= 1;
      if (referenceCount === 0) {
        init = false;
      }
    },
  },
  subscriptions: {
    setup () {
      init = false;
      referenceCount = 0;
      return () => {
        init = false;
        referenceCount = 0;
      };
    },
  },
};
