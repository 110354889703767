import { detect } from 'detect-browser';


const defaultMinWidth = 0;  // 1920 - 17 - 3
const defaultMinHeight = 576 + 50;  // 768 - (chrome tab+地址栏+书签栏  不包括底部下载栏),

const defaultLoadingKeys = {
  models: [],
  effects: [
    'app/init',
  ],
};

export default {
  state: {
    browser: {},
    os: {},

    minWidth: defaultMinWidth,
    minHeight: defaultMinHeight,

    loadingKeys: defaultLoadingKeys,
  },

  reducers: {
    update(state, { payload }) {
      return { ...state, ...payload };
    },
  },

  effects: {
    *requestMinView({ payload: { minHeight, minWidth, reset = false } = {} }, { put }) {
      if (reset) {
        yield put({
          type: 'update',
          payload: {
            minWidth: defaultMinWidth,
            minHeight: defaultMinHeight,
          },
        });

        return;
      }
      if (minWidth) yield put({ type: 'update', payload: minWidth });
      if (minHeight) yield put({ type: 'update', payload: minHeight });
    },

    *requestLoadingKeys({ payload: { models = [], effects = [] } = {} }, { put }) {
      if (Array.isArray(models) && Array.isArray(effects)) {
        yield put({
          type: 'update',
          payload: {
            loadingKeys: {
              models: [
                ...models,
                ...defaultLoadingKeys.models,
              ],
              effects: [
                ...effects,
                ...defaultLoadingKeys.effects,
              ],
            },
          },
        });
      } else {
        yield put({
          type: 'update',
          payload: {
            loadingKeys: defaultLoadingKeys,
          },
        });
      }
    },
  },

  subscriptions: {
    initUserAgent({ dispatch }) {
      const browser = detect() || {};
      const body = document.body;
      browser.version = window.parseInt(browser.version);

      const osArr = (browser.os || '').split(/\s/);
      const [osName = '', osVersion = ''] = osArr;
      const os = { name: osName.toLowerCase(), version: osVersion.toLowerCase() };

      dispatch({ type: 'update', payload: { browser, os } });

      // add class for style hacks
      // IE does not support multiple parameters for the add() & remove() methods
      body.classList.add(browser.name);
      body.classList.add(`${browser.name}${browser.version}`);
      body.classList.add(os.name);
      body.classList.add(`${os.name}${os.version}`);
    },
  },
};
