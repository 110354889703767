import { getData } from '@services/request';

// 异常告警 - 查询列表
export async function queryList ({ page, length }) {
  let fres = [];
  let totalCount = 0;
  const res = await getData('storedEnergy', 'findExportFile', { page, length });

  if (res.code === null) {
    fres = (res.dataList || []).map((item, idx) => {
      return {
        ...item,
        key: idx,
        id: item.id,
        applyTime: item.sysCreateTime,
        name: item.name,
        size: item.size,
        flag: item.state,
      };
    });
    totalCount = res.totalCount;
  }

  return [fres, totalCount, res];
}
