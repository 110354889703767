import { getDataParti } from '@/services/request';

const environment = action => getDataParti('environment', action);

/**
 * 获取websocket地址
 */
export const getWebSocketOption = environment('getWebSocketOption');

/**
 * 获取mqtt地址
 */
export const getMQTTOption = environment('getMqttSocketOption');

/**
 * 获取关联平台URL
 * @param {string} platform 关联平台的关键字
 */
export const getPlatformUrl = platform => environment('getPlatformUrl')({ platform });
/**
 * 获取指定地区名的天气
 */
export const getWeatherByAreaName = areaName => environment('getWeatherByAreaName')({ areaName });
/**
 * 获取指定地区code的天气
 */
export const getWeatherByAreaCode = areaCode => environment('getWeatherByAreaCode')({ areaCode });
